import { useLazyQuery } from '@apollo/client';
import { GqlIndexUsers } from '@monorepo/graphql/resources';
import SearchField from './SearchField';
import { useCallback } from 'react';
import { UserFragmentFragment } from '@monorepo/graphql';
import { FiUsers } from 'react-icons/fi';

interface Props {
  onSelect: (user: UserFragmentFragment) => void;
  defaultValue?: string;
  useFormik?: boolean;
  name?: string;
  hideLabel?: boolean;
  required?: boolean;
}

const UserSearchField = ({
  onSelect,
  defaultValue,
  name = 'customer',
  useFormik,
  hideLabel,
  required,
}: Props) => {
  const [fetchUsers] = useLazyQuery(GqlIndexUsers);

  const fetchSuggestions = useCallback(
    async (term: string) => {
      const { data } = await fetchUsers({
        variables: {
          input: {
            pagination: null,
            term,
          },
        },
      });

      return data?.indexUsers.items ?? [];
    },
    [fetchUsers]
  );

  return (
    <SearchField
      label={!hideLabel ? 'User' : undefined}
      placeholder="Search for a user"
      fetchSuggestions={fetchSuggestions}
      name={name}
      useFormik={useFormik}
      idProp="uuid"
      labelProp="displayName"
      onSelect={onSelect}
      defaultValue={defaultValue}
      className="mb-5"
      Icon={FiUsers}
      required={required}
    />
  );
};

export default UserSearchField;
