import classNames from 'classnames';
import { elements } from '../utility/styles';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import Loader from './Loader';
import Input from './Input';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { FiSearch } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { Button } from './Button';

interface Props<T extends Record<string, unknown>> {
  fetchSuggestions: (val: string) => Promise<T[]> | T[];
  name: string;
  placeholder: string;
  defaultValue?: string;
  loading?: boolean;
  onSelect: (suggestion: T) => void;
  idProp?: keyof T;
  labelProp: keyof T | ((arg: T) => string);
  label?: string;
  onInput?: (value: string) => void;
  useFormik?: boolean;
  className?: string;
  labelClassNames?: string;
  Icon?: IconType;
  required?: boolean;
  noSuggestionsText?: ReactNode;
  controls?: ReactNode;
}

function SearchField<T extends Record<string, unknown>>({
  fetchSuggestions,
  name,
  label,
  controls,
  loading,
  placeholder,
  onSelect,
  defaultValue,
  idProp,
  labelProp,
  onInput,
  useFormik,
  className,
  labelClassNames,
  Icon = FiSearch,
  required,
  noSuggestionsText = 'No suggestions found',
}: Props<T>) {
  const [suggestions, setSuggestions] = useState<T[] | null>(null);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const onInput1Change = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const val = e.currentTarget.value;

      onInput?.(val);

      setValue(val);
      if (timer.current) {
        clearTimeout(timer.current);
      }
      if (val.length > 2) {
        timer.current = setTimeout(async () => {
          const fetchedSuggestions = await fetchSuggestions(val);

          setSuggestions(fetchedSuggestions);
        }, 500);
      } else {
        setSuggestions(null);
      }
    },
    [fetchSuggestions, onInput]
  );

  const ulRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const onWindowClick: EventListener = (e) => {
      const path =
        (e as unknown as { path?: HTMLElement[] }).path ?? e.composedPath();

      const inPath = ulRef.current && path.includes(ulRef.current);
      if (!inPath) {
        setSuggestions(null);
      }
    };

    window.addEventListener('click', onWindowClick);
    return () => {
      window.removeEventListener('click', onWindowClick);
    };
  }, []);
  const [, setValue] = useState<string>('');
  const onSelectSuggestion = (s: T) => {
    setSuggestions(null);

    onSelect(s);

    setValue(
      labelProp instanceof Function ? labelProp(s) : (s[labelProp] as string)
    );
  };

  return (
    <Menu as="div" className={classNames(className, 'relative w-full')}>
      {!!label && (
        <div className="flex items-center mb-2 justify-between">
          {!!label && (
            <div className={classNames('flex items-center', labelClassNames)}>
              <label className={classNames(elements.inputLabel, 'lg:mb-0')}>
                {label}
              </label>
            </div>
          )}
          {controls}
        </div>
      )}
      <MenuButton as="div" className="w-full">
        <Input
          className="w-full"
          placeholder={placeholder}
          name={name}
          value={defaultValue}
          onInput={onInput1Change}
          required={required}
          useFormik={!!useFormik}
          onKeyDown={(e) => e.stopPropagation()}
          Icon={Icon}
        />
      </MenuButton>
      <MenuItems
        anchor={{
          to: 'bottom start',
          gap: 12,
          padding: 12,
        }}
        className="rounded-md bg-white shadow-sm ring-1 py-2 ring-black ring-opacity-5 focus:outline-none overflow-x-hidden flex flex-col w-[--button-width] empty:hidden"
        static
      >
        {suggestions ? (
          suggestions.length > 0 ? (
            suggestions.map((suggestion, i) => (
              <MenuItem
                as="li"
                key={idProp ? (suggestion[idProp] as string) : i}
                className="cursor-pointer text-sm list-none hover:bg-red/10"
              >
                <Button
                  type="button"
                  onClick={() => onSelectSuggestion(suggestion)}
                  className="w-full text-left px-4 py-2 "
                >
                  {labelProp instanceof Function
                    ? labelProp(suggestion)
                    : (suggestion[labelProp] as string)}
                </Button>
              </MenuItem>
            ))
          ) : (
            <MenuItem as="li" className="p-2 text-sm list-none">
              {noSuggestionsText}
            </MenuItem>
          )
        ) : (
          loading && (
            <MenuItem
              as="li"
              className="p-2 text-sm flex items-center justify-center list-none"
            >
              <Loader size="1.5rem" />
            </MenuItem>
          )
        )}
      </MenuItems>
    </Menu>
  );
}

export default SearchField;
