import { useLazyQuery } from '@apollo/client';
import { GqlIndexProducts } from '@monorepo/graphql/resources';
import { useCallback } from 'react';
import SearchField from './SearchField';
import { IndexProductsQuery } from '@monorepo/graphql';
import { FiTag } from 'react-icons/fi';

interface Props {
  onSelect: (product: IndexProductsQuery['indexProducts']['items'][0]) => void;
  onInput?: (value: string) => void;
  defaultValue?: string;
  name?: string;
  useFormik?: boolean;
  labelClassNames?: string;
  label?: string;
  controls?: React.ReactNode;
}
const labelProp = (product: IndexProductsQuery['indexProducts']['items'][0]) =>
  `${product.name} - ${product.sku}`;

const ProductSearchField = ({
  onSelect,
  onInput,
  defaultValue,
  name = 'product',
  useFormik,
  labelClassNames,
  label = 'Product',
  controls,
}: Props) => {
  const [fetchProducts, { loading }] = useLazyQuery(GqlIndexProducts, {
    fetchPolicy: 'network-only'
  });

  const fetchSuggestions = useCallback(
    async (term: string) => {
      const { data } = await fetchProducts({
        variables: {
          input: {
            orderBy: null,
            pagination: null,
            term,
          },
        },
      });

      return data?.indexProducts.items ?? [];
    },
    [fetchProducts]
  );

  return (
    <SearchField
      label={label}
      placeholder="Search for existing product"
      fetchSuggestions={fetchSuggestions}
      loading={loading}
      name={name}
      useFormik={useFormik}
      labelProp={labelProp}
      onSelect={onSelect}
      onInput={onInput}
      defaultValue={defaultValue}
      labelClassNames={labelClassNames}
      className="mb-5"
      Icon={FiTag}
      controls={controls}
    />
  );
};

export default ProductSearchField;
