/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as UnauthenticatedImport } from './routes/_unauthenticated'
import { Route as NeutralImport } from './routes/_neutral'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as NeutralIndexImport } from './routes/_neutral/index'
import { Route as UnauthenticatedLoginImport } from './routes/_unauthenticated/login'
import { Route as NeutralCartImport } from './routes/_neutral/cart'
import { Route as AuthenticatedMyAccountImport } from './routes/_authenticated/my-account'
import { Route as AuthenticatedAdminImport } from './routes/_authenticated/admin'
import { Route as UnauthenticatedLoginIndexImport } from './routes/_unauthenticated/login/index'
import { Route as NeutralCheckoutIndexImport } from './routes/_neutral/checkout/index'
import { Route as AuthenticatedMyAccountIndexImport } from './routes/_authenticated/my-account/index'
import { Route as AuthenticatedAdminIndexImport } from './routes/_authenticated/admin/index'
import { Route as UnauthenticatedLoginResetPasswordImport } from './routes/_unauthenticated/login/reset-password'
import { Route as NeutralCheckoutOrderUuidImport } from './routes/_neutral/checkout/$orderUuid'
import { Route as NeutralCheckoutQuoteIndexImport } from './routes/_neutral/checkout/quote/index'
import { Route as AuthenticatedMyAccountQuotesIndexImport } from './routes/_authenticated/my-account/quotes/index'
import { Route as AuthenticatedMyAccountPaymentMethodsIndexImport } from './routes/_authenticated/my-account/payment-methods/index'
import { Route as AuthenticatedMyAccountOrdersIndexImport } from './routes/_authenticated/my-account/orders/index'
import { Route as AuthenticatedMyAccountEditAccountIndexImport } from './routes/_authenticated/my-account/edit-account/index'
import { Route as AuthenticatedAdminUsersIndexImport } from './routes/_authenticated/admin/users/index'
import { Route as AuthenticatedAdminQuotesIndexImport } from './routes/_authenticated/admin/quotes/index'
import { Route as AuthenticatedAdminOrdersIndexImport } from './routes/_authenticated/admin/orders/index'
import { Route as AuthenticatedAdminCustomersIndexImport } from './routes/_authenticated/admin/customers/index'
import { Route as AuthenticatedAdminCouponsIndexImport } from './routes/_authenticated/admin/coupons/index'
import { Route as UnauthenticatedLoginConfirmPasswordTokenImport } from './routes/_unauthenticated/login/confirm-password/$token'
import { Route as NeutralCheckoutQuoteQuoteUuidImport } from './routes/_neutral/checkout/quote/$quoteUuid'
import { Route as AuthenticatedMyAccountQuotesQuoteUuidImport } from './routes/_authenticated/my-account/quotes/$quoteUuid'
import { Route as AuthenticatedMyAccountPaymentMethodsAddImport } from './routes/_authenticated/my-account/payment-methods/add'
import { Route as AuthenticatedMyAccountOrdersOrderUuidImport } from './routes/_authenticated/my-account/orders/$orderUuid'
import { Route as AuthenticatedMyAccountEditAccountEditPasswordImport } from './routes/_authenticated/my-account/edit-account/edit-password'
import { Route as AuthenticatedAdminUsersAddImport } from './routes/_authenticated/admin/users/add'
import { Route as AuthenticatedAdminUsersUserUuidImport } from './routes/_authenticated/admin/users/$userUuid'
import { Route as AuthenticatedAdminQuotesAddImport } from './routes/_authenticated/admin/quotes/add'
import { Route as AuthenticatedAdminQuotesQuoteUuidImport } from './routes/_authenticated/admin/quotes/$quoteUuid'
import { Route as AuthenticatedAdminOrdersOrderUuidImport } from './routes/_authenticated/admin/orders/$orderUuid'
import { Route as AuthenticatedAdminCustomersAddImport } from './routes/_authenticated/admin/customers/add'
import { Route as AuthenticatedAdminCustomersCustomerUuidImport } from './routes/_authenticated/admin/customers/$customerUuid'
import { Route as AuthenticatedAdminCouponsAddImport } from './routes/_authenticated/admin/coupons/add'
import { Route as AuthenticatedAdminCouponsCouponUuidImport } from './routes/_authenticated/admin/coupons/$couponUuid'
import { Route as AuthenticatedMyAccountQuotesLegacyIndexImport } from './routes/_authenticated/my-account/quotes/legacy/index'
import { Route as AuthenticatedMyAccountOrdersLegacyIndexImport } from './routes/_authenticated/my-account/orders/legacy/index'
import { Route as AuthenticatedAdminQuotesProductsIndexImport } from './routes/_authenticated/admin/quotes/products/index'
import { Route as AuthenticatedAdminQuotesMessageTemplatesIndexImport } from './routes/_authenticated/admin/quotes/message-templates/index'
import { Route as AuthenticatedAdminQuotesEmailTokensIndexImport } from './routes/_authenticated/admin/quotes/email-tokens/index'
import { Route as AuthenticatedAdminOrdersLegacyIndexImport } from './routes/_authenticated/admin/orders/legacy/index'
import { Route as AuthenticatedMyAccountQuotesLegacyIdImport } from './routes/_authenticated/my-account/quotes/legacy/$id'
import { Route as AuthenticatedMyAccountOrdersLegacyIdImport } from './routes/_authenticated/my-account/orders/legacy/$id'
import { Route as AuthenticatedAdminQuotesProductsAddImport } from './routes/_authenticated/admin/quotes/products/add'
import { Route as AuthenticatedAdminQuotesProductsProductUuidImport } from './routes/_authenticated/admin/quotes/products/$productUuid'
import { Route as AuthenticatedAdminQuotesMessageTemplatesAddImport } from './routes/_authenticated/admin/quotes/message-templates/add'
import { Route as AuthenticatedAdminQuotesMessageTemplatesTemplateUuidImport } from './routes/_authenticated/admin/quotes/message-templates/$templateUuid'
import { Route as AuthenticatedAdminOrdersLegacyIdImport } from './routes/_authenticated/admin/orders/legacy/$id'
import { Route as AuthenticatedAdminCustomersCartCustomerUuidImport } from './routes/_authenticated/admin/customers/cart/$customerUuid'

// Create/Update Routes

const UnauthenticatedRoute = UnauthenticatedImport.update({
  id: '/_unauthenticated',
  getParentRoute: () => rootRoute,
} as any)

const NeutralRoute = NeutralImport.update({
  id: '/_neutral',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const NeutralIndexRoute = NeutralIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => NeutralRoute,
} as any)

const UnauthenticatedLoginRoute = UnauthenticatedLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => UnauthenticatedRoute,
} as any)

const NeutralCartRoute = NeutralCartImport.update({
  id: '/cart',
  path: '/cart',
  getParentRoute: () => NeutralRoute,
} as any)

const AuthenticatedMyAccountRoute = AuthenticatedMyAccountImport.update({
  id: '/my-account',
  path: '/my-account',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedAdminRoute = AuthenticatedAdminImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const UnauthenticatedLoginIndexRoute = UnauthenticatedLoginIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => UnauthenticatedLoginRoute,
} as any)

const NeutralCheckoutIndexRoute = NeutralCheckoutIndexImport.update({
  id: '/checkout/',
  path: '/checkout/',
  getParentRoute: () => NeutralRoute,
} as any)

const AuthenticatedMyAccountIndexRoute =
  AuthenticatedMyAccountIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthenticatedMyAccountRoute,
  } as any)

const AuthenticatedAdminIndexRoute = AuthenticatedAdminIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthenticatedAdminRoute,
} as any)

const UnauthenticatedLoginResetPasswordRoute =
  UnauthenticatedLoginResetPasswordImport.update({
    id: '/reset-password',
    path: '/reset-password',
    getParentRoute: () => UnauthenticatedLoginRoute,
  } as any)

const NeutralCheckoutOrderUuidRoute = NeutralCheckoutOrderUuidImport.update({
  id: '/checkout/$orderUuid',
  path: '/checkout/$orderUuid',
  getParentRoute: () => NeutralRoute,
} as any)

const NeutralCheckoutQuoteIndexRoute = NeutralCheckoutQuoteIndexImport.update({
  id: '/checkout/quote/',
  path: '/checkout/quote/',
  getParentRoute: () => NeutralRoute,
} as any)

const AuthenticatedMyAccountQuotesIndexRoute =
  AuthenticatedMyAccountQuotesIndexImport.update({
    id: '/quotes/',
    path: '/quotes/',
    getParentRoute: () => AuthenticatedMyAccountRoute,
  } as any)

const AuthenticatedMyAccountPaymentMethodsIndexRoute =
  AuthenticatedMyAccountPaymentMethodsIndexImport.update({
    id: '/payment-methods/',
    path: '/payment-methods/',
    getParentRoute: () => AuthenticatedMyAccountRoute,
  } as any)

const AuthenticatedMyAccountOrdersIndexRoute =
  AuthenticatedMyAccountOrdersIndexImport.update({
    id: '/orders/',
    path: '/orders/',
    getParentRoute: () => AuthenticatedMyAccountRoute,
  } as any)

const AuthenticatedMyAccountEditAccountIndexRoute =
  AuthenticatedMyAccountEditAccountIndexImport.update({
    id: '/edit-account/',
    path: '/edit-account/',
    getParentRoute: () => AuthenticatedMyAccountRoute,
  } as any)

const AuthenticatedAdminUsersIndexRoute =
  AuthenticatedAdminUsersIndexImport.update({
    id: '/users/',
    path: '/users/',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminQuotesIndexRoute =
  AuthenticatedAdminQuotesIndexImport.update({
    id: '/quotes/',
    path: '/quotes/',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminOrdersIndexRoute =
  AuthenticatedAdminOrdersIndexImport.update({
    id: '/orders/',
    path: '/orders/',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminCustomersIndexRoute =
  AuthenticatedAdminCustomersIndexImport.update({
    id: '/customers/',
    path: '/customers/',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminCouponsIndexRoute =
  AuthenticatedAdminCouponsIndexImport.update({
    id: '/coupons/',
    path: '/coupons/',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const UnauthenticatedLoginConfirmPasswordTokenRoute =
  UnauthenticatedLoginConfirmPasswordTokenImport.update({
    id: '/confirm-password/$token',
    path: '/confirm-password/$token',
    getParentRoute: () => UnauthenticatedLoginRoute,
  } as any)

const NeutralCheckoutQuoteQuoteUuidRoute =
  NeutralCheckoutQuoteQuoteUuidImport.update({
    id: '/checkout/quote/$quoteUuid',
    path: '/checkout/quote/$quoteUuid',
    getParentRoute: () => NeutralRoute,
  } as any)

const AuthenticatedMyAccountQuotesQuoteUuidRoute =
  AuthenticatedMyAccountQuotesQuoteUuidImport.update({
    id: '/quotes/$quoteUuid',
    path: '/quotes/$quoteUuid',
    getParentRoute: () => AuthenticatedMyAccountRoute,
  } as any)

const AuthenticatedMyAccountPaymentMethodsAddRoute =
  AuthenticatedMyAccountPaymentMethodsAddImport.update({
    id: '/payment-methods/add',
    path: '/payment-methods/add',
    getParentRoute: () => AuthenticatedMyAccountRoute,
  } as any)

const AuthenticatedMyAccountOrdersOrderUuidRoute =
  AuthenticatedMyAccountOrdersOrderUuidImport.update({
    id: '/orders/$orderUuid',
    path: '/orders/$orderUuid',
    getParentRoute: () => AuthenticatedMyAccountRoute,
  } as any)

const AuthenticatedMyAccountEditAccountEditPasswordRoute =
  AuthenticatedMyAccountEditAccountEditPasswordImport.update({
    id: '/edit-account/edit-password',
    path: '/edit-account/edit-password',
    getParentRoute: () => AuthenticatedMyAccountRoute,
  } as any)

const AuthenticatedAdminUsersAddRoute = AuthenticatedAdminUsersAddImport.update(
  {
    id: '/users/add',
    path: '/users/add',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any,
)

const AuthenticatedAdminUsersUserUuidRoute =
  AuthenticatedAdminUsersUserUuidImport.update({
    id: '/users/$userUuid',
    path: '/users/$userUuid',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminQuotesAddRoute =
  AuthenticatedAdminQuotesAddImport.update({
    id: '/quotes/add',
    path: '/quotes/add',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminQuotesQuoteUuidRoute =
  AuthenticatedAdminQuotesQuoteUuidImport.update({
    id: '/quotes/$quoteUuid',
    path: '/quotes/$quoteUuid',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminOrdersOrderUuidRoute =
  AuthenticatedAdminOrdersOrderUuidImport.update({
    id: '/orders/$orderUuid',
    path: '/orders/$orderUuid',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminCustomersAddRoute =
  AuthenticatedAdminCustomersAddImport.update({
    id: '/customers/add',
    path: '/customers/add',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminCustomersCustomerUuidRoute =
  AuthenticatedAdminCustomersCustomerUuidImport.update({
    id: '/customers/$customerUuid',
    path: '/customers/$customerUuid',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminCouponsAddRoute =
  AuthenticatedAdminCouponsAddImport.update({
    id: '/coupons/add',
    path: '/coupons/add',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminCouponsCouponUuidRoute =
  AuthenticatedAdminCouponsCouponUuidImport.update({
    id: '/coupons/$couponUuid',
    path: '/coupons/$couponUuid',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedMyAccountQuotesLegacyIndexRoute =
  AuthenticatedMyAccountQuotesLegacyIndexImport.update({
    id: '/quotes/legacy/',
    path: '/quotes/legacy/',
    getParentRoute: () => AuthenticatedMyAccountRoute,
  } as any)

const AuthenticatedMyAccountOrdersLegacyIndexRoute =
  AuthenticatedMyAccountOrdersLegacyIndexImport.update({
    id: '/orders/legacy/',
    path: '/orders/legacy/',
    getParentRoute: () => AuthenticatedMyAccountRoute,
  } as any)

const AuthenticatedAdminQuotesProductsIndexRoute =
  AuthenticatedAdminQuotesProductsIndexImport.update({
    id: '/quotes/products/',
    path: '/quotes/products/',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminQuotesMessageTemplatesIndexRoute =
  AuthenticatedAdminQuotesMessageTemplatesIndexImport.update({
    id: '/quotes/message-templates/',
    path: '/quotes/message-templates/',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminQuotesEmailTokensIndexRoute =
  AuthenticatedAdminQuotesEmailTokensIndexImport.update({
    id: '/quotes/email-tokens/',
    path: '/quotes/email-tokens/',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminOrdersLegacyIndexRoute =
  AuthenticatedAdminOrdersLegacyIndexImport.update({
    id: '/orders/legacy/',
    path: '/orders/legacy/',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedMyAccountQuotesLegacyIdRoute =
  AuthenticatedMyAccountQuotesLegacyIdImport.update({
    id: '/quotes/legacy/$id',
    path: '/quotes/legacy/$id',
    getParentRoute: () => AuthenticatedMyAccountRoute,
  } as any)

const AuthenticatedMyAccountOrdersLegacyIdRoute =
  AuthenticatedMyAccountOrdersLegacyIdImport.update({
    id: '/orders/legacy/$id',
    path: '/orders/legacy/$id',
    getParentRoute: () => AuthenticatedMyAccountRoute,
  } as any)

const AuthenticatedAdminQuotesProductsAddRoute =
  AuthenticatedAdminQuotesProductsAddImport.update({
    id: '/quotes/products/add',
    path: '/quotes/products/add',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminQuotesProductsProductUuidRoute =
  AuthenticatedAdminQuotesProductsProductUuidImport.update({
    id: '/quotes/products/$productUuid',
    path: '/quotes/products/$productUuid',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminQuotesMessageTemplatesAddRoute =
  AuthenticatedAdminQuotesMessageTemplatesAddImport.update({
    id: '/quotes/message-templates/add',
    path: '/quotes/message-templates/add',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminQuotesMessageTemplatesTemplateUuidRoute =
  AuthenticatedAdminQuotesMessageTemplatesTemplateUuidImport.update({
    id: '/quotes/message-templates/$templateUuid',
    path: '/quotes/message-templates/$templateUuid',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminOrdersLegacyIdRoute =
  AuthenticatedAdminOrdersLegacyIdImport.update({
    id: '/orders/legacy/$id',
    path: '/orders/legacy/$id',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

const AuthenticatedAdminCustomersCartCustomerUuidRoute =
  AuthenticatedAdminCustomersCartCustomerUuidImport.update({
    id: '/customers/cart/$customerUuid',
    path: '/customers/cart/$customerUuid',
    getParentRoute: () => AuthenticatedAdminRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/_neutral': {
      id: '/_neutral'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof NeutralImport
      parentRoute: typeof rootRoute
    }
    '/_unauthenticated': {
      id: '/_unauthenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof UnauthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/admin': {
      id: '/_authenticated/admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof AuthenticatedAdminImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/my-account': {
      id: '/_authenticated/my-account'
      path: '/my-account'
      fullPath: '/my-account'
      preLoaderRoute: typeof AuthenticatedMyAccountImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_neutral/cart': {
      id: '/_neutral/cart'
      path: '/cart'
      fullPath: '/cart'
      preLoaderRoute: typeof NeutralCartImport
      parentRoute: typeof NeutralImport
    }
    '/_unauthenticated/login': {
      id: '/_unauthenticated/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof UnauthenticatedLoginImport
      parentRoute: typeof UnauthenticatedImport
    }
    '/_neutral/': {
      id: '/_neutral/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof NeutralIndexImport
      parentRoute: typeof NeutralImport
    }
    '/_neutral/checkout/$orderUuid': {
      id: '/_neutral/checkout/$orderUuid'
      path: '/checkout/$orderUuid'
      fullPath: '/checkout/$orderUuid'
      preLoaderRoute: typeof NeutralCheckoutOrderUuidImport
      parentRoute: typeof NeutralImport
    }
    '/_unauthenticated/login/reset-password': {
      id: '/_unauthenticated/login/reset-password'
      path: '/reset-password'
      fullPath: '/login/reset-password'
      preLoaderRoute: typeof UnauthenticatedLoginResetPasswordImport
      parentRoute: typeof UnauthenticatedLoginImport
    }
    '/_authenticated/admin/': {
      id: '/_authenticated/admin/'
      path: '/'
      fullPath: '/admin/'
      preLoaderRoute: typeof AuthenticatedAdminIndexImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/my-account/': {
      id: '/_authenticated/my-account/'
      path: '/'
      fullPath: '/my-account/'
      preLoaderRoute: typeof AuthenticatedMyAccountIndexImport
      parentRoute: typeof AuthenticatedMyAccountImport
    }
    '/_neutral/checkout/': {
      id: '/_neutral/checkout/'
      path: '/checkout'
      fullPath: '/checkout'
      preLoaderRoute: typeof NeutralCheckoutIndexImport
      parentRoute: typeof NeutralImport
    }
    '/_unauthenticated/login/': {
      id: '/_unauthenticated/login/'
      path: '/'
      fullPath: '/login/'
      preLoaderRoute: typeof UnauthenticatedLoginIndexImport
      parentRoute: typeof UnauthenticatedLoginImport
    }
    '/_authenticated/admin/coupons/$couponUuid': {
      id: '/_authenticated/admin/coupons/$couponUuid'
      path: '/coupons/$couponUuid'
      fullPath: '/admin/coupons/$couponUuid'
      preLoaderRoute: typeof AuthenticatedAdminCouponsCouponUuidImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/coupons/add': {
      id: '/_authenticated/admin/coupons/add'
      path: '/coupons/add'
      fullPath: '/admin/coupons/add'
      preLoaderRoute: typeof AuthenticatedAdminCouponsAddImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/customers/$customerUuid': {
      id: '/_authenticated/admin/customers/$customerUuid'
      path: '/customers/$customerUuid'
      fullPath: '/admin/customers/$customerUuid'
      preLoaderRoute: typeof AuthenticatedAdminCustomersCustomerUuidImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/customers/add': {
      id: '/_authenticated/admin/customers/add'
      path: '/customers/add'
      fullPath: '/admin/customers/add'
      preLoaderRoute: typeof AuthenticatedAdminCustomersAddImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/orders/$orderUuid': {
      id: '/_authenticated/admin/orders/$orderUuid'
      path: '/orders/$orderUuid'
      fullPath: '/admin/orders/$orderUuid'
      preLoaderRoute: typeof AuthenticatedAdminOrdersOrderUuidImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/quotes/$quoteUuid': {
      id: '/_authenticated/admin/quotes/$quoteUuid'
      path: '/quotes/$quoteUuid'
      fullPath: '/admin/quotes/$quoteUuid'
      preLoaderRoute: typeof AuthenticatedAdminQuotesQuoteUuidImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/quotes/add': {
      id: '/_authenticated/admin/quotes/add'
      path: '/quotes/add'
      fullPath: '/admin/quotes/add'
      preLoaderRoute: typeof AuthenticatedAdminQuotesAddImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/users/$userUuid': {
      id: '/_authenticated/admin/users/$userUuid'
      path: '/users/$userUuid'
      fullPath: '/admin/users/$userUuid'
      preLoaderRoute: typeof AuthenticatedAdminUsersUserUuidImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/users/add': {
      id: '/_authenticated/admin/users/add'
      path: '/users/add'
      fullPath: '/admin/users/add'
      preLoaderRoute: typeof AuthenticatedAdminUsersAddImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/my-account/edit-account/edit-password': {
      id: '/_authenticated/my-account/edit-account/edit-password'
      path: '/edit-account/edit-password'
      fullPath: '/my-account/edit-account/edit-password'
      preLoaderRoute: typeof AuthenticatedMyAccountEditAccountEditPasswordImport
      parentRoute: typeof AuthenticatedMyAccountImport
    }
    '/_authenticated/my-account/orders/$orderUuid': {
      id: '/_authenticated/my-account/orders/$orderUuid'
      path: '/orders/$orderUuid'
      fullPath: '/my-account/orders/$orderUuid'
      preLoaderRoute: typeof AuthenticatedMyAccountOrdersOrderUuidImport
      parentRoute: typeof AuthenticatedMyAccountImport
    }
    '/_authenticated/my-account/payment-methods/add': {
      id: '/_authenticated/my-account/payment-methods/add'
      path: '/payment-methods/add'
      fullPath: '/my-account/payment-methods/add'
      preLoaderRoute: typeof AuthenticatedMyAccountPaymentMethodsAddImport
      parentRoute: typeof AuthenticatedMyAccountImport
    }
    '/_authenticated/my-account/quotes/$quoteUuid': {
      id: '/_authenticated/my-account/quotes/$quoteUuid'
      path: '/quotes/$quoteUuid'
      fullPath: '/my-account/quotes/$quoteUuid'
      preLoaderRoute: typeof AuthenticatedMyAccountQuotesQuoteUuidImport
      parentRoute: typeof AuthenticatedMyAccountImport
    }
    '/_neutral/checkout/quote/$quoteUuid': {
      id: '/_neutral/checkout/quote/$quoteUuid'
      path: '/checkout/quote/$quoteUuid'
      fullPath: '/checkout/quote/$quoteUuid'
      preLoaderRoute: typeof NeutralCheckoutQuoteQuoteUuidImport
      parentRoute: typeof NeutralImport
    }
    '/_unauthenticated/login/confirm-password/$token': {
      id: '/_unauthenticated/login/confirm-password/$token'
      path: '/confirm-password/$token'
      fullPath: '/login/confirm-password/$token'
      preLoaderRoute: typeof UnauthenticatedLoginConfirmPasswordTokenImport
      parentRoute: typeof UnauthenticatedLoginImport
    }
    '/_authenticated/admin/coupons/': {
      id: '/_authenticated/admin/coupons/'
      path: '/coupons'
      fullPath: '/admin/coupons'
      preLoaderRoute: typeof AuthenticatedAdminCouponsIndexImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/customers/': {
      id: '/_authenticated/admin/customers/'
      path: '/customers'
      fullPath: '/admin/customers'
      preLoaderRoute: typeof AuthenticatedAdminCustomersIndexImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/orders/': {
      id: '/_authenticated/admin/orders/'
      path: '/orders'
      fullPath: '/admin/orders'
      preLoaderRoute: typeof AuthenticatedAdminOrdersIndexImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/quotes/': {
      id: '/_authenticated/admin/quotes/'
      path: '/quotes'
      fullPath: '/admin/quotes'
      preLoaderRoute: typeof AuthenticatedAdminQuotesIndexImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/users/': {
      id: '/_authenticated/admin/users/'
      path: '/users'
      fullPath: '/admin/users'
      preLoaderRoute: typeof AuthenticatedAdminUsersIndexImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/my-account/edit-account/': {
      id: '/_authenticated/my-account/edit-account/'
      path: '/edit-account'
      fullPath: '/my-account/edit-account'
      preLoaderRoute: typeof AuthenticatedMyAccountEditAccountIndexImport
      parentRoute: typeof AuthenticatedMyAccountImport
    }
    '/_authenticated/my-account/orders/': {
      id: '/_authenticated/my-account/orders/'
      path: '/orders'
      fullPath: '/my-account/orders'
      preLoaderRoute: typeof AuthenticatedMyAccountOrdersIndexImport
      parentRoute: typeof AuthenticatedMyAccountImport
    }
    '/_authenticated/my-account/payment-methods/': {
      id: '/_authenticated/my-account/payment-methods/'
      path: '/payment-methods'
      fullPath: '/my-account/payment-methods'
      preLoaderRoute: typeof AuthenticatedMyAccountPaymentMethodsIndexImport
      parentRoute: typeof AuthenticatedMyAccountImport
    }
    '/_authenticated/my-account/quotes/': {
      id: '/_authenticated/my-account/quotes/'
      path: '/quotes'
      fullPath: '/my-account/quotes'
      preLoaderRoute: typeof AuthenticatedMyAccountQuotesIndexImport
      parentRoute: typeof AuthenticatedMyAccountImport
    }
    '/_neutral/checkout/quote/': {
      id: '/_neutral/checkout/quote/'
      path: '/checkout/quote'
      fullPath: '/checkout/quote'
      preLoaderRoute: typeof NeutralCheckoutQuoteIndexImport
      parentRoute: typeof NeutralImport
    }
    '/_authenticated/admin/customers/cart/$customerUuid': {
      id: '/_authenticated/admin/customers/cart/$customerUuid'
      path: '/customers/cart/$customerUuid'
      fullPath: '/admin/customers/cart/$customerUuid'
      preLoaderRoute: typeof AuthenticatedAdminCustomersCartCustomerUuidImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/orders/legacy/$id': {
      id: '/_authenticated/admin/orders/legacy/$id'
      path: '/orders/legacy/$id'
      fullPath: '/admin/orders/legacy/$id'
      preLoaderRoute: typeof AuthenticatedAdminOrdersLegacyIdImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/quotes/message-templates/$templateUuid': {
      id: '/_authenticated/admin/quotes/message-templates/$templateUuid'
      path: '/quotes/message-templates/$templateUuid'
      fullPath: '/admin/quotes/message-templates/$templateUuid'
      preLoaderRoute: typeof AuthenticatedAdminQuotesMessageTemplatesTemplateUuidImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/quotes/message-templates/add': {
      id: '/_authenticated/admin/quotes/message-templates/add'
      path: '/quotes/message-templates/add'
      fullPath: '/admin/quotes/message-templates/add'
      preLoaderRoute: typeof AuthenticatedAdminQuotesMessageTemplatesAddImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/quotes/products/$productUuid': {
      id: '/_authenticated/admin/quotes/products/$productUuid'
      path: '/quotes/products/$productUuid'
      fullPath: '/admin/quotes/products/$productUuid'
      preLoaderRoute: typeof AuthenticatedAdminQuotesProductsProductUuidImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/quotes/products/add': {
      id: '/_authenticated/admin/quotes/products/add'
      path: '/quotes/products/add'
      fullPath: '/admin/quotes/products/add'
      preLoaderRoute: typeof AuthenticatedAdminQuotesProductsAddImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/my-account/orders/legacy/$id': {
      id: '/_authenticated/my-account/orders/legacy/$id'
      path: '/orders/legacy/$id'
      fullPath: '/my-account/orders/legacy/$id'
      preLoaderRoute: typeof AuthenticatedMyAccountOrdersLegacyIdImport
      parentRoute: typeof AuthenticatedMyAccountImport
    }
    '/_authenticated/my-account/quotes/legacy/$id': {
      id: '/_authenticated/my-account/quotes/legacy/$id'
      path: '/quotes/legacy/$id'
      fullPath: '/my-account/quotes/legacy/$id'
      preLoaderRoute: typeof AuthenticatedMyAccountQuotesLegacyIdImport
      parentRoute: typeof AuthenticatedMyAccountImport
    }
    '/_authenticated/admin/orders/legacy/': {
      id: '/_authenticated/admin/orders/legacy/'
      path: '/orders/legacy'
      fullPath: '/admin/orders/legacy'
      preLoaderRoute: typeof AuthenticatedAdminOrdersLegacyIndexImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/quotes/email-tokens/': {
      id: '/_authenticated/admin/quotes/email-tokens/'
      path: '/quotes/email-tokens'
      fullPath: '/admin/quotes/email-tokens'
      preLoaderRoute: typeof AuthenticatedAdminQuotesEmailTokensIndexImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/quotes/message-templates/': {
      id: '/_authenticated/admin/quotes/message-templates/'
      path: '/quotes/message-templates'
      fullPath: '/admin/quotes/message-templates'
      preLoaderRoute: typeof AuthenticatedAdminQuotesMessageTemplatesIndexImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/admin/quotes/products/': {
      id: '/_authenticated/admin/quotes/products/'
      path: '/quotes/products'
      fullPath: '/admin/quotes/products'
      preLoaderRoute: typeof AuthenticatedAdminQuotesProductsIndexImport
      parentRoute: typeof AuthenticatedAdminImport
    }
    '/_authenticated/my-account/orders/legacy/': {
      id: '/_authenticated/my-account/orders/legacy/'
      path: '/orders/legacy'
      fullPath: '/my-account/orders/legacy'
      preLoaderRoute: typeof AuthenticatedMyAccountOrdersLegacyIndexImport
      parentRoute: typeof AuthenticatedMyAccountImport
    }
    '/_authenticated/my-account/quotes/legacy/': {
      id: '/_authenticated/my-account/quotes/legacy/'
      path: '/quotes/legacy'
      fullPath: '/my-account/quotes/legacy'
      preLoaderRoute: typeof AuthenticatedMyAccountQuotesLegacyIndexImport
      parentRoute: typeof AuthenticatedMyAccountImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedAdminRouteChildren {
  AuthenticatedAdminIndexRoute: typeof AuthenticatedAdminIndexRoute
  AuthenticatedAdminCouponsCouponUuidRoute: typeof AuthenticatedAdminCouponsCouponUuidRoute
  AuthenticatedAdminCouponsAddRoute: typeof AuthenticatedAdminCouponsAddRoute
  AuthenticatedAdminCustomersCustomerUuidRoute: typeof AuthenticatedAdminCustomersCustomerUuidRoute
  AuthenticatedAdminCustomersAddRoute: typeof AuthenticatedAdminCustomersAddRoute
  AuthenticatedAdminOrdersOrderUuidRoute: typeof AuthenticatedAdminOrdersOrderUuidRoute
  AuthenticatedAdminQuotesQuoteUuidRoute: typeof AuthenticatedAdminQuotesQuoteUuidRoute
  AuthenticatedAdminQuotesAddRoute: typeof AuthenticatedAdminQuotesAddRoute
  AuthenticatedAdminUsersUserUuidRoute: typeof AuthenticatedAdminUsersUserUuidRoute
  AuthenticatedAdminUsersAddRoute: typeof AuthenticatedAdminUsersAddRoute
  AuthenticatedAdminCouponsIndexRoute: typeof AuthenticatedAdminCouponsIndexRoute
  AuthenticatedAdminCustomersIndexRoute: typeof AuthenticatedAdminCustomersIndexRoute
  AuthenticatedAdminOrdersIndexRoute: typeof AuthenticatedAdminOrdersIndexRoute
  AuthenticatedAdminQuotesIndexRoute: typeof AuthenticatedAdminQuotesIndexRoute
  AuthenticatedAdminUsersIndexRoute: typeof AuthenticatedAdminUsersIndexRoute
  AuthenticatedAdminCustomersCartCustomerUuidRoute: typeof AuthenticatedAdminCustomersCartCustomerUuidRoute
  AuthenticatedAdminOrdersLegacyIdRoute: typeof AuthenticatedAdminOrdersLegacyIdRoute
  AuthenticatedAdminQuotesMessageTemplatesTemplateUuidRoute: typeof AuthenticatedAdminQuotesMessageTemplatesTemplateUuidRoute
  AuthenticatedAdminQuotesMessageTemplatesAddRoute: typeof AuthenticatedAdminQuotesMessageTemplatesAddRoute
  AuthenticatedAdminQuotesProductsProductUuidRoute: typeof AuthenticatedAdminQuotesProductsProductUuidRoute
  AuthenticatedAdminQuotesProductsAddRoute: typeof AuthenticatedAdminQuotesProductsAddRoute
  AuthenticatedAdminOrdersLegacyIndexRoute: typeof AuthenticatedAdminOrdersLegacyIndexRoute
  AuthenticatedAdminQuotesEmailTokensIndexRoute: typeof AuthenticatedAdminQuotesEmailTokensIndexRoute
  AuthenticatedAdminQuotesMessageTemplatesIndexRoute: typeof AuthenticatedAdminQuotesMessageTemplatesIndexRoute
  AuthenticatedAdminQuotesProductsIndexRoute: typeof AuthenticatedAdminQuotesProductsIndexRoute
}

const AuthenticatedAdminRouteChildren: AuthenticatedAdminRouteChildren = {
  AuthenticatedAdminIndexRoute: AuthenticatedAdminIndexRoute,
  AuthenticatedAdminCouponsCouponUuidRoute:
    AuthenticatedAdminCouponsCouponUuidRoute,
  AuthenticatedAdminCouponsAddRoute: AuthenticatedAdminCouponsAddRoute,
  AuthenticatedAdminCustomersCustomerUuidRoute:
    AuthenticatedAdminCustomersCustomerUuidRoute,
  AuthenticatedAdminCustomersAddRoute: AuthenticatedAdminCustomersAddRoute,
  AuthenticatedAdminOrdersOrderUuidRoute:
    AuthenticatedAdminOrdersOrderUuidRoute,
  AuthenticatedAdminQuotesQuoteUuidRoute:
    AuthenticatedAdminQuotesQuoteUuidRoute,
  AuthenticatedAdminQuotesAddRoute: AuthenticatedAdminQuotesAddRoute,
  AuthenticatedAdminUsersUserUuidRoute: AuthenticatedAdminUsersUserUuidRoute,
  AuthenticatedAdminUsersAddRoute: AuthenticatedAdminUsersAddRoute,
  AuthenticatedAdminCouponsIndexRoute: AuthenticatedAdminCouponsIndexRoute,
  AuthenticatedAdminCustomersIndexRoute: AuthenticatedAdminCustomersIndexRoute,
  AuthenticatedAdminOrdersIndexRoute: AuthenticatedAdminOrdersIndexRoute,
  AuthenticatedAdminQuotesIndexRoute: AuthenticatedAdminQuotesIndexRoute,
  AuthenticatedAdminUsersIndexRoute: AuthenticatedAdminUsersIndexRoute,
  AuthenticatedAdminCustomersCartCustomerUuidRoute:
    AuthenticatedAdminCustomersCartCustomerUuidRoute,
  AuthenticatedAdminOrdersLegacyIdRoute: AuthenticatedAdminOrdersLegacyIdRoute,
  AuthenticatedAdminQuotesMessageTemplatesTemplateUuidRoute:
    AuthenticatedAdminQuotesMessageTemplatesTemplateUuidRoute,
  AuthenticatedAdminQuotesMessageTemplatesAddRoute:
    AuthenticatedAdminQuotesMessageTemplatesAddRoute,
  AuthenticatedAdminQuotesProductsProductUuidRoute:
    AuthenticatedAdminQuotesProductsProductUuidRoute,
  AuthenticatedAdminQuotesProductsAddRoute:
    AuthenticatedAdminQuotesProductsAddRoute,
  AuthenticatedAdminOrdersLegacyIndexRoute:
    AuthenticatedAdminOrdersLegacyIndexRoute,
  AuthenticatedAdminQuotesEmailTokensIndexRoute:
    AuthenticatedAdminQuotesEmailTokensIndexRoute,
  AuthenticatedAdminQuotesMessageTemplatesIndexRoute:
    AuthenticatedAdminQuotesMessageTemplatesIndexRoute,
  AuthenticatedAdminQuotesProductsIndexRoute:
    AuthenticatedAdminQuotesProductsIndexRoute,
}

const AuthenticatedAdminRouteWithChildren =
  AuthenticatedAdminRoute._addFileChildren(AuthenticatedAdminRouteChildren)

interface AuthenticatedMyAccountRouteChildren {
  AuthenticatedMyAccountIndexRoute: typeof AuthenticatedMyAccountIndexRoute
  AuthenticatedMyAccountEditAccountEditPasswordRoute: typeof AuthenticatedMyAccountEditAccountEditPasswordRoute
  AuthenticatedMyAccountOrdersOrderUuidRoute: typeof AuthenticatedMyAccountOrdersOrderUuidRoute
  AuthenticatedMyAccountPaymentMethodsAddRoute: typeof AuthenticatedMyAccountPaymentMethodsAddRoute
  AuthenticatedMyAccountQuotesQuoteUuidRoute: typeof AuthenticatedMyAccountQuotesQuoteUuidRoute
  AuthenticatedMyAccountEditAccountIndexRoute: typeof AuthenticatedMyAccountEditAccountIndexRoute
  AuthenticatedMyAccountOrdersIndexRoute: typeof AuthenticatedMyAccountOrdersIndexRoute
  AuthenticatedMyAccountPaymentMethodsIndexRoute: typeof AuthenticatedMyAccountPaymentMethodsIndexRoute
  AuthenticatedMyAccountQuotesIndexRoute: typeof AuthenticatedMyAccountQuotesIndexRoute
  AuthenticatedMyAccountOrdersLegacyIdRoute: typeof AuthenticatedMyAccountOrdersLegacyIdRoute
  AuthenticatedMyAccountQuotesLegacyIdRoute: typeof AuthenticatedMyAccountQuotesLegacyIdRoute
  AuthenticatedMyAccountOrdersLegacyIndexRoute: typeof AuthenticatedMyAccountOrdersLegacyIndexRoute
  AuthenticatedMyAccountQuotesLegacyIndexRoute: typeof AuthenticatedMyAccountQuotesLegacyIndexRoute
}

const AuthenticatedMyAccountRouteChildren: AuthenticatedMyAccountRouteChildren =
  {
    AuthenticatedMyAccountIndexRoute: AuthenticatedMyAccountIndexRoute,
    AuthenticatedMyAccountEditAccountEditPasswordRoute:
      AuthenticatedMyAccountEditAccountEditPasswordRoute,
    AuthenticatedMyAccountOrdersOrderUuidRoute:
      AuthenticatedMyAccountOrdersOrderUuidRoute,
    AuthenticatedMyAccountPaymentMethodsAddRoute:
      AuthenticatedMyAccountPaymentMethodsAddRoute,
    AuthenticatedMyAccountQuotesQuoteUuidRoute:
      AuthenticatedMyAccountQuotesQuoteUuidRoute,
    AuthenticatedMyAccountEditAccountIndexRoute:
      AuthenticatedMyAccountEditAccountIndexRoute,
    AuthenticatedMyAccountOrdersIndexRoute:
      AuthenticatedMyAccountOrdersIndexRoute,
    AuthenticatedMyAccountPaymentMethodsIndexRoute:
      AuthenticatedMyAccountPaymentMethodsIndexRoute,
    AuthenticatedMyAccountQuotesIndexRoute:
      AuthenticatedMyAccountQuotesIndexRoute,
    AuthenticatedMyAccountOrdersLegacyIdRoute:
      AuthenticatedMyAccountOrdersLegacyIdRoute,
    AuthenticatedMyAccountQuotesLegacyIdRoute:
      AuthenticatedMyAccountQuotesLegacyIdRoute,
    AuthenticatedMyAccountOrdersLegacyIndexRoute:
      AuthenticatedMyAccountOrdersLegacyIndexRoute,
    AuthenticatedMyAccountQuotesLegacyIndexRoute:
      AuthenticatedMyAccountQuotesLegacyIndexRoute,
  }

const AuthenticatedMyAccountRouteWithChildren =
  AuthenticatedMyAccountRoute._addFileChildren(
    AuthenticatedMyAccountRouteChildren,
  )

interface AuthenticatedRouteChildren {
  AuthenticatedAdminRoute: typeof AuthenticatedAdminRouteWithChildren
  AuthenticatedMyAccountRoute: typeof AuthenticatedMyAccountRouteWithChildren
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedAdminRoute: AuthenticatedAdminRouteWithChildren,
  AuthenticatedMyAccountRoute: AuthenticatedMyAccountRouteWithChildren,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

interface NeutralRouteChildren {
  NeutralCartRoute: typeof NeutralCartRoute
  NeutralIndexRoute: typeof NeutralIndexRoute
  NeutralCheckoutOrderUuidRoute: typeof NeutralCheckoutOrderUuidRoute
  NeutralCheckoutIndexRoute: typeof NeutralCheckoutIndexRoute
  NeutralCheckoutQuoteQuoteUuidRoute: typeof NeutralCheckoutQuoteQuoteUuidRoute
  NeutralCheckoutQuoteIndexRoute: typeof NeutralCheckoutQuoteIndexRoute
}

const NeutralRouteChildren: NeutralRouteChildren = {
  NeutralCartRoute: NeutralCartRoute,
  NeutralIndexRoute: NeutralIndexRoute,
  NeutralCheckoutOrderUuidRoute: NeutralCheckoutOrderUuidRoute,
  NeutralCheckoutIndexRoute: NeutralCheckoutIndexRoute,
  NeutralCheckoutQuoteQuoteUuidRoute: NeutralCheckoutQuoteQuoteUuidRoute,
  NeutralCheckoutQuoteIndexRoute: NeutralCheckoutQuoteIndexRoute,
}

const NeutralRouteWithChildren =
  NeutralRoute._addFileChildren(NeutralRouteChildren)

interface UnauthenticatedLoginRouteChildren {
  UnauthenticatedLoginResetPasswordRoute: typeof UnauthenticatedLoginResetPasswordRoute
  UnauthenticatedLoginIndexRoute: typeof UnauthenticatedLoginIndexRoute
  UnauthenticatedLoginConfirmPasswordTokenRoute: typeof UnauthenticatedLoginConfirmPasswordTokenRoute
}

const UnauthenticatedLoginRouteChildren: UnauthenticatedLoginRouteChildren = {
  UnauthenticatedLoginResetPasswordRoute:
    UnauthenticatedLoginResetPasswordRoute,
  UnauthenticatedLoginIndexRoute: UnauthenticatedLoginIndexRoute,
  UnauthenticatedLoginConfirmPasswordTokenRoute:
    UnauthenticatedLoginConfirmPasswordTokenRoute,
}

const UnauthenticatedLoginRouteWithChildren =
  UnauthenticatedLoginRoute._addFileChildren(UnauthenticatedLoginRouteChildren)

interface UnauthenticatedRouteChildren {
  UnauthenticatedLoginRoute: typeof UnauthenticatedLoginRouteWithChildren
}

const UnauthenticatedRouteChildren: UnauthenticatedRouteChildren = {
  UnauthenticatedLoginRoute: UnauthenticatedLoginRouteWithChildren,
}

const UnauthenticatedRouteWithChildren = UnauthenticatedRoute._addFileChildren(
  UnauthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof UnauthenticatedRouteWithChildren
  '/admin': typeof AuthenticatedAdminRouteWithChildren
  '/my-account': typeof AuthenticatedMyAccountRouteWithChildren
  '/cart': typeof NeutralCartRoute
  '/login': typeof UnauthenticatedLoginRouteWithChildren
  '/': typeof NeutralIndexRoute
  '/checkout/$orderUuid': typeof NeutralCheckoutOrderUuidRoute
  '/login/reset-password': typeof UnauthenticatedLoginResetPasswordRoute
  '/admin/': typeof AuthenticatedAdminIndexRoute
  '/my-account/': typeof AuthenticatedMyAccountIndexRoute
  '/checkout': typeof NeutralCheckoutIndexRoute
  '/login/': typeof UnauthenticatedLoginIndexRoute
  '/admin/coupons/$couponUuid': typeof AuthenticatedAdminCouponsCouponUuidRoute
  '/admin/coupons/add': typeof AuthenticatedAdminCouponsAddRoute
  '/admin/customers/$customerUuid': typeof AuthenticatedAdminCustomersCustomerUuidRoute
  '/admin/customers/add': typeof AuthenticatedAdminCustomersAddRoute
  '/admin/orders/$orderUuid': typeof AuthenticatedAdminOrdersOrderUuidRoute
  '/admin/quotes/$quoteUuid': typeof AuthenticatedAdminQuotesQuoteUuidRoute
  '/admin/quotes/add': typeof AuthenticatedAdminQuotesAddRoute
  '/admin/users/$userUuid': typeof AuthenticatedAdminUsersUserUuidRoute
  '/admin/users/add': typeof AuthenticatedAdminUsersAddRoute
  '/my-account/edit-account/edit-password': typeof AuthenticatedMyAccountEditAccountEditPasswordRoute
  '/my-account/orders/$orderUuid': typeof AuthenticatedMyAccountOrdersOrderUuidRoute
  '/my-account/payment-methods/add': typeof AuthenticatedMyAccountPaymentMethodsAddRoute
  '/my-account/quotes/$quoteUuid': typeof AuthenticatedMyAccountQuotesQuoteUuidRoute
  '/checkout/quote/$quoteUuid': typeof NeutralCheckoutQuoteQuoteUuidRoute
  '/login/confirm-password/$token': typeof UnauthenticatedLoginConfirmPasswordTokenRoute
  '/admin/coupons': typeof AuthenticatedAdminCouponsIndexRoute
  '/admin/customers': typeof AuthenticatedAdminCustomersIndexRoute
  '/admin/orders': typeof AuthenticatedAdminOrdersIndexRoute
  '/admin/quotes': typeof AuthenticatedAdminQuotesIndexRoute
  '/admin/users': typeof AuthenticatedAdminUsersIndexRoute
  '/my-account/edit-account': typeof AuthenticatedMyAccountEditAccountIndexRoute
  '/my-account/orders': typeof AuthenticatedMyAccountOrdersIndexRoute
  '/my-account/payment-methods': typeof AuthenticatedMyAccountPaymentMethodsIndexRoute
  '/my-account/quotes': typeof AuthenticatedMyAccountQuotesIndexRoute
  '/checkout/quote': typeof NeutralCheckoutQuoteIndexRoute
  '/admin/customers/cart/$customerUuid': typeof AuthenticatedAdminCustomersCartCustomerUuidRoute
  '/admin/orders/legacy/$id': typeof AuthenticatedAdminOrdersLegacyIdRoute
  '/admin/quotes/message-templates/$templateUuid': typeof AuthenticatedAdminQuotesMessageTemplatesTemplateUuidRoute
  '/admin/quotes/message-templates/add': typeof AuthenticatedAdminQuotesMessageTemplatesAddRoute
  '/admin/quotes/products/$productUuid': typeof AuthenticatedAdminQuotesProductsProductUuidRoute
  '/admin/quotes/products/add': typeof AuthenticatedAdminQuotesProductsAddRoute
  '/my-account/orders/legacy/$id': typeof AuthenticatedMyAccountOrdersLegacyIdRoute
  '/my-account/quotes/legacy/$id': typeof AuthenticatedMyAccountQuotesLegacyIdRoute
  '/admin/orders/legacy': typeof AuthenticatedAdminOrdersLegacyIndexRoute
  '/admin/quotes/email-tokens': typeof AuthenticatedAdminQuotesEmailTokensIndexRoute
  '/admin/quotes/message-templates': typeof AuthenticatedAdminQuotesMessageTemplatesIndexRoute
  '/admin/quotes/products': typeof AuthenticatedAdminQuotesProductsIndexRoute
  '/my-account/orders/legacy': typeof AuthenticatedMyAccountOrdersLegacyIndexRoute
  '/my-account/quotes/legacy': typeof AuthenticatedMyAccountQuotesLegacyIndexRoute
}

export interface FileRoutesByTo {
  '': typeof UnauthenticatedRouteWithChildren
  '/cart': typeof NeutralCartRoute
  '/': typeof NeutralIndexRoute
  '/checkout/$orderUuid': typeof NeutralCheckoutOrderUuidRoute
  '/login/reset-password': typeof UnauthenticatedLoginResetPasswordRoute
  '/admin': typeof AuthenticatedAdminIndexRoute
  '/my-account': typeof AuthenticatedMyAccountIndexRoute
  '/checkout': typeof NeutralCheckoutIndexRoute
  '/login': typeof UnauthenticatedLoginIndexRoute
  '/admin/coupons/$couponUuid': typeof AuthenticatedAdminCouponsCouponUuidRoute
  '/admin/coupons/add': typeof AuthenticatedAdminCouponsAddRoute
  '/admin/customers/$customerUuid': typeof AuthenticatedAdminCustomersCustomerUuidRoute
  '/admin/customers/add': typeof AuthenticatedAdminCustomersAddRoute
  '/admin/orders/$orderUuid': typeof AuthenticatedAdminOrdersOrderUuidRoute
  '/admin/quotes/$quoteUuid': typeof AuthenticatedAdminQuotesQuoteUuidRoute
  '/admin/quotes/add': typeof AuthenticatedAdminQuotesAddRoute
  '/admin/users/$userUuid': typeof AuthenticatedAdminUsersUserUuidRoute
  '/admin/users/add': typeof AuthenticatedAdminUsersAddRoute
  '/my-account/edit-account/edit-password': typeof AuthenticatedMyAccountEditAccountEditPasswordRoute
  '/my-account/orders/$orderUuid': typeof AuthenticatedMyAccountOrdersOrderUuidRoute
  '/my-account/payment-methods/add': typeof AuthenticatedMyAccountPaymentMethodsAddRoute
  '/my-account/quotes/$quoteUuid': typeof AuthenticatedMyAccountQuotesQuoteUuidRoute
  '/checkout/quote/$quoteUuid': typeof NeutralCheckoutQuoteQuoteUuidRoute
  '/login/confirm-password/$token': typeof UnauthenticatedLoginConfirmPasswordTokenRoute
  '/admin/coupons': typeof AuthenticatedAdminCouponsIndexRoute
  '/admin/customers': typeof AuthenticatedAdminCustomersIndexRoute
  '/admin/orders': typeof AuthenticatedAdminOrdersIndexRoute
  '/admin/quotes': typeof AuthenticatedAdminQuotesIndexRoute
  '/admin/users': typeof AuthenticatedAdminUsersIndexRoute
  '/my-account/edit-account': typeof AuthenticatedMyAccountEditAccountIndexRoute
  '/my-account/orders': typeof AuthenticatedMyAccountOrdersIndexRoute
  '/my-account/payment-methods': typeof AuthenticatedMyAccountPaymentMethodsIndexRoute
  '/my-account/quotes': typeof AuthenticatedMyAccountQuotesIndexRoute
  '/checkout/quote': typeof NeutralCheckoutQuoteIndexRoute
  '/admin/customers/cart/$customerUuid': typeof AuthenticatedAdminCustomersCartCustomerUuidRoute
  '/admin/orders/legacy/$id': typeof AuthenticatedAdminOrdersLegacyIdRoute
  '/admin/quotes/message-templates/$templateUuid': typeof AuthenticatedAdminQuotesMessageTemplatesTemplateUuidRoute
  '/admin/quotes/message-templates/add': typeof AuthenticatedAdminQuotesMessageTemplatesAddRoute
  '/admin/quotes/products/$productUuid': typeof AuthenticatedAdminQuotesProductsProductUuidRoute
  '/admin/quotes/products/add': typeof AuthenticatedAdminQuotesProductsAddRoute
  '/my-account/orders/legacy/$id': typeof AuthenticatedMyAccountOrdersLegacyIdRoute
  '/my-account/quotes/legacy/$id': typeof AuthenticatedMyAccountQuotesLegacyIdRoute
  '/admin/orders/legacy': typeof AuthenticatedAdminOrdersLegacyIndexRoute
  '/admin/quotes/email-tokens': typeof AuthenticatedAdminQuotesEmailTokensIndexRoute
  '/admin/quotes/message-templates': typeof AuthenticatedAdminQuotesMessageTemplatesIndexRoute
  '/admin/quotes/products': typeof AuthenticatedAdminQuotesProductsIndexRoute
  '/my-account/orders/legacy': typeof AuthenticatedMyAccountOrdersLegacyIndexRoute
  '/my-account/quotes/legacy': typeof AuthenticatedMyAccountQuotesLegacyIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/_neutral': typeof NeutralRouteWithChildren
  '/_unauthenticated': typeof UnauthenticatedRouteWithChildren
  '/_authenticated/admin': typeof AuthenticatedAdminRouteWithChildren
  '/_authenticated/my-account': typeof AuthenticatedMyAccountRouteWithChildren
  '/_neutral/cart': typeof NeutralCartRoute
  '/_unauthenticated/login': typeof UnauthenticatedLoginRouteWithChildren
  '/_neutral/': typeof NeutralIndexRoute
  '/_neutral/checkout/$orderUuid': typeof NeutralCheckoutOrderUuidRoute
  '/_unauthenticated/login/reset-password': typeof UnauthenticatedLoginResetPasswordRoute
  '/_authenticated/admin/': typeof AuthenticatedAdminIndexRoute
  '/_authenticated/my-account/': typeof AuthenticatedMyAccountIndexRoute
  '/_neutral/checkout/': typeof NeutralCheckoutIndexRoute
  '/_unauthenticated/login/': typeof UnauthenticatedLoginIndexRoute
  '/_authenticated/admin/coupons/$couponUuid': typeof AuthenticatedAdminCouponsCouponUuidRoute
  '/_authenticated/admin/coupons/add': typeof AuthenticatedAdminCouponsAddRoute
  '/_authenticated/admin/customers/$customerUuid': typeof AuthenticatedAdminCustomersCustomerUuidRoute
  '/_authenticated/admin/customers/add': typeof AuthenticatedAdminCustomersAddRoute
  '/_authenticated/admin/orders/$orderUuid': typeof AuthenticatedAdminOrdersOrderUuidRoute
  '/_authenticated/admin/quotes/$quoteUuid': typeof AuthenticatedAdminQuotesQuoteUuidRoute
  '/_authenticated/admin/quotes/add': typeof AuthenticatedAdminQuotesAddRoute
  '/_authenticated/admin/users/$userUuid': typeof AuthenticatedAdminUsersUserUuidRoute
  '/_authenticated/admin/users/add': typeof AuthenticatedAdminUsersAddRoute
  '/_authenticated/my-account/edit-account/edit-password': typeof AuthenticatedMyAccountEditAccountEditPasswordRoute
  '/_authenticated/my-account/orders/$orderUuid': typeof AuthenticatedMyAccountOrdersOrderUuidRoute
  '/_authenticated/my-account/payment-methods/add': typeof AuthenticatedMyAccountPaymentMethodsAddRoute
  '/_authenticated/my-account/quotes/$quoteUuid': typeof AuthenticatedMyAccountQuotesQuoteUuidRoute
  '/_neutral/checkout/quote/$quoteUuid': typeof NeutralCheckoutQuoteQuoteUuidRoute
  '/_unauthenticated/login/confirm-password/$token': typeof UnauthenticatedLoginConfirmPasswordTokenRoute
  '/_authenticated/admin/coupons/': typeof AuthenticatedAdminCouponsIndexRoute
  '/_authenticated/admin/customers/': typeof AuthenticatedAdminCustomersIndexRoute
  '/_authenticated/admin/orders/': typeof AuthenticatedAdminOrdersIndexRoute
  '/_authenticated/admin/quotes/': typeof AuthenticatedAdminQuotesIndexRoute
  '/_authenticated/admin/users/': typeof AuthenticatedAdminUsersIndexRoute
  '/_authenticated/my-account/edit-account/': typeof AuthenticatedMyAccountEditAccountIndexRoute
  '/_authenticated/my-account/orders/': typeof AuthenticatedMyAccountOrdersIndexRoute
  '/_authenticated/my-account/payment-methods/': typeof AuthenticatedMyAccountPaymentMethodsIndexRoute
  '/_authenticated/my-account/quotes/': typeof AuthenticatedMyAccountQuotesIndexRoute
  '/_neutral/checkout/quote/': typeof NeutralCheckoutQuoteIndexRoute
  '/_authenticated/admin/customers/cart/$customerUuid': typeof AuthenticatedAdminCustomersCartCustomerUuidRoute
  '/_authenticated/admin/orders/legacy/$id': typeof AuthenticatedAdminOrdersLegacyIdRoute
  '/_authenticated/admin/quotes/message-templates/$templateUuid': typeof AuthenticatedAdminQuotesMessageTemplatesTemplateUuidRoute
  '/_authenticated/admin/quotes/message-templates/add': typeof AuthenticatedAdminQuotesMessageTemplatesAddRoute
  '/_authenticated/admin/quotes/products/$productUuid': typeof AuthenticatedAdminQuotesProductsProductUuidRoute
  '/_authenticated/admin/quotes/products/add': typeof AuthenticatedAdminQuotesProductsAddRoute
  '/_authenticated/my-account/orders/legacy/$id': typeof AuthenticatedMyAccountOrdersLegacyIdRoute
  '/_authenticated/my-account/quotes/legacy/$id': typeof AuthenticatedMyAccountQuotesLegacyIdRoute
  '/_authenticated/admin/orders/legacy/': typeof AuthenticatedAdminOrdersLegacyIndexRoute
  '/_authenticated/admin/quotes/email-tokens/': typeof AuthenticatedAdminQuotesEmailTokensIndexRoute
  '/_authenticated/admin/quotes/message-templates/': typeof AuthenticatedAdminQuotesMessageTemplatesIndexRoute
  '/_authenticated/admin/quotes/products/': typeof AuthenticatedAdminQuotesProductsIndexRoute
  '/_authenticated/my-account/orders/legacy/': typeof AuthenticatedMyAccountOrdersLegacyIndexRoute
  '/_authenticated/my-account/quotes/legacy/': typeof AuthenticatedMyAccountQuotesLegacyIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/admin'
    | '/my-account'
    | '/cart'
    | '/login'
    | '/'
    | '/checkout/$orderUuid'
    | '/login/reset-password'
    | '/admin/'
    | '/my-account/'
    | '/checkout'
    | '/login/'
    | '/admin/coupons/$couponUuid'
    | '/admin/coupons/add'
    | '/admin/customers/$customerUuid'
    | '/admin/customers/add'
    | '/admin/orders/$orderUuid'
    | '/admin/quotes/$quoteUuid'
    | '/admin/quotes/add'
    | '/admin/users/$userUuid'
    | '/admin/users/add'
    | '/my-account/edit-account/edit-password'
    | '/my-account/orders/$orderUuid'
    | '/my-account/payment-methods/add'
    | '/my-account/quotes/$quoteUuid'
    | '/checkout/quote/$quoteUuid'
    | '/login/confirm-password/$token'
    | '/admin/coupons'
    | '/admin/customers'
    | '/admin/orders'
    | '/admin/quotes'
    | '/admin/users'
    | '/my-account/edit-account'
    | '/my-account/orders'
    | '/my-account/payment-methods'
    | '/my-account/quotes'
    | '/checkout/quote'
    | '/admin/customers/cart/$customerUuid'
    | '/admin/orders/legacy/$id'
    | '/admin/quotes/message-templates/$templateUuid'
    | '/admin/quotes/message-templates/add'
    | '/admin/quotes/products/$productUuid'
    | '/admin/quotes/products/add'
    | '/my-account/orders/legacy/$id'
    | '/my-account/quotes/legacy/$id'
    | '/admin/orders/legacy'
    | '/admin/quotes/email-tokens'
    | '/admin/quotes/message-templates'
    | '/admin/quotes/products'
    | '/my-account/orders/legacy'
    | '/my-account/quotes/legacy'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/cart'
    | '/'
    | '/checkout/$orderUuid'
    | '/login/reset-password'
    | '/admin'
    | '/my-account'
    | '/checkout'
    | '/login'
    | '/admin/coupons/$couponUuid'
    | '/admin/coupons/add'
    | '/admin/customers/$customerUuid'
    | '/admin/customers/add'
    | '/admin/orders/$orderUuid'
    | '/admin/quotes/$quoteUuid'
    | '/admin/quotes/add'
    | '/admin/users/$userUuid'
    | '/admin/users/add'
    | '/my-account/edit-account/edit-password'
    | '/my-account/orders/$orderUuid'
    | '/my-account/payment-methods/add'
    | '/my-account/quotes/$quoteUuid'
    | '/checkout/quote/$quoteUuid'
    | '/login/confirm-password/$token'
    | '/admin/coupons'
    | '/admin/customers'
    | '/admin/orders'
    | '/admin/quotes'
    | '/admin/users'
    | '/my-account/edit-account'
    | '/my-account/orders'
    | '/my-account/payment-methods'
    | '/my-account/quotes'
    | '/checkout/quote'
    | '/admin/customers/cart/$customerUuid'
    | '/admin/orders/legacy/$id'
    | '/admin/quotes/message-templates/$templateUuid'
    | '/admin/quotes/message-templates/add'
    | '/admin/quotes/products/$productUuid'
    | '/admin/quotes/products/add'
    | '/my-account/orders/legacy/$id'
    | '/my-account/quotes/legacy/$id'
    | '/admin/orders/legacy'
    | '/admin/quotes/email-tokens'
    | '/admin/quotes/message-templates'
    | '/admin/quotes/products'
    | '/my-account/orders/legacy'
    | '/my-account/quotes/legacy'
  id:
    | '__root__'
    | '/_authenticated'
    | '/_neutral'
    | '/_unauthenticated'
    | '/_authenticated/admin'
    | '/_authenticated/my-account'
    | '/_neutral/cart'
    | '/_unauthenticated/login'
    | '/_neutral/'
    | '/_neutral/checkout/$orderUuid'
    | '/_unauthenticated/login/reset-password'
    | '/_authenticated/admin/'
    | '/_authenticated/my-account/'
    | '/_neutral/checkout/'
    | '/_unauthenticated/login/'
    | '/_authenticated/admin/coupons/$couponUuid'
    | '/_authenticated/admin/coupons/add'
    | '/_authenticated/admin/customers/$customerUuid'
    | '/_authenticated/admin/customers/add'
    | '/_authenticated/admin/orders/$orderUuid'
    | '/_authenticated/admin/quotes/$quoteUuid'
    | '/_authenticated/admin/quotes/add'
    | '/_authenticated/admin/users/$userUuid'
    | '/_authenticated/admin/users/add'
    | '/_authenticated/my-account/edit-account/edit-password'
    | '/_authenticated/my-account/orders/$orderUuid'
    | '/_authenticated/my-account/payment-methods/add'
    | '/_authenticated/my-account/quotes/$quoteUuid'
    | '/_neutral/checkout/quote/$quoteUuid'
    | '/_unauthenticated/login/confirm-password/$token'
    | '/_authenticated/admin/coupons/'
    | '/_authenticated/admin/customers/'
    | '/_authenticated/admin/orders/'
    | '/_authenticated/admin/quotes/'
    | '/_authenticated/admin/users/'
    | '/_authenticated/my-account/edit-account/'
    | '/_authenticated/my-account/orders/'
    | '/_authenticated/my-account/payment-methods/'
    | '/_authenticated/my-account/quotes/'
    | '/_neutral/checkout/quote/'
    | '/_authenticated/admin/customers/cart/$customerUuid'
    | '/_authenticated/admin/orders/legacy/$id'
    | '/_authenticated/admin/quotes/message-templates/$templateUuid'
    | '/_authenticated/admin/quotes/message-templates/add'
    | '/_authenticated/admin/quotes/products/$productUuid'
    | '/_authenticated/admin/quotes/products/add'
    | '/_authenticated/my-account/orders/legacy/$id'
    | '/_authenticated/my-account/quotes/legacy/$id'
    | '/_authenticated/admin/orders/legacy/'
    | '/_authenticated/admin/quotes/email-tokens/'
    | '/_authenticated/admin/quotes/message-templates/'
    | '/_authenticated/admin/quotes/products/'
    | '/_authenticated/my-account/orders/legacy/'
    | '/_authenticated/my-account/quotes/legacy/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  NeutralRoute: typeof NeutralRouteWithChildren
  UnauthenticatedRoute: typeof UnauthenticatedRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  NeutralRoute: NeutralRouteWithChildren,
  UnauthenticatedRoute: UnauthenticatedRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authenticated",
        "/_neutral",
        "/_unauthenticated"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/admin",
        "/_authenticated/my-account"
      ]
    },
    "/_neutral": {
      "filePath": "_neutral.tsx",
      "children": [
        "/_neutral/cart",
        "/_neutral/",
        "/_neutral/checkout/$orderUuid",
        "/_neutral/checkout/",
        "/_neutral/checkout/quote/$quoteUuid",
        "/_neutral/checkout/quote/"
      ]
    },
    "/_unauthenticated": {
      "filePath": "_unauthenticated.tsx",
      "children": [
        "/_unauthenticated/login"
      ]
    },
    "/_authenticated/admin": {
      "filePath": "_authenticated/admin.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/admin/",
        "/_authenticated/admin/coupons/$couponUuid",
        "/_authenticated/admin/coupons/add",
        "/_authenticated/admin/customers/$customerUuid",
        "/_authenticated/admin/customers/add",
        "/_authenticated/admin/orders/$orderUuid",
        "/_authenticated/admin/quotes/$quoteUuid",
        "/_authenticated/admin/quotes/add",
        "/_authenticated/admin/users/$userUuid",
        "/_authenticated/admin/users/add",
        "/_authenticated/admin/coupons/",
        "/_authenticated/admin/customers/",
        "/_authenticated/admin/orders/",
        "/_authenticated/admin/quotes/",
        "/_authenticated/admin/users/",
        "/_authenticated/admin/customers/cart/$customerUuid",
        "/_authenticated/admin/orders/legacy/$id",
        "/_authenticated/admin/quotes/message-templates/$templateUuid",
        "/_authenticated/admin/quotes/message-templates/add",
        "/_authenticated/admin/quotes/products/$productUuid",
        "/_authenticated/admin/quotes/products/add",
        "/_authenticated/admin/orders/legacy/",
        "/_authenticated/admin/quotes/email-tokens/",
        "/_authenticated/admin/quotes/message-templates/",
        "/_authenticated/admin/quotes/products/"
      ]
    },
    "/_authenticated/my-account": {
      "filePath": "_authenticated/my-account.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/my-account/",
        "/_authenticated/my-account/edit-account/edit-password",
        "/_authenticated/my-account/orders/$orderUuid",
        "/_authenticated/my-account/payment-methods/add",
        "/_authenticated/my-account/quotes/$quoteUuid",
        "/_authenticated/my-account/edit-account/",
        "/_authenticated/my-account/orders/",
        "/_authenticated/my-account/payment-methods/",
        "/_authenticated/my-account/quotes/",
        "/_authenticated/my-account/orders/legacy/$id",
        "/_authenticated/my-account/quotes/legacy/$id",
        "/_authenticated/my-account/orders/legacy/",
        "/_authenticated/my-account/quotes/legacy/"
      ]
    },
    "/_neutral/cart": {
      "filePath": "_neutral/cart.tsx",
      "parent": "/_neutral"
    },
    "/_unauthenticated/login": {
      "filePath": "_unauthenticated/login.tsx",
      "parent": "/_unauthenticated",
      "children": [
        "/_unauthenticated/login/reset-password",
        "/_unauthenticated/login/",
        "/_unauthenticated/login/confirm-password/$token"
      ]
    },
    "/_neutral/": {
      "filePath": "_neutral/index.tsx",
      "parent": "/_neutral"
    },
    "/_neutral/checkout/$orderUuid": {
      "filePath": "_neutral/checkout/$orderUuid.tsx",
      "parent": "/_neutral"
    },
    "/_unauthenticated/login/reset-password": {
      "filePath": "_unauthenticated/login/reset-password.tsx",
      "parent": "/_unauthenticated/login"
    },
    "/_authenticated/admin/": {
      "filePath": "_authenticated/admin/index.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/my-account/": {
      "filePath": "_authenticated/my-account/index.tsx",
      "parent": "/_authenticated/my-account"
    },
    "/_neutral/checkout/": {
      "filePath": "_neutral/checkout/index.tsx",
      "parent": "/_neutral"
    },
    "/_unauthenticated/login/": {
      "filePath": "_unauthenticated/login/index.tsx",
      "parent": "/_unauthenticated/login"
    },
    "/_authenticated/admin/coupons/$couponUuid": {
      "filePath": "_authenticated/admin/coupons/$couponUuid.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/coupons/add": {
      "filePath": "_authenticated/admin/coupons/add.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/customers/$customerUuid": {
      "filePath": "_authenticated/admin/customers/$customerUuid.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/customers/add": {
      "filePath": "_authenticated/admin/customers/add.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/orders/$orderUuid": {
      "filePath": "_authenticated/admin/orders/$orderUuid.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/quotes/$quoteUuid": {
      "filePath": "_authenticated/admin/quotes/$quoteUuid.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/quotes/add": {
      "filePath": "_authenticated/admin/quotes/add.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/users/$userUuid": {
      "filePath": "_authenticated/admin/users/$userUuid.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/users/add": {
      "filePath": "_authenticated/admin/users/add.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/my-account/edit-account/edit-password": {
      "filePath": "_authenticated/my-account/edit-account/edit-password.tsx",
      "parent": "/_authenticated/my-account"
    },
    "/_authenticated/my-account/orders/$orderUuid": {
      "filePath": "_authenticated/my-account/orders/$orderUuid.tsx",
      "parent": "/_authenticated/my-account"
    },
    "/_authenticated/my-account/payment-methods/add": {
      "filePath": "_authenticated/my-account/payment-methods/add.tsx",
      "parent": "/_authenticated/my-account"
    },
    "/_authenticated/my-account/quotes/$quoteUuid": {
      "filePath": "_authenticated/my-account/quotes/$quoteUuid.tsx",
      "parent": "/_authenticated/my-account"
    },
    "/_neutral/checkout/quote/$quoteUuid": {
      "filePath": "_neutral/checkout/quote/$quoteUuid.tsx",
      "parent": "/_neutral"
    },
    "/_unauthenticated/login/confirm-password/$token": {
      "filePath": "_unauthenticated/login/confirm-password/$token.tsx",
      "parent": "/_unauthenticated/login"
    },
    "/_authenticated/admin/coupons/": {
      "filePath": "_authenticated/admin/coupons/index.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/customers/": {
      "filePath": "_authenticated/admin/customers/index.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/orders/": {
      "filePath": "_authenticated/admin/orders/index.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/quotes/": {
      "filePath": "_authenticated/admin/quotes/index.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/users/": {
      "filePath": "_authenticated/admin/users/index.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/my-account/edit-account/": {
      "filePath": "_authenticated/my-account/edit-account/index.tsx",
      "parent": "/_authenticated/my-account"
    },
    "/_authenticated/my-account/orders/": {
      "filePath": "_authenticated/my-account/orders/index.tsx",
      "parent": "/_authenticated/my-account"
    },
    "/_authenticated/my-account/payment-methods/": {
      "filePath": "_authenticated/my-account/payment-methods/index.tsx",
      "parent": "/_authenticated/my-account"
    },
    "/_authenticated/my-account/quotes/": {
      "filePath": "_authenticated/my-account/quotes/index.tsx",
      "parent": "/_authenticated/my-account"
    },
    "/_neutral/checkout/quote/": {
      "filePath": "_neutral/checkout/quote/index.tsx",
      "parent": "/_neutral"
    },
    "/_authenticated/admin/customers/cart/$customerUuid": {
      "filePath": "_authenticated/admin/customers/cart/$customerUuid.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/orders/legacy/$id": {
      "filePath": "_authenticated/admin/orders/legacy/$id.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/quotes/message-templates/$templateUuid": {
      "filePath": "_authenticated/admin/quotes/message-templates/$templateUuid.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/quotes/message-templates/add": {
      "filePath": "_authenticated/admin/quotes/message-templates/add.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/quotes/products/$productUuid": {
      "filePath": "_authenticated/admin/quotes/products/$productUuid.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/quotes/products/add": {
      "filePath": "_authenticated/admin/quotes/products/add.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/my-account/orders/legacy/$id": {
      "filePath": "_authenticated/my-account/orders/legacy/$id.tsx",
      "parent": "/_authenticated/my-account"
    },
    "/_authenticated/my-account/quotes/legacy/$id": {
      "filePath": "_authenticated/my-account/quotes/legacy/$id.tsx",
      "parent": "/_authenticated/my-account"
    },
    "/_authenticated/admin/orders/legacy/": {
      "filePath": "_authenticated/admin/orders/legacy/index.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/quotes/email-tokens/": {
      "filePath": "_authenticated/admin/quotes/email-tokens/index.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/quotes/message-templates/": {
      "filePath": "_authenticated/admin/quotes/message-templates/index.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/admin/quotes/products/": {
      "filePath": "_authenticated/admin/quotes/products/index.tsx",
      "parent": "/_authenticated/admin"
    },
    "/_authenticated/my-account/orders/legacy/": {
      "filePath": "_authenticated/my-account/orders/legacy/index.tsx",
      "parent": "/_authenticated/my-account"
    },
    "/_authenticated/my-account/quotes/legacy/": {
      "filePath": "_authenticated/my-account/quotes/legacy/index.tsx",
      "parent": "/_authenticated/my-account"
    }
  }
}
ROUTE_MANIFEST_END */
