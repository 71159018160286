import classNames from 'classnames';
import { Field } from 'formik';
import { ReactNode } from 'react';
import { IconType } from 'react-icons';

const RadioCard = ({
  checked,
  Icon,
  onChange,
  name,
  value,
  title,
  description,
  useFormik,
  style = 'vertical',
}: {
  checked?: boolean;
  Icon: IconType;
  onChange?: () => void;
  name: string;
  value?: string;
  title: ReactNode;
  description?: ReactNode;
  useFormik?: boolean;
  style?: 'horizontal' | 'vertical';
}) => (
  <label
    className={classNames(
      'border rounded p-4 flex justify-between text-left w-full h-full cursor-pointer',
      {
        'border-red border-2 bg-red/10': checked,
        'border-black/20': !checked,
      }
    )}
  >
    <div>
      <div
        className={classNames({
          'flex items-center': style === 'horizontal',
        })}
      >
        <Icon
          size={24}
          className={classNames('stroke-red mb-2', {
            'mr-2': style === 'horizontal',
          })}
        />
        <h5 className="font-semibold text-dark mb-2">{title}</h5>
      </div>
      {!!description && <p className="text-dark text-sm">{description}</p>}
    </div>
    {useFormik ? (
      <Field type="radio" name={name} value={value} className="hidden" />
    ) : (
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="hidden"
      />
    )}
    <div className="border border-black/20 rounded-full w-4 h-4 flex items-center justify-center [:checked+&]:border-red">
      <div className="w-2 h-2 rounded-full [:checked+div>&]:bg-red" />
    </div>
  </label>
);

export default RadioCard;
