import { createFileRoute } from '@tanstack/react-router';
import CreateOrEditCustomer, {
  Account,
} from '../../../../components/CreateOrEditCustomer';
import { useUser } from '../../../../utility/authentication';
import { useMemo } from 'react';
import { FiKey } from 'react-icons/fi';

const EditAccountComponent = () => {
  const { user } = useUser();
  const customer = useMemo<Account>(
    () => ({
      uuid: user.customer?.uuid ?? undefined,
      displayName: user.customer?.displayName ?? user.displayName,
      legacyId: user.customer?.legacyId ?? null,
      email: user.customer?.email ?? user.email,
      firstName: user.customer?.firstName ?? user.firstName,
      lastName: user.customer?.lastName ?? user.lastName,
      accountingReference: user.customer?.accountingReference ?? null,
      billingAddress: {
        uuid: user.customer?.billingAddress?.uuid ?? null,
        firstName:
          user.customer?.billingAddress?.firstName ??
          user.customer?.firstName ??
          user.firstName,
        lastName:
          user.customer?.billingAddress?.lastName ??
          user.customer?.lastName ??
          user.lastName,
        company: user.customer?.billingAddress?.company ?? null,
        phone: user.customer?.billingAddress?.phone ?? user.telephone ?? null,
        email:
          user.customer?.billingAddress?.email ??
          user.customer?.email ??
          user.email,
        line1: user.customer?.billingAddress?.line1 ?? '',
        line2: user.customer?.billingAddress?.line2 ?? null,
        city: user.customer?.billingAddress?.city ?? '',
        postCode: user.customer?.billingAddress?.postCode ?? '',
        country: user.customer?.billingAddress?.country ?? 'GB',
        state: user.customer?.billingAddress?.state ?? null,
      },
      shippingAddress: {
        uuid: user.customer?.shippingAddress?.uuid ?? null,
        firstName:
          user.customer?.shippingAddress?.firstName ??
          user.customer?.firstName ??
          user.firstName,
        lastName:
          user.customer?.shippingAddress?.lastName ??
          user.customer?.lastName ??
          user.lastName,
        company: user.customer?.shippingAddress?.company ?? null,
        phone: user.customer?.shippingAddress?.phone ?? user.telephone ?? null,
        email:
          user.customer?.shippingAddress?.email ??
          user.customer?.email ??
          user.email,
        line1: user.customer?.shippingAddress?.line1 ?? '',
        line2: user.customer?.shippingAddress?.line2 ?? null,
        city: user.customer?.shippingAddress?.city ?? '',
        postCode: user.customer?.shippingAddress?.postCode ?? '',
        country: user.customer?.shippingAddress?.country ?? 'GB',
        state: user.customer?.shippingAddress?.state ?? null,
      },
    }),
    [user]
  );

  return (
    <CreateOrEditCustomer
      title="Profile"
      subtitle="Update your account details below."
      buttonLink="/my-account/edit-account/edit-password"
      buttonText="Update Password"
      ButtonIcon={FiKey}
      customer={customer}
    />
  );
};

export const Route = createFileRoute(
  '/_authenticated/my-account/edit-account/'
)({
  component: EditAccountComponent,
});
