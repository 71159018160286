import CheckoutViewOrder from '../../../components/CheckoutViewOrder';
import { doQuery } from '../../../utility/apollo';
import { GqlReadOrder } from '@monorepo/graphql/resources';
import { createFileRoute, notFound } from '@tanstack/react-router';

const CheckoutComponent = () => {
  const { order } = Route.useLoaderData();

  return <CheckoutViewOrder order={order} />;
};

export const Route = createFileRoute('/_neutral/checkout/$orderUuid')({
  loader: async ({ params }) => {
    try {
      const { data } = await doQuery({
        query: GqlReadOrder,
        variables: {
          uuid: params.orderUuid,
        },
      });

      return { order: data.readOrder };
    } catch (error) {
      throw notFound();
    }
  },
  component: CheckoutComponent,
});
