import { createFileRoute, useNavigate } from '@tanstack/react-router';
import CreateOrEditCustomer from '../../../../components/CreateOrEditCustomer';
import { useCallback } from 'react';

const AddCustomerComponent = () => {
  const navigate = useNavigate();

  const onAfterSubmit = useCallback(
    (uuid?: string) => {
      if (uuid) {
        void navigate({
          to: `/admin/customers/${uuid}`,
        });
      }
    },
    [navigate]
  );

  return (
    <CreateOrEditCustomer
      title="Add Customer"
      subtitle="Add the customer below"
      onAfterAdd={onAfterSubmit}
    />
  );
};

export const Route = createFileRoute('/_authenticated/admin/customers/add')({
  component: AddCustomerComponent,
});
