import { useBackgroundQuery, useMutation } from '@apollo/client';
import { GqlDeleteEmailToken, GqlIndexEmailTokens } from '@monorepo/graphql/resources';
import DataLayout, { LinkType, TableColumn } from './DataLayout';
import { FiPlus, FiTag } from 'react-icons/fi';
import { useCallback, useMemo } from 'react';
import { IndexEmailTokensQuery } from '@monorepo/graphql';
import classNames from 'classnames';
import { elements } from '../utility/styles';
import { Button } from './Button';
import env from '../environment';

enum TableColumnEnum {
  email = 'email',
  expiresAt = 'expiresAt',
  actions = 'actions',
}

type Token = IndexEmailTokensQuery['indexEmailTokens'][0];

const defaultFilters = {
  term: null,
};

const links: LinkType[] = [
  {
    to: `https://login.microsoftonline.com/${env.oauth.tentantId}/oauth2/v2.0/authorize?
client_id=${env.oauth.clientId}
&response_type=code
&redirect_uri=${window.location.origin}/api/email-token
&response_mode=query
&scope=https://graph.microsoft.com%2Fmail.send https://graph.microsoft.com/mail.send.shared https://graph.microsoft.com/mail.readwrite https://graph.microsoft.com/user.read offline_access`,
    label: `Add Email Sender`,
    Icon: FiPlus,
  },
];

const MessageTemplatesList = () => {
    const [query] = useBackgroundQuery(GqlIndexEmailTokens);
    const [mutation] = useMutation(GqlDeleteEmailToken, {
        refetchQueries: [
            { query: GqlIndexEmailTokens }
        ],
        awaitRefetchQueries: true
    });
    
  const tableColumns = useMemo<Array<TableColumn<Token>>>(
    () => [
      {
        key: TableColumnEnum.email,
        label: 'Email',
        handler: (item: Token) => (
          <span>{item.email}</span>
        ),
        style: { width: '30%' },
      },
      {
        key: TableColumnEnum.expiresAt,
        label: 'Expiry',
        handler: (item: Token) => <span>{new Date(item.expiresAt).toNiceDateTimeFormat()}</span>,
        style: { width: '60%' },
      },
      {
        key: TableColumnEnum.actions,
        label: '',
        handler: (item: Token) => (
          <Button
            type='button'
            className={classNames(
              elements.button.secondary,
              'text-xs inline-flex whitespace-nowrap'
            )}
            onClick={() => void mutation({
                variables: {
                    uuid: item.uuid
                }
            })}
          >
            Delete
          </Button>
        ),
        style: { width: '10%' },
      },
    ],
    [mutation]
  );

  const extractItems = useCallback(
    (d: IndexEmailTokensQuery) => d.indexEmailTokens,
    []
  );

  return (
    <DataLayout
      Icon={FiTag}
      title="Email Senders"
      subtitle="Manage your email senders below"
      tableColumns={tableColumns}
      baseFilters={defaultFilters}
      links={links}
      query={query}
      extractItems={extractItems}
    />
  );
};

export default MessageTemplatesList;
