import { useBackgroundQuery } from '@apollo/client';
import { GqlIndexMessageTemplates } from '@monorepo/graphql/resources';
import DataLayout, { LinkType, TableColumn } from './DataLayout';
import { FiPlus, FiTag } from 'react-icons/fi';
import { useCallback, useMemo } from 'react';
import { IndexMessageTemplatesQuery } from '@monorepo/graphql';
import { Link } from '@tanstack/react-router';
import classNames from 'classnames';
import { elements } from '../utility/styles';

enum TableColumnEnum {
  name = 'name',
  path = 'path',
  actions = 'actions',
}

type Template = IndexMessageTemplatesQuery['indexMessageTemplates'][0];

const defaultFilters = {
  term: null,
};

const links: LinkType[] = [
  {
    to: '/admin/quotes/message-templates/add',
    label: `Add Email Template`,
    Icon: FiPlus,
  },
];

const MessageTemplatesList = () => {
  const [query] = useBackgroundQuery(GqlIndexMessageTemplates);
  const tableColumns = useMemo<Array<TableColumn<Template>>>(
    () => [
      {
        key: TableColumnEnum.name,
        label: 'Name',
        handler: (template: Template) => (
          <Link to={template.uuid}>{template.name}</Link>
        ),
        style: { width: '30%' },
      },
      {
        key: TableColumnEnum.path,
        label: 'Path',
        handler: (template: Template) => <span>{template.path}</span>,
        style: { width: '60%' },
      },
      {
        key: TableColumnEnum.actions,
        label: '',
        handler: (template: Template) => (
          <Link
            className={classNames(
              elements.button.secondary,
              'text-xs inline-flex whitespace-nowrap'
            )}
            to={template.uuid}
          >
            View
          </Link>
        ),
        style: { width: '10%' },
      },
    ],
    []
  );

  const extractItems = useCallback(
    (d: IndexMessageTemplatesQuery) => d.indexMessageTemplates,
    []
  );

  return (
    <DataLayout
      Icon={FiTag}
      title="Message Templates"
      subtitle="Manage your message templates below"
      tableColumns={tableColumns}
      baseFilters={defaultFilters}
      links={links}
      query={query}
      extractItems={extractItems}
    />
  );
};

export default MessageTemplatesList;
