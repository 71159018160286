import { gql } from '@apollo/client';
import {
  ConvertLegacyOrderToQuoteMutation,
  ConvertLegacyOrderToQuoteMutationVariables,
  LegacyIndexCustomersQuery,
  LegacyIndexCustomersQueryVariables,
  LegacyIndexOrdersQuery,
  LegacyIndexOrdersQueryVariables,
  ReadLegacyOrderQuery,
  ReadLegacyOrderQueryVariables,
} from '../__generated__/graphql';
import { GqlPagination } from './pagination';
import { GqlQuote } from './quotes';
import { GqlAddress } from './address';

export const GqlLegacyOrder = gql`
  fragment LegacyOrderFragment on LegacyOrder {
    id
    reference
    status
    createdAt
    paymentMethod
    shippingMethod
    customerReference
    emailMessage
    currency
    purchaseOrderUrl
    purchaseOrderReference
    trackingReference
    leadTime
    isQuote
    admin {
      displayName
      email
    }
    lineItems {
      type
      name
      qty
      sku
      amount
      meta {
        key
        value
      }
    }
    attachments {
      url
    }
    billingAddress {
      ...AddressFragment
    }
    shippingAddress {
      ...AddressFragment
    }
    notes {
      text
      createdAt
    }
    total
  }
  ${GqlAddress}
`;

export const GqlLegacyOndexCustomers = gql<
  LegacyIndexCustomersQuery,
  LegacyIndexCustomersQueryVariables
>`
  query LegacyIndexCustomers($input: LegacyCustomerFilterInput) {
    indexLegacyCustomers(input: $input) {
      pagination {
        ...PaginationFragment
      }
      items {
        id
        displayName
        firstName
        lastName
        email
        phone
        billingAddress {
          ...AddressFragment
        }
        shippingAddress {
          ...AddressFragment
        }
      }
    }
  }
  ${GqlPagination}
  ${GqlAddress}
`;

export const GqlLegacyIndexOrders = gql<
  LegacyIndexOrdersQuery,
  LegacyIndexOrdersQueryVariables
>`
  query LegacyIndexOrders($input: LegacyOrderFilterInput) {
    indexLegacyOrders(input: $input) {
      pagination {
        ...PaginationFragment
      }
      items {
        ...LegacyOrderFragment
      }
    }
  }
  ${GqlPagination}
  ${GqlLegacyOrder}
`;

export const GqlReadLegacyOrder = gql<
  ReadLegacyOrderQuery,
  ReadLegacyOrderQueryVariables
>`
  query ReadLegacyOrder($id: Int!) {
    readLegacyOrder(id: $id) {
      ...LegacyOrderFragment
    }
  }
  ${GqlLegacyOrder}
`;

export const GqlConvertLegacyOrderToQote = gql<
  ConvertLegacyOrderToQuoteMutation,
  ConvertLegacyOrderToQuoteMutationVariables
>`
  mutation ConvertLegacyOrderToQuote($id: Int!) {
    convertLegacyOrderToQuote(id: $id) {
      ...QuoteFragment
    }
  }
  ${GqlQuote}
`;
