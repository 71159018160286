import { useCallback, useMemo, useState } from 'react';
import { DateValueType } from 'react-tailwindcss-datepicker';
import DatePicker from './DatePicker';
import Dropdown, { Item } from './Dropdown';
import {
  IndexQuotesQuery,
  LineItemTypeEnum,
  QuoteFilterFunctionInput,
  QuoteStatusEnum,
  formatMoney,
  quoteStatuses,
} from '@monorepo/graphql';
import { elements } from '../utility/styles';
import classNames from 'classnames';
import { useBackgroundQuery } from '@apollo/client';
import { Link } from '@tanstack/react-router';
import DataLayout, { FilterOption, LinkType, TableColumn } from './DataLayout';
import { FiBriefcase } from 'react-icons/fi';
import { GqlIndexQuotes } from '@monorepo/graphql/resources';
import CustomerSearchField from './CustomerSearchField';
import ProductSearchField from './ProductSearchField';
import Input from './Input';
import UserSearchField from './UserSearchField';

export enum TableColumnEnum {
  reference = 'reference',
  customerReference = 'customerReference',
  createdAt = 'createdAt',
  customer = 'customer',
  total = 'total',
  status = 'status',
  products = 'products',
  actions = 'actions',
}

export enum FilterOptionEnum {
  dateRange = 'dateRange',
  customer = 'customer',
  creator = 'creator',
  status = 'status',
  search = 'search',
  productSearch = 'productSearch',
}

type Quote = IndexQuotesQuery['indexQuotes']['items'][0];

function QuotesList({
  title = 'Quotes',
  links = [],
  legacyUrl,
  hiddenColumns,
  hiddenFilters,
  initialFilters,
}: {
  title?: string;
  links?: LinkType[];
  legacyUrl: string;
  hiddenColumns?: TableColumnEnum[];
  hiddenFilters?: FilterOptionEnum[];
  initialFilters?: Partial<QuoteFilterFunctionInput>;
}) {
  const defaultFilters = useMemo<QuoteFilterFunctionInput>(
    () => ({
      customerUuid: null,
      creatorUuid: null,
      statuses: [],
      from: null,
      to: null,
      term: null,
      productTerm: null,
      ...(initialFilters ?? {}),
    }),
    [initialFilters]
  );

  const tableColumns = useMemo<Array<TableColumn<Quote>>>(
    () =>
      [
        {
          key: TableColumnEnum.reference,
          label: 'Quote #',
          handler: (quote: Quote) => (
            <Link to={quote.uuid}>{quote.accountingReference}</Link>
          ),
          style: { width: '10%' },
        },
        {
          key: TableColumnEnum.customerReference,
          label: 'Ref',
          handler: (quote: Quote) => (
            <Link to={quote.uuid}>{quote.customerReference}</Link>
          ),
          style: { width: '12.5%' },
        },
        {
          key: TableColumnEnum.createdAt,
          label: 'Date',
          handler: (quote: Quote) => (
            <span>{new Date(quote.createdAt).toNiceFormat()}</span>
          ),
          style: { width: '12.5%' },
        },
        {
          key: TableColumnEnum.customer,
          label: 'Customer',
          handler: (quote: Quote) => (
            <span>
              {[quote.customer?.firstName, quote.customer?.lastName]
                .filter(Boolean)
                .join(' ')}
            </span>
          ),
          style: { width: '12.5%' },
        },
        {
          key: TableColumnEnum.total,
          label: 'Amount (£)',
          handler: (quote: Quote) => (
            <span>{formatMoney(quote.total, quote.currency)}</span>
          ),
          style: { width: '12.5%' },
        },
        {
          key: TableColumnEnum.status,
          label: 'Status',
          handler: (quote: Quote) => (
            <span>
              {quoteStatuses.find((s) => s.value === quote.status)?.label}
            </span>
          ),
          style: { width: '12.5%' },
        },
        {
          key: TableColumnEnum.products,
          label: 'Products',
          handler: (quote: Quote) => {
            const products = quote.lineItems.filter(
              (l) => l.type === LineItemTypeEnum.product
            );

            const productLabel = `${products.find(Boolean)?.title}${
              products.length > 1 ? ` + ${products.length - 1} more` : ''
            }`;

            return <span>{productLabel}</span>;
          },
          style: { width: '17.5%' },
        },
        {
          key: TableColumnEnum.actions,
          label: '',
          handler: (quote: Quote) => (
            <Link
              className={classNames(
                elements.button.secondary,
                'text-xs inline-flex whitespace-nowrap'
              )}
              to={quote.uuid}
            >
              View
            </Link>
          ),
          style: { width: '10%' },
        },
      ].filter((c) => !hiddenColumns?.includes(c.key)),
    [hiddenColumns]
  );

  const [dateRange, setDateRange] = useState<DateValueType>({
    startDate: null,
    endDate: null,
  });

  const [customerUuid, setCustomerUuid] = useState<string | null>(null);
  const [creatorUuid, setCreatorUuid] = useState<string | null>(null);

  const [selectedStatuses, setSelectedStatuses] = useState<QuoteStatusEnum[]>(
    []
  );

  const setActiveStatus = useCallback((item: Item) => {
    setSelectedStatuses((statuses) =>
      statuses.includes(item.value as QuoteStatusEnum)
        ? statuses.filter((status) => status !== item.value)
        : [...statuses, item.value as QuoteStatusEnum]
    );
  }, []);

  const [term, setTerm] = useState<string | undefined>();
  const [productTerm, setProductTerm] = useState<string | undefined>();

  const [filters, setFilters] =
    useState<QuoteFilterFunctionInput>(defaultFilters);

  const currentFilters = useMemo(
    () => ({
      customerUuid,
      creatorUuid,
      statuses: selectedStatuses,
      from: dateRange?.startDate,
      to: dateRange?.endDate,
      term,
      productTerm,
    }),
    [selectedStatuses, dateRange, term, customerUuid, productTerm, creatorUuid]
  );

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);

  const [query] = useBackgroundQuery(GqlIndexQuotes, {
    variables: {
      input: {
        filters,
        orderBy: null,
        pagination: {
          page,
          perPage,
        },
      },
    },
  });

  const resetFilters = useCallback(() => {
    setDateRange({ startDate: null, endDate: null });

    setSelectedStatuses([]);

    setTerm(undefined);

    setProductTerm(undefined);

    setCustomerUuid(null);

    setCreatorDefaultValue(undefined);

    setCreatorUuid(null);

    setCustomerDefaultValue(undefined);
  }, []);

  const applyFilters = useCallback(() => {
    setFilters({
      customerUuid,
      creatorUuid,
      statuses: selectedStatuses,
      from: dateRange?.startDate
        ? new Date(dateRange.startDate).toISOString()
        : null,
      to: dateRange?.endDate ? new Date(dateRange.endDate).toISOString() : null,
      term,
      productTerm,
    });
  }, [selectedStatuses, dateRange, term, customerUuid, productTerm, creatorUuid]);

  const [customerDefaultValue, setCustomerDefaultValue] = useState<
    string | undefined
  >();

  const [creatorDefaultValue, setCreatorDefaultValue] = useState<
    string | undefined
  >();

  const filterOptions = useMemo<FilterOption[]>(
    () =>
      [
        {
          key: FilterOptionEnum.search,
          Component: ({ term: item }: { term?: string }) => (
            <Input
              className="w-full"
              name="term"
              value={item}
              placeholder='Search by Order #'
              onInput={(e) => setTerm(e.currentTarget.value)}
              onKeyDown={(e) => e.stopPropagation()}
              useFormik={false}
            />
          ),
        },
        {
          key: FilterOptionEnum.dateRange,
          label: 'Date Range',
          Component: () => (
            <DatePicker value={dateRange} setValue={setDateRange} useRange />
          ),
        },
        {
          key: FilterOptionEnum.customer,
          Component: () => (
            <CustomerSearchField
              onSelect={(customer) => {
                setCustomerUuid(customer.uuid);

                setCustomerDefaultValue(customer.displayName);
              }}
              defaultValue={customerDefaultValue}
            />
          ),
        },
        {
          key: FilterOptionEnum.creator,
          Component: () => (
            <UserSearchField
              onSelect={(user) => {
                setCreatorUuid(user.uuid);

                setCreatorDefaultValue(user.displayName);
              }}
              defaultValue={creatorDefaultValue}
            />
          ),
        },
        {
          key: FilterOptionEnum.status,
          label: 'Status',
          Component: () => (
            <Dropdown
              placeholder="Filter by Status"
              name="status"
              options={quoteStatuses}
              onChange={setActiveStatus}
              value={selectedStatuses}
            />
          ),
        },

        {
          key: FilterOptionEnum.productSearch,
          Component: ({ term: item }: { term?: string }) => (
            <ProductSearchField
              onSelect={(product) => setProductTerm(product.name)}
              onInput={setProductTerm}
              defaultValue={item}
            />
          ),
        },
      ].filter((f) => !hiddenFilters?.includes(f.key)),
    [
      dateRange,
      selectedStatuses,
      customerDefaultValue,
      creatorDefaultValue,
      setActiveStatus,
      hiddenFilters,
    ]
  );

  const extractItems = useCallback(
    (data: IndexQuotesQuery) => data.indexQuotes.items,
    []
  );

  const extractPagination = useCallback(
    (data: IndexQuotesQuery) => data.indexQuotes.pagination,
    []
  );

  return (
    <DataLayout
      Icon={FiBriefcase}
      title={title}
      subtitle={
        <>
          Manage your quotes below, or view{' '}
          <Link to={legacyUrl} className="text-red underline">
            legacy quotes
          </Link>
          .
        </>
      }
      setPage={setPage}
      setPerPage={setPerPage}
      tableColumns={tableColumns}
      filterOptions={filterOptions}
      onReset={resetFilters}
      onConfirm={applyFilters}
      currentFilters={currentFilters}
      baseFilters={defaultFilters}
      term={term}
      links={links}
      query={query}
      extractItems={extractItems}
      extractPagination={extractPagination}
    />
  );
}

export default QuotesList;
