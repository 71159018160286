import { useCallback } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { LoginFields } from './LoginFields';

const Login = () => {
  const navigate = useNavigate();

  const onLogin = useCallback(() => {
    void navigate({
      to: '/my-account',
    });
  }, [navigate]);

  return (
    <div className="flex flex-col grow bg-white rounded-md p-5">
      <div className="flex justify-center mb-10">
        <img src="/v2-assets/logo-old.svg" alt="Logo" className="w-40" />
      </div>
      <LoginFields onLogin={onLogin} hideIcons fullWidthButton />
    </div>
  );
};

export default Login;
