import {
  LegacyLineItemTypeEnum,
  LegacyOrderFragmentFragment,
  LegacyOrderStatusEnum,
  RoleEnum,
  formatMoney,
} from '@monorepo/graphql';
import { statuses } from './LegacyOrdersList';
import PageHeader from './PageHeader';
import { FiBriefcase, FiShoppingCart } from 'react-icons/fi';
import { elements } from '../utility/styles';
import { useMutation } from '@apollo/client';
import { GqlConvertLegacyOrderToQote } from '@monorepo/graphql/resources';
import { useNavigate } from '@tanstack/react-router';
import { toaster } from '../utility/toast';
import Loader from './Loader';
import classNames from 'classnames';
import { useUser } from '../utility/authentication';
import { useCountriesAndCities } from './AddressFieldGroup';
import { Tooltip } from 'react-tooltip';
import { Button } from './Button';

interface Props {
  order: LegacyOrderFragmentFragment;
}

const ViewLegacyOrder = ({ order }: Props) => {
  const entity = order.isQuote ? 'Legacy Quote' : 'Legacy Order';
  const navigate = useNavigate();
  const { user } = useUser();
  const [convertToQuote, { loading: convertLoading }] = useMutation(
    GqlConvertLegacyOrderToQote,
    {
      variables: { id: order.id },
      onCompleted: (data) => {
        void navigate({
          to:
            user.role === RoleEnum.admin
              ? `/admin/quotes/${data.convertLegacyOrderToQuote.uuid}`
              : `/my-account/quotes/${data.convertLegacyOrderToQuote.uuid}`,
        });

        toaster.success(
          {
            title: 'Order Converted',
            text: 'The order has been successfully converted to a quote',
          },
          {
            autoClose: 5000,
          }
        );
      },
    }
  );

  const { countries } = useCountriesAndCities();

  return (
    <div>
      <PageHeader
        title={entity}
        Icon={order.isQuote ? FiBriefcase : FiShoppingCart}
      />
      <p className="text-sm mb-5">
        {entity} <strong className="text-dark">{order.reference}</strong>, sent
        on{' '}
        <strong className="text-dark">
          {new Date(order.createdAt).toNiceFormat()}
        </strong>{' '}
        . Status{' '}
        <strong className="text-dark">
          {statuses.find((s) => s.value === order.status)?.label}
        </strong>
      </p>
      {(!!order.customerReference || !!order.emailMessage) && (
        <div className="bg-white rounded-md mb-5">
          <h3 className="text-lg text-black font-semibold border-b border-dark/20 py-3 px-5">
            Details
          </h3>
          <div className="p-5">
            {!!order.customerReference && (
              <div className="mb-5">
                <strong className="font-semibold text-black mb-2 block">
                  Customer Reference
                </strong>
                <p className="text-sm">{order.customerReference}</p>
              </div>
            )}
            {!!order.emailMessage && (
              <div>
                <strong className="font-semibold text-black mb-2 block">
                  Email Message
                </strong>
                <div
                  className="text-sm ugc"
                  dangerouslySetInnerHTML={{ __html: order.emailMessage }}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="p-5 rounded-md bg-white">
        <div className="flex mb-8 -mx-2">
          <div className="w-1/3 px-2">
            <h4 className="text-base font-semibold text-black mb-2">
              Billing Address
            </h4>
            {[
              order.billingAddress.firstName,
              order.billingAddress.lastName,
              order.billingAddress.company,
              order.billingAddress.line1,
              order.billingAddress.line2,
              order.billingAddress.city,
              order.billingAddress.state,
              order.billingAddress.postCode,
              countries.find((c) => c.isoCode === order.billingAddress.country)
                ?.name,
            ]
              .filter(Boolean)
              .map((item, index) => (
                <p key={item} className="text-sm mb-1">
                  {item}
                </p>
              ))}
          </div>
          <div className="w-1/3 px-2">
            <h4 className="text-base font-semibold text-black mb-2">
              Shipping Address
            </h4>
            {[
              order.shippingAddress.firstName,
              order.shippingAddress.lastName,
              order.shippingAddress.company,
              order.shippingAddress.line1,
              order.shippingAddress.line2,
              order.shippingAddress.city,
              order.shippingAddress.state,
              order.shippingAddress.postCode,
              countries.find((c) => c.isoCode === order.shippingAddress.country)
                ?.name,
            ]
              .filter(Boolean)
              .map((item, index) => (
                <p key={item} className="text-sm mb-1">
                  {item}
                </p>
              ))}
          </div>
          <div className="w-1/3 px-2 flex">
            <div className="flex flex-col items-end justify-end grow">
              {order.status === LegacyOrderStatusEnum.pendingQuote &&
                (convertLoading ? (
                  <Loader size="1.5rem" />
                ) : (
                  <Button
                    type="button"
                    className={classNames(
                      elements.button.primary,
                      'mb-5 last:mb-0'
                    )}
                    onClick={async () => {
                      await convertToQuote();
                    }}
                  >
                    Convert to New Quote
                  </Button>
                ))}
              {!!order.purchaseOrderUrl && (
                <a
                  href={order.purchaseOrderUrl}
                  target="_blank"
                  className={classNames(
                    elements.button.secondary,
                    'mb-5 last:mb-0'
                  )}
                  rel="noreferrer"
                >
                  Download Purchase Order
                </a>
              )}
              {order.attachments.length > 0 && (
                <>
                  {order.attachments.map((attachment, index) => (
                    <a
                      key={attachment.url}
                      href={attachment.url}
                      target="_blank"
                      className={classNames(
                        elements.button.secondary,
                        'mb-5 last:mb-0'
                      )}
                      rel="noreferrer"
                    >
                      Download Attachment #{index + 1}
                    </a>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
        <table className="w-full text-sm mb-8">
          <thead>
            <tr>
              <th className="text-left text-dark font-bold p-4 border border-black/20">
                Qty
              </th>
              <th className="text-left text-dark font-bold p-4 border border-black/20 border-l-0">
                Product / Description
              </th>
              <th className="text-right text-dark font-bold p-4 border border-black/20 border-l-0">
                Unit Price
              </th>
              <th className="text-right text-dark font-bold p-4 border border-black/20 border-l-0">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {order.lineItems
              .filter(
                (l) => l.type === LegacyLineItemTypeEnum.product && l.qty > 0
              )
              .map((item, index) => (
                <tr key={`${index}-${item.sku}`}>
                  <td className="text-left p-4 border-l border-b border-r border-black/20">
                    {item.qty}
                  </td>
                  <td className="text-left p-4 border-b  border-b border-r border-black/20">
                    <strong>{item.sku}</strong> - {item.name}
                    {item.meta.length > 0 && (
                      <ul className="mt-2 text-midGray">
                        {item.meta.map((m, i) => (
                          <li
                            key={`${i}-${m.key}-${m.value}`}
                            className="text-xs"
                          >
                            <strong>{m.key}:</strong> {m.value}
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                  <td className="text-right p-4 border-b  border-b border-r border-black/20">
                    {formatMoney(item.amount, order.currency)}
                  </td>
                  <td className="text-right p-4 border-b  border-r border-black/20">
                    {formatMoney(item.qty * item.amount, order.currency)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex -mx-4">
          <div className="w-2/3 px-4">
            {(!!order.purchaseOrderReference ||
              !!order.trackingReference ||
              !!order.leadTime ||
              !!order.admin) && (
              <ul className="mb-5 text-sm">
                {!!order.leadTime && (
                  <li className="mb-2 last:mb-0">
                    <strong>Lead Time:</strong> {order.leadTime}
                  </li>
                )}
                {!!order.purchaseOrderReference && (
                  <li className="mb-2 last:mb-0">
                    <strong className="block lg:inline">
                      Purchase Order Number:
                    </strong>{' '}
                    {order.purchaseOrderReference}
                  </li>
                )}
                {!!order.trackingReference && (
                  <li className="mb-2 last:mb-0">
                    <strong className="block lg:inline">
                      Tracking Reference:
                    </strong>{' '}
                    {order.trackingReference} (UPS)
                  </li>
                )}
                {!!order.admin && (
                  <li>
                    <strong>ABLE Contact:</strong>{' '}
                    <a
                      href={`mailto:${order.admin.email}`}
                      className="text-red underline"
                      data-tooltip-id="creator"
                      data-tooltip-content={`Send an email to ${order.admin.displayName}`}
                      data-tooltip-place="bottom"
                      target="_blank"
                    >
                      {order.admin.displayName}
                    </a>{' '}
                    on behalf of{' '}
                    {order.billingAddress.company ??
                      [
                        order.billingAddress.firstName,
                        order.billingAddress.lastName,
                      ]
                        .filter(Boolean)
                        .join(' ')}
                    <Tooltip id="creator" />
                  </li>
                )}
              </ul>
            )}
          </div>
          <div className="w-1/3 text-sm px-4">
            <div className="p-2 rounded-md">
              {order.lineItems
                .filter((l) => l.type === LegacyLineItemTypeEnum.shipping)
                .map((item, index) => (
                  <div
                    key={`${index}-${item.sku}`}
                    className="flex items-center justify-between mb-2"
                  >
                    <div>
                      <strong className="block">Delivery & Packing:</strong>
                      <span className="italic">{item.name}</span>
                    </div>
                    {formatMoney(item.amount, order.currency)}
                  </div>
                ))}
              {order.lineItems
                .filter((l) => l.type === LegacyLineItemTypeEnum.fee)
                .map((item, index) => (
                  <div
                    key={`${index}-${item.sku}`}
                    className="flex items-center justify-between mb-2"
                  >
                    <strong>{item.name}:</strong>{' '}
                    {formatMoney(item.amount, order.currency)}
                  </div>
                ))}
              <div className="flex items-center justify-between mb-2">
                <strong>Subtotal:</strong>{' '}
                {formatMoney(
                  order.lineItems
                    .filter((l) => l.type !== LegacyLineItemTypeEnum.tax)
                    .reduce(
                      (acc, item) => acc + Math.max(item.qty, 1) * item.amount,
                      0
                    ),
                  order.currency
                )}
              </div>
              {order.lineItems
                .filter((l) => l.type === LegacyLineItemTypeEnum.tax)
                .map((item, index) => (
                  <div
                    key={`${index}-${item.sku}`}
                    className="flex items-center justify-between mb-5 pb-5 border-b border-black/20"
                  >
                    <strong>{item.name}:</strong>{' '}
                    {formatMoney(item.amount, order.currency)}
                  </div>
                ))}
              <div className="flex items-center justify-between text-base">
                <strong>Total:</strong>{' '}
                <strong>{formatMoney(order.total, order.currency)}</strong>
              </div>
            </div>
          </div>
        </div>
        {order.notes.length > 0 && (
          <div className="mt-8">
            <h3 className="text-lg font-semibold text-black mb-2">Notes</h3>
            <ul className="text-sm list-disc pl-5">
              {order.notes.map((note, index) => (
                <li key={note.createdAt} className="mb-2 last:mb-0">
                  {note.text} -{' '}
                  <strong>
                    {new Date(note.createdAt).toNiceDateTimeFormat()}
                  </strong>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewLegacyOrder;
