import { useFormikContext } from 'formik';
import { elements } from '../utility/styles';
import { FiArrowRight } from 'react-icons/fi';
import { AddressInput } from '@monorepo/graphql';
import { Button } from './Button';

const ShippingContinueButton = () => {
  const {
    values: { shippingAddress },
  } = useFormikContext<{ shippingAddress: AddressInput }>();

  return (
    <Button
      type="submit"
      className={elements.button.primary}
      disabled={!shippingAddress.line1}
      hotjarEvent="Checkout_ContinueFromShipping"
    >
      Continue <FiArrowRight className="ml-2" size={24} />
    </Button>
  );
};

export default ShippingContinueButton;
