import { LinkType } from '../../../../components/DataLayout';
import QuotesList from '../../../../components/QuotesList';
import { createFileRoute } from '@tanstack/react-router';
import { FiPlus } from 'react-icons/fi';

export interface SearchParams {
  customerUuid?: string;
}

const links: LinkType[] = [
  {
    to: '/admin/quotes/add',
    label: `Create Quote`,
    Icon: FiPlus,
  },
];

const QuotesListComponent = () => {
  const { customerUuid } = Route.useSearch();

  return (
    <QuotesList
      title="Customer Quotes"
      links={links}
      legacyUrl="/admin/orders/legacy"
      initialFilters={{ customerUuid }}
    />
  );
};

export const Route = createFileRoute('/_authenticated/admin/quotes/')({
  validateSearch: (search: Record<string, unknown>): SearchParams => ({
    customerUuid: search.customerUuid as string | undefined,
  }),
  component: QuotesListComponent,
});
