import { gql } from '@apollo/client';
import { GqlTokens } from './user';
import {
  AbortCheckoutPaymentMutation,
  AbortCheckoutPaymentMutationVariables,
  AddProductToCartMutation,
  AddProductToCartMutationVariables,
  AssignedCouponToCartMutation,
  AssignedCouponToCartMutationVariables,
  BulkAddProductsToCartMutation,
  BulkAddProductsToCartMutationVariables,
  ClearCartMutation,
  ClearCartMutationVariables,
  CompleteCheckoutMutation,
  CompleteCheckoutMutationVariables,
  GetCartCountQuery,
  GetCartCountQueryVariables,
  GetCartQuery,
  GetCartQueryVariables,
  RemoveItemFromCartMutation,
  RemoveItemFromCartMutationVariables,
  SelectShippingMethodMutation,
  SelectShippingMethodMutationVariables,
  UpdateCartBillingAddressMutation,
  UpdateCartBillingAddressMutationVariables,
  UpdateCartItemQtyMutation,
  UpdateCartItemQtyMutationVariables,
  UpdateCartShippingAddressMutation,
  UpdateCartShippingAddressMutationVariables,
  UpdatePurchaseOrderDetailsMutation,
  UpdatePurchaseOrderDetailsMutationVariables,
} from '../__generated__/graphql';
import { GqlAddress } from './address';
import { GqlOrder } from './orders';

export const GqlCartLineItem = gql`
  fragment CartLineItemFragment on CartLineItem {
    uuid
    sku
    title
    type
    taxRate
    productId
    imageUrl
    quantity
    originalAmount
    amount
    isSaleItem
    meta {
      key
      value
    }
    leadTime
    links {
      type
      url
    }
  }
`;

export const GqlCartData = gql`
  fragment CartDataFragment on CartData {
    billingAddress {
      ...AddressFragment
    }
    shippingAddress {
      ...AddressFragment
    }
    shippingMethod
    lineItems {
      ...CartLineItemFragment
    }
    currency
    coupon {
      code
    }
    purchaseOrderFileUuid
    purchaseOrderReference
    paymentIntentClientSecret
    customerUuid
    userUuid
    orderUuid
    paymentProcessing
  }
  ${GqlAddress}
  ${GqlCartLineItem}
`;

export const GqlCart = gql`
  fragment CartPageFragment on CartPage {
    errorNotices
    shippingMethods {
      type
      taxRate
      amount
      expiresAt
    }
    data {
      ...CartDataFragment
    }
    count
    uuid
    authTokens {
      ...TokensFragment
    }
    certificateInCart
  }
  ${GqlTokens}
  ${GqlCartData}
`;

export const GqlGetCart = gql<GetCartQuery, GetCartQueryVariables>`
  query GetCart {
    getCart {
      ...CartPageFragment
    }
  }
  ${GqlCart}
`;

export const GqlGetCartCount = gql<
  GetCartCountQuery,
  GetCartCountQueryVariables
>`
  query GetCartCount {
    getCartCount
  }
`;

export const GqlAddProductToCart = gql<
  AddProductToCartMutation,
  AddProductToCartMutationVariables
>`
  mutation AddProductToCart($input: AddProductToCartInput!) {
    addProductToCart(input: $input) {
      ...CartPageFragment
    }
  }
  ${GqlCart}
`;

export const GqlBulkAddProductsToCart = gql<
  BulkAddProductsToCartMutation,
  BulkAddProductsToCartMutationVariables
>`
  mutation BulkAddProductsToCart($input: BulkAddProductsToCart!) {
    bulkAddProductsToCart(input: $input) {
      ...CartPageFragment
    }
  }
  ${GqlCart}
`;

export const GqlAssignedCouponToCart = gql<
  AssignedCouponToCartMutation,
  AssignedCouponToCartMutationVariables
>`
  mutation AssignedCouponToCart($coupon: String!) {
    assignedCouponToCart(coupon: $coupon) {
      ...CartPageFragment
    }
  }
  ${GqlCart}
`;

export const GqlRemoveItemFromCart = gql<
  RemoveItemFromCartMutation,
  RemoveItemFromCartMutationVariables
>`
  mutation RemoveItemFromCart($itemUuid: ID!) {
    removeItemFromCart(itemUuid: $itemUuid) {
      ...CartPageFragment
    }
  }
  ${GqlCart}
`;

export const GqlSelectShippingMethod = gql<
  SelectShippingMethodMutation,
  SelectShippingMethodMutationVariables
>`
  mutation SelectShippingMethod($method: ShippingMethodEnum!) {
    selectShippingMethod(method: $method) {
      ...CartPageFragment
    }
  }
  ${GqlCart}
`;

export const GqlUpdatePurchaseOrderDetails = gql<
  UpdatePurchaseOrderDetailsMutation,
  UpdatePurchaseOrderDetailsMutationVariables
>`
  mutation UpdatePurchaseOrderDetails($input: PurchaseOrderDetailsInput!) {
    updatePurchaseOrderDetails(input: $input) {
      ...CartPageFragment
    }
  }
  ${GqlCart}
`;

export const GqlUpdateCartBillingAddress = gql<
  UpdateCartBillingAddressMutation,
  UpdateCartBillingAddressMutationVariables
>`
  mutation UpdateCartBillingAddress($input: AddressInput!) {
    updateCartBillingAddress(input: $input) {
      ...CartPageFragment
    }
  }
  ${GqlCart}
`;

export const GqlUpdateCartItemQty = gql<
  UpdateCartItemQtyMutation,
  UpdateCartItemQtyMutationVariables
>`
  mutation UpdateCartItemQty($itemUuid: ID!, $qty: Int!) {
    updateCartItemQty(itemUuid: $itemUuid, qty: $qty) {
      ...CartPageFragment
    }
  }
  ${GqlCart}
`;

export const GqlUpdateCartShippingAddress = gql<
  UpdateCartShippingAddressMutation,
  UpdateCartShippingAddressMutationVariables
>`
  mutation UpdateCartShippingAddress($input: AddressInput!) {
    updateCartShippingAddress(input: $input) {
      ...CartPageFragment
    }
  }
  ${GqlCart}
`;

export const GqlClearCart = gql<ClearCartMutation, ClearCartMutationVariables>`
  mutation ClearCart {
    clearCart {
      ...CartPageFragment
    }
  }
  ${GqlCart}
`;

export const GqlAbortCheckoutPayment = gql<AbortCheckoutPaymentMutation, AbortCheckoutPaymentMutationVariables>`
  mutation AbortCheckoutPayment($reason: String) {
    abortCheckoutPayment(reason: $reason) {
      ...CartPageFragment
    }
  }
  ${GqlCart}
`;

export const GqlCompleteCheckout = gql<
  CompleteCheckoutMutation,
  CompleteCheckoutMutationVariables
>`
  mutation CompleteCheckout($input: CompleteCheckoutInput!) {
    completeCheckout(input: $input) {
      order {
        ...OrderFragment
      }
      authTokens {
        ...TokensFragment
      }
    }
  }
  ${GqlOrder}
  ${GqlTokens}
`;
