import { useMutation } from '@apollo/client';
import { UserFragmentFragment } from '@monorepo/graphql';
import { GqlCompletePasswordResetMutation } from '@monorepo/graphql/resources';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { elements } from '../utility/styles';
import { Link } from '@tanstack/react-router';
import { FiCornerUpLeft } from 'react-icons/fi';
import PageLoader from './PageLoader';
import Input from './Input';
import { Button } from './Button';

interface Props {
  token: string;
  user: UserFragmentFragment;
}

const LoginConfirmResetPassword = ({ token, user }: Props) => {
  const [confirmResetPassword, { loading }] = useMutation(
    GqlCompletePasswordResetMutation
  );

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors: Record<string, string> = {};
        if (values.password !== values.confirmPassword) {
          errors.confirmPassword = 'Passwords do not match';
        }
        return errors;
      }}
      onSubmit={async (values, props) => {
        await confirmResetPassword({
          variables: {
            input: {
              email: user.email,
              newPassword: values.password,
              token,
            },
          },
        });

        props.resetForm({
          values: {
            password: '',
            confirmPassword: '',
          },
        });
      }}
    >
      <Form className="flex flex-col grow bg-white rounded-md p-5">
        <div className="flex justify-center mb-10">
          <img src="/v2-assets/logo-old.svg" alt="Logo" className="w-40" />
        </div>
        <div className="flex flex-grow flex-col w-full relative">
          <Input
            type="password"
            label="Password"
            name="password"
            containerClassName="mb-5"
            required
          />
          <Input
            type="password"
            name="confirmPassword"
            label="Confirm Password"
            containerClassName="mb-5"
            required
          />
          <Button
            type="submit"
            className={classNames(elements.button.primary, 'mb-5')}
          >
            Confirm Password
          </Button>
          <Link
            to="/login"
            className="flex items-center text-red justify-center text-sm"
          >
            <FiCornerUpLeft className="mr-2" />
            <span>Back to Login</span>
          </Link>
          {loading && <PageLoader />}
        </div>
      </Form>
    </Formik>
  );
};

export default LoginConfirmResetPassword;
