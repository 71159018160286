import { useMutation, useSuspenseQuery } from '@apollo/client';
import {
  LineItemTypeEnum,
  NoteTypeEnum,
  OrderFragmentFragment,
  orderStatuses,
  formatMoney,
  OrderStatusEnum,
} from '@monorepo/graphql';
import {
  GqlAddOrderNote,
  GqlDeleteOrderNote,
  GqlEditOrder,
  GqlEditOrderNote,
  GqlGetSignedUrl,
  GqlReadOrder,
  GqlSendOrderEmail,
} from '@monorepo/graphql/resources';
import PageHeader from './PageHeader';
import { FiEdit, FiShoppingCart, FiTrash } from 'react-icons/fi';
import { elements } from '../utility/styles';
import Dropdown from './Dropdown';
import { toaster } from '../utility/toast';
import { Field, Form, Formik } from 'formik';
import { Suspense, useState } from 'react';
import classNames from 'classnames';
import PageLoader from './PageLoader';
import Input from './Input';
import { useCountriesAndCities } from './AddressFieldGroup';
import { Button } from './Button';

interface Props {
  order: OrderFragmentFragment;
}

export const PurchaseOrderLink = ({
  purchaseOrderFileUuid,
}: {
  purchaseOrderFileUuid?: string;
}) => {
  const { data } = useSuspenseQuery(GqlGetSignedUrl, {
    variables: {
      uuids: purchaseOrderFileUuid ? [purchaseOrderFileUuid] : [],
      query: '',
    },
    skip: !purchaseOrderFileUuid,
  });

  const purchaseOrderUrl = data?.getSignedUrl.find(Boolean);

  return (
    purchaseOrderUrl && (
      <a
        href={purchaseOrderUrl}
        target="_blank"
        className={elements.button.primary}
        rel="noreferrer"
      >
        Download Purchase Order
      </a>
    )
  );
};

const EditOrder = ({ order }: Props) => {
  const [status, setStatus] = useState(order.status);
  const [updateOrder] = useMutation(GqlEditOrder, {
    update: (cache, data) => {
      if (data.data?.editOrder) {
        cache.writeQuery({
          query: GqlReadOrder,
          data: {
            readOrder: data.data.editOrder,
          },
          variables: {
            uuid: data.data.editOrder.uuid,
          },
        });
      }
    },
    onCompleted: (data) => {
      setStatus(data.editOrder.status);

      toaster.success(
        {
          title: 'Order Updated',
          text: `The order status has been updated to ${
            orderStatuses.find((s) => s.value === data.editOrder.status)?.label
          }`,
        },
        {
          autoClose: 5000,
        }
      );
    },
  });

  const [addOrderNote, { loading: addNoteLoading }] = useMutation(
    GqlAddOrderNote,
    {
      update: (cache) => {
        cache.evict({ id: `Order:${order.uuid}` });

        cache.gc();
      },
      onCompleted: (data) => {
        setNotes([data.addOrderNote, ...notes]);

        toaster.success(
          {
            title: 'Note Added',
            text: 'The note has been added to the order',
          },
          {
            autoClose: 5000,
          }
        );
      },
    }
  );

  const [editOrderNote, { loading: editNoteLoading }] = useMutation(
    GqlEditOrderNote,
    {
      update: (cache) => {
        cache.evict({ id: `Order:${order.uuid}` });

        cache.gc();
      },
      onCompleted: (data) => {
        setNotes(
          notes.map((n) =>
            n.uuid === data.editOrderNote.uuid ? data.editOrderNote : n
          )
        );

        setNoteToEdit(null);

        toaster.success(
          {
            title: 'Note Updated',
            text: 'The note has been updated',
          },
          {
            autoClose: 5000,
          }
        );
      },
    }
  );

  const [deleteOrderNote] = useMutation(GqlDeleteOrderNote, {
    update: (cache) => {
      cache.evict({ id: `Order:${order.uuid}` });

      cache.gc();
    },
    onCompleted: (data) => {
      setNotes(notes.filter((n) => n.uuid !== data.deleteOrderNote));

      toaster.success(
        {
          title: 'Note Deleted',
          text: 'The note has been deleted',
        },
        {
          autoClose: 5000,
        }
      );
    },
  });

  const [sendOrderEmail] = useMutation(GqlSendOrderEmail, {
    onCompleted: () => {
      toaster.success(
        {
          title: 'Order Email Sent',
          text: `The order email has been sent to ${order.billingAddress.email}`,
        },
        {
          autoClose: 5000,
        }
      );
    },
  });

  const [notes, setNotes] = useState(order.notes);
  const [noteToEdit, setNoteToEdit] = useState<string | null>(null);
  const { countries } = useCountriesAndCities();

  return (
    <div>
      <PageHeader title={`Order Summary`} Icon={FiShoppingCart} />
      <p className="text-sm mb-5">
        Order <strong className="text-dark">{order.accountingReference}</strong>
        , sent on{' '}
        <strong className="text-dark">
          {new Date(order.createdAt).toNiceFormat()}
        </strong>{' '}
        . Status{' '}
        <strong className="text-dark">
          {orderStatuses.find((s) => s.value === order.status)?.label}
        </strong>
      </p>
      <div className="p-5 bg-white rounded-md mb-5">
        <div className="flex flex-wrap mb-8 -mx-2">
          <div className="lg:w-1/3 px-2 mb-5 lg:mb-0">
            <h4 className="text-base font-semibold text-black mb-2">
              Billing Address
            </h4>
            {[
              [order.billingAddress.firstName, order.billingAddress.lastName]
                .filter(Boolean)
                .join(' '),
              order.billingAddress.company,
              order.billingAddress.line1,
              order.billingAddress.line2,
              order.billingAddress.city,
              order.billingAddress.state,
              order.billingAddress.postCode,
              countries.find((c) => c.isoCode === order.billingAddress.country)
                ?.name,
            ]
              .filter(Boolean)
              .map((item, index) => (
                <p key={item} className="text-sm mb-1">
                  {item}
                </p>
              ))}
          </div>
          <div className="lg:w-1/3 px-2 mb-5 lg:mb-0">
            <h4 className="text-base font-semibold text-black mb-2">
              Shipping Address
            </h4>
            {[
              `${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`,
              order.shippingAddress.lastName,
              order.shippingAddress.company,
              order.shippingAddress.line1,
              order.shippingAddress.line2,
              order.shippingAddress.city,
              order.shippingAddress.state,
              order.shippingAddress.postCode,
              countries.find((c) => c.isoCode === order.shippingAddress.country)
                ?.name,
            ]
              .filter(Boolean)
              .map((item, index) => (
                <p key={item} className="text-sm mb-1">
                  {item}
                </p>
              ))}
          </div>
          <div className="lg:w-1/3 px-2 flex flex-col justify-end">
            <div className="mb-5">
              <label className={elements.inputLabel}>Change Status</label>

              <Dropdown
                placeholder="Status"
                name="status"
                value={status}
                options={orderStatuses}
                onChange={async ({ value: newStatus }) => {
                  await updateOrder({
                    variables: {
                      input: {
                        uuid: order.uuid,
                        status: newStatus,
                      },
                    },
                  });
                }}
              />
            </div>
            {order.status !== OrderStatusEnum.pending && (
              <div className="mb-5">
                <Button
                  type="button"
                  className={elements.button.primary}
                  onClick={async () => {
                    await sendOrderEmail({
                      variables: {
                        orderUuid: order.uuid,
                      },
                    });
                  }}
                >
                  Send Order Email
                </Button>
              </div>
            )}
            <div className="relative">
              <Suspense fallback={<PageLoader />}>
                <PurchaseOrderLink
                  purchaseOrderFileUuid={
                    order.purchaseOrderFileUuid ?? undefined
                  }
                />
              </Suspense>
            </div>
          </div>
        </div>
        <div className="w-full overflow-x-auto mb-8">
          <table className="w-full text-sm">
            <thead>
              <tr>
                <th className="text-left text-dark font-bold p-4 border border-black/20 whitespace-nowrap">
                  Qty
                </th>
                <th className="text-left text-dark font-bold p-4 border border-black/20 border-l-0 whitespace-nowrap">
                  Product / Description
                </th>
                <th className="text-right text-dark font-bold p-4 border border-black/20 border-l-0 whitespace-nowrap">
                  Unit Price
                </th>
                <th className="text-right text-dark font-bold p-4 border border-black/20 border-l-0 whitespace-nowrap">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {order.lineItems
                .filter(
                  (l) => l.type === LineItemTypeEnum.product && l.quantity > 0
                )
                .map((item, index) => (
                  <tr key={`${index}-${item.sku}`}>
                    <td className="text-left p-4 border-l border-b border-r border-black/20">
                      {item.quantity}
                    </td>
                    <td className="text-left p-4 border-b  border-b border-r border-black/20">
                      <strong>{item.sku}</strong> - {item.title}
                      {item.meta.length > 0 && (
                        <ul className="mt-2 text-midGray">
                          {item.meta.map((m, i) => (
                            <li
                              key={`${i}-${m.key}-${m.value}`}
                              className="text-xs"
                            >
                              <strong>{m.key}:</strong> {m.value}
                            </li>
                          ))}
                        </ul>
                      )}
                      {!!item.leadTime && (
                        <p className="text-xs mt-2">
                          <strong>Lead Time:</strong> {item.leadTime}
                        </p>
                      )}
                    </td>
                    <td className="text-right p-4 border-b  border-b border-r border-black/20">
                      {formatMoney(item.amount, order.currency)}
                    </td>
                    <td className="text-right p-4 border-b  border-r border-black/20">
                      {formatMoney(item.quantity * item.amount, order.currency)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="flex flex-wrap justify-end -mx-4">
          <div className="lg:w-2/3 px-4 empty:hidden">
            {(!!order.purchaseOrderReference ||
              !!order.trackingReference ||
              !!order.inTouchReference ||
              !!order.accountingReference ||
              !!order.paymentMethodLabel) && (
              <Formik
                initialValues={{
                  trackingReference: order.trackingReference,
                  inTouchReference: order.inTouchReference,
                  accountingReference: order.accountingReference,
                }}
                onSubmit={async (values) => {
                  await updateOrder({
                    variables: {
                      input: {
                        uuid: order.uuid,
                        ...values,
                      },
                    },
                    onCompleted: () => {
                      toaster.success(
                        {
                          title: 'Order Updated',
                          text: 'The order has been updated',
                        },
                        {
                          autoClose: 5000,
                        }
                      );
                    },
                  });
                }}
              >
                <Form className="mb-5">
                  <ul className="mb-5 text-sm empty:hidden">
                    {!!order.purchaseOrderReference && (
                      <li className="mb-2 last:mb-0">
                        <strong>Purchase Order Number:</strong>{' '}
                        {order.purchaseOrderReference}
                      </li>
                    )}
                    {!!order.trackingReference && (
                      <li>
                        <Input
                          name="trackingReference"
                          label="Tracking Reference"
                        />
                      </li>
                    )}
                    {!!order.inTouchReference && (
                      <li>
                        <Input
                          name="inTouchReference"
                          label="InTouch Reference"
                        />
                      </li>
                    )}
                    {!!order.paymentMethodLabel && (
                      <li>
                        <strong>Payment Method:</strong>{' '}
                        {order.paymentMethodLabel}
                      </li>
                    )}
                  </ul>
                  {!!order.trackingReference ||
                  !!order.inTouchReference ||
                  !!order.accountingReference ? (
                    <Button type="submit" className={elements.button.primary}>
                      Update Order
                    </Button>
                  ) : null}
                </Form>
              </Formik>
            )}
          </div>
          <div className="lg:w-1/3 w-full text-sm px-4">
            <div className="p-2 rounded-md mb-5">
              {order.lineItems
                .filter((l) => l.type === LineItemTypeEnum.shipping)
                .map((item, index) => (
                  <p
                    key={`${index}-${item.sku}`}
                    className="flex items-center justify-between mb-2"
                  >
                    <div>
                      <strong className="block">Delivery & Packing:</strong>
                      <span className="italic">{item.title}</span>
                    </div>
                    {formatMoney(item.amount, order.currency)}
                  </p>
                ))}
              <p className="flex items-center justify-between mb-2">
                <strong>Subtotal:</strong>{' '}
                {formatMoney(
                  order.lineItems.reduce(
                    (acc, item) =>
                      acc + Math.max(item.quantity, 1) * item.amount,
                    0
                  ),
                  order.currency
                )}
              </p>
              <p className="flex items-center justify-between pb-5 border-b border-black/20 mb-5">
                <strong>Tax:</strong>{' '}
                {formatMoney(
                  order.lineItems
                    .filter((l) => l.taxRate > 0)
                    .reduce(
                      (acc, item) =>
                        acc +
                        Math.max(item.quantity, 1) * item.amount * item.taxRate,
                      0
                    ),
                  order.currency
                )}
              </p>
              <p className="flex items-center justify-between text-base">
                <strong>Total:</strong>{' '}
                <strong>{formatMoney(order.total, order.currency)}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-md">
        <h3 className="text-lg font-semibold text-black py-3 px-5 border-b border-black/20">
          Notes
        </h3>
        <div className="p-5">
          {notes.length > 0 ? (
            <ul className="text-sm mb-5">
              {notes.map((note) => (
                <li
                  key={note.uuid}
                  className={classNames('mb-2 last:mb-0', {
                    'list-disc ml-5': noteToEdit !== note.uuid,
                  })}
                >
                  <div className="flex items-center -mx-1">
                    <div
                      className={classNames('px-1', {
                        grow: noteToEdit === note.uuid,
                      })}
                    >
                      {noteToEdit === note.uuid ? (
                        <Formik
                          initialValues={{
                            text: note.text,
                          }}
                          onSubmit={async (values) => {
                            await editOrderNote({
                              variables: {
                                input: {
                                  uuid: note.uuid,
                                  note: values.text,
                                },
                              },
                            });
                          }}
                        >
                          <Form className="border-b border-black/20 pb-4 mb-5 relative">
                            <Field
                              as="textarea"
                              name="text"
                              className={classNames(
                                elements.textarea,
                                'mb-5 w-full h-32'
                              )}
                            />
                            {editNoteLoading ? (
                              <PageLoader />
                            ) : (
                              <Button
                                type="submit"
                                className={elements.button.primary}
                              >
                                Update Note
                              </Button>
                            )}
                          </Form>
                        </Formik>
                      ) : (
                        <span>
                          {note.text} -{' '}
                          <strong>
                            {new Date(note.createdAt).toNiceDateTimeFormat()}
                          </strong>
                        </span>
                      )}
                    </div>
                    {noteToEdit !== note.uuid && (
                      <div className="px-1">
                        {note.type === NoteTypeEnum.freeText && (
                          <ul className="flex -mx-1">
                            <li className="px-1 flex">
                              <Button
                                type="button"
                                onClick={() => setNoteToEdit(note.uuid)}
                              >
                                <FiEdit size={16} />
                              </Button>
                            </li>
                            <li className="px-1 flex">
                              <Button
                                type="button"
                                onClick={async () => {
                                  await deleteOrderNote({
                                    variables: {
                                      orderNoteUuid: note.uuid,
                                    },
                                  });
                                }}
                              >
                                <FiTrash size={16} />
                              </Button>
                            </li>
                          </ul>
                        )}
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-sm mb-5">
              No notes have been added to this order
            </p>
          )}
          <Formik
            initialValues={{
              text: '',
            }}
            onSubmit={async (values) => {
              await addOrderNote({
                variables: {
                  input: {
                    orderUuid: order.uuid,
                    note: values.text,
                  },
                },
              });
            }}
          >
            <Form className="relative">
              <Field
                as="textarea"
                name="text"
                className={classNames(elements.textarea, 'mb-5 h-32 w-full')}
                placeholder="Write a note..."
                rows={48}
              />
              {addNoteLoading ? (
                <PageLoader />
              ) : (
                <Button type="submit" className={elements.button.primary}>
                  Add Note
                </Button>
              )}
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditOrder;
