import { Link, useRouterState } from '@tanstack/react-router';
import classNames from 'classnames';
import { IconType } from 'react-icons';
import { To } from './NotFound';

interface Props {
  route: To;
  label: string;
  Icon?: IconType;
  children?: React.ReactNode;
  isChild?: boolean;
}

const MenuLink = ({ route, label, Icon, children, isChild }: Props) => {
  const router = useRouterState();
  const selected =
    route.split('/').length >= 3
      ? router.location.pathname.startsWith(route)
      : router.location.pathname === route;

  return (
    <li
      className={classNames('px-8 transition-colors', {
        'bg-white/10': selected && !isChild,
      })}
    >
      <Link
        to={route}
        className={classNames('block flex items-center whitespace-nowrap', {
          'font-bold': selected,
          'py-8': !isChild,
          'py-4': isChild,
        })}
      >
        {!!Icon && <Icon className="mr-4" />} {label}
      </Link>
      {selected ? children : null}
    </li>
  );
};

export default MenuLink;
