import { createFileRoute, notFound } from '@tanstack/react-router';
import NotFound from '../../../../../components/NotFound';
import { doQuery } from '../../../../../utility/apollo';
import { GqlReadCustomerWithCartData } from '@monorepo/graphql/resources';
import CustomerCart from '../../../../../components/CustomerCart';

const CustomerCartComponent = () => {
  const { customer } = Route.useLoaderData();

  return <CustomerCart customer={customer} />;
};

export const Route = createFileRoute(
  '/_authenticated/admin/customers/cart/$customerUuid'
)({
  loader: async ({ params }) => {
    try {
      const { data } = await doQuery({
        query: GqlReadCustomerWithCartData,
        variables: {
          uuid: params.customerUuid,
        },
      });

      return { customer: data.readCustomer };
    } catch (error) {
      throw notFound();
    }
  },
  notFoundComponent: () => <NotFound to="/admin/customers" />,
  component: CustomerCartComponent,
});
