import { RouterProvider, createRouter } from '@tanstack/react-router';
// eslint-disable-next-line import/extensions
import { routeTree } from './routeTree.gen';
import { hotjar } from 'react-hotjar';
import { AuthProvider, useAuth } from './utility/authentication';
import PageLoader from './components/PageLoader';
import { ReactTagManager } from 'react-gtm-ts';
import env from './environment';

if (env.gtm.length > 1) {
  ReactTagManager.init({
    code: env.gtm,
    debug: !!env.dev
  });
}

const router = createRouter({
  routeTree,
  context: {
    isAuthenticated: false,
  },
  defaultPendingMs: 0,
  defaultGcTime: 0,
  defaultPendingComponent: PageLoader,
});

router.history.subscribe(() => {
  hotjar.stateChange(router.history.location.pathname);
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

const InnerApp = () => {
  const { apiToken, user } = useAuth();

  return (
    <RouterProvider
      defaultPreload="intent"
      context={{
        isAuthenticated: !!apiToken && (!!user?.customer || !!user?.uuid),
      }}
      router={router}
    />
  );
};

export const ProtectedApp = () => (
  <AuthProvider>
    <InnerApp />
  </AuthProvider>
);
