type DeepNonNullable<T> = {
  [P in keyof T]: T[P] extends object
    ? DeepNonNullable<T[P]>
    : NonNullable<T[P]>;
};

const env = {
  apiUrl: import.meta.env.PUBLIC_API_URL,
  wpUrl: import.meta.env.PUBLIC_WP_URL,
  googleMapsApiKey: import.meta.env.PUBLIC_GOOGLE_MAPS_API_KEY,
  stripePublishableKey: import.meta.env.PUBLIC_STRIPE_PUBLISHABLE_KEY,
  tinyMceApiKey: import.meta.env.PUBLIC_TINYMCE_API_KEY,
  getAddressApiKey: import.meta.env.PUBLIC_GET_ADDRESS_API_KEY,
  gtm: import.meta.env.PUBLIC_GTM_ID,
  dev: !!import.meta.env.DEV,
  oauth: {
    clientId: import.meta.env.PUBLIC_OAUTH_CLIENT_ID,
    tentantId: import.meta.env.PUBLIC_OAUTH_TENANT_ID,
  },
  sentry: {
    environment: import.meta.env.PUBLIC_SENTRY_ENVIRONMENT,
  },
};

export default env as DeepNonNullable<typeof env>;
