import {
  createRootRouteWithContext,
  Outlet,
  redirect,
} from '@tanstack/react-router';
import NotFound from '../components/NotFound';
import { Suspense } from 'react';
import PageLoader from '../components/PageLoader';

const openRoutes = ['/login'];
const skippedRoutes = ['/cart', '/checkout'];

export const Route = createRootRouteWithContext<{ isAuthenticated: boolean }>()(
  {
    beforeLoad: ({ location, context }) => {
      if (!skippedRoutes.includes(location.pathname)) {
        if (context.isAuthenticated) {
          if (openRoutes.includes(location.pathname)) {
            throw redirect({
              to: '/my-account',
            });
          }
        } else {
          if (!openRoutes.includes(location.pathname)) {
            void redirect({
              to: '/login',
            });
          }
        }
      }
    },
    component: () => (
      <Suspense fallback={<PageLoader />}>
        <Outlet />
      </Suspense>
    ),
    notFoundComponent: () => <NotFound to="/" />,
  }
);
