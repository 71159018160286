import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { Button } from './Button';

type FormValues = Record<string, unknown>;

interface Props {
  children: React.ReactNode;
  showIfNotDirty?: boolean;
  message?: string;
  afterSubmit?: () => void;
  href?: string;
  positionalClassNames?: string;
}

const FloatingButton = ({
  children,
  showIfNotDirty,
  message,
  afterSubmit,
  href,
  positionalClassNames = 'bottom-8 right-8'
}: Props) => {
  const { dirty, isValid, errors, values, submitForm } =
    useFormikContext<FormValues>();

  const hasAtLeastOneValue =
    Object.values(values).length === 0 ||
    Object.values(values).some((value) =>
      Array.isArray(value) ? value.length > 0 : Boolean(value)
    );

  const isDirtyEnough = showIfNotDirty ? hasAtLeastOneValue : dirty;

  return (
    <Button
      type="submit"
      className={classNames(
        'group fixed rounded-full bg-red text-white p-5 z-10 transition-transform flex items-center justify-center',
        !isDirtyEnough ? 'translate-y-40' : '',
        !isValid ? 'opacity-50 cursor-not-allowed' : '',
        positionalClassNames
      )}
      disabled={!isDirtyEnough || !isValid}
      onClick={(e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        if (afterSubmit) {
          e.preventDefault();

          void submitForm().then(afterSubmit);
        }
      }}
      href={href}
      as={href ? 'a' : 'button'}
      target={href ? '_blank' : undefined}
      download={href ? 'download' : undefined}
    >
      {children}
      {Object.keys(errors).length > 0 && (
        <div className="absolute right-20 p-2 bg-red text-white rounded-full text-xs hide group-hover:flex">
          <div className="whitespace-nowrap">
            {Object.values(errors)[0] as string}
          </div>
        </div>
      )}
      {isValid && message && (
        <div className="absolute right-20 p-2 bg-green text-white rounded-full text-xs hide group-hover:flex">
          <div className="whitespace-nowrap">{message}</div>
        </div>
      )}
    </Button>
  );
};

export default FloatingButton;
