import classNames from 'classnames';
import { useState } from 'react';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { Button } from './Button';

const MetaList = ({
  meta,
}: {
  meta: Array<{ key: string; value: string }>;
}) => {
  const [showMeta, setShowMeta] = useState(false);

  return (
    <>
      <ul
        className={classNames('text-sm text-midGray', {
          hidden: !showMeta,
        })}
      >
        {meta.map((item) => (
          <li className="mb-px flex items-center" key={item.key}>
            <p className="break-words">
              <strong className="font-semibold">{item.key}:</strong>{' '}
              {item.value}
            </p>
          </li>
        ))}
      </ul>
      <Button
        type="button"
        className="text-red text-sm flex items-center font-medium mt-2.5"
        onClick={() => setShowMeta(!showMeta)}
      >
        Show {showMeta ? 'less' : 'more'}{' '}
        {showMeta ? (
          <IoChevronUp size={16} className="ml-1.5" />
        ) : (
          <IoChevronDown size={16} className="ml-1.5" />
        )}
      </Button>
    </>
  );
};

export default MetaList;
