import { useMutation } from '@apollo/client';
import {
  CreateMessageTemplateInput,
  MessageTemplateFragmentFragment,
} from '@monorepo/graphql';
import {
  GqlCreateMessageTemplate,
  GqlDeleteMessageTemplate,
  GqlEditMessageTemplate,
  GqlReadMessageTemplate,
} from '@monorepo/graphql/resources';
import PageHeader from './PageHeader';
import { FiTag, FiTrash } from 'react-icons/fi';
import { useNavigate } from '@tanstack/react-router';
import { Form, Formik } from 'formik';
import SubmitButton from './SubmitButton';
import PageLoader from './PageLoader';
import { toaster } from '../utility/toast';
import Loader from './Loader';
import Input from './Input';
import { Tooltip } from 'react-tooltip';
import { elements } from '../utility/styles';
import { Button } from './Button';

interface Props {
  template?: MessageTemplateFragmentFragment;
}

const CreateOrEditMessageTemplate = ({ template }: Props) => {
  const [updateTemplate, { loading: updateLoading }] = useMutation(
    GqlEditMessageTemplate,
    {
      update: (cache, data) => {
        if (data.data?.updateMessageTemplate) {
          cache.writeQuery({
            query: GqlReadMessageTemplate,
            data: {
              readMessageTemplate: data.data.updateMessageTemplate,
            },
            variables: {
              uuid: data.data.updateMessageTemplate.uuid,
            },
          });
        }
      },
    }
  );

  const [addTemplate, { loading: addLoading }] = useMutation(
    GqlCreateMessageTemplate,
    {
      update: (cache) => {
        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'indexMessageTemplates',
        });

        cache.gc();
      },
    }
  );

  const [deleteTemplate, { loading: deleteLoading }] = useMutation(
    GqlDeleteMessageTemplate,
    {
      update: (cache) => {
        cache.evict({ id: `MessageTemplate:${template?.uuid}` });

        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'indexMessageTemplates',
        });

        cache.gc();
      },
      onCompleted: () => {
        toaster.success(
          {
            title: 'Template deleted',
            text: 'The template has been deleted',
          },
          { autoClose: 2000 }
        );

        void navigate({
          to: '/admin/quotes/message-templates',
        });
      },
    }
  );

  const loading = updateLoading || addLoading;
  const initialValues: CreateMessageTemplateInput = {
    name: template?.name ?? '',
    path: template?.path ?? '',
  };

  const navigate = useNavigate();

  return (
    <div className="flex-grow flex flex-col">
      <PageHeader
        title={template ? 'Edit Template' : 'Add Template'}
        Icon={FiTag}
      >
        {!!template &&
          (deleteLoading ? (
            <Loader size="1.5rem" />
          ) : (
            <div>
              <Button
                type="button"
                className={elements.button.tertiary}
                data-tooltip-id="deleteTemplate"
                data-tooltip-content="Delete Template"
                data-tooltip-place="bottom"
                onClick={async () => {
                  if (
                    window.confirm(
                      'Are you sure you want to delete this template?'
                    )
                  )
                    await deleteTemplate({
                      variables: {
                        uuid: template.uuid,
                      },
                    });
                }}
              >
                <FiTrash size={20} />
              </Button>
              <Tooltip id="deleteTemplate" />
            </div>
          ))}
      </PageHeader>
      <p className="mb-5">
        {template ? 'Edit your template below' : 'Add your template below'}.
      </p>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: Partial<CreateMessageTemplateInput> = {};
          if (!values.name) {
            errors.name = 'Name is required';
          }
          if (!values.path) {
            errors.path = 'Path is required';
          }
          return errors;
        }}
        onSubmit={async (values, props) => {
          const data: CreateMessageTemplateInput = {
            ...values,
          };

          if (template) {
            await updateTemplate({
              variables: {
                input: {
                  ...data,
                  uuid: template.uuid,
                },
              },
            });

            props.resetForm({
              values,
            });
          } else {
            const newProduct = await addTemplate({
              variables: {
                input: {
                  ...data,
                },
              },
            });

            const uuid = newProduct.data?.createMessageTemplate.uuid;
            if (uuid) {
              void navigate({
                to: `/admin/quotes/message-templates/${uuid}`,
              });
            }
          }
          toaster.success(
            {
              title: 'Success',
              text: `You have successfully ${
                template ? 'updated' : 'added'
              } your template!`,
            },
            {
              autoClose: 5000,
            }
          );
        }}
      >
        <Form className="relative bg-white rounded-md p-5 pb-2">
          <div className="flex flex-wrap -mx-2 items-center">
            <div className="w-full lg:w-1/2 px-2 mb-5">
              <Input placeholder="Name" name="name" label="Name" required />
            </div>
            <div className="w-full lg:w-1/2 px-2 mb-5">
              <Input placeholder="Path" name="path" required label="Path" />
            </div>
          </div>
          <SubmitButton />
          {loading && <PageLoader />}
        </Form>
      </Formik>
    </div>
  );
};

export default CreateOrEditMessageTemplate;
