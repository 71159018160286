import NotFound from '../../../../../components/NotFound';
import ViewLegacyOrder from '../../../../../components/ViewLegacyOrder';
import { doQuery } from '../../../../../utility/apollo';
import { GqlReadLegacyOrder } from '@monorepo/graphql/resources';
import { createFileRoute, notFound } from '@tanstack/react-router';

export const loader = async ({ params }: { params: { id: string } }) => {
  try {
    const { data } = await doQuery({
      query: GqlReadLegacyOrder,
      variables: {
        id: Number(params.id),
      },
    });

    return { order: data.readLegacyOrder };
  } catch (error) {
    throw notFound();
  }
};

const ViewOrderComponent = () => {
  const { order } = Route.useLoaderData();

  return <ViewLegacyOrder order={order} />;
};

export const Route = createFileRoute('/_authenticated/admin/orders/legacy/$id')(
  {
    loader,
    notFoundComponent: () => <NotFound to="/admin/orders/legacy" />,
    component: ViewOrderComponent,
  }
);
