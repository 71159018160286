import { TfiArrowRight } from 'react-icons/tfi';
import FloatingButton from './FloatingButton';

const NextButton = ({ afterSubmit }: { afterSubmit?: () => void }) => (
  <FloatingButton afterSubmit={afterSubmit} showIfNotDirty>
    <TfiArrowRight size={24} />
  </FloatingButton>
);

export default NextButton;
