export function omitEmpty<T extends Record<string, unknown>>(obj: T): T {
  const result: Record<string, unknown> = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value != null && (Array.isArray(value) ? value.length > 0 : true)) {
      result[key] = value;
    }
  }

  return result as T;
}
