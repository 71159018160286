import { useMutation } from '@apollo/client';
import {
  AddCustomerInput,
  AddressInput,
  CustomerFragmentFragment,
  RoleEnum,
} from '@monorepo/graphql';
import {
  GqlAddCustomer,
  GqlDeleteCustomer,
  GqlEditCustomer,
  GqlMeQuery,
  GqlReadCustomer,
} from '@monorepo/graphql/resources';
import { useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import { FiBriefcase, FiCreditCard, FiTrash, FiUsers } from 'react-icons/fi';
import { Link, useNavigate, useRouterState } from '@tanstack/react-router';
import AddressFieldGroup, { useCountriesAndCities } from './AddressFieldGroup';
import SubmitButton from './SubmitButton';
import PageLoader from './PageLoader';
import PageHeader from './PageHeader';
import { toaster } from '../utility/toast';
import { To } from './NotFound';
import { IconType } from 'react-icons';
import { useUser } from '../utility/authentication';
import Loader from './Loader';
import Input from './Input';
import Checkbox from './Checkbox';
import { Tooltip } from 'react-tooltip';
import { elements } from '../utility/styles';
import { Button } from './Button';

export type Account = Omit<CustomerFragmentFragment, 'uuid'> & {
  uuid?: string;
};

interface Props {
  title: string;
  subtitle: string;
  buttonLink?: To;
  buttonText?: string;
  customer?: Account;
  ButtonIcon?: IconType;
  onAfterAdd?: (uuid: string) => void;
}

type FormikValues = Omit<
  AddCustomerInput,
  'billingAddress' | 'shippingAddress'
> & {
  billingAddress: AddressInput;
  shippingAddress: AddressInput;
};

const CreateOrEditCustomer = ({
  title,
  subtitle,
  buttonLink,
  buttonText = 'View More',
  ButtonIcon,
  customer,
  onAfterAdd,
}: Props) => {
  const { user } = useUser();
  const canDelete =
    user.role === RoleEnum.admin && user.customer?.uuid !== customer?.uuid;

  const navigate = useNavigate();
  const [deleteCustomer, { loading: deleteLoading }] = useMutation(
    GqlDeleteCustomer,
    {
      update: (cache) => {
        cache.evict({ id: `Customer:${customer?.uuid}` });

        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'indexCustomers',
        });

        cache.gc();
      },
      onCompleted: () => {
        toaster.success(
          {
            title: 'Customer deleted',
            text: 'The customer has been deleted',
          },
          { autoClose: 2000 }
        );

        void navigate({
          to: '/admin/customers',
        });
      },
    }
  );

  const [addCustomer, { loading: addLoading }] = useMutation(GqlAddCustomer, {
    update: (cache) => {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'indexCustomers',
      });

      cache.gc();
    },
  });

  const [editCustomer, { loading: editLoading }] = useMutation(
    GqlEditCustomer,
    {
      refetchQueries:
        user.customer?.uuid === customer?.uuid ? [GqlMeQuery] : undefined,
      update: (cache, data) => {
        if (data.data?.editCustomer) {
          cache.writeQuery({
            query: GqlReadCustomer,
            data: {
              readCustomer: data.data.editCustomer,
            },
            variables: {
              uuid: data.data.editCustomer.uuid,
            },
          });
        }
      },
    }
  );

  const { phoneCodes } = useCountriesAndCities();
  const loading = addLoading || editLoading;
  const initialValues = useMemo<FormikValues>(
    () => ({
      email: customer?.email ?? '',
      firstName: customer?.firstName ?? '',
      lastName: customer?.lastName ?? '',
      accountingReference: customer?.accountingReference ?? '',
      billingAddress: {
        firstName:
          customer?.billingAddress?.firstName ?? customer?.firstName ?? '',
        lastName:
          customer?.billingAddress?.lastName ?? customer?.lastName ?? '',
        company: customer?.billingAddress?.company ?? '',
        phone: customer?.billingAddress?.phone,
        email: customer?.billingAddress?.email ?? customer?.email ?? '',
        line1: customer?.billingAddress?.line1 ?? '',
        line2: customer?.billingAddress?.line2 ?? '',
        city: customer?.billingAddress?.city ?? '',
        postCode: customer?.billingAddress?.postCode ?? '',
        country: customer?.billingAddress?.country ?? 'GB',
        state: customer?.billingAddress?.state ?? '',
      },
      shippingAddress: {
        firstName:
          customer?.shippingAddress?.firstName ?? customer?.firstName ?? '',
        lastName:
          customer?.shippingAddress?.lastName ?? customer?.lastName ?? '',
        company: customer?.shippingAddress?.company ?? '',
        phone: customer?.shippingAddress?.phone,
        email: customer?.shippingAddress?.email ?? customer?.email ?? '',
        line1: customer?.shippingAddress?.line1 ?? '',
        line2: customer?.shippingAddress?.line2 ?? '',
        city: customer?.shippingAddress?.city ?? '',
        postCode: customer?.shippingAddress?.postCode ?? '',
        country: customer?.shippingAddress?.country ?? 'GB',
        state: customer?.shippingAddress?.state ?? '',
      },
    }),
    [customer]
  );

  const customerUuid = customer?.uuid;
  const [shippingSameAsBilling, setBillingSameAsShipping] = useState(true);

  const router = useRouterState();

  const isAdminArea = router.location.pathname.startsWith('/admin');

  return (
    <div className="flex flex-col">
      <PageHeader title={title} Icon={FiUsers}>
        <div className="flex flex-wrap items-center -mx-1">
          {!!customerUuid &&
            canDelete &&
            (deleteLoading ? (
              <Loader size="1.5rem" />
            ) : (
              <div className="px-1 mb-2 lg:mb-0">
                <Button
                  type="button"
                  className={elements.button.tertiary}
                  data-tooltip-id="deleteCustomer"
                  data-tooltip-content="Delete Customer"
                  data-tooltip-place="bottom"
                  onClick={async () => {
                    if (
                      window.confirm(
                        'Are you sure you want to delete this customer?'
                      )
                    )
                      await deleteCustomer({
                        variables: {
                          uuid: customerUuid,
                        },
                      });
                  }}
                >
                  <FiTrash size={20} />
                </Button>
                <Tooltip id="deleteCustomer" />
              </div>
            ))}
          {!!buttonLink && (
            <div className="px-1 mb-2 lg:mb-0">
              <Link
                to={buttonLink}
                className={elements.button.tertiary}
                data-tooltip-id={buttonLink}
                data-tooltip-content={buttonText}
                data-tooltip-place="bottom"
              >
                {!!ButtonIcon && <ButtonIcon size={20} />}
              </Link>
              <Tooltip id={buttonLink} />
            </div>
          )}
          {customerUuid && user.role === RoleEnum.admin && isAdminArea && (
            <>
              <div className="px-1 mb-2 lg:mb-0">
                <Link
                  to={`/admin/orders?customerUuid=${customerUuid}`}
                  className={elements.button.tertiary}
                  data-tooltip-id="orders"
                  data-tooltip-content="View Orders"
                  data-tooltip-place="bottom"
                >
                  <FiCreditCard size={20} />
                </Link>
                <Tooltip id="orders" />
              </div>
              <div className="px-1 mb-2 lg:mb-0">
                <Link
                  to={`/admin/quotes?customerUuid=${customerUuid}`}
                  className={elements.button.tertiary}
                  data-tooltip-id="quotes"
                  data-tooltip-content="View Quotes"
                  data-tooltip-place="bottom"
                >
                  <FiBriefcase size={20} />
                </Link>
                <Tooltip id="quotes" />
              </div>
            </>
          )}
        </div>
      </PageHeader>
      <p className="mb-5 text-sm">{subtitle}</p>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, props) => {
          const billingAddress = {
            ...values.billingAddress,
            phone: `${
              phoneCodes.find((p) =>
                values.billingAddress.phone?.startsWith(p.value)
              )?.value ?? ''
            }${
              values.billingAddress.phone?.replace(
                new RegExp(
                  phoneCodes
                    .map((p) => p.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
                    .join('|'),
                  'g'
                ),
                ''
              ) ?? ''
            }`,
          };

          const data: AddCustomerInput = {
            ...values,
            accountingReference: values.accountingReference ?? null,
            billingAddress,
            shippingAddress: shippingSameAsBilling
              ? billingAddress
              : {
                  ...values.shippingAddress,
                  phone: `${
                    phoneCodes.find((p) =>
                      values.shippingAddress.phone?.startsWith(p.value)
                    )?.value ?? ''
                  }${
                    values.shippingAddress.phone?.replace(
                      new RegExp(
                        phoneCodes
                          .map((p) =>
                            p.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                          )
                          .join('|'),
                        'g'
                      ),
                      ''
                    ) ?? ''
                  }`,
                },
          };

          if (!customer?.uuid) {
            await addCustomer({
              variables: {
                input: data,
              },
              onCompleted: (d) => {
                onAfterAdd?.(d.addCustomer.uuid);
              },
            });

            props.resetForm();
          } else {
            await editCustomer({
              variables: {
                input: {
                  uuid: customer.uuid,
                  ...data,
                },
              },
            });

            props.resetForm({
              values,
            });
          }

          toaster.success(
            {
              title: 'Success',
              text: 'Account details have been updated',
            },
            {
              autoClose: 5000,
            }
          );
        }}
      >
        <Form className="relative">
          <div className="bg-white rounded-md mb-5">
            <h2 className="font-bold text-lg text-black px-5 py-3 border-b border-black/20">
              Personal Information
            </h2>
            <div className="flex flex-wrap -mx-2 items-center mb-5 px-5 pt-5 pb-2">
              <div className="w-full lg:w-1/2 px-2 mb-5">
                <Input
                  className="w-full"
                  name="firstName"
                  required
                  label="First Name"
                  placeholder="First Name"
                />
              </div>
              <div className="w-full lg:w-1/2 px-2 mb-5">
                <Input
                  className="w-full"
                  name="lastName"
                  label="Last Name"
                  placeholder="Last Name"
                />
              </div>
              <div className="w-full lg:w-1/2 px-2 mb-5">
                <Input
                  type="email"
                  className="w-full"
                  name="email"
                  required
                  pattern={new RegExp(/[^@\s]+@[^@\s]+\.[^@\s]+/)}
                  label="Email Address"
                  placeholder="Email Address"
                  id="email"
                />
              </div>
              <div className="w-full lg:w-1/2 px-2 mb-5">
                <Input
                  className="w-full"
                  label="Accounting Reference"
                  placeholder="Accounting Reference"
                  name="accountingReference"
                />
              </div>
            </div>
          </div>
          <div className="bg-white rounded-md mb-5">
            <h2 className="font-bold text-lg text-black px-5 py-3 border-b border-black/20">
              Address Information
            </h2>
            <div className="p-5">
              <div className="mb-5">
                <Checkbox
                  label="Shipping same as billing details?"
                  name="shippingSameAsBilling"
                  id="shippingSameAsBilling"
                  checked={shippingSameAsBilling}
                  onChange={() =>
                    setBillingSameAsShipping(!shippingSameAsBilling)
                  }
                  useFormik={false}
                />
              </div>
              <div className="flex flex-wrap -mx-2 items-start">
                <div className="grow px-2 mb-2">
                  <h2 className="font-bold text-base text-black mb-5">
                    Billing Address
                  </h2>
                  <AddressFieldGroup prefix="billingAddress" />
                </div>
                {!shippingSameAsBilling && (
                  <div className="grow px-2 mb-2">
                    <h2 className="font-bold text-base text-black mb-5">
                      Shipping Address
                    </h2>
                    <AddressFieldGroup prefix="shippingAddress" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <SubmitButton />
          {loading && <PageLoader />}
        </Form>
      </Formik>
    </div>
  );
};

export default CreateOrEditCustomer;
