import { useLazyQuery } from '@apollo/client';
import { GqlIndexCustomers } from '@monorepo/graphql/resources';
import SearchField from './SearchField';
import { useCallback } from 'react';
import { CustomerFragmentFragment } from '@monorepo/graphql';
import { FiUsers } from 'react-icons/fi';

interface Props {
  onSelect: (customer: CustomerFragmentFragment) => void;
  defaultValue?: string;
  useFormik?: boolean;
  name?: string;
  hideLabel?: boolean;
  required?: boolean;
}

const CustomerSearchField = ({
  onSelect,
  defaultValue,
  name = 'customer',
  useFormik,
  hideLabel,
  required,
}: Props) => {
  const [fetchCustomers] = useLazyQuery(GqlIndexCustomers);

  const fetchSuggestions = useCallback(
    async (term: string) => {
      const { data } = await fetchCustomers({
        variables: {
          input: {
            orderBy: null,
            pagination: null,
            term,
          },
        },
      });

      return data?.indexCustomers.items ?? [];
    },
    [fetchCustomers]
  );

  return (
    <SearchField
      label={!hideLabel ? 'Customer' : undefined}
      placeholder="Search for a customer"
      fetchSuggestions={fetchSuggestions}
      name={name}
      useFormik={useFormik}
      idProp="uuid"
      labelProp="displayName"
      onSelect={onSelect}
      defaultValue={defaultValue}
      className="mb-5"
      Icon={FiUsers}
      required={required}
    />
  );
};

export default CustomerSearchField;
