import classNames from 'classnames';
import { Field } from 'formik';
import { ReactNode } from 'react';
import { FiCheck } from 'react-icons/fi';

const Checkbox = ({
  label,
  name,
  id,
  checked,
  onChange,
  useFormik = true,
  labelClassName,
  value,
  reverse,
  grow,
}: {
  label: ReactNode;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  useFormik?: boolean;
  name?: string;
  id?: string;
  value?: string;
  reverse?: boolean;
  grow?: boolean;
  labelClassName?: string;
}) => (
  <label
    className={classNames('flex items-center cursor-pointer', {
      'justify-between grow': grow,
    })}
    htmlFor={id ?? name}
  >
    <div
      className={classNames(
        'flex items-center',
        reverse ? 'ml-2 order-2' : 'mr-2'
      )}
    >
      {useFormik ? (
        <Field
          type="checkbox"
          name={name}
          id={id ?? name}
          className="hidden"
          value={value}
        />
      ) : (
        <input
          type="checkbox"
          id={id ?? name}
          onChange={onChange}
          checked={checked}
          name={name}
          className="hidden"
          value={value}
        />
      )}
      <div className="w-5 h-5 border border-black/20 rounded [:checked+&]:border-red [:checked+&]:bg-red group-hover/checkbox:border-white group-hover/checkbox:bg-white flex items-center justify-center">
        <FiCheck className="w-4 h-4 text-white group-hover/checkbox:text-red" />
      </div>
    </div>
    <span className={classNames(labelClassName, 'block')}>{label}</span>
  </label>
);

export default Checkbox;
