import { useMutation } from '@apollo/client';
import { GqlAddStripePaymentMethod } from '@monorepo/graphql/resources';
import { toaster } from '../utility/toast';
import PageHeader from './PageHeader';
import { FiCreditCard } from 'react-icons/fi';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import {
  StripeCardNumberElementOptions,
  StripeElementsOptions,
  loadStripe,
} from '@stripe/stripe-js';
import env from '../environment';
import { useNavigate } from '@tanstack/react-router';
import { Form, Formik, useFormikContext } from 'formik';
import SubmitButton from './SubmitButton';
import { useMemo, useState } from 'react';
import { apolloClient } from '../utility/apollo';
import PageLoader from './PageLoader';
import { elements, styles } from '../utility/styles';
import classNames from 'classnames';

const cardElementOptions: StripeCardNumberElementOptions = {
  showIcon: true,
  disableLink: true,
  style: {
    base: {
      color: styles.colours.dark,
      fontSize: '16px',
    },
    invalid: {
      color: styles.colours.red,
      fontSize: '16px',
    },
  },
};

const initialValues = {
  hasCardNumber: false,
  emptyCardNumber: true,
  hasExpiry: false,
  emptyExpiry: true,
  hasCvc: false,
  emptyCvc: true,
};

const CreatePaymentMethodElement = ({ message }: { message?: string }) => {
  const { setValues } = useFormikContext<typeof initialValues>();

  return (
    <>
      <label className={elements.inputLabel} htmlFor="cardNumber">
        Card Number
      </label>
      <div className={classNames(elements.input, 'mb-5 pl-3 [&>div]:grow')}>
        <CardNumberElement
          options={cardElementOptions}
          onChange={async (event) => {
            await setValues((values) => ({
              ...values,
              emptyCardNumber: event.empty,
              hasCardNumber: event.complete && !event.error,
            }));
          }}
        />
      </div>
      <label className={elements.inputLabel} htmlFor="cardExpiry">
        Expiry Date
      </label>
      <div className={classNames(elements.input, 'mb-5 pl-3 [&>div]:grow')}>
        <CardExpiryElement
          options={cardElementOptions}
          onChange={async (event) => {
            await setValues((values) => ({
              ...values,
              emptyExpiry: event.empty,
              hasExpiry: event.complete && !event.error,
            }));
          }}
        />
      </div>
      <label className={elements.inputLabel} htmlFor="cardCvc">
        CVC
      </label>
      <div className={classNames(elements.input, 'pl-3 [&>div]:grow')}>
        <CardCvcElement
          options={cardElementOptions}
          onChange={async (event) => {
            await setValues((values) => ({
              ...values,
              emptyCvc: event.empty,
              hasCvc: event.complete && !event.error,
            }));
          }}
        />
      </div>
      <SubmitButton message={message} />
    </>
  );
};

const CreatePaymentMethodForm = ({ quoteUuid }: Props) => {
  const navigate = useNavigate();
  const [addPaymentMethod, { loading: addLoading }] = useMutation(
    GqlAddStripePaymentMethod,
    {
      update: (cache) => {
        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'indexStripePaymentMethods',
        });

        cache.gc();
      },
      onCompleted: async (data) => {
        toaster.success(
          {
            title: 'Success',
            text: 'Payment method added.',
          },
          { autoClose: 5000 }
        );

        await apolloClient.resetStore();
        if (quoteUuid) {
          void navigate({
            to: `/my-account/quotes/${quoteUuid}?paymentMethodId=${data.addStripePaymentMethod.id}`,
          });
        } else {
          void navigate({
            to: '/my-account/payment-methods',
          });
        }
      },
    }
  );

  const stripe = useStripe();
  const [stripeLoading, setStripeLoading] = useState(false);
  const loading = addLoading || stripeLoading;
  const newElements = useElements();
  const handleSubmit = () => {
    if (newElements == null || stripe == null) {
      return;
    }

    const cardNumberElement = newElements.getElement(CardNumberElement);

    if (cardNumberElement == null) {
      return;
    }

    setStripeLoading(true);

    stripe
      .createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
      })
      .then(async ({ paymentMethod, error }) => {
        if (error) {
          toaster.error(
            {
              title: 'Error',
              text: error.message,
            },
            { autoClose: 5000 }
          );
        } else {
          await addPaymentMethod({
            variables: {
              paymentMethodId: paymentMethod.id,
            },
          });
        }
      })
      .catch((error) => {
        toaster.error(
          {
            title: 'Error',
            text: (error as Record<string, string>).message,
          },
          { autoClose: 5000 }
        );
      })
      .finally(() => {
        setStripeLoading(false);
      });
  };

  return (
    <div className="flex-grow flex flex-col">
      <PageHeader title={'Add Payment Method'} Icon={FiCreditCard} />
      <p className="mb-5">{'Add your payment method below'}.</p>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: Record<string, string> = {};
          if (!values.hasCardNumber || !values.hasExpiry || !values.hasCvc) {
            errors.valid = 'Card details are not complete.';
          }
          return errors;
        }}
        onSubmit={handleSubmit}
      >
        <Form className="relative bg-white rounded-md p-5">
          <CreatePaymentMethodElement
            message={quoteUuid ? 'Save and go back to Quote' : undefined}
          />
          {loading && <PageLoader />}
        </Form>
      </Formik>
    </div>
  );
};

const elementsOptions: StripeElementsOptions = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
  appearance: {
    theme: 'flat',
    variables: {
      fontFamily: 'Roboto',
    },
  },
};

interface Props {
  quoteUuid?: string;
}

const CreatePaymentMethod = ({ quoteUuid }: Props) => {
  const stripePromise = useMemo(async () => {
    const res = await loadStripe(env.stripePublishableKey);

    return res;
  }, []);

  return (
    <Elements stripe={stripePromise} options={elementsOptions}>
      <CreatePaymentMethodForm quoteUuid={quoteUuid} />
    </Elements>
  );
};

export default CreatePaymentMethod;
