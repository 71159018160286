import { useMutation } from '@apollo/client';
import { CreateUserInput, UserFragmentFragment } from '@monorepo/graphql';
import {
  GqlCreateUser,
  GqlDeleteUser,
  GqlEditUser,
  GqlReadUser,
  GqlResetPasswordMutation,
} from '@monorepo/graphql/resources';
import PageHeader from './PageHeader';
import { FiUsers, FiMail, FiTag, FiTrash } from 'react-icons/fi';
import { Link, useNavigate } from '@tanstack/react-router';
import { Form, Formik } from 'formik';
import SubmitButton from './SubmitButton';
import PageLoader from './PageLoader';
import { toaster } from '../utility/toast';
import Loader from './Loader';
import { useUser } from '../utility/authentication';
import Input from './Input';
import TelField from './TelField';
import { elements } from '../utility/styles';
import { Tooltip } from 'react-tooltip';
import { Button } from './Button';

interface Props {
  user?: UserFragmentFragment;
}

const CreateOrEditUser = ({ user }: Props) => {
  const [updateUser, { loading: updateLoading }] = useMutation(GqlEditUser, {
    update: (cache, data) => {
      if (data.data?.editUser) {
        cache.writeQuery({
          query: GqlReadUser,
          data: {
            readUser: data.data.editUser,
          },
          variables: {
            uuid: data.data.editUser.uuid,
          },
        });
      }
    },
  });

  const [addUser, { loading: addLoading }] = useMutation(GqlCreateUser, {
    update: (cache) => {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'indexUsers',
      });

      cache.gc();
    },
  });

  const [deleteUser, { loading: deleteLoading }] = useMutation(GqlDeleteUser, {
    update: (cache) => {
      cache.evict({ id: `User:${user?.uuid}` });

      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'indexUsers',
      });

      cache.gc();
    },
    onCompleted: () => {
      toaster.success(
        {
          title: 'User deleted',
          text: 'The user has been deleted',
        },
        { autoClose: 2000 }
      );

      void navigate({
        to: '/admin/users',
      });
    },
  });

  const [resetPassword, { loading: resetLoading }] = useMutation(
    GqlResetPasswordMutation,
    {
      onCompleted: () => {
        toaster.success(
          {
            title: 'Sent',
            text: 'The user has been sent an email with a link to reset their password',
          },
          { autoClose: 2000 }
        );
      },
    }
  );

  const loading = updateLoading || addLoading;
  const initialValues = {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    email: user?.email ?? '',
    password: '',
    phone: user?.telephone,
  };

  const navigate = useNavigate();
  const { user: currentUser } = useUser();
  const canDelete = !!user && currentUser.uuid !== user.uuid;

  return (
    <div className="flex-grow flex flex-col">
      <PageHeader title={user ? 'Edit User' : 'Add User'} Icon={FiTag}>
        <div className="flex flex-wrap -mx-1">
          {canDelete && (
            <>
              <div className="px-1 w-full lg:w-auto mb-2 lg:mb-0">
                {deleteLoading ? (
                  <Loader size="1.5rem" />
                ) : (
                  <>
                    <Button
                      type="button"
                      className={elements.button.tertiary}
                      data-tooltip-id="deleteUser"
                      data-tooltip-content="Delete User"
                      data-tooltip-place="bottom"
                      onClick={async () => {
                        if (
                          window.confirm(
                            'Are you sure you want to delete this user?'
                          )
                        )
                          await deleteUser({
                            variables: {
                              uuid: user.uuid,
                            },
                          });
                      }}
                    >
                      <FiTrash size={20} />
                    </Button>
                    <Tooltip id="deleteUser" />
                  </>
                )}
              </div>
              <div className="px-1 w-full lg:w-auto mb-2 lg:mb-0">
                {resetLoading ? (
                  <Loader size="1.5rem" />
                ) : (
                  <>
                    <Button
                      type="button"
                      className={elements.button.tertiary}
                      data-tooltip-id="resetPassword"
                      data-tooltip-content="Reset Password"
                      data-tooltip-place="bottom"
                      onClick={async () => {
                        const isConfirmed = window.confirm(
                          'Are you sure you want send this user a password reset email?'
                        );

                        if (isConfirmed)
                          await resetPassword({
                            variables: {
                              email: user.email,
                            },
                          });
                      }}
                    >
                      <FiMail size={20} />
                    </Button>
                    <Tooltip id="resetPassword" />
                  </>
                )}
              </div>
            </>
          )}
          {!!user?.customer?.uuid && (
            <div className="px-1 w-full lg:w-auto mb-2 lg:mb-0">
              <Link
                to={`/admin/customers/${user.customer.uuid}`}
                className={elements.button.tertiary}
                data-tooltip-id="viewCustomer"
                data-tooltip-content="View Customer Account"
                data-tooltip-place="bottom"
              >
                <FiUsers size={20} />
              </Link>
              <Tooltip id="viewCustomer" />
            </div>
          )}
        </div>
      </PageHeader>
      <p className="mb-5">
        {user ? 'Edit the user below' : 'Add the user below'}.
      </p>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: Partial<CreateUserInput> = {};
          if (!values.firstName) {
            errors.firstName = 'First name is required';
          }
          if (!values.lastName) {
            errors.lastName = 'Last name is required';
          }
          if (!values.email) {
            errors.email = 'Email is required';
          }

          return errors;
        }}
        onSubmit={async ({ password, ...values }, props) => {
          if (user) {
            await updateUser({
              variables: {
                input: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  telephone: values.phone,
                  uuid: user.uuid,
                },
              },
            });

            props.resetForm({
              values: {
                ...values,
                password: '',
              },
            });
          } else {
            const newProduct = await addUser({
              variables: {
                input: {
                  password,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  telephone: values.phone,
                },
              },
            });

            const uuid = newProduct.data?.createUser.uuid;
            if (uuid) {
              void navigate({
                to: `/admin/users/${uuid}`,
              });
            }
          }
          toaster.success(
            {
              title: 'Success',
              text: `You have successfully ${
                user ? 'updated' : 'added'
              } the user!`,
            },
            {
              autoClose: 5000,
            }
          );
        }}
      >
        <Form className="relative bg-white rounded-md p-5 pb-2">
          <div className="flex flex-wrap -mx-2 items-center">
            <div className="w-full lg:w-1/2 px-2 mb-5">
              <Input label="First Name" name="firstName" required />
            </div>
            <div className="w-full lg:w-1/2 px-2 mb-5">
              <Input label="Last Name" name="lastName" required />
            </div>
            <div className="w-full lg:w-1/2 px-2 mb-5">
              <Input type="email" label="Email Address" name="email" required />
            </div>
            <div className="w-full lg:w-1/2 px-2 mb-5">
              <TelField name="phone" />
            </div>
            {!user && (
              <div className="w-full lg:w-1/2 px-2 mb-5">
                <Input
                  type="password"
                  label="Password"
                  name="password"
                  required
                />
              </div>
            )}
          </div>
          <SubmitButton />
          {loading && <PageLoader />}
        </Form>
      </Formik>
    </div>
  );
};

export default CreateOrEditUser;
