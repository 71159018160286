import {
  Link,
  Outlet,
  useNavigate,
  useRouterState,
} from '@tanstack/react-router';
import classNames from 'classnames';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { clearApiToken, useUser } from '../utility/authentication';
import { RoleEnum } from '@monorepo/graphql';
import env from '../environment';
import {
  FiArrowLeft,
  FiCornerUpLeft,
  FiLock,
  FiMenu,
  FiShoppingCart,
  FiUnlock,
  FiUser,
} from 'react-icons/fi';
import Dropdown, { Item } from './Dropdown';
import { FaWordpressSimple } from 'react-icons/fa';
import { To } from './NotFound';
import { Tooltip } from 'react-tooltip';
import { toaster } from '../utility/toast';
import { Button } from './Button';
import PageLoader from './PageLoader';

interface Props {
  sidebarClassNames?: string;
  children: React.ReactNode;
}

const backLinks: Record<To, To | undefined> = {
  '/admin/coupons/$couponUuid': '/admin/coupons',
  '/admin/coupons/add': '/admin/coupons',
  '/admin': undefined,
  '/admin/': undefined,
  '/admin/orders': undefined,
  '/admin/coupons': '/admin/orders',
  '/admin/customers': '/admin/orders',
  '/my-account': undefined,
  '/my-account/': undefined,
  '/my-account/orders': undefined,
  '/my-account/edit-account': '/my-account/orders',
  '/my-account/edit-account/edit-password': '/my-account/edit-account',
  '/my-account/orders/$orderUuid': '/my-account/orders',
  '': undefined,
  '/': undefined,
  '/cart': '/',
  '/checkout': '/',
  '/checkout/$orderUuid': '/',
  '/checkout/quote/$quoteUuid': '/',
  '/checkout/quote': '/',
  '/login/reset-password': '/login',
  '/login': '/',
  '/login/': '/',
  '/admin/customers/$customerUuid': '/admin/customers',
  '/admin/customers/add': '/admin/customers',
  '/admin/orders/$orderUuid': '/admin/orders',
  '/admin/quotes/$quoteUuid': '/admin/quotes',
  '/admin/quotes/add': '/admin/quotes',
  '/admin/users/$userUuid': '/admin/users',
  '/admin/users/add': '/admin/users',
  '/my-account/payment-methods/add': '/my-account/payment-methods',
  '/my-account/payment-methods': '/my-account/orders',
  '/admin/users': '/admin/orders',
  '/admin/quotes': '/admin/orders',
  '/my-account/quotes': '/my-account/orders',
  '/my-account/quotes/$quoteUuid': '/my-account/quotes',
  '/admin/quotes/message-templates': '/admin/quotes',
  '/admin/quotes/message-templates/$templateUuid':
    '/admin/quotes/message-templates',
  '/admin/quotes/message-templates/add': '/admin/quotes/message-templates',
  '/login/confirm-password/$token': '/login',
  '/admin/customers/cart/$customerUuid': '/admin/customers',
  '/admin/quotes/products/add': '/admin/quotes/products',
  '/my-account/quotes/legacy/$id': '/my-account/quotes/legacy',
  '/admin/quotes/products/$productUuid': '/admin/quotes/products',
  '/admin/orders/legacy/$id': '/admin/orders/legacy',
  '/admin/orders/legacy': '/admin/orders',
  '/my-account/orders/legacy/$id': '/my-account/orders/legacy',
  '/admin/quotes/products': '/admin/quotes',
  '/my-account/orders/legacy': '/my-account/orders',
  '/my-account/quotes/legacy': '/my-account/quotes',
  '/admin/quotes/email-tokens': '/admin/quotes',
};

const PageLayout = ({ sidebarClassNames, children }: Props) => {
  const { user } = useUser();
  const routerState = useRouterState();
  const [menuOpen, setMenuOpen] = useState(false);

  const matchedRoute = routerState.matches[
    routerState.matches.length - 1
  ].routeId.replace('/_authenticated', '');

  const routeId = (
    matchedRoute.endsWith('/') ? matchedRoute.slice(0, -1) : matchedRoute
  ) as To;

  useEffect(() => {
    setMenuOpen(false);
  }, [routerState.location.pathname]);

  const backLink = backLinks[routeId];

  const navigate = useNavigate();

  const profileItems = useMemo(() => {
    const items: Item[] = [
      {
        label: (
          <div className="flex items-center">
            <FiUser size={16} className="mr-2" /> Profile
          </div>
        ),
        value: '/my-account/edit-account',
        isInternalLink: true,
        closeOnClick: true,
      },
      {
        label: (
          <div className="flex items-center">
            <FiShoppingCart size={16} className="mr-2" /> Back to Website
          </div>
        ),
        value: env.wpUrl,
        isExternalLink: true,
        closeOnClick: true,
      },
    ];

    if (user.role === RoleEnum.admin) {
      if (routerState.location.pathname.startsWith('/admin')) {
        items.push({
          label: (
            <div className="flex items-center">
              <FiUnlock size={16} className="mr-2" /> Back to My Account
            </div>
          ),
          value: '/my-account',
          isInternalLink: true,
          closeOnClick: true,
        });
      } else {
        items.push({
          label: (
            <div className="flex items-center">
              <FiLock size={16} className="mr-2" /> Go to Admin Panel
            </div>
          ),
          value: '/admin',
          isInternalLink: true,
          closeOnClick: true,
        });
      }
      items.push({
        label: (
          <div className="flex items-center">
            <FaWordpressSimple size={16} className="mr-2" /> Go to Wordpress
            Admin
          </div>
        ),
        value: `${env.wpUrl}/wp-admin`,
        isExternalLink: true,
        closeOnClick: true,
      });
    }

    items.push({
      label: (
        <div className="flex items-center">
          <FiCornerUpLeft size={16} className="mr-2" /> Logout
        </div>
      ),
      value: 'logout',
      onClick: () => {
        clearApiToken();

        toaster.success(
          {
            title: 'See you soon!',
            text: 'You have successfully logged out.',
          },
          { autoClose: 5000 }
        );

        setTimeout(
          () =>
            void navigate({
              to: '/login',
            }),
          10
        );
      },
      closeOnClick: true,
    });

    return items;
  }, [user, routerState.location.pathname, navigate]);

  const backTooltipContent = backLink
    ? 'Go to Previous Page'
    : routerState.location.pathname.startsWith('/admin')
    ? 'Go back to My Account'
    : 'Go back to Website';

  return (
    <div className="flex w-dvw h-dvh">
      <aside
        className={classNames(
          'flex flex-col justify-between text-white h-full w-[16rem] shrink-0 fixed lg:relative transition-transform top-0 left-0 z-10 lg:translate-x-0',
          !menuOpen ? '-translate-x-full' : '',
          sidebarClassNames
        )}
      >
        <div className="overflow-y-auto">
          <div className="py-6 px-8">
            <div className="flex justify-between items-center">
              <img src="/v2-assets/logo-white-old.svg" alt="Logo" width={120} />
              <Button
                type="button"
                className="lg:hidden flex -mr-3"
                onClick={() => setMenuOpen(false)}
              >
                <MdClose size={32} />
              </Button>
            </div>
          </div>
          {children}
        </div>
      </aside>
      <section className="flex flex-col flex-grow max-h-dvh overflow-y-auto bg-lightGray">
        <header
          className={classNames(
            'lg:hidden sticky w-full text-white p-4 flex justify-between items-center',
            sidebarClassNames
          )}
        >
          <Button type="button" onClick={() => setMenuOpen(true)}>
            <FiMenu size={32} />
          </Button>
          <div>
            <img src="/v2-assets/logo-white-old.svg" alt="Logo" width={120} />
          </div>
          <div className="w-8">
            {user.role === RoleEnum.admin && (
              <a href={`${env.wpUrl}/wp-admin`}>
                <FaWordpressSimple size={32} />
              </a>
            )}
          </div>
        </header>
        <header className="bg-white px-5 py-3 flex justify-between items-center">
          <div
            data-tooltip-content={backTooltipContent}
            data-tooltip-id="goBack"
            data-tooltip-place="right"
          >
            {backLink ? (
              <Link to={backLink}>
                <FiArrowLeft size={24} />
              </Link>
            ) : routerState.location.pathname.startsWith('/admin') ? (
              <Link to="/my-account">
                <FiArrowLeft size={24} />
              </Link>
            ) : (
              <a href={env.wpUrl}>
                <FiArrowLeft size={24} />
              </a>
            )}
          </div>
          <div>
            <Dropdown
              label={<FiUser size={20} />}
              options={profileItems}
              name="profile"
            />
          </div>
          <Tooltip id="goBack" />
        </header>
        <main className="flex flex-col flex-grow p-5 relative">
          <Suspense fallback={<PageLoader />}>
            <Outlet />
          </Suspense>
        </main>
      </section>
    </div>
  );
};

export default PageLayout;
