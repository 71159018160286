import CreateOrEditProduct from '../../../../../components/CreateOrEditProduct';
import NotFound from '../../../../../components/NotFound';
import { doQuery } from '../../../../../utility/apollo';
import { GqlReadInternalProduct } from '@monorepo/graphql/resources';
import { createFileRoute, notFound } from '@tanstack/react-router';

export const loader = async ({
  params,
}: {
  params: { productUuid: string };
}) => {
  try {
    const { data } = await doQuery({
      query: GqlReadInternalProduct,
      variables: {
        uuid: params.productUuid,
      },
    });

    return { product: data.readInternalProduct };
  } catch (error) {
    throw notFound();
  }
};

const ProductEditComponent = () => {
  const { product } = Route.useLoaderData();

  return <CreateOrEditProduct product={product} />;
};

export const Route = createFileRoute(
  '/_authenticated/admin/quotes/products/$productUuid'
)({
  loader,
  errorComponent: () => <NotFound to="/admin/quotes/products" />,
  component: ProductEditComponent,
});
