import ViewLegacyOrder from '../../../../../components/ViewLegacyOrder';
import { createFileRoute } from '@tanstack/react-router';
import { loader } from '../../../admin/orders/legacy/$id';
import NotFound from '../../../../../components/NotFound';

const ViewLegacyOrderComponent = () => {
  const { order } = Route.useLoaderData();

  return <ViewLegacyOrder order={order} />;
};

export const Route = createFileRoute(
  '/_authenticated/my-account/quotes/legacy/$id'
)({
  loader: async (props) => {
    const { order } = await loader(props);

    return { order, isQuote: true };
  },
  errorComponent: () => <NotFound to="/my-account/quotes/legacy" />,
  component: ViewLegacyOrderComponent,
});
