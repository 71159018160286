import { FiMail, FiPhone } from 'react-icons/fi';

const CheckoutFooter = () => (
  <footer className="bg-black text-white p-8 lg:p-12 flex justify-center">
    <div className="flex flex-col lg:flex-row lg:max-w-[74rem] grow">
      <div className="w-full lg:w-1/3 mb-6 lg:mb-0">
        <img
          src="/v2-assets/logo-white-old.svg"
          alt="Logo"
          width="120"
          className="mb-5"
        />
        <p className="mb-5 text-sm">
          © {new Date().getFullYear()} ABLE Instruments & Controls Ltd t/a
          247able.
        </p>
        <img src="/v2-assets/cards.png" alt="Cards" />
      </div>
      <div className="w-full lg:w-1/3 mb-6 lg:mb-0 flex flex-col lg:items-center">
        <div>
          <h3 className="text-lg font-bold mb-5">Get help</h3>
          <ul>
            <li className="mb-3">
              <a
                href="tel:+4401186199420"
                target="_blank"
                className="flex items-center"
                rel="noreferrer"
              >
                <FiPhone className="mr-2" size={20} />
                <span>+44 (0) 118 619 9420</span>
              </a>
            </li>
            <li>
              <a
                href="mailtp:info@247able.com"
                target="_blank"
                className="flex items-center"
                rel="noreferrer"
              >
                <FiMail className="mr-2" size={20} />
                <span>info@247able.com</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full lg:w-1/3 flex flex-col lg:items-center">
        <div>
          <h3 className="text-lg font-bold mb-5">Policies</h3>
          <ul>
            <li className="mb-3">
              <a href="/terms-and-conditions" target="_blank">
                Terms
              </a>
            </li>
            <li className="mb-3">
              <a href="/privacy-policy" target="_blank">
                Privacy
              </a>
            </li>
            <li>
              <a href="/returns" target="_blank">
                Returns
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default CheckoutFooter;
