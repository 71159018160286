import { gql } from '@apollo/client';
import {
  AddStripePaymentMethodMutation,
  AddStripePaymentMethodMutationVariables,
  IndexStripePaymentMethodsQuery,
  IndexStripePaymentMethodsQueryVariables,
  RemoveStripePaymentMethodMutation,
  RemoveStripePaymentMethodMutationVariables,
} from '../__generated__/graphql';

export const GqlStripePaymentMethod = gql`
  fragment StripePaymentMethodFragment on StripePaymentMethod {
    id
    billingDetails {
      address {
        city
        country
        line1
        line2
        postalCode
        state
      }
      email
      name
      phone
    }
    card {
      brand
      country
      expMonth
      expYear
      fingerprint
      funding
      last4
    }
    expired
  }
`;

export const GqlIndexStripePaymentMethods = gql<
  IndexStripePaymentMethodsQuery,
  IndexStripePaymentMethodsQueryVariables
>`
  query IndexStripePaymentMethods {
    indexStripePaymentMethods {
      ...StripePaymentMethodFragment
    }
  }
  ${GqlStripePaymentMethod}
`;

export const GqlRemoveStripePaymentMethod = gql<
  RemoveStripePaymentMethodMutation,
  RemoveStripePaymentMethodMutationVariables
>`
  mutation RemoveStripePaymentMethod($id: String!) {
    removeStripePaymentMethod(id: $id)
  }
`;

export const GqlAddStripePaymentMethod = gql<
  AddStripePaymentMethodMutation,
  AddStripePaymentMethodMutationVariables
>`
  mutation AddStripePaymentMethod($paymentMethodId: String!) {
    addStripePaymentMethod(paymentMethodId: $paymentMethodId) {
      ...StripePaymentMethodFragment
    }
  }
  ${GqlStripePaymentMethod}
`;
