import OrdersList, {
  FilterOptionEnum,
  TableColumnEnum,
} from '../../../../components/OrdersList';
import { useUser } from '../../../../utility/authentication';
import { createFileRoute } from '@tanstack/react-router';
import { useMemo } from 'react';

const hiddenColumns = [TableColumnEnum.customer];
const hiddenFilters = [FilterOptionEnum.customer];

const OrdersComponent = () => {
  const { user } = useUser();
  const initialFilters = useMemo(
    () => ({
      customerUuid: user.customer?.uuid ?? undefined,
    }),
    [user]
  );

  return (
    <OrdersList
      hiddenColumns={hiddenColumns}
      hiddenFilters={hiddenFilters}
      legacyUrl="/my-account/orders/legacy"
      initialFilters={initialFilters}
    />
  );
};

export const Route = createFileRoute('/_authenticated/my-account/orders/')({
  component: OrdersComponent,
});
