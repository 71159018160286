import {
  LineItemTypeEnum,
  OrderFragmentFragment,
  orderStatuses,
  formatMoney,
} from '@monorepo/graphql';
import PageHeader from './PageHeader';
import { FiShoppingCart } from 'react-icons/fi';
import { useCountriesAndCities } from './AddressFieldGroup';
import { PurchaseOrderLink } from './EditOrder';

interface Props {
  order: OrderFragmentFragment;
}

const ViewOrder = ({ order }: Props) => {
  const { countries } = useCountriesAndCities();

  return (
    <div>
      <PageHeader title={`Order Summary`} Icon={FiShoppingCart} />
      <p className="text-sm mb-5">
        Order <strong className="text-dark">{order.accountingReference}</strong>
        , sent on{' '}
        <strong className="text-dark">
          {new Date(order.createdAt).toNiceFormat()}
        </strong>{' '}
        . Status{' '}
        <strong className="text-dark">
          {orderStatuses.find((s) => s.value === order.status)?.label}
        </strong>
      </p>
      <div className="p-5 rounded-md bg-white mb-5">
        <div className="flex flex-wrap mb-8 -mx-2">
          <div className="lg:w-1/3 px-2 mb-5 lg:mb-0">
            <h4 className="text-base font-semibold text-black mb-2">
              Billing Address
            </h4>
            {[
              [order.billingAddress.firstName, order.billingAddress.lastName]
                .filter(Boolean)
                .join(' '),
              order.billingAddress.company,
              order.billingAddress.line1,
              order.billingAddress.line2,
              order.billingAddress.city,
              order.billingAddress.state,
              order.billingAddress.postCode,
              countries.find((c) => c.isoCode === order.billingAddress.country)
                ?.name,
            ]
              .filter(Boolean)
              .map((item, index) => (
                <p key={item} className="text-sm mb-1">
                  {item}
                </p>
              ))}
          </div>
          <div className="lg:w-1/3 px-2 mb-5 lg:mb-0">
            <h4 className="text-base font-semibold text-black mb-2">
              Shipping Address
            </h4>
            {[
              `${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`,
              order.shippingAddress.company,
              order.shippingAddress.line1,
              order.shippingAddress.line2,
              order.shippingAddress.city,
              order.shippingAddress.state,
              order.shippingAddress.postCode,
              countries.find((c) => c.isoCode === order.shippingAddress.country)
                ?.name,
            ]
              .filter(Boolean)
              .map((item, index) => (
                <p key={item} className="text-sm mb-1">
                  {item}
                </p>
              ))}
          </div>
          <div className="lg:w-1/3 px-2 flex flex-col justify-end">
            <PurchaseOrderLink
              purchaseOrderFileUuid={order.purchaseOrderFileUuid ?? undefined}
            />
          </div>
        </div>
        <div className="overflow-x-auto mb-8">
          <table className="w-full text-sm">
            <thead>
              <tr>
                <th className="text-left text-dark font-bold p-4 border border-black/20">
                  Qty
                </th>
                <th className="text-left text-dark font-bold p-4 border border-black/20 border-l-0">
                  Product / Description
                </th>
                <th className="text-right text-dark font-bold p-4 border border-black/20 border-l-0">
                  Unit Price
                </th>
                <th className="text-right text-dark font-bold p-4 border border-black/20 border-l-0">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {order.lineItems
                .filter(
                  (l) => l.type === LineItemTypeEnum.product && l.quantity > 0
                )
                .map((item, index) => (
                  <tr key={`${index}-${item.sku}`}>
                    <td className="text-left p-4 border-l border-b border-r border-black/20">
                      {item.quantity}
                    </td>
                    <td className="text-left p-4 border-b  border-b border-r border-black/20">
                      <strong>{item.sku}</strong> - {item.title}
                      {item.meta.length > 0 && (
                        <ul className="mt-2 text-midGray">
                          {item.meta.map((m, i) => (
                            <li
                              key={`${i}-${m.key}-${m.value}`}
                              className="text-xs"
                            >
                              <strong>{m.key}:</strong> {m.value}
                            </li>
                          ))}
                        </ul>
                      )}
                      {!!item.leadTime && (
                        <p className="text-xs mt-2">
                          <strong>Lead Time:</strong> {item.leadTime}
                        </p>
                      )}
                      {item.links.length > 0 && (
                        <ul className="mt-2 -mx-1">
                          {item.links.map((link, i) => (
                            <li
                              key={`${i}-${link.type}-${link.url}`}
                              className="px-1"
                            >
                              <a
                                href={link.url}
                                target="_blank"
                                rel="noreferrer"
                                className="text-sm text-red"
                              >
                                Download {link.type}
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </td>
                    <td className="text-right p-4 border-b  border-b border-r border-black/20">
                      {item.originalAmount !== item.amount && (
                        <span className="line-through text-dark text-xs font-normal block">
                          {formatMoney(item.originalAmount, order.currency)}
                        </span>
                      )}
                      <span className="block">
                        {formatMoney(item.amount, order.currency)}
                      </span>
                    </td>
                    <td className="text-right p-4 border-b  border-r border-black/20">
                      {formatMoney(item.quantity * item.amount, order.currency)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="flex flex-wrap justify-end -mx-2 lg:-mx-4">
          <div className="w-full lg:w-2/3 px-2 lg:px-4 empty:hidden">
            {(!!order.purchaseOrderReference ||
              !!order.trackingReference ||
              !!order.inTouchReference ||
              !!order.accountingReference ||
              !!order.paymentMethodLabel ||
              !!order.coupon?.code) && (
              <ul className="mb-5 text-sm">
                {!!order.purchaseOrderReference && (
                  <li className="mb-2 last:mb-0">
                    <strong className="block lg:inline">
                      Purchase Order Number:
                    </strong>{' '}
                    {order.purchaseOrderReference}
                  </li>
                )}
                {!!order.trackingReference && (
                  <li>
                    <strong className="block lg:inline">
                      Tracking Reference:
                    </strong>{' '}
                    {order.trackingReference} (UPS)
                  </li>
                )}
                {!!order.inTouchReference && (
                  <li>
                    <strong className="block lg:inline">
                      InTouch Reference:
                    </strong>{' '}
                    {order.inTouchReference}
                  </li>
                )}
                {!!order.accountingReference && (
                  <li>
                    <strong className="block lg:inline">SAGE Reference:</strong>{' '}
                    {order.accountingReference}
                  </li>
                )}
                {!!order.paymentMethodLabel && (
                  <li>
                    <strong className="block lg:inline">Payment Method:</strong>{' '}
                    {order.paymentMethodLabel}
                  </li>
                )}
                {!!order.coupon?.code && (
                  <li>
                    <strong className="block lg:inline">Coupon Code:</strong>{' '}
                    {order.coupon.code}
                  </li>
                )}
              </ul>
            )}
          </div>
          <div className="w-full lg:w-1/3 text-sm px-2 lg:px-4">
            <div className="p-2 rounded-md">
              {order.lineItems
                .filter((l) => l.type === LineItemTypeEnum.shipping)
                .map((item, index) => (
                  <div
                    key={`${index}-${item.sku}`}
                    className="flex items-center justify-between mb-2"
                  >
                    <div>
                      <strong className="block">Delivery & Packing:</strong>
                      <span className="italic">{item.title}</span>
                    </div>
                    {formatMoney(item.amount, order.currency)}
                  </div>
                ))}
              {order.lineItems
                .filter((l) => l.type === LineItemTypeEnum.discount)
                .map((item) => (
                  <div
                    key={JSON.stringify(item)}
                    className="flex items-center justify-between mb-2"
                  >
                    <div>
                      <strong className="block">Discount:</strong>
                      <span className="italic">{item.title}</span>
                    </div>
                    {formatMoney(item.amount, order.currency)}
                  </div>
                ))}
              <div className="flex items-center justify-between mb-2">
                <strong>Subtotal:</strong>{' '}
                {formatMoney(
                  order.lineItems.reduce(
                    (acc, item) =>
                      acc + Math.max(item.quantity, 1) * item.amount,
                    0
                  ),
                  order.currency
                )}
              </div>
              <div className="flex items-center justify-between mb-5 border-b border-black/20 pb-5">
                <strong>Tax:</strong>{' '}
                {formatMoney(
                  order.lineItems
                    .filter((l) => l.taxRate > 0)
                    .reduce(
                      (acc, item) =>
                        acc +
                        Math.max(item.quantity, 1) * item.amount * item.taxRate,
                      0
                    ),
                  order.currency
                )}
              </div>
              <div className="flex items-center justify-between text-base">
                <strong>Total:</strong>{' '}
                <strong>{formatMoney(order.total, order.currency)}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      {order.notes.length > 0 && (
        <div className="bg-white rounded-md">
          <h3 className="text-lg font-semibold text-black px-5 py-3 border-b border-black/20">
            Notes
          </h3>
          <div className="p-5">
            <ul className="text-sm pl-5 list-disc">
              {order.notes.map((note) => (
                <li key={note.uuid} className="mb-2 last:mb-0">
                  {note.text} -{' '}
                  {new Date(note.createdAt).toNiceDateTimeFormat()}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewOrder;
