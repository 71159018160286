import CreateOrEditCustomer from '../../../../components/CreateOrEditCustomer';
import NotFound from '../../../../components/NotFound';
import { doQuery } from '../../../../utility/apollo';
import { GqlReadCustomer } from '@monorepo/graphql/resources';
import { createFileRoute, notFound } from '@tanstack/react-router';
import { FiShoppingCart } from 'react-icons/fi';

const EditCustomerComponent = () => {
  const { customer } = Route.useLoaderData();

  return (
    <CreateOrEditCustomer
      title="Edit Customer"
      customer={customer}
      subtitle="Edit the customer below"
      buttonLink="/admin/customers/cart/$customerUuid"
      buttonText="View Cart"
      ButtonIcon={FiShoppingCart}
    />
  );
};

export const Route = createFileRoute(
  '/_authenticated/admin/customers/$customerUuid'
)({
  loader: async ({ params }) => {
    try {
      const { data } = await doQuery({
        query: GqlReadCustomer,
        variables: {
          uuid: params.customerUuid,
        },
      });

      return { customer: data.readCustomer };
    } catch (error) {
      throw notFound();
    }
  },
  notFoundComponent: () => <NotFound to="/admin/customers" />,
  component: EditCustomerComponent,
});
