import PageLoader from '../../components/PageLoader';
import env from '../../environment';
import { Outlet, createFileRoute } from '@tanstack/react-router';
import { Suspense } from 'react';
import { FiCornerUpLeft } from 'react-icons/fi';

const LayoutComponent = () => (
  <div className="flex flex-col h-dvh w-dvw">
    <div className="bg-black py-4 px-8">
      <a href={`${env.wpUrl}`} className="text-white text-sm flex items-center">
        <FiCornerUpLeft className="mr-2" />
        <span>Back to Site</span>
      </a>
    </div>
    <div className="flex-grow flex bg-lightGray p-8">
      <div className="flex items-center justify-center flex-col flex-grow">
        <div className="max-w-md flex w-full relative">
          <Suspense fallback={<PageLoader />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  </div>
);

export const Route = createFileRoute('/_unauthenticated/login')({
  component: LayoutComponent,
});
