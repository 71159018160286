import { IconType } from 'react-icons';

interface Props {
  title: string;
  Icon: IconType;
  children?: React.ReactNode;
}

const PageHeader = ({ title, children, Icon }: Props) => (
  <div className="flex lg:items-center flex-col lg:flex-row justify-between mb-5">
    <div className="mb-5 lg:mb-0 flex items-center text-black">
      <Icon size={28} className="mr-3" />
      <h1 className="font-bold text-2xl">{title}</h1>
    </div>

    {children}
  </div>
);

export default PageHeader;
