import { elements } from '../utility/styles';
import { useFormikContext } from 'formik';
import { getStatesOfCountry } from 'country-state-city/lib/state';
import { AddressFragmentFragment, AddressInput } from '@monorepo/graphql';
import { useCallback, useEffect, useMemo, useState } from 'react';
import env from '../environment';
import SearchField from './SearchField';
import Input from './Input';
import { FiMail } from 'react-icons/fi';
import { ICity, ICountry } from 'country-state-city/lib/interface';
import { toaster } from '../utility/toast';
import TelField from './TelField';
import Dropdown from './Dropdown';
import { Button } from './Button';


const getCountries = async () => {
  const Country = await import('country-state-city/lib/country');
  const { getAllCountries } = Country.default;

  return getAllCountries().sort((a, b) => a.name.localeCompare(b.name));
};

const getCities = async (country: string, state?: string) => {
  const City = await import('country-state-city/lib/city');
  const { getCitiesOfState, getCitiesOfCountry } = City.default;

  return state
    ? getCitiesOfState(country, state)
    : getCitiesOfCountry(country) ?? [];
};

export const useCountriesAndCities = (props?: {
  country?: string;
  state?: string;
}) => {
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);

  useEffect(() => {
    getCountries()
      .then((res) => {
        const uk = res.find((c) => c.isoCode === 'GB');
        const us = res.find((c) => c.isoCode === 'US');
        const others = res.filter(
          (c) => c.isoCode !== 'GB' && c.isoCode !== 'US'
        );

        setCountries(
          [uk, us, ...others].filter((c?: ICountry): c is ICountry => !!c)
        );
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (props?.country && props.state) {
      getCities(props.country, props.state)
        .then(setCities)
        .catch(console.error);
    }
  }, [props?.country, props?.state]);

  const phoneCodes = useMemo(() => {
    const gb = countries.find((country) => country.isoCode === 'GB');
    const us = countries.find((country) => country.isoCode === 'US');

    const others = countries.filter(
      (country) => country.isoCode !== 'GB' && country.isoCode !== 'US'
    );

    const sortedCountries = [gb, us, ...others].filter(
      (c?: ICountry): c is ICountry => !!c
    );

    return sortedCountries
      .map((country) => ({
        value: country.phonecode.startsWith('+')
          ? country.phonecode
          : `+${country.phonecode}`,
        name: country.name,
        flag: country.flag,
      }))
      .filter((a, i, arr) => arr.findIndex((b) => b.value === a.value) === i);
  }, [countries]);

  return {
    countries,
    cities,
    phoneCodes,
  };
};

export interface FormikType {
  billingAddress?: AddressInput;
  shippingAddress?: AddressInput;
}

interface Props {
  prefix: keyof FormikType;
  onChange?: (
    name: keyof NonNullable<FormikType['billingAddress']>,
    value?: string
  ) => void;
  shrink?: boolean;
  postcodeRequired?: boolean;
}

interface GeocodingResponse {
  results: GeocodingResult[];
  status: string;
}

interface GeocodingResult {
  address_components: AddressComponent[];
  formatted_address: string;
  geometry: Geometry;
  place_id: string;
  types: string[];
  plus_code?: PlusCode;
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface Geometry {
  location: Location;
  location_type: string;
  viewport: Viewport;
  bounds?: Viewport;
}

interface Location {
  lat: number;
  lng: number;
}

interface Viewport {
  northeast: Location;
  southwest: Location;
}

interface PlusCode {
  compound_code: string;
  global_code: string;
}

type Address = Pick<
  AddressFragmentFragment,
  'line1' | 'line2' | 'city' | 'state' | 'postCode' | 'country'
> & {
  formatted_address: string;
  place_id: string;
};

function transformGeocodingResponseToAddressFragments(
  result: GeocodingResult
): Address {
  const line1: string[] = [];
  const line2: string[] = [];
  const city: string[] = [];
  let postCode: string | undefined;
  const country = result.address_components.find((component) =>
    component.types.includes('country')
  )?.short_name;

  const preDefinedStates = country ? getStatesOfCountry(country) : [];
  const potentialState = result.address_components.find((component) =>
    component.types.includes('administrative_area_level_1')
  );

  const state = preDefinedStates.length
    ? preDefinedStates.find(
        (s) =>
          s.isoCode.toLowerCase() === potentialState?.short_name.toLowerCase()
      )?.isoCode ?? potentialState?.long_name
    : potentialState?.long_name;

  result.address_components.forEach((component) => {
    if (component.types.includes('street_number')) {
      line1.push(component.long_name);
    } else if (component.types.includes('subpremise')) {
      line1.push(component.long_name);
    } else if (component.types.includes('establishment')) {
      line1.push(component.long_name);
    } else if (component.types.includes('point_of_interest')) {
      line1.push(component.long_name);
    } else if (component.types.includes('transit_station')) {
      line1.push(component.long_name);
    } else if (component.types.includes('route')) {
      line1.push(component.long_name);
    } else if (component.types.includes('sublocality')) {
      line1.push(component.long_name);
    } else if (component.types.includes('sublocality_level_1')) {
      line1.push(component.long_name);
    }
    if (component.types.includes('neighborhood')) {
      line2.push(component.long_name);
    } else if (component.types.includes('administrative_area_level_3')) {
      line2.push(component.long_name);
    } else if (component.types.includes('administrative_area_level_4')) {
      line2.push(component.long_name);
    }  else if (
      component.types.includes('locality') &&
      !potentialState?.long_name.includes(component.long_name)
    ) {
      line2.push(component.long_name);
    }
    if (component.types.includes('postal_town')) {
      city.push(component.long_name);
    } else if (component.types.includes('administrative_area_level_2')) {
      city.push(component.long_name);
    }
    if (component.types.includes('postal_code')) {
      postCode = component.long_name.toUpperCase();
    }
  });

  return {
    line1: line1.map((l) => l.trim()).join(' '),
    line2: line2.map((l) => l.trim()).join(' '),
    city: city.map((l) => l.trim()).join(' '),
    state: state ?? '',
    postCode: postCode ?? '',
    country: country ?? 'GB',
    formatted_address: result.formatted_address,
    place_id: result.place_id,
  };
}

interface Suggestion {
  address: string;
  url: string;
  id: string;
}

interface ApiResponse {
  suggestions: Suggestion[];
  [key: string]: unknown;
}

const fetchGetAddressOptions = async (
  postCode: string
): Promise<{
  suggestions: Array<{
    address: string;
    url: string;
    id: string;
  }>;
}> => {
  const url = `https://api.getAddress.io/autocomplete/${postCode}?api-key=${env.getAddressApiKey}`;

  const response = await fetch(url);
  try {
    const json = (await response.json()) as ApiResponse;
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    if (json.suggestions.length === 0) {
      throw new Error('No addresses found');
    }
    return json;
  } catch (error: unknown) {
    toaster.error(
      {
        title: 'Error',
        text: (error as Error).message,
      },
      {
        autoClose: 5000,
      }
    );
    return { suggestions: [] };
  }
};

interface AddressResponse {
  postcode: string;
  latitude: number;
  longitude: number;
  formatted_address: string[];
  thoroughfare: string;
  building_name: string;
  sub_building_name: string;
  sub_building_number: string;
  building_number: number;
  line_1: string;
  line_2: string;
  line_3: string;
  line_4: string;
  locality: string;
  town_or_city: string;
  county: string;
  district: string;
  country: string;
  residential: true;
}

const fetchAddressById = async (id: string): Promise<AddressResponse> => {
  const url = `https://api.getAddress.io/get/${id}?api-key=${env.getAddressApiKey}`;

  const response = await fetch(url);
  const json = (await response.json()) as AddressResponse;

  return json;
};

const fetchSuggestions = async (
  val: string,
  country: string
): Promise<Address[]> => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${val}&key=${env.googleMapsApiKey}&components=country:${country}`
  );

  const json: GeocodingResponse = (await response.json()) as GeocodingResponse;

  return json.results
    .filter(({ types }) => !types.includes('country'))
    .map(transformGeocodingResponseToAddressFragments);
};

const AddressFieldGroup = ({
  prefix,
  onChange,
  shrink,
  postcodeRequired,
}: Props) => {
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<FormikType>();

  const country = values[prefix]?.country ?? 'GB';
  const state = values[prefix]?.state;
  const city = values[prefix]?.city;
  const line1 = values[prefix]?.line1;
  const postCode = values[prefix]?.postCode;
  const phone = values[prefix]?.phone;
  const filteredStates = useMemo(() => getStatesOfCountry(country), [country]);
  const onSelectSuggestion = async ({
    formatted_address: formattedAddress,
    place_id: placeId,
    ...suggestion
  }: Address) => {
    setResultPreviouslySelected(true);

    await setFieldValue(
      prefix,
      {
        ...values[prefix],
        ...suggestion,
      },
      true
    );

    setTimeout(() => void setFieldTouched(prefix, true), 0);
  };

  const { countries, cities } = useCountriesAndCities();
  const countryOptions = useMemo(
    () =>
      countries.map((item) => ({
        value: item.isoCode,
        label: `${item.flag} ${item.name}`,
      })),
    [countries]
  );

  const [ukResults, setUkResults] = useState<
    Array<{ value: string; label: string }>
  >([]);

  const [resultPreviouslySelected, setResultPreviouslySelected] = useState(
    !!line1 && !!country && !!postCode
  );

  useEffect(() => {
    if (country) {
      setResultPreviouslySelected(!!line1 && !!postCode);
      if (!postCode) {
        void setFieldValue(`${prefix}.line1`, '');

        void setFieldValue(`${prefix}.line2`, '');

        void setFieldValue(`${prefix}.city`, '');

        void setFieldValue(`${prefix}.state`, '');

        void setFieldValue(`${prefix}.postCode`, '');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postCode, prefix, country, setFieldValue]);

  const onPhoneChange = useCallback(
    (fullPhone: string) => {
      onChange?.('phone', fullPhone);
    },
    [onChange]
  );

  return (
    <div>
      <Input
        label="First name"
        className={shrink ? 'lg:max-w-sm' : undefined}
        placeholder="First Name"
        name={`${prefix}.firstName`}
        onInput={(e: React.FormEvent<HTMLInputElement>) =>
          onChange?.('firstName', e.currentTarget.value)
        }
        autoFocus
        required
        containerClassName="mb-5"
      />
      <Input
        className={shrink ? 'lg:max-w-sm' : undefined}
        label="Last name"
        placeholder="Last Name"
        name={`${prefix}.lastName`}
        onInput={(e: React.FormEvent<HTMLInputElement>) =>
          onChange?.('lastName', e.currentTarget.value)
        }
        containerClassName="mb-5"
      />
      <Input
        type="email"
        label="Email address"
        Icon={FiMail}
        placeholder="Email"
        name={`${prefix}.email`}
        onInput={(e: React.FormEvent<HTMLInputElement>) =>
          onChange?.('email', e.currentTarget.value)
        }
        required
        containerClassName="mb-5"
        pattern={new RegExp(/[^@\s]+@[^@\s]+\.[^@\s]+/)}
      />
      <TelField
        name={`${prefix}.phone`}
        value={phone ?? undefined}
        onChange={onPhoneChange}
        containerClassName="mb-5"
      />
      <Input
        className={shrink ? 'lg:max-w-sm' : undefined}
        label="Company"
        placeholder="Company"
        name={`${prefix}.company`}
        onInput={(e: React.FormEvent<HTMLInputElement>) =>
          onChange?.('company', e.currentTarget.value)
        }
        containerClassName="mb-5"
      />
      {(!!line1 || !!postCode || country !== 'GB') && (
        <div className="flex items-center justify-end">
          <Button
            type="button"
            hotjarEvent="AddressFieldGroup_ClearAddress"
            className="text-red underline text-xs"
            onClick={() => {
              void setFieldValue(prefix, {
                ...values[prefix],
                line1: '',
                line2: '',
                city: '',
                state: '',
                postCode: '',
                country: 'GB',
              });

              setResultPreviouslySelected(false);

              setTimeout(() => void setFieldTouched(prefix, true), 0);
            }}
          >
            Clear {prefix.replace('Address', '')} address
          </Button>
        </div>
      )}
      <div className="mb-5">
        <label className={elements.inputLabel}>Country</label>
        <Dropdown
          name={`${prefix}.country`}
          options={countryOptions}
          canSearch
          onChange={async (obj) => {
            onChange?.('country', obj.value);

            if (obj.value !== country) {
              await setFieldValue(prefix, {
                ...values[prefix],
                line1: '',
                line2: '',
                city: '',
                state: '',
                postCode: '',
                country: obj.value,
              });

              setResultPreviouslySelected(false);

              setUkResults([]);
            }
          }}
          required
          useFormik
        />
      </div>
      {country === 'GB' ? (
        <>
          {!resultPreviouslySelected && (
            <>
              <div className="mb-5 last:mb-0">
                <Input
                  label="Post Code / Zip"
                  placeholder="Post Code"
                  name={`${prefix}.postCode`}
                  className={shrink ? 'lg:max-w-40' : undefined}
                  required
                  onInput={(e: React.FormEvent<HTMLInputElement>) => {
                    setUkResults([]);

                    onChange?.('postCode', e.currentTarget.value);
                  }}
                />
              </div>
              {!!postCode && !ukResults.length && (
                <div className="mb-5">
                  <Button
                    type="button"
                    hotjarEvent="AddressFieldGroup_FindAddresses"
                    className={elements.button.secondary}
                    onClick={() => {
                      fetchGetAddressOptions(postCode)
                        .then(({ suggestions }) => {
                          setUkResults(
                            suggestions.map((result) => ({
                              value: result.id,
                              label: result.address,
                            }))
                          );
                        })
                        .catch(console.error);
                    }}
                  >
                    Find Addresses
                  </Button>
                </div>
              )}
              {!!ukResults.length && (
                <div className="mb-5">
                  <label className={elements.inputLabel}>
                    Select an address
                  </label>
                  <Dropdown
                    name={`${prefix}.ukAddressId`}
                    options={ukResults}
                    buttonClassNames="mb-4"
                    onChange={({ value }) => {
                      if (value) {
                        fetchAddressById(value)
                          .then(async (result) => {
                            setResultPreviouslySelected(true);

                            const ukLine1 = result.line_1
                              ? result.line_3
                                ? [result.line_1, result.line_2]
                                    .filter(Boolean)
                                    .join(', ')
                                : result.line_1
                              : [
                                  result.building_number,
                                  result.building_name,
                                  result.sub_building_number,
                                  result.sub_building_name,
                                ]
                                  .filter(Boolean)
                                  .join(', ');

                            const ukLine2 =
                              result.line_1 && result.line_3
                                ? [result.line_3, result.line_4]
                                    .filter(Boolean)
                                    .join(', ')
                                : [result.line_2, result.line_3, result.line_4]
                                    .filter(Boolean)
                                    .join(', ');

                            await setFieldValue(
                              `${prefix}.line1`,
                              ukLine1,
                              true
                            );

                            await setFieldValue(
                              `${prefix}.line2`,
                              ukLine2,
                              true
                            );

                            await setFieldValue(
                              `${prefix}.city`,
                              result.town_or_city,
                              true
                            );

                            await setFieldValue(
                              `${prefix}.state`,
                              result.county,
                              true
                            );

                            await setFieldValue(
                              `${prefix}.postCode`,
                              result.postcode.toUpperCase(),
                              true
                            );

                            setTimeout(
                              () => void setFieldTouched(prefix, true),
                              0
                            );

                            onChange?.('line1', result.line_1);

                            onChange?.('line2', result.line_2);

                            onChange?.('city', result.town_or_city);

                            onChange?.('state', result.county);

                            onChange?.(
                              'postCode',
                              result.postcode.toUpperCase()
                            );
                          })
                          .catch(console.error);
                      }
                    }}
                    required
                  />
                  <Button
                    type='button'
                    hotjarEvent="AddressFieldGroup_EnterAddressManually"
                    className="text-red underline"
                    onClick={() => setResultPreviouslySelected(true)}
                  >
                    Enter address manually
                  </Button>
                </div>
              )}
            </>
          )}
          {resultPreviouslySelected && (
            <Input
              label="Address Line 1"
              placeholder="Line 2"
              name={`${prefix}.line1`}
              containerClassName="mb-5"
              onInput={(e: React.FormEvent<HTMLInputElement>) =>
                onChange?.('line1', e.currentTarget.value)
              }
            />
          )}
        </>
      ) : (
        <SearchField
          label={
            resultPreviouslySelected ? 'Address Line 1' : 'Search for address'
          }
          placeholder={
            resultPreviouslySelected ? 'Line 1' : 'Search for address'
          }
          name={`${prefix}.line1`}
          fetchSuggestions={async (val) => {
            const res = await fetchSuggestions(val, country);

            return res;
          }}
          onSelect={onSelectSuggestion}
          onInput={(value) => onChange?.('line1', value)}
          idProp="place_id"
          labelProp="formatted_address"
          className="mb-5 last:mb-0"
          noSuggestionsText={
            <>
              No addresses found,{' '}
              <Button
                type="button"
                hotjarEvent="AddressFieldGroup_EnterAddressManually"
                className="text-red underline"
                onClick={() => setResultPreviouslySelected(true)}
              >
                enter address manually
              </Button>
            </>
          }
          useFormik
        />
      )}
      {resultPreviouslySelected && (
        <>
          <Input
            label="Address Line 2 (optional)"
            placeholder="Line 2"
            name={`${prefix}.line2`}
            containerClassName="mb-5"
            onInput={(e: React.FormEvent<HTMLInputElement>) =>
              onChange?.('line2', e.currentTarget.value)
            }
          />
          {cities.length === 0 ||
          (city &&
            !cities.some(
              (c) => c.name.toLowerCase() === city.toLowerCase()
            )) ? (
            <Input
              label="Town / City"
              placeholder="Town / City"
              name={`${prefix}.city`}
              className={shrink ? 'lg:max-w-sm' : undefined}
              onInput={(e: React.FormEvent<HTMLInputElement>) =>
                onChange?.('city', e.currentTarget.value)
              }
              containerClassName="mb-5"
            />
          ) : (
            <div className="mb-5">
              <label className={elements.inputLabel}>Town / City</label>
              <Dropdown
                useFormik
                canSearch
                name={`${prefix}.city`}
                options={cities.map((item) => ({
                  value: item.name,
                  label: item.name,
                }))}
                onChange={({ value }) => onChange?.('city', value)}
              />
            </div>
          )}
          {filteredStates.length === 0 ||
          (state &&
            !filteredStates.some(
              (s) => s.isoCode.toLowerCase() === state.toLowerCase()
            )) ? (
            <Input
              className={shrink ? 'lg:max-w-sm' : undefined}
              label="County / State"
              placeholder="County / State"
              name={`${prefix}.state`}
              onInput={(e: React.FormEvent<HTMLInputElement>) =>
                onChange?.('state', e.currentTarget.value)
              }
              containerClassName="mb-5"
            />
          ) : (
            <div className="mb-5">
              <label className={elements.inputLabel}>County / State</label>
              <Dropdown
                name={`${prefix}.state`}
                useFormik
                canSearch
                options={filteredStates.map((item) => ({
                  value: item.isoCode,
                  label: item.name,
                }))}
                onChange={({ value }) => onChange?.('state', value)}
              />
            </div>
          )}
          <Input
            label="Post Code / Zip"
            placeholder="Post Code"
            name={`${prefix}.postCode`}
            className={shrink ? 'lg:max-w-40' : undefined}
            required={postcodeRequired}
            onInput={(e: React.FormEvent<HTMLInputElement>) =>
              onChange?.('postCode', e.currentTarget.value)
            }
          />
        </>
      )}
    </div>
  );
};

export default AddressFieldGroup;
