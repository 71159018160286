import {
  BaseMutationOptions,
  useMutation,
  useSuspenseQuery,
} from '@apollo/client';
import {
  CreateFileMutation,
  CreateInternalProductInput,
  InternalProductFragmentFragment,
  MetaDataInput,
  ProductUploadInput,
  ProductUploadType,
} from '@monorepo/graphql';
import {
  GqlCreateInternalProduct,
  GqlDeleteInternalProduct,
  GqlGetSignedUrl,
  GqlReadInternalProduct,
  GqlUpdateInternalProduct,
} from '@monorepo/graphql/resources';
import PageHeader from './PageHeader';
import { FiDownload, FiPlus, FiTag, FiTrash } from 'react-icons/fi';
import { useNavigate } from '@tanstack/react-router';
import { elements } from '../utility/styles';
import { Field, Form, Formik, useFormikContext } from 'formik';
import SubmitButton from './SubmitButton';
import PageLoader from './PageLoader';
import { toaster } from '../utility/toast';
import Loader from './Loader';
import { Suspense, useCallback } from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import UploadField from './UploadField';
import Input from './Input';
import { Tooltip } from 'react-tooltip';
import Dropdown from './Dropdown';
import { Button } from './Button';
import classNames from 'classnames';

interface Props {
  product?: InternalProductFragmentFragment;
}

type FormikType = Omit<CreateInternalProductInput, 'uploads' | 'meta'> & {
  uploads: Array<
    ProductUploadInput & {
      createdAt?: string;
    }
  >;
  meta?: Array<
    MetaDataInput & {
      createdAt: string;
    }
  >;
};

const Meta = () => {
  const { values, setValues } = useFormikContext<FormikType>();

  return (
    <div className="bg-white rounded-md mb-5">
      <h4 className="mb-2 font-semibold py-3 px-5 border-b border-black/20 text-black">
        Meta Data
      </h4>
      <div className="p-5">
        <ul className="mb-5 w-full flex flex-col">
          {values.meta?.map((m, index) => (
            <li
              key={m.createdAt}
              className="flex items-center grow justify-between mb-5 last:mb-0"
            >
              <div className="w-12" />
              <div className="flex items-center">
                <div>
                  <Input name={`meta[${index}].key`} placeholder="Key" />
                </div>
                <span className="px-0.5">
                  <BiDotsVerticalRounded size={20} />
                </span>
                <div>
                  <Field
                    as="textarea"
                    name={`meta[${index}].value`} 
                    placeholder="Value"
                    className={classNames(elements.textarea, 'h-11')}
                  />
                </div>
              </div>
              <div className="w-12">
                <Button
                  type="button"
                  className={elements.button.tertiary}
                  onClick={async () => {
                    await setValues({
                      ...values,
                      meta: values.meta?.filter((_, i) => i !== index),
                    });
                  }}
                >
                  <FiTrash size={20} />
                </Button>
              </div>
            </li>
          ))}
        </ul>
        <div className="flex justify-center">
          <Button
            type="button"
            className={elements.button.tertiary}
            onClick={async () => {
              await setValues({
                ...values,
                meta: [
                  ...(values.meta ?? []),
                  { key: '', value: '', createdAt: new Date().toISOString() },
                ],
              });
            }}
          >
            <FiPlus size={20} />
          </Button>
        </div>
      </div>
    </div>
  );
};

const ProductUploadField = ({
  type,
  index,
  productUuid,
  productName,
  productSku,
}: {
  type: ProductUploadType;
  index: number;
  productUuid?: string;
  productName?: string;
  productSku?: string;
}) => {
  const { setValues, values } = useFormikContext<FormikType>();
  const productItemFileUuid = values.uploads.find(
    (_u, i) => i === index
  )?.fileUuid;

  const onUpload = useCallback<
    (data: CreateFileMutation, clientOptions?: BaseMutationOptions) => void
  >(
    async ({ createFile }) => {
      await setValues({
        ...values,
        uploads: values.uploads.map((u, i) =>
          i === index
            ? {
                ...u,
                fileUuid: createFile.uuid,
              }
            : u
        ),
      });
    },
    [values, setValues, index]
  );

  return (
    <div className="flex -mx-1 items-center">
      {!!productItemFileUuid && (
        <div className="px-1">
          <Button
            type="button"
            className={elements.button.secondary}
            onClick={async () => {
              await setValues({
                ...values,
                uploads: values.uploads.map((u, i) =>
                  i === index
                    ? {
                        ...u,
                        fileUuid: '',
                      }
                    : u
                ),
              });
            }}
          >
            Pull from Product
          </Button>
        </div>
      )}

      <div className="px-1">
        <UploadField
          onCompleted={onUpload}
          product={{
            uuid: productUuid,
            sku: productSku,
            name: productName,
            type,
          }}
        />
      </div>
      <div className="px-1">
        <Button
          type="button"
          className={elements.button.tertiary}
          onClick={async () => {
            await setValues({
              ...values,
              uploads: values.uploads.filter((_, i) => i !== index),
            });
          }}
        >
          <FiTrash size={20} />
        </Button>
      </div>
    </div>
  );
};

const ProductUploadLink = ({
  fileUuid,
  url,
  index,
}: {
  fileUuid?: string | null;
  url?: string | null;
  index: number;
}) => {
  const { setValues, values } = useFormikContext<FormikType>();

  const { data } = useSuspenseQuery(GqlGetSignedUrl, {
    variables: {
      uuids: fileUuid ? [fileUuid] : [],
      query: '',
    },
    skip: !fileUuid || !!url,
  });

  return (
    <div className="flex items-center -mx-1">
      <div className="px-1">
        <a
          href={url ?? data?.getSignedUrl.find(Boolean) ?? ''}
          target="_blank"
          rel="noreferrer"
          className={elements.button.tertiary}
        >
          <FiDownload size={20} />
        </a>
      </div>
      <div className="px-1">
        <Button
          type="button"
          className={elements.button.tertiary}
          onClick={async () => {
            await setValues({
              ...values,
              uploads: values.uploads.filter((_, i) => i !== index),
            });
          }}
        >
          <FiTrash size={20} />
        </Button>
      </div>
    </div>
  );
};

const Attachments = ({ productUuid }: { productUuid?: string }) => {
  const { values, setValues } = useFormikContext<FormikType>();

  return (
    <div className="bg-white rounded-md">
      <h4 className="font-semibold py-3 px-5 text-black border-b border-black/20">
        Attachments
      </h4>
      <div className="p-5">
        <ul className="mb-5 w-full">
          {values.uploads.map((link, li) => (
            <li className="mb-5 last:mb-0" key={li}>
              <div className="flex items-center">
                <Dropdown
                  name={`uploads[${li}].type`}
                  useFormik
                  options={[
                    { value: ProductUploadType.datasheet, label: 'Datasheet' },
                    { value: ProductUploadType.manual, label: 'Manual' },
                  ]}
                />
                <span className="px-0.5">
                  <BiDotsVerticalRounded size={20} />
                </span>
                {!!link.fileUuid || !!link.url ? (
                  <div className="relative">
                    <Suspense fallback={<PageLoader />}>
                      <ProductUploadLink {...link} index={li} />
                    </Suspense>
                  </div>
                ) : (
                  <div>
                    <ProductUploadField
                      type={link.type}
                      index={li}
                      productUuid={productUuid}
                      productName={values.name}
                      productSku={values.sku}
                    />
                  </div>
                )}
              </div>
              {link.createdAt && (
                <div>
                  <span className="text-xs">
                    Version: {new Date(link.createdAt).toNiceDateTimeFormat()}
                  </span>
                </div>
              )}
            </li>
          ))}
        </ul>
        <div className="flex justify-center">
          <Button
            type="button"
            className={elements.button.tertiary}
            onClick={async () => {
              await setValues({
                ...values,
                uploads: [
                  ...values.uploads,
                  {
                    type: ProductUploadType.datasheet,
                    fileUuid: '',
                    createdAt: new Date().toISOString(),
                  },
                ],
              });
            }}
          >
            <FiPlus size={20} />
          </Button>
        </div>
      </div>
    </div>
  );
};

const CreateOrEditProduct = ({ product }: Props) => {
  const [updateProduct, { loading: updateLoading }] = useMutation(
    GqlUpdateInternalProduct,
    {
      update: (cache, data) => {
        if (data.data?.updateInternalProduct) {
          cache.writeQuery({
            query: GqlReadInternalProduct,
            data: {
              readInternalProduct: data.data.updateInternalProduct,
            },
          });
        }
      },
    }
  );

  const [addProduct, { loading: addLoading }] = useMutation(
    GqlCreateInternalProduct,
    {
      update: (cache) => {
        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'indexInternalProducts',
        });

        cache.gc();
      },
    }
  );

  const [deleteProduct, { loading: deleteLoading }] = useMutation(
    GqlDeleteInternalProduct,
    {
      update: (cache) => {
        cache.evict({
          id: `InternalProduct:${product?.uuid}`,
        });

        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'indexInternalProducts',
        });

        cache.gc();
      },
      onCompleted: () => {
        toaster.success(
          {
            title: 'Product deleted',
            text: 'The product has been deleted',
          },
          { autoClose: 2000 }
        );

        void navigate({
          to: '/admin/quotes/products',
        });
      },
    }
  );

  const loading = updateLoading || addLoading;
  const initialValues: FormikType = {
    name: product?.name ?? '',
    sku: product?.sku ?? '',
    productId: product?.productId,
    meta:
      product?.meta.map((m, i) => ({
        key: m.key,
        value: m.value,
        createdAt: new Date(Date.now() + i).toISOString(),
      })) ?? [],
    uploads:
      product?.uploads.map((u) => ({
        uuid: u.uuid,
        type: u.type,
        fileUuid: u.fileUuid,
        url: u.url,
        createdAt: u.file?.createdAt,
      })) ?? [],
  };

  const navigate = useNavigate();

  return (
    <div className="flex-grow flex flex-col">
      <PageHeader title={product ? 'Edit Product' : 'Add Product'} Icon={FiTag}>
        {!!product &&
          (deleteLoading ? (
            <Loader size="1.5rem" />
          ) : (
            <div>
              <Button
                type="button"
                className={elements.button.tertiary}
                data-tooltip-id="deleteProduct"
                data-tooltip-content="Delete Product"
                data-tooltip-place="bottom"
                onClick={async () => {
                  if (
                    window.confirm(
                      'Are you sure you want to delete this product?'
                    )
                  )
                    await deleteProduct({
                      variables: {
                        uuid: product.uuid,
                      },
                    });
                }}
              >
                <FiTrash size={20} />
              </Button>
              <Tooltip id="deleteProduct" />
            </div>
          ))}
      </PageHeader>
      <p className="mb-5">
        {product ? 'Edit your product below' : 'Add your product below'}.
      </p>
      <div className="mb-5">
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const errors: Partial<FormikType> = {};
            if (!values.name) {
              errors.name = 'Name is required';
            }
            if (!values.sku) {
              errors.sku = 'Sku is required';
            }
            return errors;
          }}
          onSubmit={async (values, props) => {
            const data: FormikType = {
              ...values,
            };

            if (product) {
              await updateProduct({
                variables: {
                  input: {
                    ...data,
                    uploads: data.uploads.map((u) => ({
                      type: u.type,
                      fileUuid: u.fileUuid,
                      url: u.url,
                    })),
                    meta: data.meta?.map((m) => ({
                      key: m.key,
                      value: m.value,
                    })),
                    uuid: product.uuid,
                  },
                },
              });

              props.resetForm({
                values,
              });
            } else {
              const newProduct = await addProduct({
                variables: {
                  input: {
                    ...data,
                    uploads: data.uploads.map((u) => ({
                      type: u.type,
                      fileUuid: u.fileUuid,
                      url: u.url,
                    })),
                    meta: data.meta?.map((m) => ({
                      key: m.key,
                      value: m.value,
                    })),
                  },
                },
              });

              const uuid = newProduct.data?.createInternalProduct.uuid;
              if (uuid) {
                void navigate({
                  to: `/admin/quotes/products/${uuid}`,
                });
              }
            }
            toaster.success(
              {
                title: 'Success',
                text: `You have successfully ${
                  product ? 'updated' : 'added'
                } your product!`,
              },
              {
                autoClose: 5000,
              }
            );
          }}
        >
          <Form className="relative">
            <div className="bg-white rounded-md p-5 pb-2 mb-5">
              <div className="flex flex-wrap -mx-2 items-center">
                <div className="w-full lg:w-1/2 px-2 mb-5">
                  <Input placeholder="Name" name="name" required label="Name" />
                </div>
                <div className="w-full lg:w-1/2 px-2 mb-5">
                  <Input
                    placeholder="Part No."
                    name="sku"
                    required
                    label="Part No."
                  />
                </div>
              </div>
            </div>
            <Meta />
            <Attachments productUuid={product?.uuid} />
            <SubmitButton />
            {loading && <PageLoader />}
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default CreateOrEditProduct;
