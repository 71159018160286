import { gql } from '@apollo/client';
import {
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
  CompletePasswordResetMutation,
  CompletePasswordResetMutationVariables,
  CreateUserMutation,
  CreateUserMutationVariables,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  EditUserMutation,
  EditUserMutationVariables,
  IndexUsersQuery,
  IndexUsersQueryVariables,
  MeQuery,
  MeQueryVariables,
  ReadUserQuery,
  ReadUserQueryVariables,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  SignInMutation,
  SignInMutationVariables,
  VerifyResetPasswordQuery,
  VerifyResetPasswordQueryVariables,
} from '../__generated__/graphql';
import { GqlAddress, GqlCustomer } from './address';
import { GqlPagination } from './pagination';

export const GqlUser = gql`
  fragment UserFragment on User {
    uuid
    email
    firstName
    lastName
    displayName
    role
    legacyId
    telephone
    customer {
      ...CustomerFragment
    }
  }
  ${GqlAddress}
  ${GqlCustomer}
`;

export const GqlContextUser = gql`
  fragment ContextUserFragment on ContextUser {
    uuid
    email
    firstName
    lastName
    displayName
    role
    legacyId
    telephone
    customer {
      ...CustomerFragment
    }
  }
  ${GqlAddress}
  ${GqlCustomer}
`;

export const GqlMeQuery = gql<MeQuery, MeQueryVariables>`
  query Me {
    me {
      ...ContextUserFragment
    }
  }
  ${GqlContextUser}
`;

export const GqlTokens = gql`
  fragment TokensFragment on Tokens {
    jwt
    refreshToken
  }
`;

export const GqlSignInMutation = gql<SignInMutation, SignInMutationVariables>`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      ...TokensFragment
    }
  }
  ${GqlTokens}
`;

export const GqlResetPasswordMutation = gql<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>`
  mutation ResetPassword($email: String!) {
    resetPasswordRequest(email: $email)
  }
`;

export const GqlChangePasswordMutation = gql<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`;

export const GqlCompletePasswordResetMutation = gql<
  CompletePasswordResetMutation,
  CompletePasswordResetMutationVariables
>`
  mutation CompletePasswordReset($input: CompleteResetPasswordInput!) {
    completeResetPassword(input: $input) {
      uuid
    }
  }
`;

export const GqlVerifyResetPassword = gql<
  VerifyResetPasswordQuery,
  VerifyResetPasswordQueryVariables
>`
  query VerifyResetPassword($token: String!) {
    verifyResetPasswordToken(token: $token) {
      ...UserFragment
    }
  }
  ${GqlUser}
`;

export const GqlIndexUsers = gql<IndexUsersQuery, IndexUsersQueryVariables>`
  query IndexUsers($input: UserFilterInput!) {
    indexUsers(input: $input) {
      pagination {
        ...PaginationFragment
      }
      items {
        ...UserFragment
      }
    }
  }
  ${GqlPagination}
  ${GqlUser}
`;

export const GqlReadUser = gql<ReadUserQuery, ReadUserQueryVariables>`
  query ReadUser($uuid: ID!) {
    readUser(uuid: $uuid) {
      ...UserFragment
    }
  }
  ${GqlUser}
`;

export const GqlCreateUser = gql<
  CreateUserMutation,
  CreateUserMutationVariables
>`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ...UserFragment
    }
  }
  ${GqlUser}
`;

export const GqlEditUser = gql<EditUserMutation, EditUserMutationVariables>`
  mutation EditUser($input: EditUserInput!) {
    editUser(input: $input) {
      ...UserFragment
    }
  }
  ${GqlUser}
`;

export const GqlDeleteUser = gql<
  DeleteUserMutation,
  DeleteUserMutationVariables
>`
  mutation DeleteUser($uuid: ID!) {
    deleteUser(uuid: $uuid)
  }
`;
