import { gql } from '@apollo/client';
import {
  AddOrderNoteMutation,
  AddOrderNoteMutationVariables,
  EditOrderMutation,
  EditOrderMutationVariables,
  IndexOrdersQuery,
  IndexOrdersQueryVariables,
  ReadOrderQuery,
  ReadOrderQueryVariables,
  SendOrderEmailMutation,
  SendOrderEmailMutationVariables,
  EditOrderNoteMutation,
  EditOrderNoteMutationVariables,
  DeleteOrderNoteMutation,
  DeleteOrderNoteMutationVariables,
} from '../__generated__/graphql';
import { GqlPagination } from './pagination';
import { GqlAddress, GqlCustomer } from './address';

export const GqlOrderNote = gql`
  fragment OrderNoteFragment on OrderNote {
    uuid
    text
    type
    createdAt
  }
`;

export const GqlOrder = gql`
  fragment OrderFragment on Order {
    uuid
    status
    createdAt
    paymentMethodId
    paymentMethodLabel
    purchaseOrderFileUuid
    purchaseOrderReference
    trackingReference
    inTouchReference
    accountingReference
    paymentProcessing
    shippingMethod
    currency
    coupon {
      code
    }
    lineItems {
      type
      title
      quantity
      sku
      title
      amount
      taxRate
      leadTime
      originalAmount
      meta {
        key
        value
      }
      links {
        url
        type
      }
    }
    customer {
      ...CustomerFragment
    }
    notes {
      ...OrderNoteFragment
    }
    billingAddress {
      ...AddressFragment
    }
    shippingAddress {
      ...AddressFragment
    }
    total
  }
  ${GqlCustomer}
  ${GqlOrderNote}
  ${GqlAddress}
`;

export const GqlIndexOrders = gql<IndexOrdersQuery, IndexOrdersQueryVariables>`
  query IndexOrders($input: OrderFilterInput) {
    indexOrders(input: $input) {
      pagination {
        ...PaginationFragment
      }
      items {
        ...OrderFragment
      }
    }
  }
  ${GqlPagination}
  ${GqlOrder}
`;

export const GqlReadOrder = gql<ReadOrderQuery, ReadOrderQueryVariables>`
  query ReadOrder($uuid: ID!) {
    readOrder(uuid: $uuid) {
      ...OrderFragment
    }
  }
  ${GqlOrder}
`;

export const GqlEditOrder = gql<EditOrderMutation, EditOrderMutationVariables>`
  mutation EditOrder($input: EditOrderInput!) {
    editOrder(input: $input) {
      ...OrderFragment
    }
  }
  ${GqlOrder}
`;

export const GqlSendOrderEmail = gql<
  SendOrderEmailMutation,
  SendOrderEmailMutationVariables
>`
  mutation SendOrderEmail($orderUuid: ID!) {
    sendOrderEmail(orderUuid: $orderUuid)
  }
`;

export const GqlAddOrderNote = gql<
  AddOrderNoteMutation,
  AddOrderNoteMutationVariables
>`
  mutation AddOrderNote($input: AddOrderNoteInput!) {
    addOrderNote(input: $input) {
      ...OrderNoteFragment
    }
  }
  ${GqlOrderNote}
`;

export const GqlEditOrderNote = gql<
  EditOrderNoteMutation,
  EditOrderNoteMutationVariables
>`
  mutation EditOrderNote($input: EditOrderNoteInput!) {
    editOrderNote(input: $input) {
      ...OrderNoteFragment
    }
  }
  ${GqlOrderNote}
`;

export const GqlDeleteOrderNote = gql<
  DeleteOrderNoteMutation,
  DeleteOrderNoteMutationVariables
>`
  mutation DeleteOrderNote($orderNoteUuid: ID!) {
    deleteOrderNote(orderNoteUuid: $orderNoteUuid)
  }
`;
