import CreateOrEditUser from '../../../../components/CreateOrEditUser';
import NotFound from '../../../../components/NotFound';
import { doQuery } from '../../../../utility/apollo';
import { GqlReadUser } from '@monorepo/graphql/resources';
import { createFileRoute, notFound } from '@tanstack/react-router';

export const loader = async ({ params }: { params: { userUuid: string } }) => {
  try {
    const { data } = await doQuery({
      query: GqlReadUser,
      variables: {
        uuid: params.userUuid,
      },
    });

    return { user: data.readUser };
  } catch (error) {
    throw notFound();
  }
};

const UserEditComponent = () => {
  const { user } = Route.useLoaderData();

  return <CreateOrEditUser user={user} />;
};

export const Route = createFileRoute('/_authenticated/admin/users/$userUuid')({
  loader,
  errorComponent: () => <NotFound to="/admin/users" />,
  component: UserEditComponent,
});
