import {
  CatchBoundary,
  createFileRoute,
  Outlet,
  useRouter,
} from '@tanstack/react-router';
import * as Sentry from '@sentry/react';
import ErrorScreen from '../components/ErrorScreen';
import { UserProvider } from '../utility/authentication';

export const Route = createFileRoute('/_neutral')({
  component: LayoutComponent,
});

function LayoutComponent() {
  const router = useRouter();

  return (
    <CatchBoundary
      onCatch={(error) => {
        Sentry.captureException(error);
      }}
      getResetKey={() => router.state.resolvedLocation.state.key ?? ''}
      errorComponent={ErrorScreen}
    >
      <UserProvider>
        <Outlet />
      </UserProvider>
    </CatchBoundary>
  );
}
