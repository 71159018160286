import CreateOrEditCoupon from '../../../../components/CreateOrEditCoupon';
import NotFound from '../../../../components/NotFound';
import { doQuery } from '../../../../utility/apollo';
import { GqlReadCoupon } from '@monorepo/graphql/resources';
import { createFileRoute, notFound } from '@tanstack/react-router';

const CouponEditorComponent = () => {
  const { coupon } = Route.useLoaderData();

  return <CreateOrEditCoupon coupon={coupon} />;
};

export const Route = createFileRoute(
  '/_authenticated/admin/coupons/$couponUuid'
)({
  loader: async ({ params }) => {
    try {
      const { data } = await doQuery({
        query: GqlReadCoupon,
        variables: {
          uuid: params.couponUuid,
        },
      });

      return { coupon: data.readCoupon };
    } catch (error) {
      throw notFound();
    }
  },
  errorComponent: () => <NotFound to="/admin/coupons" />,
  component: CouponEditorComponent,
});
