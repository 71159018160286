import { useCallback, useMemo, useState } from 'react';
import { DateValueType } from 'react-tailwindcss-datepicker';
import DatePicker from './DatePicker';
import Dropdown, { Item } from './Dropdown';
import {
  formatMoney,
  IndexOrdersQuery,
  LineItemTypeEnum,
  OrderFilterFunctionInput,
  OrderStatusEnum,
  orderStatuses,
} from '@monorepo/graphql';
import { elements } from '../utility/styles';
import classNames from 'classnames';
import { useBackgroundQuery } from '@apollo/client';
import { Link } from '@tanstack/react-router';
import DataLayout, { FilterOption, TableColumn } from './DataLayout';
import { FiShoppingCart } from 'react-icons/fi';
import { GqlIndexOrders } from '@monorepo/graphql/resources';
import { To } from './NotFound';
import CustomerSearchField from './CustomerSearchField';
import ProductSearchField from './ProductSearchField';
import Input from './Input';

export enum TableColumnEnum {
  reference = 'reference',
  createdAt = 'createdAt',
  customer = 'customer',
  total = 'total',
  status = 'status',
  products = 'products',
  actions = 'actions',
}

export enum FilterOptionEnum {
  dateRange = 'dateRange',
  customer = 'customer',
  status = 'status',
  search = 'search',
  productSearch = 'productSearch',
}

type Order = IndexOrdersQuery['indexOrders']['items'][0];

function OrdersList({
  title = 'Orders',
  legacyUrl,
  hiddenColumns,
  hiddenFilters,
  initialFilters,
}: {
  title?: string;
  legacyUrl: To;
  hiddenColumns?: TableColumnEnum[];
  hiddenFilters?: FilterOptionEnum[];
  initialFilters?: Partial<OrderFilterFunctionInput>;
}) {
  const defaultFilters = useMemo<OrderFilterFunctionInput>(
    () => ({
      customerUuid: null,
      statuses: [],
      from: null,
      to: null,
      term: null,
      productTerm: null,
      ...(initialFilters ?? {}),
    }),
    [initialFilters]
  );

  const tableColumns = useMemo<Array<TableColumn<Order>>>(
    () =>
      [
        {
          key: TableColumnEnum.reference,
          label: 'Order #',
          handler: (order: Order) => (
            <Link to={order.uuid}>{order.accountingReference}</Link>
          ),
          style: { width: '10%' },
        },
        {
          key: TableColumnEnum.createdAt,
          label: 'Date',
          handler: (order: Order) => (
            <span>{new Date(order.createdAt).toNiceFormat()}</span>
          ),
          style: { width: '12.5%' },
        },
        {
          key: TableColumnEnum.customer,
          label: 'Customer',
          handler: (order: Order) => (
            <span>
              {[order.customer?.firstName, order.customer?.lastName]
                .filter(Boolean)
                .join(' ')}
            </span>
          ),
          style: { width: '12.5%' },
        },
        {
          key: TableColumnEnum.total,
          label: 'Amount (£)',
          handler: (order: Order) => (
            <span>{formatMoney(order.total, order.currency)}</span>
          ),
          style: { width: '12.5%' },
        },
        {
          key: TableColumnEnum.status,
          label: 'Status',
          handler: (order: Order) => (
            <span>
              {orderStatuses.find((o) => o.value === order.status)?.label}
            </span>
          ),
          style: { width: '12.5%' },
        },
        {
          key: TableColumnEnum.products,
          label: 'Products',
          handler: (order: Order) => {
            const products = order.lineItems.filter(
              (l) => l.type === LineItemTypeEnum.product
            );

            const productLabel = `${products.find(Boolean)?.title}${
              products.length > 1 ? ` + ${products.length - 1} more` : ''
            }`;

            return <span>{productLabel}</span>;
          },
          style: { width: '30%' },
        },
        {
          key: TableColumnEnum.actions,
          label: '',
          handler: (order: Order) => (
            <Link
              className={classNames(
                elements.button.secondary,
                'text-xs inline-flex whitespace-nowrap'
              )}
              to={order.uuid}
            >
              View
            </Link>
          ),
          style: { width: '10%' },
        },
      ].filter((c) => !hiddenColumns?.includes(c.key)),
    [hiddenColumns]
  );

  const [dateRange, setDateRange] = useState<DateValueType>({
    startDate: null,
    endDate: null,
  });

  const [customerUuid, setCustomerUuid] = useState<string | null>(null);

  const [selectedStatuses, setSelectedStatuses] = useState<OrderStatusEnum[]>(
    []
  );

  const setActiveStatus = useCallback((item: Item) => {
    setSelectedStatuses((statuses) =>
      statuses.includes(item.value as OrderStatusEnum)
        ? statuses.filter((status) => status !== item.value)
        : [...statuses, item.value as OrderStatusEnum]
    );
  }, []);

  const [term, setTerm] = useState<string | undefined>();
  const [productTerm, setProductTerm] = useState<string | undefined>();

  const [filters, setFilters] =
    useState<OrderFilterFunctionInput>(defaultFilters);

  const currentFilters = useMemo(
    () => ({
      customerUuid,
      statuses: selectedStatuses,
      from: dateRange?.startDate,
      to: dateRange?.endDate,
      term,
      productTerm
    }),
    [selectedStatuses, dateRange, term, customerUuid, productTerm]
  );

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);

  const [query] = useBackgroundQuery(GqlIndexOrders, {
    variables: {
      input: {
        filters,
        orderBy: null,
        pagination: {
          page,
          perPage,
        },
      },
    },
  });

  const resetFilters = useCallback(() => {
    setDateRange({ startDate: null, endDate: null });

    setSelectedStatuses([]);

    setTerm(undefined);

    setProductTerm(undefined);

    setCustomerUuid(null);
    
    setCustomerDefaultValue(undefined);
  }, []);

  const applyFilters = useCallback(() => {
    setFilters({
      customerUuid,
      statuses: selectedStatuses,
      from: dateRange?.startDate
        ? new Date(dateRange.startDate).toISOString()
        : null,
      to: dateRange?.endDate ? new Date(dateRange.endDate).toISOString() : null,
      term,
      productTerm
    });
  }, [selectedStatuses, dateRange, term, customerUuid, productTerm]);

  const [customerDefaultValue, setCustomerDefaultValue] = useState<
    string | undefined
  >();

  const filterOptions = useMemo<FilterOption[]>(
    () =>
      [
        {
          key: FilterOptionEnum.search,
          Component: ({ term: item }: { term?: string }) => (
            <Input
              className="w-full"
              name="term"
              value={item}
              placeholder='Search by Order #'
              onInput={(e) => setTerm(e.currentTarget.value)}
              onKeyDown={(e) => e.stopPropagation()}
              useFormik={false}
            />
          ),
        },
        {
          key: FilterOptionEnum.dateRange,
          label: 'Date Range',
          Component: () => (
            <DatePicker value={dateRange} setValue={setDateRange} useRange />
          ),
        },
        {
          key: FilterOptionEnum.customer,
          Component: () => (
            <CustomerSearchField
              onSelect={(customer) => {
                setCustomerUuid(customer.uuid);

                setCustomerDefaultValue(customer.displayName);
              }}
              defaultValue={customerDefaultValue}
            />
          ),
        },
        {
          key: FilterOptionEnum.status,
          label: 'Status',
          Component: () => (
            <Dropdown
              placeholder="Filter by Status"
              name="status"
              options={orderStatuses}
              onChange={setActiveStatus}
              value={selectedStatuses}
            />
          ),
        },
        {
          key: FilterOptionEnum.productSearch,
          Component: ({ term: item }: { term?: string }) => (
            <ProductSearchField
              onSelect={(product) => {
                setProductTerm(product.name);
              }}
              onInput={setProductTerm}
              defaultValue={item}
            />
          ),
        },
      ].filter((f) => !hiddenFilters?.includes(f.key)),
    [
      dateRange,
      selectedStatuses,
      customerDefaultValue,
      hiddenFilters,
      setActiveStatus,
    ]
  );

  const extractItems = useCallback(
    (data: IndexOrdersQuery) => data.indexOrders.items,
    []
  );

  const extractPagination = useCallback(
    (data: IndexOrdersQuery) => data.indexOrders.pagination,
    []
  );

  return (
    <DataLayout
      Icon={FiShoppingCart}
      title={title}
      subtitle={
        <>
          Manage your orders below, or view{' '}
          <Link to={legacyUrl} className="text-red underline">
            legacy orders
          </Link>
          .
        </>
      }
      setPage={setPage}
      setPerPage={setPerPage}
      tableColumns={tableColumns}
      filterOptions={filterOptions}
      onReset={resetFilters}
      onConfirm={applyFilters}
      currentFilters={currentFilters}
      baseFilters={defaultFilters}
      term={term}
      query={query}
      extractItems={extractItems}
      extractPagination={extractPagination}
    />
  );
}

export default OrdersList;
