import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  InMemoryCache,
  MutationFunctionOptions,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useSuspenseQuery,
} from '@apollo/client';
import {
  GqlAbortQuoteCheckoutPayment,
  GqlCompleteQuote,
  GqlDeleteFile,
  GqlEditQuote,
  GqlGetQuoteStripePaymentIntentClientSecret,
  GqlGetSignedUrl,
  GqlIndexStripePaymentMethods,
  GqlReadQuote,
} from '@monorepo/graphql/resources';
import {
  FiArrowDown,
  FiArrowLeft,
  FiArrowRight,
  FiCreditCard,
  FiDownload,
  FiPaperclip,
  FiTrash,
} from 'react-icons/fi';
import {
  AddressInput,
  EditQuoteInput,
  EditQuoteMutation,
  Exact,
  formatMoney,
  LineItemTypeEnum,
  PaymentMethodEnum,
  QuoteFragmentFragment,
  ShippingMethodEnum,
  shippingMethodLabels,
} from '@monorepo/graphql';
import { Form, Formik } from 'formik';
import PageLoader from './PageLoader';
import { useUser } from '../utility/authentication';
import UploadField from './UploadField';
import { toaster } from '../utility/toast';
import { loadStripe } from '@stripe/stripe-js';
import env from '../environment';
import { Elements, useElements } from '@stripe/react-stripe-js';
import { Fragment, Suspense, useEffect, useMemo, useState } from 'react';
import AddressFieldGroup from './AddressFieldGroup';
import { elements } from '../utility/styles';
import classNames from 'classnames';
import Input from './Input';
import RadioCard from './RadioCard';
import { CiDeliveryTruck } from 'react-icons/ci';
import Checkbox from './Checkbox';
import CheckoutFooter from './CheckoutFooter';
import { Tooltip } from 'react-tooltip';
import Accordion from './Accordion';
import LoginStep from './LoginStep';
import ShippingContinueButton from './ShippingContinueButton';
import PaymentMethod from './PaymentMethod';
import MetaList from './MetaList';
import { Link } from '@tanstack/react-router';
import { Button } from './Button';
import { cache } from '../utility/cache';

const stripePromise = loadStripe(env.stripePublishableKey);

const PaymentStep = ({
  quoteUuid,
  password,
  customerUuid,
}: {
  quoteUuid: string;
  password?: string;
  customerUuid?: string;
}) => {
  const { data } = useSuspenseQuery(
    GqlGetQuoteStripePaymentIntentClientSecret,
    {
      variables: {
        input: {
          quoteUuid,
        },
      },
    }
  );

  const paymentIntentClientSecret =
    data.getQuoteStripePaymentIntentClientSecret;

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: paymentIntentClientSecret,
        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap',
          },
        ],
        appearance: {
          theme: 'flat',
          variables: {
            fontFamily: 'Open Sans',
            borderRadius: '4px',
            colorBackground: '#fff',
          },
          rules: {
            '.Input': {
              borderColor: '#cccccc',
              borderWidth: '1px',
              borderStyle: 'solid',
            },
            '.Label': {
              fontSize: '0.875rem',
              lineHeight: '1.25rem',
              fontWeight: '600',
              marginBottom: '8px',
            },
          },
        },
      }}
    >
      <PaymentStage
        password={password}
        customerUuid={customerUuid}
        quoteUuid={quoteUuid}
      />
    </Elements>
  );
};

const PaymentStage = ({
  password,
  customerUuid,
  quoteUuid,
}: {
  password?: string;
  customerUuid?: string;
  quoteUuid: string;
}) => {
  const { data: stripePaymentMethods } = useSuspenseQuery(
    GqlIndexStripePaymentMethods,
    {
      skip: !customerUuid,
    }
  );

  const stripElements = useElements();
  const [stripeLoading, setStripeLoading] = useState(false);
  const [abortQuoteCheckoutPayment] = useMutation(
    GqlAbortQuoteCheckoutPayment,
    {
      variables: {
        quoteUuid,
      },
    }
  );

  const [completeQuote, { loading }] = useMutation(GqlCompleteQuote, {
    onCompleted: () => {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'readQuote',
      });

      cache.gc();
    },
  });

  const [displayAllPaymentMethods, setDisplayAllPaymentMethods] =
    useState(false);

  const [fetchClientSecret] = useLazyQuery(
    GqlGetQuoteStripePaymentIntentClientSecret
  );

  return (
    <Formik
      initialValues={{
        paymentMethodId: PaymentMethodEnum.card,
      }}
      onSubmit={async (values) => {
        setStripeLoading(true);

        if (values.paymentMethodId !== PaymentMethodEnum.creditLimit) {
          await stripePromise.then(async (stripe) => {
            const returnUrl = window.location.href;
            const secretData = await fetchClientSecret({
              variables: {
                input: {
                  quoteUuid,
                  paymentMethodId: values.paymentMethodId,
                },
              },
            });

            const paymentIntentClientSecret =
              secretData.data?.getQuoteStripePaymentIntentClientSecret;

            if (!paymentIntentClientSecret) {
              toaster.error(
                {
                  title: 'Payment Error',
                  text: 'Failed to generate payment intent',
                },
                {
                  autoClose: 5000,
                }
              );

              setStripeLoading(false);
              return;
            }
            if (values.paymentMethodId === PaymentMethodEnum.card) {
              if (stripElements) {
                await stripElements.submit().then(async (res) => {
                  if (res.error) {
                    toaster.error(
                      {
                        title: 'Payment Error',
                        text: res.error.message,
                      },
                      {
                        autoClose: 5000,
                      }
                    );

                    void abortQuoteCheckoutPayment();
                  } else {
                    const cRes = await stripe?.confirmPayment({
                      elements: stripElements,
                      clientSecret: paymentIntentClientSecret,
                      confirmParams: {
                        return_url: returnUrl,
                      },
                    });

                    if (cRes?.error) {
                      toaster.error(
                        {
                          title: 'Payment Error',
                          text: cRes.error.message,
                        },
                        {
                          autoClose: 5000,
                        }
                      );

                      void abortQuoteCheckoutPayment();
                    } else {
                      toaster.success(
                        {
                          title: 'Quote Paid',
                          text: 'The quote has been paid successfully.',
                        },
                        {
                          autoClose: 5000,
                        }
                      );

                      await completeQuote({
                        variables: {
                          input: {
                            quoteUuid,
                            paymentMethodId: values.paymentMethodId,
                            password,
                          },
                        },
                      });
                    }
                  }
                });
              } else {
                toaster.error(
                  {
                    title: 'Payment Error',
                    text: 'Please select a payment method',
                  },
                  {
                    autoClose: 5000,
                  }
                );

                void abortQuoteCheckoutPayment();
              }
            } else {
              const res = await stripe?.confirmCardPayment(
                paymentIntentClientSecret,
                {
                  payment_method: values.paymentMethodId,
                  return_url: returnUrl,
                }
              );

              if (res?.error) {
                toaster.error(
                  {
                    title: 'Payment Error',
                    text: res.error.message,
                  },
                  {
                    autoClose: 5000,
                  }
                );

                void abortQuoteCheckoutPayment();
              } else if (res?.paymentIntent.status === 'succeeded') {
                toaster.success(
                  {
                    title: 'Quote Paid',
                    text: 'The quote has been paid successfully.',
                  },
                  {
                    autoClose: 5000,
                  }
                );

                await completeQuote({
                  variables: {
                    input: {
                      quoteUuid,
                      paymentMethodId: values.paymentMethodId,
                      password,
                    },
                  },
                });
              }
            }
          });
        } else {
          toaster.success(
            {
              title: 'Quote Completed',
              text: 'The quote has been completed successfully.',
            },
            {
              autoClose: 5000,
            }
          );

          await completeQuote({
            variables: {
              input: {
                quoteUuid,
                paymentMethodId: values.paymentMethodId,
                password,
              },
            },
          });
        }

        setStripeLoading(false);
      }}
    >
      <Form className="relative">
        <label className={elements.inputLabel}>Payment method</label>
        <div className="flex flex-wrap -mx-1 mb-8">
          <div className="px-1 w-full mb-5">
            <RadioCard
              Icon={FiPaperclip}
              name="paymentMethodId"
              value={PaymentMethodEnum.creditLimit}
              title="ABLE Account Holder"
              description="Existing account? Use credit"
              useFormik
              style="horizontal"
            />
          </div>
          {stripePaymentMethods &&
            stripePaymentMethods.indexStripePaymentMethods.length > 0 && (
              <>
                <div
                  className="px-1 w-full mb-5"
                  key={stripePaymentMethods.indexStripePaymentMethods[0].id}
                >
                  <RadioCard
                    Icon={FiCreditCard}
                    name="paymentMethodId"
                    value={stripePaymentMethods.indexStripePaymentMethods[0].id}
                    title={
                      <div className="flex items-center">
                        **** **** ****{' '}
                        {stripePaymentMethods.indexStripePaymentMethods[0].card
                          .last4 ??
                          stripePaymentMethods.indexStripePaymentMethods[0].id}
                        <span className="bg-black text-white py-1 px-2 rounded-md ml-2 text-xs font-semibold">
                          Default
                        </span>
                      </div>
                    }
                    description={
                      <>
                        Use card ending{' '}
                        {stripePaymentMethods.indexStripePaymentMethods[0].card
                          .last4 ??
                          stripePaymentMethods.indexStripePaymentMethods[0].id}
                      </>
                    }
                    useFormik
                    style="horizontal"
                  />
                </div>
                {stripePaymentMethods.indexStripePaymentMethods.length > 1 && (
                  <>
                    <div className="px-1 w-full mb-5 flex justify-center">
                      <Button
                        className="text-red underline text-sm flex items-center"
                        type="button"
                        hotjarEvent="CheckoutQuote__ViewMorePaymentMethods"
                        onClick={() => setDisplayAllPaymentMethods((d) => !d)}
                      >
                        View {displayAllPaymentMethods ? 'less' : 'more'} cards{' '}
                        <FiArrowDown
                          size={16}
                          className={classNames('ml-1 transition-all', {
                            'rotate-180': displayAllPaymentMethods,
                          })}
                        />
                      </Button>
                    </div>
                    {displayAllPaymentMethods && (
                      <>
                        {stripePaymentMethods.indexStripePaymentMethods
                          .slice(1)
                          .map((method) => (
                            <div className="px-1 w-full mb-5" key={method.id}>
                              <RadioCard
                                Icon={FiCreditCard}
                                name="paymentMethodId"
                                value={method.id}
                                title={`**** **** **** ${
                                  method.card.last4 ?? method.id
                                }`}
                                description={`Use card ending ${
                                  method.card.last4 ?? method.id
                                }`}
                                useFormik
                                style="horizontal"
                              />
                            </div>
                          ))}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          <div className="px-1 w-full">
            <RadioCard
              Icon={FiCreditCard}
              name="paymentMethodId"
              value={PaymentMethodEnum.card}
              title="Add New Card"
              description="Use a new card to pay"
              useFormik
              style="horizontal"
            />
          </div>
        </div>

        <PaymentMethod />
        {(loading || stripeLoading) && <PageLoader />}
      </Form>
    </Formik>
  );
};

interface Props {
  quote: QuoteFragmentFragment;
}

const PoUploadArea = ({
  purchaseOrderFileUuid,
  quoteUuid,
  updateQuote,
}: {
  purchaseOrderFileUuid?: string;
  quoteUuid: string;
  updateQuote: (
    options:
      | MutationFunctionOptions<
          EditQuoteMutation,
          Exact<{
            input: EditQuoteInput;
          }>,
          DefaultContext,
          ApolloCache<InMemoryCache>
        >
      | undefined
  ) => Promise<FetchResult<EditQuoteMutation>>;
}) => {
  const [deleteFile] = useMutation(GqlDeleteFile);

  const { data: signedUrlData } = useSuspenseQuery(GqlGetSignedUrl, {
    variables: {
      uuids: purchaseOrderFileUuid ? [purchaseOrderFileUuid] : [],
      query: null,
    },
    skip: !purchaseOrderFileUuid,
  });

  return (
    signedUrlData?.getSignedUrl.find(Boolean) &&
    !!purchaseOrderFileUuid && (
      <div className="flex items-center -mx-2 mb-5">
        <div className="px-2">
          <a
            href={signedUrlData.getSignedUrl[0]}
            target="_blank"
            rel="noreferrer"
            className={elements.button.secondary}
            data-tooltip-id={`download-${purchaseOrderFileUuid}`}
            data-tooltip-content={`Download Purchase Order - ${
              new URLSearchParams(signedUrlData.getSignedUrl[0].split('?')[1])
                .get('rscd')
                ?.split('filename=')[1]
            }`}
          >
            <FiDownload size={20} />
          </a>
          <Tooltip id={`download-${purchaseOrderFileUuid}`} />
        </div>
        <div className="px-2">
          <Button
            type="button"
            data-tooltip-id={`delete-${purchaseOrderFileUuid}`}
            data-tooltip-content={`Download Purchase Order - ${
              new URLSearchParams(signedUrlData.getSignedUrl[0].split('?')[1])
                .get('rscd')
                ?.split('filename=')[1]
            }`}
            className={classNames(
              elements.button.secondary,
              'h-10 flex items-center'
            )}
            onClick={async () => {
              await deleteFile({
                variables: { uuid: purchaseOrderFileUuid },
                onCompleted: async () => {
                  await updateQuote({
                    variables: {
                      input: {
                        uuid: quoteUuid,
                        purchaseOrderFileUuid: null,
                      },
                    },
                  });
                },
              });
            }}
          >
            <FiTrash size={20} />
          </Button>
          <Tooltip id={`delete-${purchaseOrderFileUuid}`} />
        </div>
      </div>
    )
  );
};

const CheckoutQuote = ({ quote }: Props) => {
  const { user } = useUser();

  const [updateQuote, { loading: editingLoading }] = useMutation(GqlEditQuote, {
    onCompleted: (data) => {
      cache.writeQuery({
        query: GqlReadQuote,
        data: {
          readQuote: data.editQuote,
        },
      });
    },
  });

  const purchaseOrderFileUuid = quote.purchaseOrderFileUuid;

  const loading = editingLoading;

  const apolloClient = useApolloClient();

  useEffect(() => {
    let timer: NodeJS.Timer | null;
    const maybeRefetch = () => {
      if (timer) {
        clearTimeout(timer);
      }
      if (quote.paymentProcessing) {
        void apolloClient.refetchQueries({
          updateCache(c) {
            c.evict({
              id: `Quote:${quote.uuid}`,
            });
          },
        });

        timer = setTimeout(maybeRefetch, 5000);
      }
    };

    maybeRefetch();
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [quote.paymentProcessing, apolloClient, quote.uuid]);

  const subtotal = quote.lineItems.reduce(
    (acc, item) => acc + Math.max(item.quantity, 1) * item.amount,
    0
  );

  const totalTax = quote.lineItems
    .filter((l) => l.taxRate > 0)
    .reduce(
      (acc, item) =>
        acc + Math.max(item.quantity, 1) * item.amount * item.taxRate,
      0
    );

  const total = quote.total;
  const currency = quote.currency;

  const [password, setPassword] = useState<string | undefined>();
  const initialValues = useMemo<{
    billingAddress: AddressInput;
    shippingAddress: AddressInput;
  }>(
    () => ({
      billingAddress: {
        firstName: quote.billingAddress?.firstName ?? '',
        lastName: quote.billingAddress?.lastName ?? '',
        email: quote.billingAddress?.email ?? '',
        company: quote.billingAddress?.company ?? '',
        phone: quote.billingAddress?.phone ?? '',
        line1: quote.billingAddress?.line1 ?? '',
        line2: quote.billingAddress?.line2 ?? '',
        city: quote.billingAddress?.city ?? '',
        state: quote.billingAddress?.state ?? '',
        postCode: quote.billingAddress?.postCode ?? '',
        country: quote.billingAddress?.country ?? 'GB',
      },
      shippingAddress: {
        firstName: quote.shippingAddress?.firstName ?? '',
        lastName: quote.shippingAddress?.lastName ?? '',
        email: quote.shippingAddress?.email ?? '',
        company: quote.shippingAddress?.company ?? '',
        phone: quote.shippingAddress?.phone ?? '',
        line1: quote.shippingAddress?.line1 ?? '',
        line2: quote.shippingAddress?.line2 ?? '',
        city: quote.shippingAddress?.city ?? '',
        state: quote.shippingAddress?.state ?? '',
        postCode: quote.shippingAddress?.postCode ?? '',
        country: quote.shippingAddress?.country ?? 'GB',
      },
    }),
    [quote]
  );

  const shippingMethodLineItem = quote.lineItems.find(
    (l) => l.type === LineItemTypeEnum.shipping
  );

  const [activeAccordion, setActiveAccordion] = useState<number>(1);
  const [checkoutType, setCheckoutType] = useState<
    'login' | 'guest' | undefined
  >(user.uuid ? 'login' : undefined);

  useEffect(() => {
    if (user.uuid) {
      setCheckoutType('login');
    }
  }, [user.uuid]);
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(
    JSON.stringify(initialValues.billingAddress) ===
      JSON.stringify(initialValues.shippingAddress)
  );

  return (
    <main className="h-dvh overflow-y-auto flex flex-col justify-between">
      <div className="flex flex-col lg:flex-row grow">
        <div className="px-8 lg:px-20 bg-lightGray text-dark flex justify-center grow lg:w-1/2 lg:justify-end">
          <div className="w-full lg:max-w-lg flex flex-col justify-between py-10 lg:py-16 h-max">
            <div>
              {user.uuid ? (
                <Link
                  to={`/my-account/quotes/${quote.uuid}`}
                  className="flex mb-8 items-center"
                >
                  <FiArrowLeft className="mr-4" size={28} />
                  <img src="/v2-assets/logo-old.svg" alt="Logo" width="120" />
                </Link>
              ) : (
                <a href={env.wpUrl} className="flex mb-8 items-center">
                  <FiArrowLeft className="mr-4" size={28} />
                  <img src="/v2-assets/logo-old.svg" alt="Logo" width="120" />
                </a>
              )}
              <div className="mb-5 flex items-center justify-between text-xl">
                <h4 className="font-bold mb-2">Summary</h4>
                <div className="flex items-center">
                  <h2 className="font-normal mr-2">Total:</h2>
                  <h2 className="text-darkGreen font-bold">
                    {formatMoney(total, currency)}
                  </h2>
                </div>
              </div>
              <div className="mb-6 border border-black/20 rounded">
                <table className="w-full">
                  <tbody>
                    {quote.lineItems
                      .filter((l) => l.type === LineItemTypeEnum.product)
                      .map((item) => (
                        <Fragment key={item.uuid}>
                          {item.products.map((product, i) => (
                            <tr
                              className="border-b border-black/20 last:border-0"
                              key={product.sku}
                            >
                              <td className="p-4 text-left">
                                <div className="flex justify-between mb-5 last:mb-0 -mx-2">
                                  <div className="px-2">
                                    <div className="flex items-center">
                                      <h4 className="font-semibold">
                                        {item.title}
                                      </h4>
                                    </div>
                                    {product.meta.length > 0 && (
                                      <div className="mt-4">
                                        <MetaList meta={product.meta} />
                                      </div>
                                    )}
                                  </div>
                                  <div className="px-2">
                                    <h5 className="text-darkGreen font-semibold flex flex-col text-right mb-5">
                                      {formatMoney(
                                        !i ? item.amount : 0,
                                        currency
                                      )}
                                    </h5>
                                    <div className="flex">
                                      <input
                                        type="number"
                                        className={classNames(
                                          elements.input,
                                          'pl-3',
                                          'w-20'
                                        )}
                                        value={item.quantity}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="flex border-b border-black/20 py-3 justify-between text-sm">
                <p>Subtotal</p>
                <p className="text-right">{formatMoney(subtotal, currency)}</p>
              </div>
              <div className="flex border-b border-black/20 py-3 justify-between text-sm">
                <p>Shipping</p>
                <p className="text-right">
                  {formatMoney(shippingMethodLineItem?.amount ?? 0, currency)}
                </p>
              </div>
              <div className="flex border-b border-black/20 py-3 justify-between text-sm">
                <p>Tax</p>
                <p className="text-right">{formatMoney(totalTax, currency)}</p>
              </div>
              <div className="flex py-3 justify-between">
                <p>Total</p>
                <p className="text-right font-bold">
                  {formatMoney(total, currency)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-8 lg:px-20 flex justify-center lg:justify-start grow lg:w-1/2">
          <div className="w-full lg:max-w-lg py-4 lg:py-14 h-max">
            {!user.uuid && (
              <Accordion
                index={1}
                title="Account"
                active={activeAccordion === 1}
                editable={activeAccordion > 1}
                setActive={setActiveAccordion}
              >
                <LoginStep
                  choice={checkoutType}
                  setChoice={setCheckoutType}
                  onContinue={() => setActiveAccordion(2)}
                  setPassword={setPassword}
                />
              </Accordion>
            )}
            <Accordion
              index={user.uuid ? 1 : 2}
              title="Shipping details"
              active={activeAccordion === (user.uuid ? 1 : 2)}
              setActive={setActiveAccordion}
              editable={activeAccordion > (user.uuid ? 1 : 2)}
              disabled={
                checkoutType === undefined ||
                (checkoutType === 'login' && !user.uuid)
              }
            >
              <Formik
                initialValues={{
                  shippingAddress: initialValues.shippingAddress,
                }}
                onSubmit={async (values) => {
                  await updateQuote({
                    variables: {
                      input: {
                        uuid: quote.uuid,
                        shippingAddress: values.shippingAddress,
                      },
                    },
                  });

                  setActiveAccordion(user.uuid ? 2 : 3);
                }}
              >
                <Form className="mb-8">
                  <div className="mb-6">
                    <AddressFieldGroup
                      prefix="shippingAddress"
                      shrink
                      postcodeRequired
                    />
                  </div>
                  <ShippingContinueButton />
                </Form>
              </Formik>
            </Accordion>
            <Accordion
              index={user.uuid ? 2 : 3}
              title="Shipping method"
              active={activeAccordion === (user.uuid ? 2 : 3)}
              setActive={setActiveAccordion}
              editable={activeAccordion > (user.uuid ? 2 : 3)}
              disabled={
                checkoutType === undefined ||
                (checkoutType === 'login' && !user.uuid)
              }
            >
              <ul className="mb-5 flex flex-wrap -mx-1">
                <li className="px-1 w-full lg:w-1/2">
                  <RadioCard
                    checked
                    Icon={CiDeliveryTruck}
                    title={
                      shippingMethodLabels[
                        quote.shippingAddress?.country === 'GB'
                          ? ShippingMethodEnum.uk
                          : ShippingMethodEnum.international
                      ]
                    }
                    description={formatMoney(
                      shippingMethodLineItem?.amount ?? 0,
                      currency
                    )}
                    name="shippingMethod"
                    value="shippingMethod"
                  />
                </li>
              </ul>
              <Button
                className={elements.button.primary}
                hotjarEvent="CheckoutQuote__ContinueFromShipping"
                type="button"
                onClick={() => setActiveAccordion(user.uuid ? 3 : 4)}
              >
                Continue <FiArrowRight className="ml-2" size={24} />
              </Button>
            </Accordion>
            <Accordion
              index={user.uuid ? 3 : 4}
              title="Billing details"
              active={activeAccordion === (user.uuid ? 3 : 4)}
              setActive={setActiveAccordion}
              editable={activeAccordion > (user.uuid ? 3 : 4)}
              disabled={
                checkoutType === undefined ||
                (checkoutType === 'login' && !user.uuid)
              }
            >
              <Formik
                initialValues={{
                  billingAddress: initialValues.billingAddress,
                  purchaseOrderReference: quote.purchaseOrderReference,
                }}
                onSubmit={async (values) => {
                  const billingAddress = billingSameAsShipping
                    ? initialValues.shippingAddress
                    : values.billingAddress;

                  await updateQuote({
                    variables: {
                      input: {
                        uuid: quote.uuid,
                        billingAddress,
                        purchaseOrderReference: values.purchaseOrderReference,
                      },
                    },
                  });

                  setActiveAccordion(user.uuid ? 4 : 5);
                }}
              >
                <Form>
                  <div className="mb-6">
                    <Checkbox
                      label="Same as shipping details"
                      name="billingSameAsShipping"
                      id="billingSameAsShipping"
                      checked={billingSameAsShipping}
                      onChange={() =>
                        setBillingSameAsShipping(!billingSameAsShipping)
                      }
                      useFormik={false}
                    />
                    {!billingSameAsShipping && (
                      <div className="mt-4">
                        <AddressFieldGroup
                          prefix="billingAddress"
                          shrink
                          postcodeRequired
                        />
                      </div>
                    )}
                  </div>
                  <div className="mb-6">
                    <label className={elements.inputLabel}>
                      Upload a purchase order (optional)
                    </label>
                    <PoUploadArea
                      purchaseOrderFileUuid={purchaseOrderFileUuid ?? undefined}
                      quoteUuid={quote.uuid}
                      updateQuote={updateQuote}
                    />
                    {!purchaseOrderFileUuid && (
                      <UploadField
                        onCompleted={async (orderData) => {
                          await updateQuote({
                            variables: {
                              input: {
                                uuid: quote.uuid,
                                purchaseOrderFileUuid:
                                  orderData.createFile.uuid,
                              },
                            },
                          });
                        }}
                      />
                    )}
                  </div>
                  <div className="mb-6">
                    <Input
                      name="purchaseOrderReference"
                      className="max-w-sm"
                      label="PO reference (optional)"
                    />
                  </div>
                  <Button
                    type="submit"
                    className={elements.button.primary}
                    hotjarEvent="CheckoutQuote_ContinueFromBilling"
                  >
                    Continue <FiArrowRight className="ml-2" size={24} />
                  </Button>
                </Form>
              </Formik>
            </Accordion>
            <Accordion
              index={user.uuid ? 4 : 5}
              title="Payment details"
              active={activeAccordion === (user.uuid ? 4 : 5)}
              setActive={setActiveAccordion}
              editable={activeAccordion > (user.uuid ? 4 : 5)}
              disabled={
                checkoutType === undefined ||
                (checkoutType === 'login' && !user.uuid)
              }
            >
              <Suspense fallback={<PageLoader />}>
                <PaymentStep
                  password={password}
                  quoteUuid={quote.uuid}
                  customerUuid={user.customer?.uuid}
                />
              </Suspense>
            </Accordion>
          </div>
        </div>
        {quote.paymentProcessing ? (
          <PageLoader text="Waiting for confirmation from Stripe" />
        ) : (
          loading && <PageLoader />
        )}
      </div>
      <CheckoutFooter />
    </main>
  );
};

export default CheckoutQuote;
