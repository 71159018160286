import { useCallback, useEffect, useRef } from 'react';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import { elements } from '../utility/styles';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

interface Props {
  value: DateValueType;
  setValue: (value: DateValueType) => void;
  useRange?: boolean;
  inputName?: string;
  placeholder?: string;
}

const DatePicker = ({
  value,
  setValue,
  useRange,
  inputName,
  placeholder = 'Select Date Range',
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current
        .querySelector('.date-picker-container > div:last-child')
        ?.classList.add('top-10');
    }
  }, []);
  return (
    <div ref={ref}>
      <Datepicker
        value={value}
        onChange={setValue}
        placeholder={placeholder}
        primaryColor="red"
        inputClassName={classNames(elements.input, 'grow pl-3')}
        toggleClassName="hidden"
        containerClassName="flex relative w-full text-dark date-picker-container"
        displayFormat={'DD/MM/YYYY'}
        separator="-"
        showShortcuts
        showFooter
        readOnly
        useRange={useRange}
        asSingle={!useRange}
        inputName={inputName}
      />
    </div>
  );
};

interface FormikProps<T> {
  useRange?: boolean;
  inputName: T;
  placeholder?: string;
}

export function FormikDatePicker<T extends string>({
  useRange,
  inputName,
  placeholder = 'Select Date',
}: FormikProps<T>) {
  const { setFieldValue, values } =
    useFormikContext<Record<string, DateValueType>>();

  const value = values[inputName];
  const setValue = useCallback(
    async (newValue: DateValueType) => {
      await setFieldValue(inputName, newValue);
    },
    [setFieldValue, inputName]
  );

  return (
    <DatePicker
      value={value}
      setValue={setValue}
      useRange={useRange}
      inputName={inputName}
      placeholder={placeholder}
    />
  );
}

export default DatePicker;
