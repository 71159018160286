import { useBackgroundQuery } from '@apollo/client';
import { GqlIndexCustomers } from '@monorepo/graphql/resources';
import { useCallback, useMemo, useState } from 'react';
import DataLayout, { FilterOption, LinkType, TableColumn } from './DataLayout';
import { elements } from '../utility/styles';
import { FiPlus, FiUsers } from 'react-icons/fi';
import { CustomerFilterInput, IndexCustomersQuery } from '@monorepo/graphql';
import { Link } from '@tanstack/react-router';
import classNames from 'classnames';
import Input from './Input';

enum TableColumnEnum {
  uuid = 'uuid',
  displayName = 'displayName',
  company = 'company',
  postCode = 'postCode',
  email = 'email',
  accountingReference = 'accountingReference',
  actions = 'actions',
}

enum FilterOptionEnum {
  search = 'search',
}

const defaultFilters = {
  term: null,
};

const links: LinkType[] = [
  {
    to: '/admin/customers/add',
    label: `Add Customer`,
    Icon: FiPlus,
  },
];

type Customer = IndexCustomersQuery['indexCustomers']['items'][0];

const CustomerList = () => {
  const [filters, setFilters] =
    useState<Pick<CustomerFilterInput, 'term'>>(defaultFilters);

  const [page, setPage] = useState(1);
  const [query] = useBackgroundQuery(GqlIndexCustomers, {
    variables: {
      input: {
        ...filters,
        orderBy: null,
        pagination: {
          page,
          perPage: 20,
        },
      },
    },
  });

  const [term, setTerm] = useState<string | null>(null);
  const currentFilters = useMemo(
    () => ({
      term,
    }),
    [term]
  );

  const applyFilters = useCallback(() => {
    setFilters({
      term,
    });
  }, [term]);

  const resetFilters = useCallback(() => {
    setTerm(null);
  }, []);

  const filterOptions = useMemo<FilterOption[]>(
    () => [
      {
        key: FilterOptionEnum.search,
        label: 'Search',
        Component: ({ term: item }: { term?: string }) => (
          <Input
            type="text"
            placeholder="Search by Customer Name..."
            name="search"
            defaultValue={item}
            onInput={(e) => setTerm(e.currentTarget.value)}
            useFormik={false}
          />
        ),
      },
    ],
    []
  );

  const tableColumns = useMemo<Array<TableColumn<Customer>>>(
    () => [
      {
        key: TableColumnEnum.displayName,
        label: 'Name',
        handler: (item: Customer) =>
          [item.firstName, item.lastName].filter(Boolean).join(' ') ||
          item.email,
        style: { width: '20%' },
      },
      {
        key: TableColumnEnum.email,
        label: 'Email',
        handler: (item: Customer) => item.email,
        style: { width: '20%' },
      },
      {
        key: TableColumnEnum.company,
        label: 'Company',
        handler: (item: Customer) => item.billingAddress?.company,
        style: { width: '20%' },
      },
      {
        key: TableColumnEnum.postCode,
        label: 'Post Code',
        handler: (item: Customer) => item.billingAddress?.postCode,
        style: { width: '15%' },
      },
      {
        key: TableColumnEnum.accountingReference,
        label: 'Accounting Ref',
        handler: (item: Customer) => item.accountingReference,
        style: { width: '15%' },
      },
      {
        key: TableColumnEnum.actions,
        label: '',
        handler: (item: Customer) => (
          <Link
            className={classNames(
              elements.button.secondary,
              'text-xs inline-flex whitespace-nowrap'
            )}
            to={item.uuid}
          >
            View
          </Link>
        ),
        style: { width: '10%' },
      },
    ],
    []
  );

  const extractItems = useCallback(
    (data: IndexCustomersQuery) => data.indexCustomers.items,
    []
  );

  const extractPagination = useCallback(
    (data: IndexCustomersQuery) => data.indexCustomers.pagination,
    []
  );

  return (
    <DataLayout
      Icon={FiUsers}
      title="Customers"
      subtitle="Manage your customers below"
      setPage={setPage}
      tableColumns={tableColumns}
      filterOptions={filterOptions}
      onReset={resetFilters}
      onConfirm={applyFilters}
      currentFilters={currentFilters}
      baseFilters={defaultFilters}
      links={links}
      query={query}
      extractItems={extractItems}
      extractPagination={extractPagination}
    />
  );
};

export default CustomerList;
