export const styles = {
  colours: {
    'gray-50': '#efefef',
    gray: '#d7d8d8',
    'gray-300': '#d7d8d8',
    lightGray: '#F5F5F5',
    darkGray: '#999',
    offWhite: '#fafafa',
    midGray: '#686B77',
    input: '#000000bf',
    dark: '#222222',
    'red-100': '#ebb1c1',
    'red-400': '#d41124',
    red: '#d41124',
    'red-500': '#d41124',
    'red-600': '#a50d1c',
    'red-700': '#a50d1c',
    darkRed: '#a50d1c',
    green: '#3fce33',
    darkGreen: '#1F8916',
  },
  fonts: {
    helvetica: '"Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
    openSans: '"Open Sans", sans-serif',
  },
};

export const elements = {
  button: {
    primary:
      'transition-all duration-300 bg-red border-red font-bold uppercase text-white border px-4 rounded hover:bg-darkRed flex justify-center items-center h-11',
    positive:
      'transition-all h-11 duration-300 bg-green text-white px-5 py-2 rounded-md focus:ring-2 focus:ring-offset-2 uppercase font-bold hover:bg-darkGreen focus:ring-green block text-center',
    secondary:
      'transition-all duration-300 border-2 px-4 border-red rounded text-red uppercase font-bold h-11 flex items-center justify-center hover:bg-red/10',
    tertiary:
      'transition-all duration-300 border py-2 px-3 border-black/20 rounded bg-white text-dark uppercase font-bold flex items-center justify-center h-11 hover:bg-black/5',
    disabled:
      'transition-all duration-300 text-midGray px-4 rounded font-bold h-11 flex items-center justify-center bg-gray uppercase',
  },
  input:
    'border border-black/20 h-11 flex items-center rounded-md outline-none focus:border-dark focus:bg-dark/5 min-w-12 max-w-full',
  textarea:
    'bg-white p-3 border border-black/20 focus:bg-offWhite focus:border-darkGray transition-colors rounded-md outline-none text-sm font-normal text-input text-left overflow-hidden',
  inputLabel: 'text-sm font-semibold mb-2 block',
};
