import { StrictMode, Suspense } from 'react';
import * as ReactDOM from 'react-dom/client';
import { ProtectedApp } from './AppShell';
import { GqlProvider } from './utility/apollo';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import * as Sentry from '@sentry/react';
import env from './environment';
import { CatchBoundary, redirect } from '@tanstack/react-router';
import { toaster } from './utility/toast';
import { hotjar } from 'react-hotjar';
import PageLoader from './components/PageLoader';
import './styles.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

hotjar.initialize({
  id: 5200758,
  sv: 6,
  debug: env.sentry.environment === 'development',
});

Sentry.init({
  dsn: 'https://cdc4f3262cdb2a1c23c0220fbd932d70@o4507906053636096.ingest.de.sentry.io/4507906065104976',
  environment: env.sentry.environment,
  beforeBreadcrumb: excludeGraphQLFetch,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0
});

root.render(
  <StrictMode>
    <Suspense fallback={<PageLoader />}>
      <GqlProvider>
        <CatchBoundary
          getResetKey={() => 'reset'}
          onCatch={(error) => {
            toaster.error(
              {
                title: 'Error',
                text: error.message,
              },
              {
                autoClose: 5000,
              }
            );

            localStorage.clear();

            sessionStorage.clear();
            return redirect({
              to: '/login',
            });
          }}
        >
          <ProtectedApp />
        </CatchBoundary>
      </GqlProvider>
    </Suspense>
  </StrictMode>
);
