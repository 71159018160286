import LegacyOrdersList, {
  FilterOptionEnum,
  TableColumnEnum,
} from '../../../../../components/LegacyOrdersList';
import { useUser } from '../../../../../utility/authentication';
import { LegacyOrderStatusEnum } from '@monorepo/graphql';
import { createFileRoute } from '@tanstack/react-router';
import { useMemo } from 'react';

const hiddenColumns = [TableColumnEnum.customer];
const hiddenFilters = [FilterOptionEnum.customer];
const hiddenStatuses = [
  LegacyOrderStatusEnum.cancelled,
  LegacyOrderStatusEnum.failed,
  LegacyOrderStatusEnum.pendingPayment,
  LegacyOrderStatusEnum.completed,
  LegacyOrderStatusEnum.processing,
  LegacyOrderStatusEnum.refunded,
  LegacyOrderStatusEnum.onHold,
];

const LegacyQuotesComponent = () => {
  const { user } = useUser();
  const initialFilters = useMemo(
    () => ({
      customerId: user.customer?.legacyId ?? undefined,
    }),
    [user]
  );

  return (
    <LegacyOrdersList
      title="Legacy Quotes"
      hiddenColumns={hiddenColumns}
      hiddenFilters={hiddenFilters}
      hiddenStatuses={hiddenStatuses}
      initialFilters={initialFilters}
    />
  );
};

export const Route = createFileRoute(
  '/_authenticated/my-account/quotes/legacy/'
)({
  component: LegacyQuotesComponent,
});
