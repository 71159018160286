import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  InMemoryCache,
  MutationFunctionOptions,
  useMutation,
  useSuspenseQuery,
} from '@apollo/client';
import {
  GqlAssignedCouponToCart,
  GqlAbortCheckoutPayment,
  GqlClearCart,
  GqlCompleteCheckout,
  GqlConvertCartToQuote,
  GqlDeleteFile,
  GqlGetCart,
  GqlGetSignedUrl,
  GqlIndexStripePaymentMethods,
  GqlRemoveItemFromCart,
  GqlSelectShippingMethod,
  GqlUpdateCartBillingAddress,
  GqlUpdateCartItemQty,
  GqlUpdateCartShippingAddress,
  GqlUpdatePurchaseOrderDetails,
} from '@monorepo/graphql/resources';
import {
  FiArrowDown,
  FiArrowLeft,
  FiArrowRight,
  FiCreditCard,
  FiDownload,
  FiPaperclip,
  FiTrash,
} from 'react-icons/fi';
import { TbTrash } from 'react-icons/tb';
import {
  AddressInput,
  CartPageFragmentFragment,
  CompleteCheckoutInput,
  Exact,
  formatMoney,
  LineItemTypeEnum,
  PaymentMethodEnum,
  PurchaseOrderDetailsInput,
  RoleEnum,
  ShippingMethodEnum,
  shippingMethodLabels,
  UpdatePurchaseOrderDetailsMutation,
} from '@monorepo/graphql';
import { useNavigate } from '@tanstack/react-router';
import { Field, Form, Formik } from 'formik';
import PageLoader from './PageLoader';
import { useAuth, writeApiToken } from '../utility/authentication';
import UploadField from './UploadField';
import { toaster } from '../utility/toast';
import { loadStripe } from '@stripe/stripe-js';
import env from '../environment';
import { Elements, useElements } from '@stripe/react-stripe-js';
import { Suspense, useEffect, useMemo, useState } from 'react';
import AddressFieldGroup from './AddressFieldGroup';
import { elements } from '../utility/styles';
import classNames from 'classnames';
import Input from './Input';
import RadioCard from './RadioCard';
import { CiDeliveryTruck } from 'react-icons/ci';
import Checkbox from './Checkbox';
import Loader from './Loader';
import CheckoutFooter from './CheckoutFooter';
import { Tooltip } from 'react-tooltip';
import MetaList from './MetaList';
import Accordion from './Accordion';
import LoginStep from './LoginStep';
import ShippingContinueButton from './ShippingContinueButton';
import PaymentMethod from './PaymentMethod';
import { Button } from './Button';
import { cache } from '../utility/cache';

interface FormikType extends Partial<CompleteCheckoutInput> {
  billingAddress: AddressInput;
  shippingAddress: AddressInput;
}

const CouponField = ({ code }: { code?: string }) => {
  const [assignCoupon, { loading: couponLoading }] = useMutation(
    GqlAssignedCouponToCart,
    {
      onCompleted: (data) => {
        handleErrorsAndStoreToken(data.assignedCouponToCart);
      },
    }
  );

  const [showField, setShowField] = useState(!!code);

  return (
    <Formik
      initialValues={{
        code: code ?? '',
      }}
      validate={(values) => {
        const errors: Record<string, string> = {};
        if (!values.code) {
          errors.code = 'Please enter a coupon code';
        }
        return errors;
      }}
      onSubmit={async (values) => {
        await assignCoupon({
          variables: {
            coupon: values.code,
          },
        });
      }}
    >
      {couponLoading ? (
        <div className="w-full flex items-center justify-center py-4">
          <Loader />
        </div>
      ) : (
        <Form className="flex items-center justify-between mb-5">
          {showField && (
            <div className="grow flex">
              <Field
                type="text"
                name="code"
                className={classNames(
                  'grow h-11 bg-white p-2 border-l border-t border-b border-dark/50 focus:bg-offWhite focus:border-darkGray transition-colors rounded-l outline-none text-sm font-normal text-input text-left'
                )}
                autoFocus
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.currentTarget.value === '') {
                    setShowField(false);
                  }
                }}
              />
            </div>
          )}
          <div>
            <Button
              type={showField ? 'submit' : 'button'}
              hotjarEvent="Checkout_ApplyCoupon"
              className={classNames(elements.button.secondary)}
              onClick={() => {
                if (!showField) {
                  setShowField(true);
                }
              }}
            >
              {showField ? 'Apply' : 'Add'} Voucher
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const stripePromise = loadStripe(env.stripePublishableKey);

const PaymentStage = ({
  password,
  paymentIntentClientSecret,
  customerUuid,
}: {
  password?: string;
  paymentIntentClientSecret: string;
  customerUuid?: string;
}) => {
  const { data: stripePaymentMethods } = useSuspenseQuery(
    GqlIndexStripePaymentMethods,
    {
      skip: !customerUuid,
    }
  );

  const stripElements = useElements();
  const [stripeLoading, setStripeLoading] = useState(false);
  const navigate = useNavigate();
  const [abortCheckoutPayment] = useMutation(GqlAbortCheckoutPayment);
  const [completeCheckout, { loading }] = useMutation(GqlCompleteCheckout, {
    onCompleted: async (data) => {
      
      const paymentMethod = data.completeCheckout.order.paymentMethodId;
      if (paymentMethod !== PaymentMethodEnum.creditLimit) {
        setStripeLoading(true);

        await stripePromise.then(async (stripe) => {
          const returnUrl = `${window.location.protocol}//${window.location.host}/checkout/${data.completeCheckout.order.uuid}`;
          if (paymentMethod === PaymentMethodEnum.card || !paymentMethod) {
            if (stripElements) {
              await stripElements.submit().then(async (res) => {
                if (res.error) {
                  toaster.error(
                    {
                      title: 'Payment Error',
                      text: res.error.message,
                    },
                    {
                      autoClose: 5000,
                    }
                  );

                  writeApiToken(data.completeCheckout.authTokens.jwt);
                  
                  void abortCheckoutPayment({
                    variables: {
                      reason: res.error.message ?? null
                    }
                  });

                  setStripeLoading(false);
                } else {
                  const cRes = await stripe?.confirmPayment({
                    elements: stripElements,
                    clientSecret: paymentIntentClientSecret,
                    confirmParams: {
                      return_url: `${returnUrl}?token=${data.completeCheckout.authTokens.jwt}`,

                    },
                  });

                  writeApiToken(data.completeCheckout.authTokens.jwt);

                  if (cRes?.error) {
                    toaster.error(
                      {
                        title: 'Payment Error',
                        text: cRes.error.message,
                      },
                      {
                        autoClose: 5000,
                      }
                    );

                    void abortCheckoutPayment({
                      variables: {
                        reason: cRes.error.message ?? null
                      }
                    });
                  } else {
                    toaster.success(
                      {
                        title: 'Order Paid',
                        text: 'The order has been paid successfully.',
                      },
                      {
                        autoClose: 5000,
                      }
                    );

                    void navigate({
                      to: `/checkout/${data.completeCheckout.order.uuid}`,
                    });
                  }

                  setStripeLoading(false);
                }
              }).catch((err) => {
                writeApiToken(data.completeCheckout.authTokens.jwt);

                void abortCheckoutPayment({
                  variables: {
                    reason: (err as Error).message
                  }
                });

                setStripeLoading(false);
              });
            } else {
              writeApiToken(data.completeCheckout.authTokens.jwt);

              toaster.error(
                {
                  title: 'Payment Error',
                  text: 'Please select a payment method',
                },
                {
                  autoClose: 5000,
                }
              );

              setStripeLoading(false);
            }
          } else {
            const res = await stripe?.confirmCardPayment(
              paymentIntentClientSecret,
              {
                payment_method: paymentMethod,
                return_url: returnUrl,
              }
            );

            writeApiToken(data.completeCheckout.authTokens.jwt);

            if (res?.error) {
              toaster.error(
                {
                  title: 'Payment Error',
                  text: res.error.message,
                },
                {
                  autoClose: 5000,
                }
              );

              void abortCheckoutPayment({
                variables: {
                  reason: res.error.message ?? null
                }
              });

              setStripeLoading(false);
            } else if (res?.paymentIntent.status === 'succeeded') {
              toaster.success(
                {
                  title: 'Order Paid',
                  text: 'The order has been paid successfully.',
                },
                {
                  autoClose: 5000,
                }
              );

              void navigate({
                to: `/checkout/${data.completeCheckout.order.uuid}`,
              });
            } else {
              toaster.error(
                {
                  title: 'Payment Error',
                  text: 'Payment failed',
                },
                {
                  autoClose: 5000,
                }
              );

              void abortCheckoutPayment({
                variables: {
                  reason: 'Payment failed'
                }
              });

              setStripeLoading(false);
            }
          }
        }).catch((err) => {
          writeApiToken(data.completeCheckout.authTokens.jwt);

          void abortCheckoutPayment({
            variables: {
              reason: (err as Error).message
            }
          });

          setStripeLoading(false);
        });
      } else {
        writeApiToken(data.completeCheckout.authTokens.jwt);

        toaster.success(
          {
            title: 'Order Completed',
            text: 'The order has been completed successfully.',
          },
          {
            autoClose: 5000,
          }
        );

        void navigate({ to: `/checkout/${data.completeCheckout.order.uuid}` });
      }
    },
  });

  const [displayAllPaymentMethods, setDisplayAllPaymentMethods] =
    useState(false);

  return (
    <Formik
      initialValues={{
        paymentMethodId: PaymentMethodEnum.card,
      }}
      onSubmit={async (values) => {
        await completeCheckout({
          variables: {
            input: {
              paymentMethodId: values.paymentMethodId,
              password,
            },
          },
        });
      }}
    >
      <Form className="relative">
        <label className={elements.inputLabel}>Payment method</label>
        <div className="flex flex-wrap -mx-1 mb-8">
          <div className="px-1 w-full mb-5">
            <RadioCard
              Icon={FiPaperclip}
              name="paymentMethodId"
              value={PaymentMethodEnum.creditLimit}
              title="ABLE Account Holder"
              description="Existing account? Use credit"
              useFormik
              style="horizontal"
            />
          </div>
          {stripePaymentMethods &&
            stripePaymentMethods.indexStripePaymentMethods.length > 0 && (
              <>
                <div
                  className="px-1 w-full mb-5"
                  key={stripePaymentMethods.indexStripePaymentMethods[0].id}
                >
                  <RadioCard
                    Icon={FiCreditCard}
                    name="paymentMethodId"
                    value={stripePaymentMethods.indexStripePaymentMethods[0].id}
                    title={
                      <div className="flex items-center">
                        **** **** ****{' '}
                        {stripePaymentMethods.indexStripePaymentMethods[0].card
                          .last4 ??
                          stripePaymentMethods.indexStripePaymentMethods[0].id}
                        <span className="bg-black text-white py-1 px-2 rounded-md ml-2 text-xs font-semibold">
                          Default
                        </span>
                      </div>
                    }
                    description={
                      <>
                        Use card ending{' '}
                        {stripePaymentMethods.indexStripePaymentMethods[0].card
                          .last4 ??
                          stripePaymentMethods.indexStripePaymentMethods[0].id}
                      </>
                    }
                    useFormik
                    style="horizontal"
                  />
                </div>
                {stripePaymentMethods.indexStripePaymentMethods.length > 1 && (
                  <>
                    <div className="px-1 w-full mb-5 flex justify-center">
                      <Button
                        className="text-red underline text-sm flex items-center"
                        type="button"
                        hotjarEvent="Checkout_ViewMorePaymentMethods"
                        onClick={() => setDisplayAllPaymentMethods((d) => !d)}
                      >
                        View {displayAllPaymentMethods ? 'less' : 'more'} cards{' '}
                        <FiArrowDown
                          size={16}
                          className={classNames('ml-1 transition-all', {
                            'rotate-180': displayAllPaymentMethods,
                          })}
                        />
                      </Button>
                    </div>
                    {displayAllPaymentMethods && (
                      <>
                        {stripePaymentMethods.indexStripePaymentMethods
                          .slice(1)
                          .map((method) => (
                            <div className="px-1 w-full mb-5" key={method.id}>
                              <RadioCard
                                Icon={FiCreditCard}
                                name="paymentMethodId"
                                value={method.id}
                                title={`**** **** **** ${
                                  method.card.last4 ?? method.id
                                }`}
                                description={`Use card ending ${
                                  method.card.last4 ?? method.id
                                }`}
                                useFormik
                                style="horizontal"
                              />
                            </div>
                          ))}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          <div className="px-1 w-full">
            <RadioCard
              Icon={FiCreditCard}
              name="paymentMethodId"
              value={PaymentMethodEnum.card}
              title="Add New Card"
              description="Use a new card to pay"
              useFormik
              style="horizontal"
            />
          </div>
        </div>

        <PaymentMethod />
        {(loading || stripeLoading) && <PageLoader />}
      </Form>
    </Formik>
  );
};

const handleErrorsAndStoreToken = (
  cart: CartPageFragmentFragment,
  updateCart = true
) => {
  if (cart.errorNotices.length) {
    toaster.error(
      {
        title: 'Cart Error',
        text: cart.errorNotices.join(', '),
      },
      {
        autoClose: 5000,
      }
    );
  }
  if (cart.authTokens?.jwt) {
    writeApiToken(cart.authTokens.jwt);

    if (updateCart) {
      cache.writeQuery({
        query: GqlGetCart,
        data: {
          getCart: cart,
        },
      });
    }
  }
};

const PoUploadArea = ({
  purchaseOrderFileUuid,
  updatePurchaseOrderDetails,
}: {
  purchaseOrderFileUuid?: string;
  updatePurchaseOrderDetails: (
    options:
      | MutationFunctionOptions<
          UpdatePurchaseOrderDetailsMutation,
          Exact<{
            input: PurchaseOrderDetailsInput;
          }>,
          DefaultContext,
          ApolloCache<InMemoryCache>
        >
      | undefined
  ) => Promise<FetchResult<UpdatePurchaseOrderDetailsMutation>>;
}) => {
  const [deleteFile] = useMutation(GqlDeleteFile);

  const { data: signedUrlData } = useSuspenseQuery(GqlGetSignedUrl, {
    variables: {
      uuids: purchaseOrderFileUuid ? [purchaseOrderFileUuid] : [],
      query: null,
    },
    skip: !purchaseOrderFileUuid,
  });

  return (
    signedUrlData?.getSignedUrl.find(Boolean) &&
    !!purchaseOrderFileUuid && (
      <div className="flex items-center -mx-2 mb-5">
        <div className="px-2">
          <a
            href={signedUrlData.getSignedUrl[0]}
            target="_blank"
            rel="noreferrer"
            className={elements.button.secondary}
            data-tooltip-id={`download-${purchaseOrderFileUuid}`}
            data-tooltip-content={`Download Purchase Order - ${
              new URLSearchParams(signedUrlData.getSignedUrl[0].split('?')[1])
                .get('rscd')
                ?.split('filename=')[1]
            }`}
          >
            <FiDownload size={20} />
          </a>
          <Tooltip id={`download-${purchaseOrderFileUuid}`} />
        </div>
        <div className="px-2">
          <Button
            type="button"
            data-tooltip-id={`delete-${purchaseOrderFileUuid}`}
            data-tooltip-content={`Download Purchase Order - ${
              new URLSearchParams(signedUrlData.getSignedUrl[0].split('?')[1])
                .get('rscd')
                ?.split('filename=')[1]
            }`}
            className={classNames(
              elements.button.secondary,
              'h-10 flex items-center'
            )}
            onClick={async () => {
              await deleteFile({
                variables: { uuid: purchaseOrderFileUuid },
                onCompleted: async () => {
                  await updatePurchaseOrderDetails({
                    variables: {
                      input: {
                        purchaseOrderFileUuid: null,
                      },
                    },
                  });
                },
              });
            }}
          >
            <FiTrash size={20} />
          </Button>
          <Tooltip id={`delete-${purchaseOrderFileUuid}`} />
        </div>
      </div>
    )
  );
};

const CheckoutCart = () => {
  const { user } = useAuth();
  const { data, refetch } = useSuspenseQuery(GqlGetCart, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    handleErrorsAndStoreToken(data.getCart, false);
  }, [data]);

  const [, { loading: clearLoading }] = useMutation(GqlClearCart, {
    onCompleted: (cartData) => {
      handleErrorsAndStoreToken(cartData.clearCart);
    },
  });

  const [removeItem, { loading: removeLoading }] = useMutation(
    GqlRemoveItemFromCart,
    {
      onCompleted: (cartData) => {
        handleErrorsAndStoreToken(cartData.removeItemFromCart);
      },
    }
  );

  const [updateItemQty, { loading: qtyLoading }] = useMutation(
    GqlUpdateCartItemQty,
    {
      onCompleted: (cartData) => {
        handleErrorsAndStoreToken(cartData.updateCartItemQty);
      },
    }
  );

  const [selectShippingMethod, { loading: shippingLoading }] = useMutation(
    GqlSelectShippingMethod,
    {
      onCompleted: (cartData) => {
        handleErrorsAndStoreToken(cartData.selectShippingMethod);
      },
    }
  );

  const [updateCartShippingAddress, { loading: shippingAddressLoading }] =
    useMutation(GqlUpdateCartShippingAddress, {
      onCompleted: (cartData) => {
        handleErrorsAndStoreToken(cartData.updateCartShippingAddress);
      },
    });

  const [updateCartBillingAddress, { loading: billingAddressLoading }] =
    useMutation(GqlUpdateCartBillingAddress);

  const navigate = useNavigate();
  const [convertToQuote, { loading: convertLoading }] = useMutation(
    GqlConvertCartToQuote,
    {
      onCompleted: (cartData) => {
        void navigate({
          to: `/admin/quotes/${cartData.convertCartToQuote.uuid}`,
        });

        const title = user?.role === RoleEnum.admin ? 'Quote created' : 'Quote requested';
        const text = user?.role === RoleEnum.admin ? 'The quote has been created and is ready to edit.' : 'Your quote has been requested. We will be in touch shortly.';

        toaster.success(
          {
            title,
            text
          },
          {
            autoClose: 5000,
          }
        );
      },
    }
  );

  const [updatePurchaseOrderDetails, { loading: poLoading }] = useMutation(
    GqlUpdatePurchaseOrderDetails,
    {
      onCompleted: (cartData) => {
        handleErrorsAndStoreToken(cartData.updatePurchaseOrderDetails);
      },
    }
  );

  const purchaseOrderFileUuid = data.getCart.data.purchaseOrderFileUuid;

  const loading =
    clearLoading ||
    removeLoading ||
    qtyLoading ||
    shippingLoading ||
    shippingAddressLoading ||
    billingAddressLoading ||
    convertLoading ||
    poLoading;

  useEffect(() => {
    let timer: NodeJS.Timer | null;
    const maybeRefetch = () => {
      if (timer) {
        clearTimeout(timer);
      }
      if (data.getCart.data.paymentProcessing) {
        void refetch();

        timer = setTimeout(maybeRefetch, 5000);
      }
    };

    maybeRefetch();
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [data.getCart.data.paymentProcessing, refetch]);

  const shippingMethod = data.getCart.shippingMethods.find(
    (m) => m.type === data.getCart.data.shippingMethod
  );

  const subtotal = data.getCart.data.lineItems.filter(l => l.type !== LineItemTypeEnum.shipping).reduce(
    (acc, item) => acc + item.quantity * item.amount,
    0
  );

  const discountLineItem = data.getCart.data.lineItems.find(
    (l) => l.type === LineItemTypeEnum.discount
  );

  const shippingLineItem = data.getCart.data.lineItems.find(
    (l) => l.type === LineItemTypeEnum.shipping
  );

  const shippingSubtotal = shippingLineItem?.amount ?? 0;

  const totalTax =
    data.getCart.data.lineItems.reduce(
      (acc, item) => acc + item.quantity * item.amount * item.taxRate,
      0
    );

  const total = subtotal + totalTax + shippingSubtotal;
  const currency = data.getCart.data.currency;
  const [password, setPassword] = useState<string | undefined>();
  const initialValues = useMemo<FormikType>(
    () => ({
      billingAddress: {
        firstName: data.getCart.data.billingAddress.firstName ?? '',
        lastName: data.getCart.data.billingAddress.lastName ?? '',
        email: data.getCart.data.billingAddress.email,
        company: data.getCart.data.billingAddress.company ?? '',
        phone: data.getCart.data.billingAddress.phone ?? '',
        line1: data.getCart.data.billingAddress.line1,
        line2: data.getCart.data.billingAddress.line2 ?? '',
        city: data.getCart.data.billingAddress.city,
        state: data.getCart.data.billingAddress.state ?? '',
        postCode: data.getCart.data.billingAddress.postCode ?? '',
        country: data.getCart.data.billingAddress.country,
      },
      shippingAddress: {
        firstName: data.getCart.data.shippingAddress.firstName ?? '',
        lastName: data.getCart.data.shippingAddress.lastName ?? '',
        email: data.getCart.data.shippingAddress.email,
        company: data.getCart.data.shippingAddress.company ?? '',
        phone: data.getCart.data.shippingAddress.phone ?? '',
        line1: data.getCart.data.shippingAddress.line1,
        line2: data.getCart.data.shippingAddress.line2 ?? '',
        city: data.getCart.data.shippingAddress.city,
        state: data.getCart.data.shippingAddress.state ?? '',
        postCode: data.getCart.data.shippingAddress.postCode ?? '',
        country: data.getCart.data.shippingAddress.country,
      },
    }),
    [data]
  );

  const customerUuid = data.getCart.data.customerUuid;
  const canChooseShippingMethod =
    data.getCart.data.shippingAddress.postCode &&
    data.getCart.data.shippingAddress.country &&
    (data.getCart.data.shippingAddress.state ??
      data.getCart.data.shippingAddress.city);

  const hasItems = !!data.getCart.data.lineItems.length;
  const [activeAccordion, setActiveAccordion] = useState<number>(1);
  const [checkoutType, setCheckoutType] = useState<
    'login' | 'guest' | undefined
  >(user?.uuid ? 'login' : undefined);

  useEffect(() => {
    if (user?.uuid) {
      setCheckoutType('login');
    }
  }, [user?.uuid]);
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(
    JSON.stringify(initialValues.billingAddress) ===
      JSON.stringify(initialValues.shippingAddress)
  );

  const paymentIntentClientSecret = data.getCart.data.paymentIntentClientSecret;

  return (
    <main className="h-dvh overflow-y-auto flex flex-col justify-between">
      <div className="flex flex-col lg:flex-row grow">
        <div
          className={classNames(
            'px-8 lg:px-20 bg-lightGray text-dark flex justify-center grow lg:w-1/2',
            {
              'lg:justify-end': hasItems,
            }
          )}
        >
          <div
            className={classNames(
              'w-full lg:max-w-lg flex flex-col justify-between py-10 lg:py-16',
              {
                'h-max': hasItems,
                'h-dvh': !hasItems,
              }
            )}
          >
            <div
              className={classNames({
                'flex flex-col items-center justify-center flex-grow':
                  !hasItems,
              })}
            >
              <a href={env.wpUrl} className="flex mb-8 items-center">
                {hasItems && <FiArrowLeft className="mr-4" size={28} />}
                <img src="/v2-assets/logo-old.svg" alt="Logo" width="120" />
              </a>
              {hasItems ? (
                <div className="mb-5 flex items-center justify-between text-xl">
                  <h4 className="font-bold mb-2">Summary</h4>
                  <div className="flex items-center">
                    <h2 className="font-normal mr-2">Total:</h2>
                    <h2 className="text-darkGreen font-bold">
                      {formatMoney(total, currency)}
                    </h2>
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <h4 className="text-xl font-normal mb-8">
                    There are no items in your cart
                  </h4>
                  <div>
                    <a
                      href={env.wpUrl}
                      className={classNames(
                        elements.button.secondary,
                        'text-red block'
                      )}
                    >
                      Continue Shopping
                    </a>
                  </div>
                </div>
              )}
              {hasItems && (
                <div className="mb-6 border border-black/20 rounded">
                  <table className="w-full">
                    <tbody>
                      {data.getCart.data.lineItems
                        .filter((l) => l.type === LineItemTypeEnum.product)
                        .map((item) => (
                          <tr
                            className="border-b border-black/20 last:border-0"
                            key={item.sku}
                          >
                            <td className="p-4 text-left">
                              <div className="flex justify-between mb-5 last:mb-0 -mx-2">
                                <div className="px-2">
                                  <div className="flex items-center">
                                    {!!item.imageUrl && (
                                      <div className="w-10 h-10 mr-4 relative">
                                        <img
                                          src={item.imageUrl}
                                          alt={item.title}
                                          className="w-full h-full absolute object-cover"
                                        />
                                      </div>
                                    )}
                                    <h4 className="font-semibold">
                                      {item.title}
                                    </h4>
                                  </div>
                                  {item.meta.length > 0 && (
                                    <div className="mt-4">
                                      <MetaList meta={item.meta} />
                                    </div>
                                  )}
                                </div>
                                <div className="px-2">
                                  <h5 className="text-darkGreen font-semibold flex flex-col text-right mb-5">
                                    {item.originalAmount > item.amount && (
                                      <span className="line-through text-dark text-sm font-normal">
                                        {formatMoney(
                                          item.originalAmount,
                                          currency
                                        )}
                                      </span>
                                    )}
                                    {formatMoney(item.amount, currency)}
                                  </h5>
                                  <div className="flex">
                                    <input
                                      type="number"
                                      className={classNames(
                                        elements.input,
                                        'pl-3',
                                        'w-20'
                                      )}
                                      value={item.quantity}
                                      onChange={async (e) => {
                                        await updateItemQty({
                                          variables: {
                                            itemUuid: item.uuid,
                                            qty: parseInt(e.target.value),
                                          },
                                        });
                                      }}
                                    />
                                    <Button
                                      type="button"
                                      hotjarEvent="Checkout_RemoveItem"
                                      className="border border-black/20 h-11 flex items-center rounded-md ml-2 w-11 justify-center text-red bg-white"
                                      onClick={async () => {
                                        await removeItem({
                                          variables: { itemUuid: item.uuid },
                                        });
                                      }}
                                    >
                                      <TbTrash size={22} />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {hasItems && (
                <>
                  {!discountLineItem ? (
                    <CouponField code={data.getCart.data.coupon?.code} />
                  ) : (
                    <div className="flex border-b border-black/20 py-3 justify-between text-sm">
                      <div className="flex items">
                        <p>{discountLineItem.title}</p>
                        {
                          <Button
                            type="button"
                            hotjarEvent="Checkout_RemoveDiscount"
                            className="ml-2 text-red"
                            onClick={async () => {
                              await removeItem({
                                variables: {
                                  itemUuid: discountLineItem.uuid,
                                },
                              });
                            }}
                          >
                            <TbTrash size={16} />
                          </Button>
                        }
                      </div>
                      <p className="text-right">
                        {formatMoney(discountLineItem.amount, currency)}
                      </p>
                    </div>
                  )}
                  <div className="flex border-b border-black/20 py-3 justify-between text-sm">
                    <p>Subtotal</p>
                    <p className="text-right">
                      {formatMoney(subtotal, currency)}
                    </p>
                  </div>
                  <div className="flex border-b border-black/20 py-3 justify-between text-sm">
                    <p>Shipping</p>
                    <p className="text-right">
                      {shippingMethod
                        ? formatMoney(shippingMethod.amount, currency)
                        : canChooseShippingMethod
                        ? 'Please choose a shipping method'
                        : 'Enter address to calculate'}
                    </p>
                  </div>
                  <div className="flex border-b border-black/20 py-3 justify-between text-sm">
                    <p>Tax</p>
                    <p className="text-right">
                      {formatMoney(totalTax, currency)}
                    </p>
                  </div>
                  <div className="flex py-3 justify-between">
                    <p>Total</p>
                    <p className="text-right font-bold">
                      {formatMoney(total, currency)}
                    </p>
                  </div>
                </>
              )}
              {hasItems && user?.uuid && customerUuid && (
                <div className="mt-8">
                  <p className="text-sm mb-2">
                    Prefer to get a quote from us for these items?{` `}
                    <Button
                      type="button"
                      hotjarEvent="Checkout_RequestQuote"
                      className="text-red underline"
                      onClick={async () => {
                        await convertToQuote({
                          variables: {
                            customerUuid,
                          },
                        });
                      }}
                    >
                      Request a Quote
                    </Button>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {hasItems && (
          <div className="px-8 lg:px-20 flex justify-center lg:justify-start grow lg:w-1/2">
            <div className="w-full lg:max-w-lg py-4 lg:py-14 h-max">
              {!user?.uuid && (
                <Accordion
                  index={1}
                  title="Account"
                  active={activeAccordion === 1}
                  editable={activeAccordion > 1}
                  setActive={setActiveAccordion}
                >
                  <LoginStep
                    choice={checkoutType}
                    setChoice={setCheckoutType}
                    onLogin={refetch}
                    onContinue={() => setActiveAccordion(2)}
                    setPassword={setPassword}
                  />
                </Accordion>
              )}
              <Accordion
                index={user?.uuid ? 1 : 2}
                title="Shipping details"
                active={activeAccordion === (user?.uuid ? 1 : 2)}
                setActive={setActiveAccordion}
                editable={activeAccordion > (user?.uuid ? 1 : 2)}
                disabled={
                  checkoutType === undefined ||
                  (checkoutType === 'login' && !user?.uuid)
                }
              >
                <Formik
                  initialValues={{
                    shippingAddress: initialValues.shippingAddress,
                  }}
                  onSubmit={async (values) => {
                    if (billingSameAsShipping) {
                      await updateCartBillingAddress({
                        variables: {
                          input: values.shippingAddress,
                        },
                      });
                    }
                    await updateCartShippingAddress({
                      variables: {
                        input: values.shippingAddress,
                      },
                    });

                    setActiveAccordion(user?.uuid ? 2 : 3);
                  }}
                >
                  <Form className="mb-8">
                    <div className="mb-6">
                      <AddressFieldGroup
                        prefix="shippingAddress"
                        shrink
                        postcodeRequired
                      />
                    </div>
                    <ShippingContinueButton />
                  </Form>
                </Formik>
              </Accordion>
              <Accordion
                index={user?.uuid ? 2 : 3}
                title="Shipping method"
                active={activeAccordion === (user?.uuid ? 2 : 3)}
                setActive={setActiveAccordion}
                editable={activeAccordion > (user?.uuid ? 2 : 3)}
                disabled={
                  checkoutType === undefined ||
                  (checkoutType === 'login' && !user?.uuid)
                }
              >
                <Formik
                  initialValues={
                    {
                      shippingMethod: shippingMethod?.type,
                    } as {
                      shippingMethod?: ShippingMethodEnum;
                    }
                  }
                  validate={(values) => {
                    const errors: Record<string, string> = {};
                    if (!values.shippingMethod) {
                      errors.shippingMethod = 'Please select a shipping method';
                    }
                    return errors;
                  }}
                  onSubmit={async (values) => {
                    if (values.shippingMethod) {
                      await selectShippingMethod({
                        variables: { method: values.shippingMethod },
                      });

                      setActiveAccordion(user?.uuid ? 3 : 4);
                    }
                  }}
                >
                  <Form>
                    <div className="mb-8">
                      {(data.getCart.shippingMethods.length || 0) > 0 ? (
                        <ul className="mb-5 flex flex-wrap -mx-1">
                          {data.getCart.shippingMethods.map((method) => (
                            <li
                              className="px-1 w-full lg:w-1/2"
                              key={method.type}
                            >
                              <RadioCard
                                checked={method.type === shippingMethod?.type}
                                Icon={CiDeliveryTruck}
                                title={shippingMethodLabels[method.type]}
                                description={formatMoney(
                                  method.amount,
                                  currency
                                )}
                                name="shippingMethod"
                                value={method.type}
                                useFormik
                              />
                            </li>
                          ))}
                        </ul>
                      ) : canChooseShippingMethod ? (
                        <p>No shipping methods available</p>
                      ) : (
                        <p>Enter address to calculate</p>
                      )}
                    </div>
                    <Button
                      type="submit"
                      className={elements.button.primary}
                      hotjarEvent="Checkout_ContinueFromShippingMethod"
                    >
                      Continue <FiArrowRight className="ml-2" size={24} />
                    </Button>
                  </Form>
                </Formik>
              </Accordion>
              <Accordion
                index={user?.uuid ? 3 : 4}
                title="Billing details"
                active={activeAccordion === (user?.uuid ? 3 : 4)}
                setActive={setActiveAccordion}
                editable={activeAccordion > (user?.uuid ? 3 : 4)}
                disabled={
                  checkoutType === undefined ||
                  (checkoutType === 'login' && !user?.uuid)
                }
              >
                <Formik
                  initialValues={{
                    billingAddress: initialValues.billingAddress,
                    purchaseOrderReference:
                      data.getCart.data.purchaseOrderReference,
                  }}
                  onSubmit={async (values) => {
                    const billingAddress = billingSameAsShipping
                      ? initialValues.shippingAddress
                      : values.billingAddress;

                    await updateCartBillingAddress({
                      variables: {
                        input: billingAddress,
                      },
                    });

                    await updatePurchaseOrderDetails({
                      variables: {
                        input: {
                          purchaseOrderReference: values.purchaseOrderReference,
                        },
                      },
                    });

                    setActiveAccordion(user?.uuid ? 4 : 5);
                  }}
                >
                  <Form>
                    <div className="mb-6">
                      <Checkbox
                        label="Same as shipping details"
                        name="billingSameAsShipping"
                        id="billingSameAsShipping"
                        checked={billingSameAsShipping}
                        onChange={() =>
                          setBillingSameAsShipping(!billingSameAsShipping)
                        }
                        useFormik={false}
                      />
                      {!billingSameAsShipping && (
                        <div className="mt-4">
                          <AddressFieldGroup
                            prefix="billingAddress"
                            shrink
                            postcodeRequired
                          />
                        </div>
                      )}
                    </div>
                    <div className="mb-6">
                      <label className={elements.inputLabel}>
                        Upload a purchase order (optional)
                      </label>
                      <PoUploadArea
                        purchaseOrderFileUuid={
                          purchaseOrderFileUuid ?? undefined
                        }
                        updatePurchaseOrderDetails={updatePurchaseOrderDetails}
                      />
                      {!purchaseOrderFileUuid && (
                        <UploadField
                          isCart
                          onCompleted={async (orderData) => {
                            await updatePurchaseOrderDetails({
                              variables: {
                                input: {
                                  purchaseOrderFileUuid:
                                    orderData.createFile.uuid,
                                },
                              },
                            });
                          }}
                        />
                      )}
                    </div>
                    <div className="mb-6">
                      <Input
                        name="purchaseOrderReference"
                        className="max-w-sm"
                        label="PO reference (optional)"
                      />
                    </div>
                    <Button
                      type="submit"
                      className={elements.button.primary}
                      hotjarEvent="Checkout_ContinueFromBillig"
                    >
                      Continue <FiArrowRight className="ml-2" size={24} />
                    </Button>
                  </Form>
                </Formik>
              </Accordion>
              <Accordion
                index={user?.uuid ? 4 : 5}
                title="Payment details"
                active={activeAccordion === (user?.uuid ? 4 : 5)}
                setActive={setActiveAccordion}
                editable={activeAccordion > (user?.uuid ? 4 : 5)}
                disabled={
                  checkoutType === undefined ||
                  (checkoutType === 'login' && !user?.uuid)
                }
              >
                {!!paymentIntentClientSecret && (
                  <Elements
                    stripe={stripePromise}
                    options={{
                      clientSecret: paymentIntentClientSecret,
                      fonts: [
                        {
                          cssSrc:
                            'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap',
                        },
                      ],
                      appearance: {
                        theme: 'flat',
                        variables: {
                          fontFamily: 'Open Sans',
                          borderRadius: '4px',
                          colorBackground: '#fff',
                        },
                        rules: {
                          '.Input': {
                            borderColor: '#cccccc',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                          },
                          '.Label': {
                            fontSize: '0.875rem',
                            lineHeight: '1.25rem',
                            fontWeight: '600',
                            marginBottom: '8px',
                          },
                        },
                      },
                    }}
                  >
                    <Suspense fallback={<PageLoader />}>
                      <PaymentStage
                        password={password}
                        paymentIntentClientSecret={paymentIntentClientSecret}
                        customerUuid={user?.customer?.uuid}
                      />
                    </Suspense>
                  </Elements>
                )}
              </Accordion>
            </div>
          </div>
        )}
        {data.getCart.data.paymentProcessing ? (
          <PageLoader text="Waiting for confirmation from Stripe" />
        ) : (
          loading && <PageLoader />
        )}
      </div>
      {!!hasItems && <CheckoutFooter />}
    </main>
  );
};

export default CheckoutCart;
