import { LineItemTypeEnum, QuoteFragmentFragment, QuoteStatusEnum } from '@monorepo/graphql';
import env from '../environment';
import { FiArrowLeft } from 'react-icons/fi';
import CheckoutFooter from './CheckoutFooter';
import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GqlReadQuote } from '@monorepo/graphql/resources';
import ViewQuote from './ViewQuote';
import { Link } from '@tanstack/react-router';
import { useUser } from '../utility/authentication';
import { ReactTagManager } from 'react-gtm-ts';

interface Props {
  quote: QuoteFragmentFragment;
}

const CheckoutViewQuote = ({ quote }: Props) => {
  const [status, setStatus] = useState(quote.status);

  const transformedQuote = useMemo(
    () => ({
      ...quote,
      status,
    }),
    [status, quote]
  );

  const [fetchQuote] = useLazyQuery(GqlReadQuote, {
    variables: {
      uuid: quote.uuid,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (status !== QuoteStatusEnum.pending) return;
    const interval = setInterval(async () => {
      await fetchQuote({
        onCompleted: (data) => {
          setStatus(data.readQuote.status);
          if (data.readQuote.status !== QuoteStatusEnum.pending) {
            clearInterval(interval);
          }
        },
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [status, fetchQuote]);

  const { user } = useUser();

  useEffect(() => {
    ReactTagManager.action({
      event: 'purchase',
      ecommerce: {
          transaction_id: quote.accountingReference,
          affiliation: '247Able Store',
          value: quote.total,
          currency: quote.currency,
          tax: quote.lineItems
            .filter((l) => l.taxRate > 0)
            .reduce(
              (acc, item) =>
                acc +
                Math.max(item.quantity, 1) * item.amount * item.taxRate,
              0
            ),
          shipping: quote.lineItems
            .filter((l) => l.type === LineItemTypeEnum.shipping)
            .map((l) => l.amount)
            .reduce((acc, item) => acc + item, 0),
          items: quote.lineItems.filter((l) => l.type === LineItemTypeEnum.product).map((l) => l.products.map((p, i) => ({
              item_name: p.title,
              item_id: p.sku,
              price: !i ? l.amount : 0,
              quantity: l.quantity
          }))),
      }
    });
  }, [quote]);

  return (
    <div className="h-dvh overflow-y-auto flex flex-col">
      <div className="bg-lightGray p-8 lg:px-20 lg:py-10 flex justify-center grow">
        <div className="lg:max-w-[74rem] w-full">
          {user.uuid ? (
            <Link
              to={`/my-account/quotes/${quote.uuid}`}
              className="flex mb-8 items-center"
            >
              <FiArrowLeft className="mr-4" size={28} />
              <img src="/v2-assets/logo-old.svg" alt="Logo" width="120" />
            </Link>
          ) : (
            <a href={env.wpUrl} className="flex mb-8 items-center">
              <FiArrowLeft className="mr-4" size={28} />
              <img src="/v2-assets/logo-old.svg" alt="Logo" width="120" />
            </a>
          )}
          <ViewQuote quote={transformedQuote} />
        </div>
      </div>
      <CheckoutFooter />
    </div>
  );
};

export default CheckoutViewQuote;
