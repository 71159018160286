import { gql } from '@apollo/client';

export const GqlAddress = gql`
  fragment AddressFragment on Address {
    line1
    line2
    email
    firstName
    lastName
    company
    state
    city
    postCode
    country
    phone
    uuid
  }
`;

export const GqlCustomer = gql`
  fragment CustomerFragment on Customer {
    uuid
    legacyId
    firstName
    lastName
    displayName
    email
    billingAddress {
      ...AddressFragment
    }
    shippingAddress {
      ...AddressFragment
    }
    accountingReference
  }
  ${GqlAddress}
`;
