import { useSuspenseQuery } from '@apollo/client';
import CheckoutQuote from '../../../../components/CheckoutQuote';
import CheckoutViewQuote from '../../../../components/CheckoutViewQuote';
import { doQuery } from '../../../../utility/apollo';
import { GqlReadQuote } from '@monorepo/graphql/resources';
import { createFileRoute, notFound } from '@tanstack/react-router';
import { PaymentMethodEnum, QuoteStatusEnum } from '@monorepo/graphql';
import { useEffect } from 'react';
import { toaster } from '../../../../utility/toast';

const CheckoutComponent = () => {
  const { quote: immutableQuote } = Route.useLoaderData();

  const { data: quoteData } = useSuspenseQuery(GqlReadQuote, {
    variables: {
      uuid: immutableQuote.uuid,
    },
  });

  const quote = quoteData.readQuote;

  useEffect(() => {
    if (
      immutableQuote.paymentProcessing &&
      immutableQuote.status === QuoteStatusEnum.pending &&
      !quoteData.readQuote.paymentProcessing
    ) {
      if (quoteData.readQuote.status === QuoteStatusEnum.processing) {
        if (
          quoteData.readQuote.paymentMethodId !== PaymentMethodEnum.creditLimit
        ) {
          toaster.success(
            {
              title: 'Quote Paid',
              text: 'The quote has been paid successfully.',
            },
            {
              autoClose: 5000,
            }
          );
        } else {
          toaster.success(
            {
              title: 'Quote Completed',
              text: 'The quote has been completed successfully.',
            },
            {
              autoClose: 5000,
            }
          );
        }
      } else {
        toaster.error(
          {
            title: 'Payment Failed',
            text: 'There was an issue processing your payment. Please try again.',
          },
          {
            autoClose: 5000,
          }
        );
      }
    }
  }, [
    immutableQuote.status,
    quoteData.readQuote.status,
    quoteData.readQuote.paymentMethodId,
    immutableQuote.paymentProcessing,
    quoteData.readQuote.paymentProcessing,
  ]);

  return quote.status === QuoteStatusEnum.pending ? (
    <CheckoutQuote quote={quote} />
  ) : (
    <CheckoutViewQuote quote={quote} />
  );
};

export const Route = createFileRoute('/_neutral/checkout/quote/$quoteUuid')({
  loader: async ({ params }) => {
    try {
      const { data } = await doQuery({
        query: GqlReadQuote,
        variables: {
          uuid: params.quoteUuid,
        },
      });

      if (
        data.readQuote.status === QuoteStatusEnum.cancelled ||
        data.readQuote.status === QuoteStatusEnum.expired
      ) {
        throw new Error('Quote is no longer valid');
      }

      return { quote: data.readQuote };
    } catch (error) {
      throw notFound();
    }
  },
  component: CheckoutComponent,
});
