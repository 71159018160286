import {
  AddressInput,
  LineItemTypeEnum,
  PaymentMethodEnum,
  QuoteFragmentFragment,
  QuoteStatusEnum,
  quoteStatuses,
  RecipientGroup,
  User,
  formatMoney,
} from '@monorepo/graphql';
import PageHeader from './PageHeader';
import { FiBriefcase, FiDownload, FiShoppingCart } from 'react-icons/fi';
import AddressFieldGroup, { useCountriesAndCities } from './AddressFieldGroup';
import { elements } from '../utility/styles';
import classNames from 'classnames';
import { getApiToken } from '../utility/authentication';
import env from '../environment';
import { Form, Formik, useFormikContext } from 'formik';
import { useMutation } from '@apollo/client';
import { GqlEditQuote } from '@monorepo/graphql/resources';
import { toaster } from '../utility/toast';
import PageLoader from './PageLoader';
import SubmitButton from './SubmitButton';
import { Suspense, useState } from 'react';
import Input from './Input';
import { omitEmpty } from '../utility/helpers';
import Checkbox from './Checkbox';
import { Tooltip } from 'react-tooltip';
import { Link } from '@tanstack/react-router';
import PurchaseOrderUploadField from './PurchaseOrderUploadField';

interface Props {
  quote: QuoteFragmentFragment;
  paymentMethodId?: string;
}

type FormikType = {
  billingAddress?: AddressInput & {
    uuid: string | null;
    phoneCode?: string;
  };
  shippingAddress?: AddressInput & {
    uuid: string | null;
    phoneCode?: string;
  };
  paymentMethodLabel: string;
  paymentMethodId: string;
  password: string | null;
  notes: QuoteFragmentFragment['notes'];
  status: QuoteStatusEnum;
  purchaseOrderReference: string;
  purchaseOrderFileUuid: string | null;
};

const getInitialValues = (
  quote: QuoteFragmentFragment,
  paymentMethodId?: string
): FormikType => ({
  status: quote.status,
  paymentMethodId:
    paymentMethodId ?? quote.paymentMethodId ?? PaymentMethodEnum.creditLimit,
  paymentMethodLabel: quote.paymentMethodLabel ?? 'ABLE Account Holder',
  purchaseOrderReference: quote.purchaseOrderReference ?? '',
  purchaseOrderFileUuid: quote.purchaseOrderFileUuid,
  billingAddress: quote.billingAddress ?? {
    uuid: null,
    firstName: quote.customer?.firstName ?? '',
    lastName: quote.customer?.lastName ?? '',
    email: quote.customer?.email ?? '',
    country: 'GB',
    city: '',
    postCode: '',
    line1: '',
  },
  shippingAddress: quote.shippingAddress ?? {
    uuid: null,
    firstName: quote.customer?.firstName ?? '',
    lastName: quote.customer?.lastName ?? '',
    email: quote.customer?.email ?? '',
    country: 'GB',
    city: '',
    postCode: '',
    line1: '',
  },
  notes: quote.notes,
  password: null,
});

const Info = ({
  inTouchReference,
  creator,
  company,
}: {
  inTouchReference?: string;
  creator?: Pick<User, 'displayName' | 'email'>;
  company?: string;
}) => {
  const { values } = useFormikContext<ReturnType<typeof getInitialValues>>();
  const paymentMethodLabel = values.paymentMethodLabel;

  return (
    <>
      {(!!inTouchReference || !!paymentMethodLabel || !!creator) && (
        <ul className="mb-5 last:mb-0 text-sm">
          {!!inTouchReference && (
            <li className="mb-2 last:mb-0">
              <strong className="block lg:inline">InTouch Reference:</strong>{' '}
              {inTouchReference}
            </li>
          )}
          {!!paymentMethodLabel && (
            <li className="mb-2 last:mb-0">
              <strong className="block lg:inline">Payment Method:</strong>{' '}
              {paymentMethodLabel}
            </li>
          )}
          {!!creator && (
            <li>
              <strong className="block lg:inline">ABLE Contact:</strong>{' '}
              <a
                href={`mailto:${creator.email}`}
                data-tooltip-id="creator"
                data-tooltip-content={`Send an email to ${creator.displayName}`}
                data-tooltip-place="bottom"
                target="_blank"
                className="text-red"
              >
                {creator.displayName}
              </a>{' '}
              on behalf of {company}
              <Tooltip id="creator" />
            </li>
          )}
        </ul>
      )}
    </>
  );
};

const Notes = () => {
  const { values } = useFormikContext<ReturnType<typeof getInitialValues>>();

  return (
    <>
      {values.notes.length > 0 && (
        <div className="bg-white rounded-md">
          <h3 className="text-lg font-semibold text-black px-5 py-3 border-b border-black/20">
            Notes
          </h3>
          <div className="p-5">
            <ul className="text-sm list-disc pl-5">
              {values.notes.map((note, index) => (
                <li key={note.uuid} className="mb-2 last:mb-0">
                  {note.text} -{' '}
                  <strong>
                    {new Date(note.createdAt).toNiceDateTimeFormat()}
                  </strong>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

const ViewQuote = ({ quote, paymentMethodId }: Props) => {
  const [updateQuote, { loading: quoteLoading }] = useMutation(GqlEditQuote, {
    onCompleted: (data) => {
      toaster.success(
        {
          title: 'Quote Updated',
          text: 'The quote has been updated successfully.',
        },
        {
          autoClose: 5000,
        }
      );

      setInitialValues(getInitialValues(data.editQuote));
    },
  });

  const [initialValues, setInitialValues] = useState(
    getInitialValues(quote, paymentMethodId)
  );

  const [shippingSameAsBilling, setBillingSameAsShipping] = useState(true);

  const { countries } = useCountriesAndCities();

  return (
    <div>
      <PageHeader title={`Quote Summary`} Icon={FiBriefcase} />
      <p className="text-sm leading-loose mb-5">
        Quote{' '}
        <strong className="text-dark">
          {quote.accountingReference}
          {(quote.revisionNumber ?? 0) > 0
            ? ` (Revision #${quote.revisionNumber})`
            : ''}
        </strong>{' '}
        , sent on{' '}
        <strong className="text-dark">
          {new Date(quote.createdAt).toNiceFormat()}
        </strong>{' '}
        . Status{' '}
        <strong className="text-dark">
          {quoteStatuses.find((s) => s.value === initialValues.status)?.label}
        </strong>{' '}
        {initialValues.status === QuoteStatusEnum.pending && (
          <>
            and expires on{' '}
            <strong className="text-dark">
              {new Date(quote.expiresAt).toNiceFormat()}
            </strong>
          </>
        )}
      </p>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, props) => {
          if (values.billingAddress && values.shippingAddress) {
            const billingAddress = omitEmpty({
              ...values.billingAddress,
              uuid: undefined,
              __typename: undefined,
            });

            await updateQuote({
              variables: {
                input: {
                  uuid: quote.uuid,
                  paymentMethodId: values.paymentMethodId,
                  purchaseOrderReference: values.purchaseOrderReference,
                  purchaseOrderFileUuid: values.purchaseOrderFileUuid,
                  billingAddress,
                  shippingAddress: shippingSameAsBilling
                    ? billingAddress
                    : omitEmpty({
                        ...values.shippingAddress,
                        uuid: undefined,
                        __typename: undefined,
                      }),
                },
              },
            });
          }
          props.resetForm({
            values,
          });
        }}
      >
        <Form className="relative">
          <div className="p-5 rounded-md bg-white mb-5">
            <div className="flex flex-wrap mb-8 -mx-2">
              <div className="w-full lg:w-1/3 px-2 mb-5 lg:mb-0 flex flex-col justify-between">
                <h4 className="text-sm lg:text-base font-semibold text-black mb-2">
                  Billing Address
                </h4>
                {!initialValues.billingAddress?.uuid ? (
                  <AddressFieldGroup prefix="billingAddress" />
                ) : (
                  [
                    [
                      initialValues.billingAddress.firstName,
                      initialValues.billingAddress.lastName,
                    ]
                      .filter(Boolean)
                      .join(' '),
                    initialValues.billingAddress.company,
                    initialValues.billingAddress.line1,
                    initialValues.billingAddress.line2,
                    initialValues.billingAddress.city,
                    initialValues.billingAddress.state,
                    initialValues.billingAddress.postCode,
                    countries.find(
                      (c) => c.isoCode === initialValues.billingAddress?.country
                    )?.name,
                  ]
                    .filter(Boolean)
                    .map((item, index) => (
                      <p key={item} className="text-sm mb-1">
                        {item}
                      </p>
                    ))
                )}
              </div>
              <div className="w-full lg:w-1/3 px-2 mb-5 lg:mb-0 flex flex-col justify-between">
                <div>
                  <h4 className="text-sm lg:text-base font-semibold text-black mb-2">
                    Shipping Address
                  </h4>
                  {!initialValues.shippingAddress?.uuid && (
                    <div className="mb-5">
                      <Checkbox
                        label="Same as billing details"
                        name="shippingSameAsBilling"
                        id="shippingSameAsBilling"
                        checked={shippingSameAsBilling}
                        onChange={() =>
                          setBillingSameAsShipping(!shippingSameAsBilling)
                        }
                        useFormik={false}
                      />
                    </div>
                  )}
                </div>
                {!initialValues.shippingAddress?.uuid ? (
                  <>
                    {!shippingSameAsBilling && (
                      <AddressFieldGroup prefix="shippingAddress" />
                    )}
                  </>
                ) : (
                  [
                    `${initialValues.shippingAddress.firstName} ${initialValues.shippingAddress.lastName}`,
                    initialValues.shippingAddress.company,
                    initialValues.shippingAddress.line1,
                    initialValues.shippingAddress.line2,
                    initialValues.shippingAddress.city,
                    initialValues.shippingAddress.state,
                    initialValues.shippingAddress.postCode,
                    countries.find(
                      (c) =>
                        c.isoCode === initialValues.shippingAddress?.country
                    )?.name,
                  ]
                    .filter(Boolean)
                    .map((item, index) => (
                      <p key={item} className="text-sm mb-1">
                        {item}
                      </p>
                    ))
                )}
              </div>
              <div className="w-full lg:w-1/3 px-2 flex flex-col items-end justify-end">
                {initialValues.status === QuoteStatusEnum.pending && (
                  <>
                    <Link
                      type="button"
                      to={`/checkout/quote/${quote.uuid}`}
                      className={classNames(
                        elements.button.positive,
                        'relative mb-5 w-52'
                      )}
                    >
                      <div className="flex items-center justify-center">
                        <FiShoppingCart size={20} className="mr-2" />
                        <span>Pay Now</span>
                      </div>
                    </Link>
                    <a
                      href={`${
                        env.apiUrl
                      }/api/pdf?token=${getApiToken()}&quoteUuid=${
                        quote.uuid
                      }&download=1`}
                      target="_blanl"
                      className={classNames(elements.button.tertiary, 'w-52')}
                    >
                      <FiDownload size={20} className="mr-2" />
                      Download PDF
                    </a>
                  </>
                )}
              </div>
            </div>
            <div className="overflow-x-auto w-full mb-8">
              <table className="w-full text-sm">
                <thead>
                  <tr>
                    <th className="text-left text-dark font-bold p-4 border border-black/20">
                      Qty
                    </th>
                    <th className="text-left text-dark font-bold p-4 border border-black/20 border-l-0">
                      Product / Description
                    </th>
                    <th className="text-right text-dark font-bold p-4 border border-black/20 border-l-0">
                      Unit Price
                    </th>
                    <th className="text-right text-dark font-bold p-4 border border-black/20 border-l-0">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {quote.lineItems
                    .filter(
                      (l) =>
                        l.type === LineItemTypeEnum.product && l.quantity > 0
                    )
                    .map((item) => (
                      <tr key={JSON.stringify(item)}>
                        <td className="text-left p-4 border-l border-b border-r border-black/20">
                          {item.quantity}
                        </td>
                        <td className="text-left p-4 border-b  border-b border-r border-black/20">
                          {item.products.map((p) => (
                            <div key={p.uuid}>
                              <strong>{p.sku}</strong> - {p.title}
                              {p.meta.length > 0 && (
                                <ul className="mt-2 text-midGray">
                                  {p.meta.map((m, i) => (
                                    <li
                                      key={`${i}-${m.key}-${m.value}`}
                                      className="text-xs"
                                    >
                                      <strong>{m.key}:</strong> {m.value}
                                    </li>
                                  ))}
                                </ul>
                              )}
                              {!!p.leadTime && (
                                <p className="text-xs mt-2">
                                  <strong>Lead Time:</strong> {p.leadTime}
                                </p>
                              )}
                              {p.links.length > 0 && (
                                <ul className="mt-2 -mx-1">
                                  {p.links.map((link, i) => (
                                    <li
                                      key={`${i}-${link.type}-${link.fileUuid}-${link.url}`}
                                      className="px-1"
                                    >
                                      <a
                                        href={link.url}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-sm text-red"
                                      >
                                        Download {link.type}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          ))}
                        </td>
                        <td className="text-right p-4 border-b  border-b border-r border-black/20">
                          {formatMoney(item.amount, quote.currency)}
                        </td>
                        <td className="text-right p-4 border-b  border-r border-black/20">
                          {formatMoney(
                            item.quantity * item.amount,
                            quote.currency
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="flex flex-wrap justify-end -mx-2 lg:-mx-4">
              <div className="w-full lg:w-2/3 px-2 lg:px-4 mb-5 lg:mb-0 empty:hidden">
                <Info
                  inTouchReference={quote.inTouchReference ?? undefined}
                  creator={quote.creator ?? undefined}
                  company={
                    quote.billingAddress?.company ??
                    [
                      quote.billingAddress?.firstName,
                      quote.billingAddress?.lastName,
                    ]
                      .filter(Boolean)
                      .join(' ')
                  }
                />
              </div>
              <div className="w-full lg:w-1/3 text-sm px-2 lg:px-4">
                <div className="p-2 rounded-md">
                  {quote.lineItems
                    .filter((l) => l.type === LineItemTypeEnum.shipping)
                    .map((item) => (
                      <div
                        key={JSON.stringify(item)}
                        className="flex items-center justify-between mb-2"
                      >
                        <div>
                          <strong className="block">Delivery & Packing:</strong>
                          <span className="italic">{item.title}</span>
                        </div>
                        {formatMoney(item.amount, quote.currency)}
                      </div>
                    ))}
                  {quote.lineItems
                    .filter((l) => l.type === LineItemTypeEnum.discount)
                    .map((item) => (
                      <div
                        key={JSON.stringify(item)}
                        className="flex items-center justify-between mb-2"
                      >
                        <div>
                          <strong className="block">Discount:</strong>
                          <span className="italic">{item.title}</span>
                        </div>
                        {formatMoney(item.amount, quote.currency)}
                      </div>
                    ))}
                  <div className="flex items-center justify-between mb-2">
                    <strong>Subtotal:</strong>{' '}
                    {formatMoney(
                      quote.lineItems.reduce(
                        (acc, item) =>
                          acc + Math.max(item.quantity, 1) * item.amount,
                        0
                      ),
                      quote.currency
                    )}
                  </div>
                  <div className="flex items-center justify-between mb-5 border-b border-black/20 pb-5">
                    <strong>Tax:</strong>{' '}
                    {formatMoney(
                      quote.lineItems
                        .filter((l) => l.taxRate > 0)
                        .reduce(
                          (acc, item) =>
                            acc +
                            Math.max(item.quantity, 1) *
                              item.amount *
                              item.taxRate,
                          0
                        ),
                      quote.currency
                    )}
                  </div>
                  <div className="flex items-center justify-between text-base">
                    <strong>Total:</strong>{' '}
                    <strong>{formatMoney(quote.total, quote.currency)}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-md mb-5 bg-white">
            <h4 className="text-lg font-semibold text-black px-5 py-3 border-b border-black/20">
              Purchase Order Details
            </h4>
            <div className="flex flex-col justify-center p-5">
              <div className="w-full mb-5">
                <Input
                  name="purchaseOrderReference"
                  className="max-w-sm"
                  label="Purchase Order Reference"
                />
              </div>
              <div className="w-full mb-5 last:mb-0 relative">
                <Suspense fallback={<PageLoader />}>
                  <PurchaseOrderUploadField />
                </Suspense>
              </div>
            </div>
            {quoteLoading && <PageLoader />}
          </div>
          {(!!quote.customerReference ||
            quote.recipients.filter((r) => r.group === RecipientGroup.external)
              .length > 0 ||
            !!quote.emailMessage) && (
            <div className="bg-white rounded-md mb-5">
              <h3 className="text-lg border-b border-black/20 text-black font-semibold py-3 px-5">
                Quote Details
              </h3>
              <div className="p-5">
                {!!quote.customerReference && (
                  <div className="mb-5">
                    <strong className="block font-semibold text-black mb-2">
                      Customer Reference
                    </strong>
                    <p className="text-sm">{quote.customerReference}</p>
                  </div>
                )}
                {quote.recipients.filter(
                  (r) => r.group === RecipientGroup.external
                ).length > 0 && (
                  <div className="mb-5">
                    <strong className="block font-semibold text-black mb-2">
                      Recipients
                    </strong>
                    <ul className="text-sm list-disc pl-5">
                      {quote.recipients
                        .filter((r) => r.group === RecipientGroup.external)
                        .map((recipient, index) => (
                          <li
                            key={`${index}-${recipient.email}-${recipient.group}`}
                            className="mb-2"
                          >
                            {recipient.email}
                            {
                              <strong className="text-dark">
                                {' '}
                                ({recipient.group})
                              </strong>
                            }
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
                {!!quote.emailMessage && (
                  <div>
                    <strong className="block font-semibold text-black mb-2">
                      Email Message
                    </strong>
                    <div
                      className="text-sm ugc"
                      dangerouslySetInnerHTML={{ __html: quote.emailMessage }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          <Notes />
          <SubmitButton />
        </Form>
      </Formik>
    </div>
  );
};

export default ViewQuote;
