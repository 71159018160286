import { toaster } from '../../../utility/toast';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/my-account/')({
  loader: () => {
    const redirectTo = new URLSearchParams(window.location.search).get('redirect_to');
    if (redirectTo) {
      window.location.href = redirectTo;

      toaster.warn({
        title: 'Redirecting',
        text: 'You are being redirected to the page you requested.',
      }, {
        autoClose: false,
      });

      throw Error('Redirecting');
    }
    throw redirect({
      to: '/my-account/orders',
    });
  },
});
