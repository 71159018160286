import FloatingButton from './FloatingButton';
import { FiDownload } from 'react-icons/fi';

interface Props {
  href: string;
}

const DownloadButton = ({ href }: Props) => (
  <FloatingButton showIfNotDirty href={href} positionalClassNames="bottom-8 right-28">
    <FiDownload size={24} />
  </FloatingButton>
);

export default DownloadButton;
