import { useLazyQuery } from '@apollo/client';
import { GqlLegacyOndexCustomers } from '@monorepo/graphql/resources';
import SearchField from './SearchField';
import { useCallback } from 'react';
import { LegacyIndexCustomersQuery } from '@monorepo/graphql';

interface Props {
  onSelect: (
    customer: LegacyIndexCustomersQuery['indexLegacyCustomers']['items'][0]
  ) => void;
  defaultValue?: string;
  label?: string;
  useFormik?: boolean;
  name?: string;
  hideLabel?: boolean;
}

const LegacyCustomerSearchField = ({
  onSelect,
  defaultValue,
  name = 'customer',
  useFormik,
  label = 'Customer',
  hideLabel,
}: Props) => {
  const [fetchCustomers, { loading }] = useLazyQuery(GqlLegacyOndexCustomers);

  const fetchSuggestions = useCallback(
    async (term: string) => {
      const { data } = await fetchCustomers({
        variables: {
          input: {
            pagination: null,
            term,
          },
        },
      });

      return data?.indexLegacyCustomers.items ?? [];
    },
    [fetchCustomers]
  );

  return (
    <SearchField
      label={!hideLabel ? label : undefined}
      placeholder="Search for a legacy customer"
      fetchSuggestions={fetchSuggestions}
      name={name}
      useFormik={useFormik}
      loading={loading}
      idProp="id"
      labelProp="displayName"
      onSelect={onSelect}
      defaultValue={defaultValue}
      className="mb-5"
    />
  );
};

export default LegacyCustomerSearchField;
