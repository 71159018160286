import {
  LineItemTypeEnum,
  OrderFragmentFragment,
  OrderStatusEnum,
  PaymentMethodEnum,
} from '@monorepo/graphql';
import ViewOrder from './ViewOrder';
import env from '../environment';
import { FiArrowLeft } from 'react-icons/fi';
import CheckoutFooter from './CheckoutFooter';
import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GqlReadOrder } from '@monorepo/graphql/resources';
import { toaster } from '../utility/toast';
import { ReactTagManager } from 'react-gtm-ts';

interface Props {
  order: OrderFragmentFragment;
}

const CheckoutViewOrder = ({ order }: Props) => {
  const [status, setStatus] = useState(order.status);

  const transformedOrder = useMemo(
    () => ({
      ...order,
      status,
    }),
    [status, order]
  );

  const [fetchOrder] = useLazyQuery(GqlReadOrder, {
    variables: {
      uuid: order.uuid,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!order.paymentProcessing || status !== OrderStatusEnum.pending) return;
    const interval = setInterval(async () => {
      await fetchOrder({
        onCompleted: (data) => {
          setStatus(data.readOrder.status);
          if (!data.readOrder.paymentProcessing) {
            clearInterval(interval);
            if (data.readOrder.status === OrderStatusEnum.processing) {
              if (
                data.readOrder.paymentMethodId !== PaymentMethodEnum.creditLimit
              ) {
                toaster.success(
                  {
                    title: 'Order Paid',
                    text: 'The order has been paid successfully.',
                  },
                  {
                    autoClose: 5000,
                  }
                );
              } else {
                toaster.success(
                  {
                    title: 'Order Completed',
                    text: 'The order has been completed successfully.',
                  },
                  {
                    autoClose: 5000,
                  }
                );
              }
            } else {
              toaster.error(
                {
                  title: 'Payment Failed',
                  text: 'There was an issue processing your payment. Please try again.',
                },
                {
                  autoClose: 5000,
                }
              );
            }
          }
        },
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [status, fetchOrder, order.paymentProcessing]);

  useEffect(() => {
    ReactTagManager.action({
      event: 'purchase',
      ecommerce: {
          transaction_id: order.accountingReference,
          affiliation: '247Able Store',
          value: order.total,
          currency: order.currency,
          coupon: order.coupon?.code,
          tax: order.lineItems
            .filter((l) => l.taxRate > 0)
            .reduce(
              (acc, item) =>
                acc +
                Math.max(item.quantity, 1) * item.amount * item.taxRate,
              0
            ),
          shipping: order.lineItems
            .filter((l) => l.type === LineItemTypeEnum.shipping)
            .map((l) => l.amount)
            .reduce((acc, item) => acc + item, 0),
          items: order.lineItems.filter((l) => l.type === LineItemTypeEnum.product).map(l => ({
              item_name: l.title,
              item_id: l.sku,
              price: l.amount,
              quantity: l.quantity
          })),
      }
    });
  }, [order]);

  return (
    <div className="h-dvh overflow-y-auto flex flex-col">
      <div className="bg-lightGray p-8 lg:px-20 lg:py-10 flex justify-center grow">
        <div className="lg:max-w-[74rem] w-full">
          <a href={env.wpUrl} className="flex mb-8 items-center">
            <FiArrowLeft className="mr-4" size={28} />
            <img src="/v2-assets/logo-old.svg" alt="Logo" width="120" />
          </a>
          <ViewOrder order={transformedOrder} />
        </div>
      </div>
      <CheckoutFooter />
    </div>
  );
};

export default CheckoutViewOrder;
