import { gql } from '@apollo/client';
import {
  AddQuoteNoteMutation,
  AddQuoteNoteMutationVariables,
  CompleteQuoteMutation,
  CompleteQuoteMutationVariables,
  ConvertCartToQuoteMutation,
  ConvertCartToQuoteMutationVariables,
  CreateMessageTemplateMutation,
  CreateMessageTemplateMutationVariables,
  CreateQuoteMutation,
  CreateQuoteMutationVariables,
  DeleteEmailTokenMutation,
  DeleteEmailTokenMutationVariables,
  DeleteMessageTemplateMutation,
  DeleteMessageTemplateMutationVariables,
  DeleteQuoteMutation,
  DeleteQuoteMutationVariables,
  DeleteQuoteNoteMutation,
  DeleteQuoteNoteMutationVariables,
  EditQuoteMutation,
  EditQuoteMutationVariables,
  EditQuoteNoteMutation,
  EditQuoteNoteMutationVariables,
  GetQuoteStripePaymentIntentClientSecretQuery,
  GetQuoteStripePaymentIntentClientSecretQueryVariables,
  IndexEmailTokensQuery,
  IndexEmailTokensQueryVariables,
  IndexMessageTemplatesQuery,
  IndexMessageTemplatesQueryVariables,
  IndexQuotesQuery,
  IndexQuotesQueryVariables,
  ReadMessageTemplateQuery,
  ReadMessageTemplateQueryVariables,
  ReadQuoteQuery,
  ReadQuoteQueryVariables,
  SendQuoteEmailMutation,
  SendQuoteEmailMutationVariables,
  UpdateMessageTemplateMutation,
  UpdateMessageTemplateMutationVariables,
} from '../__generated__/graphql';
import { GqlPagination } from './pagination';
import { GqlUser } from './user';
import { GqlAddress, GqlCustomer } from './address';

export const GqlQuoteNote = gql`
  fragment QuoteNoteFragment on QuoteNote {
    uuid
    text
    type
    createdAt
  }
`;

export const GqlQuote = gql`
  fragment QuoteFragment on Quote {
    uuid
    accountingReference
    status
    total
    currency
    createdAt
    expiresAt
    customerReference
    inTouchReference
    purchaseOrderReference
    purchaseOrderFileUuid
    paymentMethodId
    paymentIntentId
    paymentMethodLabel
    paymentProcessing
    emailMessage
    revisionNumber
    recipients {
      group
      email
    }
    creator {
      ...UserFragment
    }
    customer {
      ...CustomerFragment
    }
    billingAddress {
      ...AddressFragment
    }
    shippingAddress {
      ...AddressFragment
    }
    notes {
      ...QuoteNoteFragment
    }
    lineItems {
      uuid
      title
      type
      taxRate
      quantity
      originalAmount
      shippingMethod
      amount
      products {
        uuid
        title
        sku
        productId
        productUuid
        isSaleItem
        leadTime
        meta {
          key
          value
        }
        links {
          url
          type
          fileUuid
          file {
            createdAt
          }
        }
      }
    }
  }
  ${GqlCustomer}
  ${GqlAddress}
  ${GqlQuoteNote}
  ${GqlUser}
`;

export const GqlIndexQuotes = gql<IndexQuotesQuery, IndexQuotesQueryVariables>`
  query IndexQuotes($input: QuoteFilterInput) {
    indexQuotes(input: $input) {
      pagination {
        ...PaginationFragment
      }
      items {
        ...QuoteFragment
      }
    }
  }
  ${GqlPagination}
  ${GqlQuote}
`;

export const GqlReadQuote = gql<ReadQuoteQuery, ReadQuoteQueryVariables>`
  query ReadQuote($uuid: ID!) {
    readQuote(uuid: $uuid) {
      ...QuoteFragment
    }
  }
  ${GqlQuote}
`;

export const GqlCreateQuote = gql<
  CreateQuoteMutation,
  CreateQuoteMutationVariables
>`
  mutation CreateQuote($input: CreateQuoteInput!) {
    createQuote(input: $input) {
      ...QuoteFragment
    }
  }
  ${GqlQuote}
`;

export const GqlEditQuote = gql<EditQuoteMutation, EditQuoteMutationVariables>`
  mutation EditQuote($input: EditQuoteInput!) {
    editQuote(input: $input) {
      ...QuoteFragment
    }
  }
  ${GqlQuote}
`;

export const GqlDeleteQuote = gql<
  DeleteQuoteMutation,
  DeleteQuoteMutationVariables
>`
  mutation DeleteQuote($quoteUuid: ID!) {
    deleteQuote(quoteUuid: $quoteUuid)
  }
`;

export const GqlSendQuoteEmail = gql<
  SendQuoteEmailMutation,
  SendQuoteEmailMutationVariables
>`
  mutation SendQuoteEmail($quoteUuid: ID!) {
    sendQuoteEmail(quoteUuid: $quoteUuid)
  }
`;

export const GqlAddQuoteNote = gql<
  AddQuoteNoteMutation,
  AddQuoteNoteMutationVariables
>`
  mutation AddQuoteNote($input: AddQuoteNoteInput!) {
    addQuoteNote(input: $input) {
      ...QuoteNoteFragment
    }
  }
  ${GqlQuoteNote}
`;

export const GqlGetQuoteStripePaymentIntentClientSecret = gql<
  GetQuoteStripePaymentIntentClientSecretQuery,
  GetQuoteStripePaymentIntentClientSecretQueryVariables
>`
  query GetQuoteStripePaymentIntentClientSecret(
    $input: GetQuoteStripePaymentIntentClientSecretInput!
  ) {
    getQuoteStripePaymentIntentClientSecret(input: $input)
  }
`;

export const GqlCompleteQuote = gql<
  CompleteQuoteMutation,
  CompleteQuoteMutationVariables
>`
  mutation CompleteQuote($input: CompleteQuoteInput!) {
    completeQuote(input: $input) {
      ...QuoteFragment
    }
  }
  ${GqlQuote}
`;

export const GqlMessageTemplate = gql`
  fragment MessageTemplateFragment on MessageTemplate {
    uuid
    name
    path
    html
  }
`;

export const GqlEmailToken = gql`
  fragment EmailTokenFragment on EmailToken {
    uuid
    email
    expiresAt
  }
`;

export const GqlIndexMessageTemplates = gql<
  IndexMessageTemplatesQuery,
  IndexMessageTemplatesQueryVariables
>`
  query IndexMessageTemplates {
    indexMessageTemplates {
      ...MessageTemplateFragment
    }
  }
  ${GqlMessageTemplate}
`;


export const GqlIndexEmailTokens = gql<
  IndexEmailTokensQuery,
  IndexEmailTokensQueryVariables
>`
  query IndexEmailTokens {
    indexEmailTokens {
      ...EmailTokenFragment
    }
  }
  ${GqlEmailToken}
`;

export const GqlReadMessageTemplate = gql<
  ReadMessageTemplateQuery,
  ReadMessageTemplateQueryVariables
>`
  query ReadMessageTemplate($uuid: ID!) {
    readMessageTemplate(uuid: $uuid) {
      ...MessageTemplateFragment
    }
  }
  ${GqlMessageTemplate}
`;

export const GqlCreateMessageTemplate = gql<
  CreateMessageTemplateMutation,
  CreateMessageTemplateMutationVariables
>`
  mutation CreateMessageTemplate($input: CreateMessageTemplateInput!) {
    createMessageTemplate(input: $input) {
      ...MessageTemplateFragment
    }
  }
  ${GqlMessageTemplate}
`;

export const GqlEditMessageTemplate = gql<
  UpdateMessageTemplateMutation,
  UpdateMessageTemplateMutationVariables
>`
  mutation UpdateMessageTemplate($input: UpdateMessageTemplateInput!) {
    updateMessageTemplate(input: $input) {
      ...MessageTemplateFragment
    }
  }
  ${GqlMessageTemplate}
`;

export const GqlDeleteMessageTemplate = gql<
  DeleteMessageTemplateMutation,
  DeleteMessageTemplateMutationVariables
>`
  mutation DeleteMessageTemplate($uuid: ID!) {
    deleteMessageTemplate(uuid: $uuid)
  }
`;

export const GqlDeleteEmailToken = gql<
  DeleteEmailTokenMutation,
  DeleteEmailTokenMutationVariables
>`
  mutation DeleteEmailToken($uuid: ID!) {
    deleteEmailToken(uuid: $uuid)
  }
`;

export const GqlEditQuoteNote = gql<
  EditQuoteNoteMutation,
  EditQuoteNoteMutationVariables
>`
  mutation EditQuoteNote($input: EditQuoteNoteInput!) {
    editQuoteNote(input: $input) {
      ...QuoteNoteFragment
    }
  }
  ${GqlQuoteNote}
`;

export const GqlDeleteQuoteNote = gql<
  DeleteQuoteNoteMutation,
  DeleteQuoteNoteMutationVariables
>`
  mutation DeleteQuoteNote($quoteNoteUuid: ID!) {
    deleteQuoteNote(quoteNoteUuid: $quoteNoteUuid)
  }
`;

export const GqlConvertCartToQuote = gql<
  ConvertCartToQuoteMutation,
  ConvertCartToQuoteMutationVariables
>`
  mutation ConvertCartToQuote($customerUuid: ID!) {
    convertCartToQuote(customerUuid: $customerUuid) {
      ...QuoteFragment
    }
  }
  ${GqlQuote}
`;

export const GqlAbortQuoteCheckoutPayment = gql`
  mutation AbortQuoteCheckoutPayment($quoteUuid: ID!, $reason: String) {
    abortQuoteCheckoutPayment(quoteUuid: $quoteUuid, reason: $reason) {
      ...QuoteFragment
    }
  }
`;
