import { FileRoutesByPath, useNavigate } from '@tanstack/react-router';
import { elements } from '../utility/styles';
import { Button } from './Button';

export type To = FileRoutesByPath[keyof FileRoutesByPath]['fullPath'];

interface Props {
  to: To;
}

const NotFound = ({ to }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center flex-col flex-grow">
      <div className="text-center">
        <h1 className="text-dark text-4xl font-bold mb-5">404</h1>
        <h2 className="text-dark text-base mb-5">
          Sorry we can't seem to find what you're looking for
        </h2>
        <div className="inline-flex">
          <Button
            type="button"
            className={elements.button.primary}
            onClick={() => void navigate({ to })}
          >
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
