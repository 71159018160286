import { useCallback, useMemo, useState } from 'react';
import { DateValueType } from 'react-tailwindcss-datepicker';
import DatePicker from './DatePicker';
import Dropdown, { Item } from './Dropdown';
import {
  LegacyOrderFilterFunctionInput,
  LegacyOrderStatusEnum,
  LegacyIndexOrdersQuery,
  LegacyLineItemTypeEnum,
  formatMoney,
} from '@monorepo/graphql';
import { elements } from '../utility/styles';
import classNames from 'classnames';
import { useBackgroundQuery } from '@apollo/client';
import { Link } from '@tanstack/react-router';
import DataLayout, { FilterOption, TableColumn } from './DataLayout';
import { FiShoppingCart } from 'react-icons/fi';
import { GqlLegacyIndexOrders } from '@monorepo/graphql/resources';
import ProductSearchField from './ProductSearchField';
import LegacyCustomerSearchField from './LegacyCustomerSearchField';
import Input from './Input';

export enum TableColumnEnum {
  reference = 'reference',
  createdAt = 'createdAt',
  customer = 'customer',
  total = 'total',
  status = 'status',
  products = 'products',
  actions = 'actions',
}

export enum FilterOptionEnum {
  dateRange = 'dateRange',
  customer = 'customer',
  status = 'status',
  search = 'search',
  productSearch = 'productSearch',
}

export const statuses = [
  {
    value: LegacyOrderStatusEnum.completed,
    label: 'Completed',
  },
  {
    value: LegacyOrderStatusEnum.processing,
    label: 'Processing',
  },
  {
    value: LegacyOrderStatusEnum.cancelled,
    label: 'Cancelled',
  },
  {
    value: LegacyOrderStatusEnum.failed,
    label: 'Failed',
  },
  {
    value: LegacyOrderStatusEnum.acceptedQuote,
    label: 'Accepted Quote',
  },
  {
    value: LegacyOrderStatusEnum.exiredQuote,
    label: 'Expired Quote',
  },
  {
    value: LegacyOrderStatusEnum.onHold,
    label: 'On Hold',
  },
  {
    value: LegacyOrderStatusEnum.pendingPayment,
    label: 'Pending Payment',
  },
  {
    value: LegacyOrderStatusEnum.pendingQuote,
    label: 'Pending Quote',
  },
  {
    value: LegacyOrderStatusEnum.refunded,
    label: 'Refunded',
  },
  {
    value: LegacyOrderStatusEnum.rejectedQuote,
    label: 'Rejected Quote',
  },
];

function LegacyOrdersList({
  title = 'Legacy Orders',
  hiddenColumns,
  hiddenFilters,
  hiddenStatuses,
  initialFilters,
}: {
  title?: string;
  hiddenColumns?: TableColumnEnum[];
  hiddenFilters?: FilterOptionEnum[];
  hiddenStatuses?: LegacyOrderStatusEnum[];
  initialFilters?: Partial<LegacyOrderFilterFunctionInput>;
}) {
  type Order = LegacyIndexOrdersQuery['indexLegacyOrders']['items'][0];

  const defaultFilters = useMemo<LegacyOrderFilterFunctionInput>(
    () => ({
      customerId: null,
      statuses: [],
      from: null,
      to: null,
      term: null,
      productTerm: null,
      ...(initialFilters ?? {}),
    }),
    [initialFilters]
  );

  const tableColumns = useMemo<Array<TableColumn<Order>>>(
    () =>
      [
        {
          key: TableColumnEnum.reference,
          label: 'Order #',
          handler: (order: Order) => (
            <Link to={`${order.id}`}>{order.reference}</Link>
          ),
          style: { width: '10%' },
        },
        {
          key: TableColumnEnum.createdAt,
          label: 'Date',
          handler: (order: Order) => (
            <span>{new Date(order.createdAt).since()}</span>
          ),
          style: { width: '12.5%' },
        },
        {
          key: TableColumnEnum.customer,
          label: 'Customer',
          handler: (order: Order) => (
            <span>
              {[order.billingAddress.firstName, order.billingAddress.lastName]
                .filter(Boolean)
                .join(' ')}
            </span>
          ),
          style: { width: '12.5%' },
        },
        {
          key: TableColumnEnum.total,
          label: 'Amount (£)',
          handler: (order: Order) => (
            <span>{formatMoney(order.total, order.currency)}</span>
          ),
          style: { width: '12.5%' },
        },
        {
          key: TableColumnEnum.status,
          label: 'Status',
          handler: (order: Order) => (
            <span>{statuses.find((s) => s.value === order.status)?.label}</span>
          ),
          style: { width: '12.5%' },
        },
        {
          key: TableColumnEnum.products,
          label: 'Products',
          handler: (order: Order) => {
            const products = order.lineItems.filter(
              (l) => l.type === LegacyLineItemTypeEnum.product
            );

            const productLabel = `${products.find(Boolean)?.name ?? ''}${
              products.length > 1 ? ` + ${products.length - 1} more` : ''
            }`;

            return <span>{productLabel}</span>;
          },
          style: { width: '30%' },
        },
        {
          key: TableColumnEnum.actions,
          label: '',
          handler: (order: Order) => (
            <Link
              className={classNames(
                elements.button.secondary,
                'text-xs inline-flex whitespace-nowrap'
              )}
              to={`${order.id}`}
            >
              View
            </Link>
          ),
          style: { width: '10%' },
        },
      ].filter((c) => !hiddenColumns?.includes(c.key)),
    [hiddenColumns]
  );

  const [dateRange, setDateRange] = useState<DateValueType>({
    startDate: null,
    endDate: null,
  });

  const [customerId, setCustomerId] = useState<number | null>(
    defaultFilters.customerId ?? null
  );

  const [selectedStatuses, setSelectedStatuses] = useState<
    LegacyOrderStatusEnum[]
  >([]);

  const setActiveStatus = useCallback((item: Item) => {
    setSelectedStatuses((prevStatuses) =>
      prevStatuses.includes(item.value as LegacyOrderStatusEnum)
        ? prevStatuses.filter((status) => status !== item.value)
        : [...prevStatuses, item.value as LegacyOrderStatusEnum]
    );
  }, []);

  const [term, setTerm] = useState<string | undefined>();
  const [productTerm, setProductTerm] = useState<string | undefined>();

  const [filters, setFilters] = useState<LegacyOrderFilterFunctionInput>({
    ...defaultFilters,
  });

  const currentFilters = useMemo(
    () => ({
      customerId,
      statuses: selectedStatuses,
      from: dateRange?.startDate
        ? new Date(dateRange.startDate).toISOString()
        : null,
      to: dateRange?.endDate ? new Date(dateRange.endDate).toISOString() : null,
      term,
      productTerm
    }),
    [selectedStatuses, dateRange, term, customerId, productTerm]
  );

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);

  const [query] = useBackgroundQuery(GqlLegacyIndexOrders, {
    variables: {
      input: {
        filters,
        orderBy: null,
        pagination: {
          page,
          perPage,
        },
      },
    },
  });

  const resetFilters = useCallback(() => {
    setCustomerDefaultValue(undefined);

    setDateRange({ startDate: null, endDate: null });

    setSelectedStatuses([]);

    setTerm(undefined);

    setProductTerm(undefined);

    setCustomerId(null);
    
    setCustomerDefaultValue(undefined);
  }, []);

  const applyFilters = useCallback(() => {
    setFilters({
      customerId,
      statuses: selectedStatuses,
      from: dateRange?.startDate
        ? new Date(dateRange.startDate).toISOString()
        : null,
      to: dateRange?.endDate ? new Date(dateRange.endDate).toISOString() : null,
      term,
      productTerm
    });
  }, [selectedStatuses, dateRange, term, customerId, productTerm]);

  const [customerDefaultValue, setCustomerDefaultValue] = useState<
    string | undefined
  >();

  const filterOptions = useMemo<FilterOption[]>(
    () =>
      [
        {
          key: FilterOptionEnum.search,
          Component: ({ term: item }: { term?: string }) => (
            <Input
              className="w-full"
              name="term"
              value={item}
              placeholder='Search by Order #'
              onInput={(e) => setTerm(e.currentTarget.value)}
              onKeyDown={(e) => e.stopPropagation()}
              useFormik={false}
            />
          ),
        },
        {
          key: FilterOptionEnum.dateRange,
          label: 'Date Range',
          Component: () => (
            <DatePicker value={dateRange} setValue={setDateRange} useRange />
          ),
        },
        {
          key: FilterOptionEnum.customer,
          Component: () => (
            <LegacyCustomerSearchField
              onSelect={(customer) => {
                setCustomerId(customer.id);

                setCustomerDefaultValue(customer.displayName);
              }}
              defaultValue={customerDefaultValue}
            />
          ),
        },
        {
          key: FilterOptionEnum.status,
          label: 'Status',
          Component: () => (
            <Dropdown
              placeholder="Filter by Status"
              name="status"
              options={statuses.filter(
                (s) => !hiddenStatuses?.includes(s.value)
              )}
              onChange={setActiveStatus}
              value={selectedStatuses}
            />
          ),
        },
        {
          key: FilterOptionEnum.productSearch,
          Component: ({ term: item }: { term?: string }) => (
            <ProductSearchField
              onSelect={(product) => {
                setProductTerm(product.name);
              }}
              onInput={setProductTerm}
              defaultValue={item}
            />
          ),
        },
      ].filter((f) => !hiddenFilters?.includes(f.key)),
    [
      dateRange,
      selectedStatuses,
      customerDefaultValue,
      setActiveStatus,
      hiddenFilters,
      hiddenStatuses,
    ]
  );

  const extractItems = useCallback(
    (d: LegacyIndexOrdersQuery) => d.indexLegacyOrders.items,
    []
  );

  const extractPagination = useCallback(
    (d: LegacyIndexOrdersQuery) => d.indexLegacyOrders.pagination,
    []
  );

  return (
    <DataLayout
      Icon={FiShoppingCart}
      title={title}
      subtitle={
        defaultFilters.customerId
          ? 'Manage your legacy orders below'
          : 'Manage legacy orders below'
      }
      setPage={setPage}
      setPerPage={setPerPage}
      tableColumns={tableColumns}
      filterOptions={filterOptions}
      onReset={resetFilters}
      onConfirm={applyFilters}
      currentFilters={currentFilters}
      baseFilters={defaultFilters}
      term={term}
      query={query}
      extractItems={extractItems}
      extractPagination={extractPagination}
    />
  );
}

export default LegacyOrdersList;
