import { FiShoppingCart } from 'react-icons/fi';
import PageHeader from './PageHeader';
import {
  CurrencyEnum,
  CustomerWithCartDataFragmentFragment,
  formatMoney,
  LineItemTypeEnum,
} from '@monorepo/graphql';
import Loader from './Loader';
import { useMutation } from '@apollo/client';
import { GqlConvertCartToQuote } from '@monorepo/graphql/resources';
import classNames from 'classnames';
import { elements } from '../utility/styles';
import { useNavigate } from '@tanstack/react-router';
import { toaster } from '../utility/toast';
import { useCountriesAndCities } from './AddressFieldGroup';
import { Button } from './Button';

interface Props {
  customer: Omit<CustomerWithCartDataFragmentFragment, 'uuid'> & {
    uuid: string;
  };
}

const CustomerCart = ({ customer }: Props) => {
  const navigate = useNavigate();
  const [convertToQuote, { loading: convertLoading }] = useMutation(
    GqlConvertCartToQuote,
    {
      update: (cache) => {
        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'indexQuotes',
        });

        cache.gc();
      },
      variables: {
        customerUuid: customer.uuid,
      },
      onCompleted: (data) => {
        void navigate({ to: `/admin/quotes/${data.convertCartToQuote.uuid}` });

        toaster.success(
          {
            title: 'Quote Created',
            text: 'The cart has been converted to a quote',
          },
          {
            autoClose: 5000,
          }
        );
      },
    }
  );

  const { countries } = useCountriesAndCities();

  return (
    <div>
      <PageHeader title={`Customer Cart`} Icon={FiShoppingCart} />
      <div className="border border-black/20 p-5 bg-white rounded-md">
        <div className="flex mb-8 -mx-2">
          <div className="w-1/3 px-2">
            <h4 className="text-base font-semibold text-black mb-2">
              Billing Address
            </h4>
            {[
              [
                customer.cart?.billingAddress.firstName ?? customer.firstName,
                customer.cart?.billingAddress.lastName ?? customer.lastName,
              ]
                .filter(Boolean)
                .join(' '),
              customer.cart?.billingAddress.company,
              customer.cart?.billingAddress.line1,
              customer.cart?.billingAddress.line2,
              customer.cart?.billingAddress.city,
              customer.cart?.billingAddress.state,
              customer.cart?.billingAddress.postCode,
              countries.find(
                (c) => c.isoCode === customer.cart?.billingAddress.country
              )?.name,
            ]
              .filter(Boolean)
              .map((item, index) => (
                <p key={item} className="text-sm mb-1">
                  {item}
                </p>
              ))}
          </div>
          <div className="w-1/3 px-2">
            <h4 className="text-base font-semibold text-black mb-2">
              Shipping Address
            </h4>
            {[
              [
                customer.cart?.shippingAddress.firstName ?? customer.firstName,
                customer.cart?.shippingAddress.lastName ?? customer.lastName,
              ]
                .filter(Boolean)
                .join(' '),
              customer.cart?.shippingAddress.company,
              customer.cart?.shippingAddress.line1,
              customer.cart?.shippingAddress.line2,
              customer.cart?.shippingAddress.city,
              customer.cart?.shippingAddress.state,
              customer.cart?.shippingAddress.postCode,
              countries.find(
                (c) => c.isoCode === customer.cart?.shippingAddress.country
              )?.name,
            ]
              .filter(Boolean)
              .map((item, index) => (
                <p key={item} className="text-sm mb-1">
                  {item}
                </p>
              ))}
          </div>
          {(customer.cart?.lineItems.length ?? 0) > 0 && (
            <div className="w-1/3 px-2 flex flex-col items-end">
              {convertLoading ? (
                <Loader size="1.5rem" />
              ) : (
                <Button
                  type="button"
                  onClick={async () => {
                    await convertToQuote();
                  }}
                  className={classNames(
                    elements.button.positive,
                    'mb-5 last:mb-0'
                  )}
                >
                  <div className="flex items-center justify-center">
                    <FiShoppingCart size={20} className="mr-2" />
                    <span>Convert to Quote</span>
                  </div>
                </Button>
              )}
            </div>
          )}
        </div>
        <table className="w-full text-sm mb-8">
          <thead>
            <tr>
              <th className="text-left text-dark font-bold p-4 border border-black/20">
                Qty
              </th>
              <th className="text-left text-dark font-bold p-4 border border-black/20 border-l-0">
                Product / Description
              </th>
              <th className="text-right text-dark font-bold p-4 border border-black/20 border-l-0">
                Unit Price
              </th>
              <th className="text-right text-dark font-bold p-4 border border-black/20 border-l-0">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {customer.cart?.lineItems
              .filter(
                (l) => l.type === LineItemTypeEnum.product && l.quantity > 0
              )
              .map((item, index) => (
                <tr key={`${index}-${item.sku}`}>
                  <td className="text-left p-4 border-l border-b border-r border-black/20">
                    {item.quantity}
                  </td>
                  <td className="text-left p-4 border-b  border-b border-r border-black/20">
                    <div>
                      <strong>{item.sku}</strong> - {item.title}
                      {item.meta.length > 0 && (
                        <ul className="mt-2 text-midGray">
                          {item.meta.map((m, i) => (
                            <li
                              key={`${i}-${m.key}-${m.value}`}
                              className="text-xs"
                            >
                              <strong>{m.key}:</strong> {m.value}
                            </li>
                          ))}
                        </ul>
                      )}
                      {!!item.leadTime && (
                        <p className="text-xs mt-2">
                          <strong>Lead Time:</strong> {item.leadTime}
                        </p>
                      )}
                      {item.links.length > 0 && (
                        <ul className="mt-2 -mx-1">
                          {item.links.map((link, i) => (
                            <li
                              key={`${i}-${link.type}-${link.url}`}
                              className="px-1"
                            >
                              <a
                                href={link.url}
                                target="_blank"
                                rel="noreferrer"
                                className="text-xs text-red"
                              >
                                Download {link.type}
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </td>
                  <td className="text-right p-4 border-b  border-b border-r border-black/20">
                    <span>
                      {formatMoney(
                        item.amount,
                        customer.cart?.currency ?? CurrencyEnum.gbp
                      )}
                    </span>
                  </td>
                  <td className="text-right p-4 border-b  border-r border-black/20">
                    {formatMoney(
                      item.quantity * item.amount,
                      customer.cart?.currency ?? CurrencyEnum.gbp
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex -mx-4">
          <div className="w-2/3 px-4">
            {!!customer.cart?.coupon?.code && (
              <ul className="mb-5 text-sm">
                <li>
                  <strong>Coupon Code:</strong> {customer.cart.coupon.code}
                </li>
              </ul>
            )}
          </div>
          <div className="w-1/3 text-sm px-4">
            <div className="p-2 rounded-md mb-5">
              {customer.cart?.lineItems
                .filter((l) => l.type === LineItemTypeEnum.shipping)
                .map((item, index) => (
                  <div
                    key={`${index}-${item.sku}`}
                    className="flex items-center justify-between mb-2"
                  >
                    <div>
                      <strong className="block">Delivery & Packing:</strong>
                      <span className="italic">{item.title}</span>
                    </div>
                    {formatMoney(
                      item.amount,
                      customer.cart?.currency ?? CurrencyEnum.gbp
                    )}
                  </div>
                ))}
              <div className="flex items-center justify-between mb-2">
                <strong>Subtotal:</strong>{' '}
                {formatMoney(
                  customer.cart?.lineItems.reduce(
                    (acc, item) =>
                      acc + Math.max(item.quantity, 1) * item.amount,
                    0
                  ) ?? 0,
                  customer.cart?.currency ?? CurrencyEnum.gbp
                )}
              </div>
              <div className="flex items-center justify-between pb-5 border-b border-black/20 mb-5">
                <strong>Tax:</strong>{' '}
                {formatMoney(
                  customer.cart?.lineItems
                    .filter((l) => l.taxRate > 0)
                    .reduce(
                      (acc, item) =>
                        acc +
                        Math.max(item.quantity, 1) * item.amount * item.taxRate,
                      0
                    ) ?? 0,
                  customer.cart?.currency ?? CurrencyEnum.gbp
                )}
              </div>
              <div className="flex items-center justify-between text-base">
                <strong>Total:</strong>{' '}
                <strong>
                  {formatMoney(
                    customer.cart?.lineItems
                      .filter((l) => l.taxRate > 0)
                      .reduce(
                        (acc, item) =>
                          acc +
                          Math.max(item.quantity, 1) *
                            item.amount *
                            (1 + item.taxRate),
                        0
                      ) ?? 0,
                    customer.cart?.currency ?? CurrencyEnum.gbp
                  )}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerCart;
