import OrdersList from '../../../../components/OrdersList';
import { createFileRoute } from '@tanstack/react-router';

export interface SearchParams {
  customerUuid?: string;
}

const OrdersListComponent = () => {
  const { customerUuid } = Route.useSearch();

  return (
    <OrdersList
      title="Customer Orders"
      legacyUrl="/admin/orders/legacy"
      initialFilters={{ customerUuid }}
    />
  );
};

export const Route = createFileRoute('/_authenticated/admin/orders/')({
  validateSearch: (search: Record<string, unknown>): SearchParams => ({
    customerUuid: search.customerUuid as string | undefined,
  }),
  component: OrdersListComponent,
});
