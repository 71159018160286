import { User } from '@prisma/client';
import { OrderStatusEnum, QuoteStatusEnum, Resolver } from './__generated__/graphql';

export type ContextUser = Omit<
  User,
  'password' | 'resetPasswordToken' | 'deletedAt' | 'uuid'
> & {
  uuid: string | undefined;
};


export type FullUser = Omit<
  User,
  'password' | 'resetPasswordToken' | 'deletedAt'
>;

export enum PaymentMethodEnum {
  creditLimit = 'creditLimit',
  card = 'card',
}

export interface InvocationContext {
  user?: ContextUser;
  cartUuid?: string;
  orderUuid?: string;
  remember: boolean;
}

export type MapperDiffType<T, X> = 
  Omit<T, keyof X> 
  & {
    [K in keyof T & keyof X as 
      Resolver<X[K], X, InvocationContext> extends T[K]
        ? never
        : K
    ]: T[K]
  };

export const quoteStatuses = [
  {
    value: QuoteStatusEnum.completed,
    label: 'Completed',
  },
  {
    value: QuoteStatusEnum.processing,
    label: 'Processing',
  },
  {
    value: QuoteStatusEnum.pending,
    label: 'Pending',
  },
  {
    value: QuoteStatusEnum.cancelled,
    label: 'Cancelled',
  },
  {
    value: QuoteStatusEnum.expired,
    label: 'Expired',
  },
];

export const orderStatuses = [
  {
    value: OrderStatusEnum.completed,
    label: 'Completed',
  },
  {
    value: OrderStatusEnum.processing,
    label: 'Processing',
  },
  {
    value: OrderStatusEnum.pending,
    label: 'Pending',
  },
  {
    value: OrderStatusEnum.failed,
    label: 'Failed',
  },
  {
    value: OrderStatusEnum.cancelled,
    label: 'Cancelled',
  },
];

export * from './__generated__/graphql';

export * from './__generated__/typedefs';

export * from './helpers/enums';

export * from './helpers/date';
