import { useBackgroundQuery, useMutation } from '@apollo/client';
import {
  GqlIndexStripePaymentMethods,
  GqlRemoveStripePaymentMethod,
} from '@monorepo/graphql/resources';
import DataLayout, { LinkType, TableColumn } from './DataLayout';
import { FiCreditCard, FiPlus } from 'react-icons/fi';
import { useCallback, useMemo } from 'react';
import { IndexStripePaymentMethodsQuery } from '@monorepo/graphql';
import classNames from 'classnames';
import { elements } from '../utility/styles';
import { toaster } from '../utility/toast';
import { Button } from './Button';

enum TableColumnEnum {
  id = 'id',
  last4 = 'last4',
  brand = 'brand',
  expiry = 'expiry',
  actions = 'actions',
}

type PaymentMethod =
  IndexStripePaymentMethodsQuery['indexStripePaymentMethods'][0];

const PaymentMethodsList = () => {
  const [query] = useBackgroundQuery(GqlIndexStripePaymentMethods);

  const [deletePaymentMethod] = useMutation(GqlRemoveStripePaymentMethod, {
    update: (cache, { data: item }) => {
      cache.evict({ id: `PaymentMethod:${item?.removeStripePaymentMethod}` });

      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'indexStripePaymentMethods',
      });

      cache.gc();
    },
    onCompleted: () => {
      toaster.success(
        {
          title: 'Success',
          text: 'Payment method removed.',
        },
        { autoClose: 5000 }
      );
    },
  });

  const links = useMemo<LinkType[]>(
    () => [
      {
        to: '/my-account/payment-methods/add',
        label: 'Add Payment Method',
        Icon: FiPlus,
      },
    ],
    []
  );

  const extractItems = useCallback(
    (data: IndexStripePaymentMethodsQuery) => data.indexStripePaymentMethods,
    []
  );

  const tableColumns = useMemo<Array<TableColumn<PaymentMethod>>>(
    () => [
      {
        key: TableColumnEnum.last4,
        label: 'Last 4 Digits',
        handler: (item) => item.card.last4,
        style: { width: '30%' },
      },
      {
        key: TableColumnEnum.brand,
        label: 'Brand',
        handler: (item) => item.card.brand?.toUpperCase(),
        style: { width: '30%' },
      },
      {
        key: TableColumnEnum.expiry,
        label: 'Expiry',
        handler: (item) =>
          `${item.card.expMonth}/${item.card.expYear}${
            item.expired ? ' (Expired)' : ''
          }`,
        style: { width: '30%' },
      },
      {
        key: TableColumnEnum.actions,
        label: '',
        handler: (item) => (
          <Button
            type="button"
            hotjarEvent="RemovePaymentMethod"
            className={classNames(
              elements.button.secondary,
              'text-xs inline-flex'
            )}
            onClick={async () => {
              await deletePaymentMethod({
                variables: {
                  id: item.id,
                },
              });
            }}
          >
            Remove
          </Button>
        ),
        style: { width: '10%' },
      },
    ],
    [deletePaymentMethod]
  );

  return (
    <DataLayout
      Icon={FiCreditCard}
      title="Payment Methods"
      subtitle="Manage your payment methods below"
      tableColumns={tableColumns}
      links={links}
      extractItems={extractItems}
      query={query}
    />
  );
};

export default PaymentMethodsList;
