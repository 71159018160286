import Loader from './Loader';

interface Props {
  text?: string;
}

const PageLoader = ({ text }: Props) => (
  <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-white/75">
    <Loader message={text} />
  </div>
);

export default PageLoader;
