import { gql } from '@apollo/client';
import { GqlUser } from './user';
import { GqlPagination } from './pagination';
import {
  AddCouponMutation,
  AddCouponMutationVariables,
  DeleteCouponMutation,
  DeleteCouponMutationVariables,
  EditCouponMutation,
  EditCouponMutationVariables,
  IndexCouponsQuery,
  IndexCouponsQueryVariables,
  ReadCouponQuery,
  ReadCouponQueryVariables,
} from '../__generated__/graphql';

const GqlCoupon = gql`
  fragment CouponFragment on Coupon {
    uuid
    code
    amount
    description
    usageCount
    expiresAt
    freeShipping
    creator {
      ...UserFragment
    }
  }
  ${GqlUser}
`;

export const GqlIndexCoupons = gql<
  IndexCouponsQuery,
  IndexCouponsQueryVariables
>`
  query IndexCoupons($input: CouponFilterInput) {
    indexCoupons(input: $input) {
      pagination {
        ...PaginationFragment
      }
      items {
        ...CouponFragment
      }
    }
  }
  ${GqlCoupon}
  ${GqlPagination}
`;

export const GqlReadCoupon = gql<ReadCouponQuery, ReadCouponQueryVariables>`
  query ReadCoupon($uuid: ID!) {
    readCoupon(uuid: $uuid) {
      ...CouponFragment
    }
  }
  ${GqlCoupon}
`;

export const GqlAddCoupon = gql<AddCouponMutation, AddCouponMutationVariables>`
  mutation AddCoupon($input: AddCouponInput!) {
    addCoupon(input: $input) {
      ...CouponFragment
    }
  }
  ${GqlCoupon}
`;

export const GqlEditCoupon = gql<
  EditCouponMutation,
  EditCouponMutationVariables
>`
  mutation EditCoupon($input: EditCouponInput!) {
    editCoupon(input: $input) {
      ...CouponFragment
    }
  }
  ${GqlCoupon}
`;

export const GqlDeleteCoupon = gql<
  DeleteCouponMutation,
  DeleteCouponMutationVariables
>`
  mutation DeleteCoupon($uuid: ID!) {
    deleteCoupon(uuid: $uuid)
  }
`;
