import { createFileRoute } from '@tanstack/react-router';
import {
  FiUser,
  FiCreditCard,
  FiShoppingCart,
  FiBriefcase,
} from 'react-icons/fi';
import PageLayout from '../../components/PageLayout';
import MenuLink from '../../components/MenuLink';
import NotFound from '../../components/NotFound';

export const Route = createFileRoute('/_authenticated/my-account')({
  component: LayoutComponent,
  notFoundComponent: () => <NotFound to="/my-account" />,
});

function LayoutComponent() {
  return (
    <PageLayout sidebarClassNames="bg-black">
      <ul>
        <MenuLink
          route="/my-account/orders"
          label="Orders"
          Icon={FiShoppingCart}
        />
        <MenuLink
          route="/my-account/quotes"
          label="Quotes"
          Icon={FiBriefcase}
        />
        <MenuLink
          route="/my-account/edit-account"
          label="Profile"
          Icon={FiUser}
        />
        <MenuLink
          route="/my-account/payment-methods"
          label="Payment Methods"
          Icon={FiCreditCard}
        />
      </ul>
    </PageLayout>
  );
}
