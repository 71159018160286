import { ButtonProps, Button as HButton } from '@headlessui/react';
import { Children, isValidElement, ReactNode } from 'react';
import { hotjar } from 'react-hotjar';

function extractTextFromChildren(children: ReactNode) {
  let text = '';

  Children.forEach(children, (child) => {
    if (typeof child === 'string' || typeof child === 'number') {
      text += child;
    } else if (isValidElement<{ children: ReactNode }>(child)) {
      text += extractTextFromChildren(child.props.children);
    }
  });

  return text;
}

export function Button<TTag extends 'button' | 'a' = 'button'>({
  hotjarEvent,
  ...props
}: ButtonProps<TTag> & {
  hotjarEvent?: string;
  children: ReactNode;
  type: 'button' | 'submit' | 'reset';
  href?: string;
  as?: 'button' | 'a';
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  target?: string;
  download?: string;
}) {
  const event =
    hotjarEvent ??
    props['data-tooltip-content'] ??
    (props.children ? extractTextFromChildren(props.children) : undefined);

  return (
    <HButton
      {...props}
      onClick={
        event
          ? (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
              hotjar.event(event);

              props.onClick?.(e);
            }
          : props.onClick
      }
    />
  );
}
