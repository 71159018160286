import { gql } from '@apollo/client';
import { GqlPagination } from './pagination';
import {
  CreateInternalProductMutation,
  CreateInternalProductMutationVariables,
  DeleteInternalProductMutation,
  DeleteInternalProductMutationVariables,
  DeleteProductUploadMutation,
  DeleteProductUploadMutationVariables,
  IndexInternalProductsQuery,
  IndexInternalProductsQueryVariables,
  IndexProductsQuery,
  IndexProductsQueryVariables,
  ReadInternalProductQuery,
  ReadInternalProductQueryVariables,
  ReadProductQuery,
  ReadProductQueryVariables,
  UpdateInternalProductMutation,
  UpdateInternalProductMutationVariables,
} from '../__generated__/graphql';

export const GqlProduct = gql`
  fragment ProductFragment on Product {
    id
    uuid
    name
    sku
    accountingReference
    stockManaged
    remainingStock
    stockStatus
    weight
    width
    height
    length
    hireable
    soldIndividually
    regularPrices {
      currency
      amount
      customerAccountReference
    }
    salePrices {
      currency
      amount
      customerAccountReference
    }
    hirePrices {
      currency
      amount
      customerAccountReference
    }
    upSells
    crossSells
    meta {
      key
      value
    }
    datasheet {
      id
      name
      url
    }
    manual {
      id
      name
      url
    }
    leadTime
    isPublic
    portalIds
    uploads {
      type
      url
      fileUuid
      file {
        createdAt
      }
    }
  }
`;

export const GqlInternalProduct = gql`
  fragment InternalProductFragment on InternalProduct {
    uuid
    name
    sku
    productId
    uploads {
      uuid
      type
      url
      fileUuid
      file {
        createdAt
      }
    }
    meta {
      key
      value
    }
  }
`;

export const GqlIndexProducts = gql<
  IndexProductsQuery,
  IndexProductsQueryVariables
>`
  query IndexProducts($input: ProductFilterInput!) {
    indexProducts(input: $input) {
      pagination {
        ...PaginationFragment
      }
      items {
        ...ProductFragment
      }
    }
  }
  ${GqlPagination}
  ${GqlProduct}
`;

export const GqlIndexInternalProducts = gql<
  IndexInternalProductsQuery,
  IndexInternalProductsQueryVariables
>`
  query IndexInternalProducts($input: ProductFilterInput!) {
    indexInternalProducts(input: $input) {
      pagination {
        ...PaginationFragment
      }
      items {
        ...InternalProductFragment
      }
    }
  }
  ${GqlPagination}
  ${GqlInternalProduct}
`;

export const GqlReadProduct = gql<ReadProductQuery, ReadProductQueryVariables>`
  query ReadProduct($id: Int!) {
    readProduct(id: $id) {
      ...ProductFragment
    }
  }
  ${GqlProduct}
`;

export const GqlReadInternalProduct = gql<
  ReadInternalProductQuery,
  ReadInternalProductQueryVariables
>`
  query ReadInternalProduct($uuid: ID!) {
    readInternalProduct(uuid: $uuid) {
      ...InternalProductFragment
    }
  }
  ${GqlInternalProduct}
`;

export const GqlCreateInternalProduct = gql<
  CreateInternalProductMutation,
  CreateInternalProductMutationVariables
>`
  mutation CreateInternalProduct($input: CreateInternalProductInput!) {
    createInternalProduct(input: $input) {
      ...InternalProductFragment
    }
  }
  ${GqlInternalProduct}
`;

export const GqlUpdateInternalProduct = gql<
  UpdateInternalProductMutation,
  UpdateInternalProductMutationVariables
>`
  mutation UpdateInternalProduct($input: UpdateInternalProductInput!) {
    updateInternalProduct(input: $input) {
      ...InternalProductFragment
    }
  }
  ${GqlInternalProduct}
`;

export const GqlDeleteInternalProduct = gql<
  DeleteInternalProductMutation,
  DeleteInternalProductMutationVariables
>`
  mutation DeleteInternalProduct($uuid: ID!) {
    deleteInternalProduct(uuid: $uuid)
  }
`;

export const GqlDeleteProductUpload = gql<
  DeleteProductUploadMutation,
  DeleteProductUploadMutationVariables
>`
  mutation DeleteProductUpload($uuid: ID!) {
    deleteProductUpload(uuid: $uuid)
  }
`;
