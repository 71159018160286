import { useState } from 'react';
import { elements } from '../utility/styles';
import RadioCard from './RadioCard';
import { FiArrowRight, FiLock, FiLogOut, FiUser } from 'react-icons/fi';
import { LoginFields } from './LoginFields';
import { Form, Formik } from 'formik';
import Input from './Input';
import { Button } from './Button';

const LoginStep = ({
  choice,
  setChoice,
  onLogin,
  onContinue,
  setPassword,
}: {
  choice: 'login' | 'guest' | undefined;
  setChoice: (c: 'login' | 'guest' | undefined) => void;
  onLogin?: () => void;
  onContinue: () => void;
  setPassword: (p?: string) => void;
}) => {
  const [createAccount, setCreateAccount] = useState(false);

  return (
    <div>
      <label className={elements.inputLabel}>Checkout type</label>
      <div className="flex flex-wrap -mx-1">
        <div className="px-1 w-full lg:w-1/2 mb-5 lg:mb-0">
          <RadioCard
            checked={choice === 'login'}
            Icon={FiLogOut}
            onChange={() => setChoice('login')}
            name="checkoutType"
            value="login"
            title="Log in"
            description="Use your account"
          />
        </div>
        <div className="px-1 w-full lg:w-1/2">
          <RadioCard
            checked={choice === 'guest'}
            Icon={FiUser}
            onChange={() => setChoice('guest')}
            name="checkoutType"
            value="guest"
            title="Guest checkout"
            description="Continue without logging in"
          />
        </div>
      </div>
      {choice === 'login' && (
        <div className="mt-6">
          <LoginFields onLogin={onLogin} />
        </div>
      )}
      {choice === 'guest' && (
        <Formik
          initialValues={{ password: '' }}
          onSubmit={(values) => {
            setPassword(createAccount ? values.password : undefined);

            onContinue();
          }}
        >
          <Form className="mt-6">
            <div className="mb-8">
              <div className="flex items-center">
                <div className="mr-2 flex items-center">
                  <input
                    type="checkbox"
                    name="createAccount"
                    id="createAccount"
                    checked={createAccount}
                    onChange={(e) => setCreateAccount(e.target.checked)}
                  />
                </div>
                <label className="block" htmlFor="createAccount">
                  Automatically create an account during checkout
                </label>
              </div>
            </div>
            {createAccount && (
              <Input
                label="Password"
                Icon={FiLock}
                type="password"
                name="password"
                containerClassName="mb-6"
                required
                autoFocus
              />
            )}
            <Button
              type="submit"
              className={elements.button.primary}
              hotjarEvent="Checkout_ContinueFromLogin"
            >
              Continue <FiArrowRight className="ml-2" size={24} />
            </Button>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default LoginStep;
