import {
  useContext,
  createContext,
  ReactNode,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useSuspenseQuery } from '@apollo/client';
import { MeQuery } from '@monorepo/graphql';
import { GqlMeQuery } from '@monorepo/graphql/resources';
import { CookieChangeListener } from 'universal-cookie';
import { jsCookie } from './cookie';
import { hotjar } from 'react-hotjar';

export interface AuthContext {
  user?: MeQuery['me'];
  displayName?: string;
  firstName?: string;
  apiToken?: string;
}

export const writeApiToken = (token: string) => {
  jsCookie.set('apiToken', token);
};

export const clearApiToken = (redirectUrl?: string) => {
  jsCookie.remove('apiToken');
  if (redirectUrl) {
    localStorage.setItem('redirectUrl', redirectUrl);
  }
};

const getApiTokenFromSearchParams = () =>
  new URLSearchParams(window.location.search).get('token');

const getApiTokenFromCookie = () =>
  jsCookie.get('apiToken') as string | undefined;

export const getApiToken: () => string | undefined = () =>
  getApiTokenFromSearchParams() ?? getApiTokenFromCookie();

const AuthContext = createContext<AuthContext | null>(null);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [apiToken, setApiToken] = useState<string | undefined>(getApiToken());

  const { data, refetch } = useSuspenseQuery(GqlMeQuery, {
    skip: !apiToken,
  });

  const prevToken = useRef<string | undefined>(apiToken);

  useEffect(() => {
    const apiTokenFromSearchParams = getApiTokenFromSearchParams();
    const apiTokenFromCookie = getApiTokenFromCookie();
    if (
      apiTokenFromSearchParams &&
      apiTokenFromSearchParams !== apiTokenFromCookie
    ) {
      writeApiToken(apiTokenFromSearchParams);
    }
    const onCookieChange: CookieChangeListener = (e) => {
      if (e.name === 'apiToken') {
        setApiToken(e.value ? String(e.value) : undefined);
      }
    };

    jsCookie.addChangeListener(onCookieChange);
    return () => {
      jsCookie.removeChangeListener(onCookieChange);
    };
  }, []);

  useEffect(() => {
    if (prevToken.current && prevToken.current !== apiToken) {
      void refetch();
    }
    prevToken.current = apiToken;
  }, [apiToken, refetch]);

  useEffect(() => {
    if (data?.me.customer?.uuid) {
      hotjar.identify(data.me.customer.uuid, { userProperty: 'value' });
    }
  }, [data]);

  const user = data?.me ?? undefined;
  const firstName = user?.firstName ?? user?.customer?.firstName;
  const displayName =
    user?.displayName ??
    user?.customer?.displayName ??
    user?.customer?.email ??
    user?.email;

  return (
    <AuthContext.Provider
      value={{
        user,
        displayName,
        firstName,
        apiToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export interface UserContext {
  user: MeQuery['me'];
}

const UserContext = createContext<UserContext>({} as UserContext);

export function UserProvider({ children }: { children: ReactNode }) {
  const { data } = useSuspenseQuery(GqlMeQuery);

  return (
    <UserContext.Provider value={{ user: data.me }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);

  return context;
}
