
  import gql from "graphql-tag";
  export const typeDefs = gql`schema {
  query: Query
  mutation: Mutation
}

input AddCouponInput {
  amount: Float!
  code: String!
  description: String
  expiresAt: DateTime
  freeShipping: Boolean
}

input AddCustomerInput {
  accountingReference: String
  billingAddress: AddressInput
  email: EmailAddress!
  firstName: String!
  lastName: String!
  shippingAddress: AddressInput
}

input AddOrderNoteInput {
  note: String!
  orderUuid: ID!
}

input AddProductToCartInput {
  amount: Float!
  currency: CurrencyEnum!
  height: Float!
  imageUrl: URL
  isSaleItem: Boolean!
  leadTime: String
  length: Float!
  links: [LineItemLinkWithUrlInput!]!
  meta: [MetaDataInput!]!
  name: String!
  originalAmount: Float!
  productId: Int!
  qty: Int!
  signature: String!
  sku: String!
  weight: Float!
  width: Float!
}

input AddQuoteNoteInput {
  note: String!
  quoteUuid: ID!
}

type Address {
  city: String!
  company: String
  country: String!
  email: String!
  firstName: String
  lastName: String
  line1: String!
  line2: String
  phone: String
  postCode: String
  state: String
  uuid: ID
}

input AddressInput {
  city: String!
  company: String
  country: String!
  email: String!
  firstName: String
  lastName: String
  line1: String!
  line2: String
  phone: String
  postCode: String
  state: String
}

type Admin {
  displayName: String!
  email: EmailAddress!
  id: Int!
}

type Attachment {
  id: Int!
  name: String!
  url: URL
}

input BulkAddProductToCart {
  amount: Float!
  height: Float!
  imageUrl: URL
  isSaleItem: Boolean!
  leadTime: String
  length: Float!
  links: [LineItemLinkWithUrlInput!]!
  meta: [MetaDataInput!]!
  name: String!
  originalAmount: Float!
  productId: Int!
  qty: Int!
  sku: String!
  weight: Float!
  width: Float!
}

input BulkAddProductsToCart {
  currency: CurrencyEnum!
  products: [BulkAddProductToCart!]!
  signature: String!
}

type CartData {
  billingAddress: Address!
  coupon: Coupon
  currency: CurrencyEnum!
  customerUuid: ID
  lineItems: [CartLineItem!]!
  orderUuid: ID
  paymentIntentClientSecret: String
  paymentProcessing: Boolean!
  purchaseOrderFileUuid: ID
  purchaseOrderReference: String
  shippingAddress: Address!
  shippingMethod: ShippingMethodEnum!
  userUuid: ID
}

type CartLineItem {
  amount: Float!
  height: Float!
  imageUrl: URL
  isSaleItem: Boolean!
  leadTime: String
  length: Float!
  links: [CartLineItemLink!]!
  meta: [MetaData!]!
  originalAmount: Float!
  productId: Int
  quantity: Int!
  shippingMethod: ShippingMethodEnum
  sku: String
  taxRate: Float!
  title: String!
  type: LineItemTypeEnum!
  uuid: ID!
  weight: Float!
  width: Float!
}

type CartLineItemLink {
  type: ProductUploadType!
  url: URL!
}

type CartPage {
  authTokens: Tokens
  certificateInCart: Boolean!
  count: Int!
  data: CartData!
  errorNotices: [String!]!
  role: RoleEnum
  shippingMethods: [ShippingMethodOption!]!
  uuid: ID!
}

input ChangePasswordInput {
  newPassword: String!
  password: String!
}

type CheckoutCompletePage {
  authTokens: Tokens!
  order: Order!
}

input CompleteCheckoutInput {
  password: String
  paymentMethodId: String
}

input CompleteQuoteInput {
  password: String
  paymentMethodId: String
  quoteUuid: ID!
}

input CompleteResetPasswordInput {
  email: String!
  newPassword: String!
  token: String!
}

type ContextUser {
  customer: Customer
  displayName: String!
  email: EmailAddress!
  firstName: String!
  lastName: String!
  legacyId: Int
  role: RoleEnum!
  telephone: String
  uuid: ID
}

type Coupon {
  amount: Float!
  code: String!
  creator: User!
  description: String
  expiresAt: DateTime
  freeShipping: Boolean
  usageCount: Int!
  uuid: ID!
}

input CouponFilterInput {
  orderBy: CouponOrderByEnum
  pagination: PaginationInput
  term: String
}

enum CouponOrderByEnum {
  codeAsc
  codeDesc
}

type CouponsPage {
  items: [Coupon!]!
  pagination: PaginationResponse!
}

input CreateFileInput {
  isCart: Boolean!
  key: String!
  legacyProduct: LegacyProductFileInput
  mimeType: String!
  name: String!
  product: ProductFileInput
}

input CreateInternalProductInput {
  meta: [MetaDataInput!]
  name: String!
  productId: Int
  sku: String!
  uploads: [ProductUploadInput!]
}

input CreateMessageTemplateInput {
  name: String!
  path: String!
}

input CreateQuoteInput {
  billingAddress: AddressInput
  currency: CurrencyEnum
  customer: CustomerInput!
  customerReference: String!
  emailMessage: String!
  expiresAt: DateTime
  inTouchReference: String
  lineItems: [LineItemInput!]!
  purchaseOrderFileUuid: ID
  purchaseOrderReference: String
  recipients: [QuoteRecipientInput!]
  shippingAddress: AddressInput
  shippingMethod: ShippingMethodEnum
  status: QuoteStatusEnum
}

input CreateUserInput {
  email: EmailAddress!
  firstName: String!
  lastName: String!
  password: String!
  telephone: String
}

enum CurrencyEnum {
  chf
  eur
  gbp
  usd
}

type Customer {
  accountingReference: String
  billingAddress: Address
  cart: CartData
  displayName: String!
  email: EmailAddress!
  firstName: String!
  lastName: String!
  legacyId: Int
  shippingAddress: Address
  uuid: ID!
}

input CustomerFilterInput {
  orderBy: CustomerOrderByEnum
  pagination: PaginationInput
  term: String
}

input CustomerInput {
  accountingReference: String
  billingAddress: AddressInput
  email: EmailAddress
  firstName: String
  lastName: String
  legacyId: Int
  shippingAddress: AddressInput
  uuid: ID
}

enum CustomerOrderByEnum {
  accountingReferenceAsc
  accountingReferenceDesc
  emailAsc
  emailDesc
  firstNameAsc
  firstNameDesc
  lastNameAsc
  lastNameDesc
}

type CustomerPage {
  items: [Customer!]!
  pagination: PaginationResponse!
}

scalar DateTime

input EditCouponInput {
  amount: Float
  code: String
  description: String
  expiresAt: DateTime
  freeShipping: Boolean
  uuid: ID!
}

input EditCustomerInput {
  accountingReference: String
  billingAddress: AddressInput
  email: EmailAddress
  firstName: String
  lastName: String
  shippingAddress: AddressInput
  uuid: ID!
}

input EditFileInput {
  fileUuid: ID!
  name: String
}

input EditOrderInput {
  inTouchReference: String
  status: OrderStatusEnum
  trackingReference: String
  uuid: ID!
}

input EditOrderNoteInput {
  note: String!
  uuid: ID!
}

input EditQuoteInput {
  billingAddress: AddressInput
  currency: CurrencyEnum
  customer: CustomerInput
  customerReference: String
  emailMessage: String
  expiresAt: DateTime
  inTouchReference: String
  lineItems: [LineItemInput!]
  paymentMethodId: String
  purchaseOrderFileUuid: ID
  purchaseOrderReference: String
  recipients: [QuoteRecipientInput!]
  shippingAddress: AddressInput
  shippingMethod: ShippingMethodEnum
  status: QuoteStatusEnum
  uuid: ID!
}

input EditQuoteNoteInput {
  note: String!
  uuid: ID!
}

input EditUserInput {
  email: EmailAddress
  firstName: String
  lastName: String
  telephone: String
  uuid: ID!
}

scalar EmailAddress

type EmailToken {
  email: String!
  expiresAt: DateTime!
  uuid: ID!
}

type File {
  createdAt: DateTime!
  creator: User
  key: String!
  mimeType: String!
  name: String!
  uuid: ID!
}

enum FileAccess {
  private
  public
}

input FileUploadRequest {
  access: FileAccess!
  mimeType: String!
  name: String!
}

input GetQuoteStripePaymentIntentClientSecretInput {
  paymentMethodId: String
  quoteUuid: ID!
}

type InternalProduct {
  meta: [MetaData!]!
  name: String!
  productId: Int
  sku: String!
  uploads: [ProductUpload!]!
  uuid: ID!
}

type InternalProductPage {
  items: [InternalProduct!]!
  pagination: PaginationResponse!
}

scalar JSONObject

type LegacyAttachment {
  id: Int!
  name: String!
  url: URL!
}

type LegacyCustomer {
  billingAddress: Address
  displayName: String!
  email: EmailAddress!
  firstName: String!
  id: Int
  lastName: String!
  phone: String
  shippingAddress: Address
}

input LegacyCustomerFilterInput {
  pagination: PaginationInput
  term: String
}

type LegacyCustomerPage {
  items: [LegacyCustomer!]!
  pagination: PaginationResponse!
}

type LegacyLineItem {
  amount: Float!
  id: Int!
  meta: [MetaData!]!
  name: String!
  productId: Int
  qty: Int!
  sku: String
  type: LegacyLineItemTypeEnum!
}

enum LegacyLineItemTypeEnum {
  discount
  fee
  product
  shipping
  tax
}

type LegacyNote {
  createdAt: DateTime!
  text: String!
}

type LegacyOrder {
  admin: Admin
  attachments: [LegacyAttachment!]!
  billingAddress: Address!
  createdAt: DateTime!
  currency: CurrencyEnum!
  customer: LegacyCustomer
  customerReference: String!
  emailMessage: String
  id: Int!
  isQuote: Boolean!
  leadTime: String
  lineItems: [LegacyLineItem!]!
  notes: [LegacyNote!]!
  paymentMethod: String!
  purchaseOrderReference: String
  purchaseOrderUrl: URL
  reference: String!
  shippingAddress: Address!
  shippingMethod: String!
  status: LegacyOrderStatusEnum!
  total: Float!
  trackingReference: String
}

input LegacyOrderFilterFunctionInput {
  customerId: Int
  from: DateTime
  productTerm: String
  statuses: [LegacyOrderStatusEnum!]
  term: String
  to: DateTime
}

input LegacyOrderFilterInput {
  filters: LegacyOrderFilterFunctionInput
  orderBy: LegacyOrderOrderByEnum
  pagination: PaginationInput
}

enum LegacyOrderOrderByEnum {
  createdAtAsc
  createdAtDesc
  statusAsc
  statusDesc
}

type LegacyOrderPage {
  items: [LegacyOrder!]!
  pagination: PaginationResponse!
}

enum LegacyOrderStatusEnum {
  acceptedQuote
  cancelled
  completed
  exiredQuote
  failed
  onHold
  pendingPayment
  pendingQuote
  processing
  refunded
  rejectedQuote
}

input LegacyProductFileInput {
  id: Int!
  sku: String!
  type: ProductUploadType!
}

input LineItemInput {
  amount: Float!
  discountPercentage: Float
  originalAmount: Float!
  products: [LineItemProductInput!]
  quantity: Int!
  shippingMethod: ShippingMethodEnum
  taxRate: Float!
  title: String!
  type: LineItemTypeEnum!
  uuid: ID
}

input LineItemLinkWithUrlInput {
  type: ProductUploadType!
  url: URL!
}

input LineItemProductInput {
  isSaleItem: Boolean!
  leadTime: String!
  links: [ProductUploadInput!]
  meta: [MetaDataInput!]
  productId: Int
  productUuid: ID
  sku: String
  title: String!
  uuid: ID
}

enum LineItemTypeEnum {
  discount
  product
  shipping
}

type MessageTemplate {
  html: String!
  name: String!
  path: String!
  uuid: ID!
}

type MetaData {
  key: String!
  value: String!
}

input MetaDataInput {
  key: String!
  value: String!
}

type Mutation {
  abortCheckoutPayment(reason: String): CartPage!
  abortQuoteCheckoutPayment(quoteUuid: ID!, reason: String): Quote!
  addCoupon(input: AddCouponInput!): Coupon!
  addCustomer(input: AddCustomerInput!): Customer!
  addOrderNote(input: AddOrderNoteInput!): OrderNote!
  addProductToCart(input: AddProductToCartInput!): CartPage!
  addQuoteNote(input: AddQuoteNoteInput!): QuoteNote!
  addStripePaymentMethod(paymentMethodId: String!): StripePaymentMethod!
  assignedCouponToCart(coupon: String!): CartPage!
  bulkAddProductsToCart(input: BulkAddProductsToCart!): CartPage!
  changePassword(input: ChangePasswordInput!): Void
  clearCart: CartPage!
  completeCheckout(input: CompleteCheckoutInput!): CheckoutCompletePage!
  completeQuote(input: CompleteQuoteInput!): Quote!
  completeResetPassword(input: CompleteResetPasswordInput!): User!
  convertCartToQuote(customerUuid: ID!): Quote!
  convertLegacyOrderToQuote(id: Int!): Quote!
  createFile(input: CreateFileInput!): File!
  createInternalProduct(input: CreateInternalProductInput!): InternalProduct!
  createMessageTemplate(input: CreateMessageTemplateInput!): MessageTemplate!
  createQuote(input: CreateQuoteInput!): Quote!
  createUser(input: CreateUserInput!): User!
  deleteCoupon(uuid: ID!): ID!
  deleteCustomer(uuid: ID!): ID!
  deleteEmailToken(uuid: ID!): ID!
  deleteFile(uuid: ID!): ID!
  deleteInternalProduct(uuid: ID!): ID!
  deleteMessageTemplate(uuid: ID!): ID!
  deleteOrderNote(orderNoteUuid: ID!): ID!
  deleteProductUpload(uuid: ID!): ID!
  deleteQuote(quoteUuid: ID!): ID!
  deleteQuoteNote(quoteNoteUuid: ID!): ID!
  deleteUser(uuid: ID!): ID!
  editCoupon(input: EditCouponInput!): Coupon!
  editCustomer(input: EditCustomerInput!): Customer!
  editFile(input: EditFileInput!): File!
  editOrder(input: EditOrderInput!): Order!
  editOrderNote(input: EditOrderNoteInput!): OrderNote!
  editQuote(input: EditQuoteInput!): Quote!
  editQuoteNote(input: EditQuoteNoteInput!): QuoteNote!
  editUser(input: EditUserInput!): User!
  removeItemFromCart(itemUuid: ID!): CartPage!
  removeStripePaymentMethod(id: String!): ID!
  resetPasswordRequest(email: String!): Void
  selectShippingMethod(method: ShippingMethodEnum!): CartPage!
  sendOrderEmail(orderUuid: ID!): Boolean!
  sendQuoteEmail(quoteUuid: ID!): Boolean!
  signIn(input: SignInInput!): Tokens!
  updateCartBillingAddress(input: AddressInput!): CartPage!
  updateCartItemQty(itemUuid: ID!, qty: Int!): CartPage!
  updateCartShippingAddress(input: AddressInput!): CartPage!
  updateInternalProduct(input: UpdateInternalProductInput!): InternalProduct!
  updateMessageTemplate(input: UpdateMessageTemplateInput!): MessageTemplate!
  updatePurchaseOrderDetails(input: PurchaseOrderDetailsInput!): CartPage!
}

enum NoteTypeEnum {
  freeText
  notification
  statusChange
}

type Order {
  accountingReference: String!
  billingAddress: Address!
  coupon: Coupon
  createdAt: DateTime!
  currency: CurrencyEnum!
  customer: Customer
  inTouchReference: String
  lineItems: [OrderLineItem!]!
  notes: [OrderNote!]!
  paymentMethodId: String
  paymentMethodLabel: String
  paymentProcessing: Boolean!
  purchaseOrderFileUuid: ID
  purchaseOrderReference: String
  shippingAddress: Address!
  shippingMethod: ShippingMethodEnum!
  status: OrderStatusEnum!
  total: Float!
  trackingReference: String
  updatedAt: DateTime!
  uuid: ID!
}

input OrderFilterFunctionInput {
  customerUuid: ID
  from: DateTime
  productTerm: String
  statuses: [OrderStatusEnum!]
  term: String
  to: DateTime
}

input OrderFilterInput {
  filters: OrderFilterFunctionInput
  orderBy: OrderOrderByEnum
  pagination: PaginationInput
}

type OrderLineItem {
  amount: Float!
  height: Float!
  isSaleItem: Boolean!
  leadTime: String
  length: Float!
  links: [CartLineItemLink!]!
  meta: [MetaData!]!
  originalAmount: Float!
  productId: Int
  quantity: Int!
  shippingMethod: ShippingMethodEnum
  sku: String
  taxRate: Float!
  title: String!
  type: LineItemTypeEnum!
  uuid: ID!
  weight: Float!
  width: Float!
}

type OrderNote {
  createdAt: DateTime!
  text: String!
  type: NoteTypeEnum!
  uuid: ID!
}

enum OrderOrderByEnum {
  amountAsc
  amountDesc
  createdAtAsc
  createdAtDesc
  statusAsc
  statusDesc
}

type OrderPage {
  items: [Order!]!
  pagination: PaginationResponse!
}

enum OrderStatusEnum {
  cancelled
  completed
  failed
  pending
  processing
}

input PaginationInput {
  page: Int!
  perPage: Int!
}

type PaginationResponse {
  currentPage: Int!
  from: Int!
  lastPage: Int!
  perPage: Int!
  to: Int!
  total: Int!
}

type Price {
  amount: Float!
  currency: CurrencyEnum!
  customerAccountReference: String
}

type Product {
  accountingReference: String
  crossSells: [Int!]!
  datasheet: Attachment
  height: Int
  hirePrices: [Price!]!
  hireable: Boolean!
  id: Int
  isPublic: Boolean!
  leadTime: String
  length: Float
  manual: Attachment
  meta: [MetaData!]!
  name: String!
  portalIds: [Int!]!
  regularPrices: [Price!]!
  remainingStock: Int
  salePrices: [Price!]!
  sku: String!
  soldIndividually: Boolean!
  stockManaged: Boolean!
  stockStatus: StockStatusEnum!
  upSells: [Int!]!
  uploads: [ProductUpload!]!
  uuid: ID
  weight: Float
  width: Float
}

input ProductFileInput {
  name: String
  sku: String
  type: ProductUploadType!
  uuid: ID
}

input ProductFilterInput {
  orderBy: ProductOrderByEnum
  pagination: PaginationInput
  term: String
}

enum ProductOrderByEnum {
  nameAsc
  nameDesc
  skuAsc
  skuDesc
}

type ProductPage {
  items: [Product!]!
  pagination: PaginationResponse!
}

type ProductUpload {
  file: File
  fileUuid: ID
  type: ProductUploadType!
  url: URL
  uuid: ID!
}

input ProductUploadInput {
  fileUuid: ID
  type: ProductUploadType!
  url: URL
}

enum ProductUploadType {
  datasheet
  manual
}

input PurchaseOrderDetailsInput {
  purchaseOrderFileUuid: ID
  purchaseOrderReference: String
}

type Query {
  getCart: CartPage!
  getCartCount: Int!
  getQuoteStripePaymentIntentClientSecret(input: GetQuoteStripePaymentIntentClientSecretInput!): String!
  getSignedUrl(query: String, uuids: [ID!]!): [URL!]!
  indexCoupons(input: CouponFilterInput): CouponsPage!
  indexCustomers(input: CustomerFilterInput): CustomerPage!
  indexEmailTokens: [EmailToken!]!
  indexInternalProducts(input: ProductFilterInput!): InternalProductPage!
  indexLegacyCustomers(input: LegacyCustomerFilterInput): LegacyCustomerPage!
  indexLegacyOrders(input: LegacyOrderFilterInput): LegacyOrderPage!
  indexMessageTemplates: [MessageTemplate!]!
  indexOrders(input: OrderFilterInput): OrderPage!
  indexProducts(input: ProductFilterInput!): ProductPage!
  indexQuotes(input: QuoteFilterInput): QuotePage!
  indexStripePaymentMethods: [StripePaymentMethod!]!
  indexUsers(input: UserFilterInput!): UserPage!
  me: ContextUser!
  readCoupon(uuid: ID!): Coupon!
  readCustomer(uuid: ID!): Customer!
  readInternalProduct(uuid: ID!): InternalProduct!
  readLegacyOrder(id: Int!): LegacyOrder!
  readMessageTemplate(uuid: ID!): MessageTemplate!
  readOrder(uuid: ID!): Order!
  readProduct(id: Int!): Product!
  readQuote(uuid: ID!): Quote!
  readUser(uuid: ID!): User!
  uploadFileInstructions(files: [FileUploadRequest!]!): [UploadInstruction!]!
  verifyResetPasswordToken(token: String!): User!
}

type Quote {
  accountingReference: String!
  billingAddress: Address
  createdAt: DateTime!
  creator: User
  currency: CurrencyEnum!
  customer: Customer
  customerReference: String
  emailMessage: String
  expiresAt: DateTime!
  inTouchReference: String
  lineItems: [QuoteLineItem!]!
  notes: [QuoteNote!]!
  paymentIntentId: String
  paymentMethodId: String
  paymentMethodLabel: String
  paymentProcessing: Boolean
  purchaseOrderFileUuid: ID
  purchaseOrderReference: String
  recipients: [QuoteRecipient!]!
  revisionNumber: Int
  shippingAddress: Address
  shippingMethod: ShippingMethodEnum!
  status: QuoteStatusEnum!
  total: Float!
  updatedAt: DateTime!
  uuid: ID!
}

input QuoteFilterFunctionInput {
  creatorUuid: ID
  customerUuid: ID
  from: DateTime
  productTerm: String
  statuses: [QuoteStatusEnum!]
  term: String
  to: DateTime
}

input QuoteFilterInput {
  filters: QuoteFilterFunctionInput
  orderBy: OrderOrderByEnum
  pagination: PaginationInput
}

type QuoteLineItem {
  amount: Float!
  discountPercentage: Float
  originalAmount: Float!
  products: [QuoteLineItemProduct!]!
  quantity: Int!
  shippingMethod: ShippingMethodEnum
  taxRate: Float!
  title: String!
  type: LineItemTypeEnum!
  uuid: ID!
}

type QuoteLineItemLink {
  file: File
  fileUuid: ID
  type: ProductUploadType!
  url: URL!
}

type QuoteLineItemProduct {
  isSaleItem: Boolean!
  leadTime: String!
  lineItemUuid: ID!
  links: [QuoteLineItemLink!]!
  meta: [MetaData!]!
  productId: Int
  productUuid: ID
  sku: String
  title: String!
  uuid: ID!
}

type QuoteNote {
  createdAt: DateTime!
  text: String!
  type: NoteTypeEnum!
  uuid: ID!
}

type QuotePage {
  items: [Quote!]!
  pagination: PaginationResponse!
}

type QuoteRecipient {
  email: EmailAddress!
  group: RecipientGroup!
}

input QuoteRecipientInput {
  email: EmailAddress!
  group: RecipientGroup!
}

enum QuoteStatusEnum {
  cancelled
  completed
  expired
  pending
  processing
}

enum RecipientGroup {
  external
  internal
}

enum RoleEnum {
  admin
  customer
  guest
}

enum ShippingMethodEnum {
  collect
  international
  uk
}

type ShippingMethodOption {
  amount: Float!
  expiresAt: DateTime
  taxRate: Float!
  type: ShippingMethodEnum!
}

input SignInInput {
  email: String!
  password: String!
  remember: Boolean
}

enum StockStatusEnum {
  instock
  onBackorder
  outOfStock
}

type StripeAddress {
  city: String
  country: String
  line1: String
  line2: String
  postalCode: String
  state: String
}

type StripeHostedBillingDetails {
  address: StripeAddress
  email: EmailAddress
  name: String
  phone: String
}

type StripeHostedCardDetails {
  brand: String
  country: String
  expMonth: Int
  expYear: Int
  fingerprint: String
  funding: String
  last4: String
}

type StripePaymentMethod {
  billingDetails: StripeHostedBillingDetails!
  card: StripeHostedCardDetails!
  expired: Boolean!
  id: String!
}

type Tokens {
  jwt: String!
  refreshToken: String!
}

scalar URL

input UpdateInternalProductInput {
  meta: [MetaDataInput!]
  name: String
  productId: Int
  sku: String
  uploads: [ProductUploadInput!]
  uuid: ID!
}

input UpdateMessageTemplateInput {
  name: String
  path: String
  uuid: ID!
}

input UpdateUserInput {
  email: EmailAddress
  firstName: String
  lastName: String
  telephone: String
  uuid: ID!
}

type UploadInstruction {
  headers: JSONObject!
  httpMethod: String!
  key: String!
  url: String!
}

type User {
  customer: Customer
  displayName: String!
  email: EmailAddress!
  firstName: String!
  lastName: String!
  legacyId: Int
  role: RoleEnum!
  telephone: String
  uuid: ID!
}

input UserFilterInput {
  pagination: PaginationInput
  term: String
}

type UserPage {
  items: [User!]!
  pagination: PaginationResponse!
}

scalar Void`;
