import classNames from 'classnames';
import { Button } from './Button';

const Accordion = ({
  index,
  title,
  active,
  children,
  setActive,
  editable,
  disabled,
}: {
  index: number;
  title: string;
  active: boolean;
  children: React.ReactNode;
  setActive: (n: number) => void;
  editable: boolean;
  disabled?: boolean;
}) => (
  <div
    className={classNames('py-4 border-black/20 last:border-0', {
      'opacity-50': disabled,
      'border-b': !active,
    })}
  >
    <Button
      type="button"
      disabled={disabled}
      className="flex justify-between items-center w-full"
      onClick={() => setActive(index)}
    >
      <h3 className="text-xl font-bold">
        {index}. {title}
      </h3>
      {editable && <span className="text-red underline">Edit</span>}
    </Button>
    <div
      className={classNames('mt-4 relative', {
        hidden: !active,
      })}
    >
      {active && children}
    </div>
  </div>
);

export default Accordion;
