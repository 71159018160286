import { BaseMutationOptions, useLazyQuery, useMutation } from '@apollo/client';
import {
  CreateFileMutation,
  FileAccess,
  LegacyProductFileInput,
  ProductFileInput,
} from '@monorepo/graphql';
import {
  GqlCreateFile,
  GqlUploadFileInstructions,
} from '@monorepo/graphql/resources';
import Loader from './Loader';
import { elements } from '../utility/styles';
import { IoFolderOutline } from 'react-icons/io5';
import { Button } from './Button';

interface Props {
  product?: ProductFileInput;
  legacyProduct?: LegacyProductFileInput;
  isCart?: boolean;
  onCompleted: (
    data: CreateFileMutation,
    clientOptions?: BaseMutationOptions
  ) => void;
}

const UploadField = ({
  product,
  legacyProduct,
  onCompleted,
  isCart = false,
}: Props) => {
  const [fetchInstructions, { loading: fetchLoading }] = useLazyQuery(
    GqlUploadFileInstructions
  );

  const [createFile, { loading: createLoading }] = useMutation(GqlCreateFile);
  const loading = fetchLoading || createLoading;

  return loading ? (
    <Loader size="1.5rem" />
  ) : (
    <>
      <Button
        className={elements.button.tertiary}
        type="button"
        hotjarEvent="BrowseFilesForUpload"
        onClick={(e) => {
          (e.currentTarget.nextElementSibling as HTMLInputElement).click();
        }}
      >
        <span className="mr-4">Browse</span>
        <IoFolderOutline size={24} />
      </Button>
      <input
        type="file"
        placeholder="Upload file"
        accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .docx, .xls, .xlsx"
        className="hidden"
        onChange={async (e) => {
          const files = e.target.files;
          if (files) {
            await fetchInstructions({
              variables: {
                files: Array.from(files).map((f) => ({
                  name: f.name,
                  mimeType: f.type,
                  access: FileAccess.private,
                })),
              },
              onCompleted: ({ uploadFileInstructions }) => {
                uploadFileInstructions.forEach(
                  async ({ url, key, headers }, i) => {
                    await fetch(url, {
                      method: 'PUT',
                      headers: JSON.parse(headers) as HeadersInit,
                      body: files[i],
                    });

                    await createFile({
                      variables: {
                        input: {
                          key,
                          mimeType: files[i].type,
                          name: files[i].name,
                          isCart,
                          product,
                          legacyProduct,
                        },
                      },
                      onCompleted,
                    });

                    e.target.value = '';
                  }
                );
              },
            });
          } else {
            e.target.value = '';
          }
        }}
      />
    </>
  );
};

export default UploadField;
