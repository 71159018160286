export type DATE = `${number}-${string}-${string}`;

declare global {
  interface Date {
    toNiceFormat: (fullMonthLabel?: boolean) => string;
    toNiceTimeFormat: (use24Hour?: boolean) => string;
    toNiceDateTimeFormat: (
      use24Hour?: boolean,
      fullMonthLabel?: boolean
    ) => string;
    getMonthLabel: () => string;
    getFullMonthLabel: () => string;
    since: () => string;
  }
}

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const fullMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const pad = (n: number) => String('00' + n.toString()).slice(-2);

const nth = (n: number) =>
  ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';

Date.prototype.toNiceTimeFormat = function (use24Hour?: boolean) {
  const amPm = this.getHours() >= 12 ? 'pm' : 'am';

  return `${pad(
    this.getHours() === 12
      ? this.getHours()
      : this.getHours() % (use24Hour ? 24 : 12)
  )}:${pad(this.getMinutes())}${!use24Hour ? amPm : ''}`;
};

Date.prototype.toNiceFormat = function (fullMonthLabel?: boolean) {
  return `${this.getDate()}${nth(this.getDate())} ${
    fullMonthLabel ? this.getFullMonthLabel() : this.getMonthLabel()
  } ${this.getFullYear()}`;
};

Date.prototype.toNiceDateTimeFormat = function (
  use24Hour?: boolean,
  fullMonthLabel?: boolean
) {
  return `${this.toNiceFormat(fullMonthLabel)} at ${this.toNiceTimeFormat(
    use24Hour
  )}`;
};

Date.prototype.getMonthLabel = function () {
  return months[this.getMonth()];
};

Date.prototype.getFullMonthLabel = function () {
  return fullMonths[this.getMonth()];
};

Date.prototype.since = function () {
  const seconds = Math.floor((new Date().getTime() - this.getTime()) / 1000);
  let interval = Math.floor(seconds / 3600);

  if (interval > 24) {
    return this.toNiceFormat();
  }

  if (interval > 1) {
    return `${interval} hours ago`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 0) {
    return `${interval} minutes ago`;
  }
  if (seconds < 30) {
    return 'Just Now';
  }
  return `${Math.floor(seconds)} seconds ago`;
};
