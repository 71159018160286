import { ReactElement } from 'react';

interface Props {
  error: Error;
}
const ErrorScreen = ({ error }: Props): ReactElement => (
  <div className="flex w-screen h-screen justify-center max-w-500 flex-col items-center">
    <img src="/v2-assets/logo-old.svg" width={200} alt="Logo" />
    <h1 className="font-semibold font-nunito text-2xl mt-8">{error.message}</h1>
  </div>
);

export default ErrorScreen;
