import CreateOrEditQuote from '../../../../components/CreateOrEditQuote';
import NotFound from '../../../../components/NotFound';
import { doQuery } from '../../../../utility/apollo';
import { GqlReadQuote } from '@monorepo/graphql/resources';
import { createFileRoute, notFound } from '@tanstack/react-router';

export const loader = async ({ params }: { params: { quoteUuid: string } }) => {
  try {
    const { data } = await doQuery({
      query: GqlReadQuote,
      variables: {
        uuid: params.quoteUuid,
      },
    });

    return { quote: data.readQuote };
  } catch (error) {
    throw notFound();
  }
};

const QuoteEditComponent = () => {
  const { quote } = Route.useLoaderData();

  return <CreateOrEditQuote quote={quote} />;
};

export const Route = createFileRoute('/_authenticated/admin/quotes/$quoteUuid')(
  {
    loader,
    errorComponent: () => <NotFound to="/admin/quotes" />,
    component: QuoteEditComponent,
  }
);
