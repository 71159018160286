import CreatePaymentMethod from '../../../../components/CreatePaymentMethod';
import { createFileRoute } from '@tanstack/react-router';

export interface SearchParams {
  quoteUuid?: string;
}

const CreatePaymentMethodComponent = () => {
  const { quoteUuid } = Route.useSearch();

  return <CreatePaymentMethod quoteUuid={quoteUuid} />;
};

export const Route = createFileRoute(
  '/_authenticated/my-account/payment-methods/add'
)({
  validateSearch: (search: Record<string, unknown>): SearchParams => ({
    quoteUuid: search.quoteUuid as string | undefined,
  }),
  component: CreatePaymentMethodComponent,
});
