import CreateOrEditMessageTemplate from '../../../../../components/CreateOrEditMessageTemplate';
import NotFound from '../../../../../components/NotFound';
import { doQuery } from '../../../../../utility/apollo';
import { GqlReadMessageTemplate } from '@monorepo/graphql/resources';
import { createFileRoute, notFound } from '@tanstack/react-router';

export const loader = async ({
  params,
}: {
  params: { templateUuid: string };
}) => {
  try {
    const { data } = await doQuery({
      query: GqlReadMessageTemplate,
      variables: {
        uuid: params.templateUuid,
      },
    });

    return { template: data.readMessageTemplate };
  } catch (error) {
    throw notFound();
  }
};

const MessageTemplateEditComponent = () => {
  const { template } = Route.useLoaderData();

  return <CreateOrEditMessageTemplate template={template} />;
};

export const Route = createFileRoute(
  '/_authenticated/admin/quotes/message-templates/$templateUuid'
)({
  loader,
  errorComponent: () => <NotFound to="/admin/quotes/message-templates" />,
  component: MessageTemplateEditComponent,
});
