import LegacyOrdersList, {
  FilterOptionEnum,
  TableColumnEnum,
} from '../../../../../components/LegacyOrdersList';
import { useUser } from '../../../../../utility/authentication';
import { LegacyOrderStatusEnum } from '@monorepo/graphql';
import { createFileRoute } from '@tanstack/react-router';
import { useMemo } from 'react';

const hiddenColumns = [TableColumnEnum.customer];
const hiddenFilters = [FilterOptionEnum.customer];
const hiddenStatuses = [
  LegacyOrderStatusEnum.acceptedQuote,
  LegacyOrderStatusEnum.pendingQuote,
  LegacyOrderStatusEnum.exiredQuote,
  LegacyOrderStatusEnum.rejectedQuote,
];

const LegacyOrdersComponent = () => {
  const { user } = useUser();
  const initialFilters = useMemo(
    () => ({
      customerId: user.customer?.legacyId ?? undefined,
    }),
    [user]
  );

  return (
    <LegacyOrdersList
      hiddenColumns={hiddenColumns}
      hiddenFilters={hiddenFilters}
      hiddenStatuses={hiddenStatuses}
      initialFilters={initialFilters}
    />
  );
};

export const Route = createFileRoute(
  '/_authenticated/my-account/orders/legacy/'
)({
  component: LegacyOrdersComponent,
});
