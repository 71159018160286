import { CatchBoundary, createFileRoute, Outlet } from '@tanstack/react-router';
import ErrorScreen from '../components/ErrorScreen';
import * as Sentry from '@sentry/react';

export const Route = createFileRoute('/_unauthenticated')({
  component: LayoutComponent,
});

function LayoutComponent() {
  return (
    <CatchBoundary
      onCatch={(error) => Sentry.captureException(error)}
      getResetKey={() => 'unauthenticated'}
      errorComponent={ErrorScreen}
    >
      <Outlet />
    </CatchBoundary>
  );
}
