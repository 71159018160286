import ViewQuote from '../../../../components/ViewQuote';
import { createFileRoute } from '@tanstack/react-router';
import { loader } from '../../admin/quotes/$quoteUuid';
import NotFound from '../../../../components/NotFound';

export interface SearchParams {
  paymentMethodId?: string;
}

const ViewQuoteComponent = () => {
  const { quote } = Route.useLoaderData();
  const { paymentMethodId } = Route.useSearch();

  return <ViewQuote quote={quote} paymentMethodId={paymentMethodId} />;
};

export const Route = createFileRoute(
  '/_authenticated/my-account/quotes/$quoteUuid'
)({
  loader,
  errorComponent: () => <NotFound to="/my-account/quotes" />,
  validateSearch: (search: Record<string, unknown>): SearchParams => ({
    paymentMethodId: search.paymentMethodId as string | undefined,
  }),
  component: ViewQuoteComponent,
});
