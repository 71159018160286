import { gql } from '@apollo/client';

export const GqlPagination = gql`
  fragment PaginationFragment on PaginationResponse {
    total
    perPage
    currentPage
    lastPage
    from
    to
  }
`;
