import { useMutation } from '@apollo/client';
import { AddCouponInput, CouponFragmentFragment } from '@monorepo/graphql';
import {
  GqlAddCoupon,
  GqlDeleteCoupon,
  GqlEditCoupon,
  GqlReadCoupon,
} from '@monorepo/graphql/resources';
import PageHeader from './PageHeader';
import { FiTag, FiTrash } from 'react-icons/fi';
import { useNavigate } from '@tanstack/react-router';
import { elements } from '../utility/styles';
import { Form, Formik } from 'formik';
import { FormikDatePicker } from './DatePicker';
import { DateValueType } from 'react-tailwindcss-datepicker';
import SubmitButton from './SubmitButton';
import PageLoader from './PageLoader';
import { toaster } from '../utility/toast';
import Loader from './Loader';
import Input from './Input';
import Checkbox from './Checkbox';
import { Tooltip } from 'react-tooltip';
import { Button } from './Button';

interface Props {
  coupon?: CouponFragmentFragment;
}

type Stringified<T> = {
  [K in keyof T]: string;
};

type TransformArraysToStrings<T> = {
  [K in keyof T]: T[K] extends unknown[] | undefined | null
    ? string | undefined | null
    : T[K];
};

const CreateOrEditCoupon = ({ coupon }: Props) => {
  const [updateCoupon, { loading: updateLoading }] = useMutation(
    GqlEditCoupon,
    {
      update(cache, data) {
        if (data.data?.editCoupon) {
          cache.writeQuery({
            query: GqlReadCoupon,
            data: {
              readCoupon: data.data.editCoupon,
            },
            variables: {
              uuid: data.data.editCoupon.uuid,
            },
          });
        }
      },
    }
  );

  const [addCoupon, { loading: addLoading }] = useMutation(GqlAddCoupon, {
    update(cache) {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'indexCoupons',
      });

      cache.gc();
    },
  });

  const [deleteCoupon, { loading: deleteLoading }] = useMutation(
    GqlDeleteCoupon,
    {
      update(cache) {
        cache.evict({ id: `Coupon:${coupon?.uuid}` });

        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'indexCoupons',
        });

        cache.gc();
      },
      onCompleted: () => {
        toaster.success(
          {
            title: 'Coupon deleted',
            text: 'The coupon has been deleted',
          },
          { autoClose: 2000 }
        );

        void navigate({
          to: '/admin/coupons',
        });
      },
    }
  );

  const loading = updateLoading || addLoading;
  const initialValues: Omit<
    TransformArraysToStrings<AddCouponInput>,
    'expiresAt' | 'individualUse' | 'excludeSaleItems' | 'freeShipping'
  > & {
    expiresAt: DateValueType;
    checked: string[];
  } = {
    amount: coupon?.amount ?? 0,
    code: coupon?.code ?? '',
    description: coupon?.description ?? '',
    expiresAt: coupon?.expiresAt
      ? {
          startDate: new Date(coupon.expiresAt),
          endDate: new Date(coupon.expiresAt),
        }
      : null,
    checked: coupon?.freeShipping ? ['freeShipping'] : [],
  };

  const navigate = useNavigate();

  return (
    <div className="flex-grow flex flex-col">
      <PageHeader title={coupon ? 'Edit Coupon' : 'Add Coupon'} Icon={FiTag}>
        {!!coupon &&
          (deleteLoading ? (
            <Loader size="1.5rem" />
          ) : (
            <div>
              <Button
                type="button"
                className={elements.button.tertiary}
                data-tooltip-id="deleteCoupon"
                data-tooltip-content="Delete Coupon"
                data-tooltip-place="bottom"
                onClick={async () => {
                  if (
                    window.confirm(
                      'Are you sure you want to delete this coupon?'
                    )
                  )
                    await deleteCoupon({
                      variables: {
                        uuid: coupon.uuid,
                      },
                    });
                }}
              >
                <FiTrash size={20} />
              </Button>
              <Tooltip id="deleteCoupon" />
            </div>
          ))}
      </PageHeader>
      <p className="mb-5">
        {coupon ? 'Edit your coupon below' : 'Add your coupon below'}.
      </p>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: Partial<Stringified<AddCouponInput>> = {};
          if (!values.code) {
            errors.code = 'Code is required';
          }
          if (!values.amount) {
            errors.amount = 'Amount is required';
          }
          return errors;
        }}
        onSubmit={async ({ checked, ...values }, props) => {
          const data: AddCouponInput = {
            ...values,
            expiresAt:
              values.expiresAt?.startDate instanceof Date
                ? values.expiresAt.startDate.toISOString()
                : values.expiresAt?.startDate ?? null,
            freeShipping: checked.includes('freeShipping'),
          };

          if (coupon) {
            await updateCoupon({
              variables: {
                input: {
                  ...data,
                  uuid: coupon.uuid,
                },
              },
            });

            props.resetForm({
              values: {
                ...values,
                checked,
              },
            });
          } else {
            const newCoupon = await addCoupon({
              variables: {
                input: {
                  ...data,
                },
              },
            });

            const uuid = newCoupon.data?.addCoupon.uuid;
            if (uuid) {
              void navigate({
                to: `/admin/coupons/${uuid}`,
              });
            }
          }
          toaster.success(
            {
              title: 'Success',
              text: `You have successfully ${
                coupon ? 'updated' : 'added'
              } your coupon!`,
            },
            {
              autoClose: 5000,
            }
          );
        }}
      >
        <Form className="relative bg-white rounded-md p-5">
          <div className="flex flex-wrap -mx-2 items-center">
            <div className="w-full lg:w-1/2 px-2 mb-5">
              <Input placeholder="Code" name="code" required label="Code" />
            </div>
            <div className="w-full lg:w-1/2 px-2 mb-5">
              <Input
                placeholder="Description"
                name="description"
                label="Description"
              />
            </div>
            <div className="w-full lg:w-1/2 px-2 mb-5">
              <Input
                type="number"
                min="0"
                placeholder="Amount (%)"
                name="amount"
                required
                label="Amount"
                max="100"
              />
            </div>
            <div className="w-full lg:w-1/2 px-2 mb-5">
              <label className={elements.inputLabel}>Expiry Date</label>
              <FormikDatePicker inputName="expiresAt" />
            </div>
            <div className="w-full lg:w-1/2 px-2">
              <Checkbox
                name="checked"
                label="Free Shipping"
                value="freeShipping"
              />
            </div>
          </div>
          <SubmitButton />
          {loading && <PageLoader />}
        </Form>
      </Formik>
    </div>
  );
};

export default CreateOrEditCoupon;
