import FloatingButton from './FloatingButton';
import { FiSend } from 'react-icons/fi';

interface Props {
  showIfNotDirty?: boolean;
}

const SendButton = ({ showIfNotDirty }: Props) => (
  <FloatingButton showIfNotDirty={showIfNotDirty}>
    <FiSend size={24} />
  </FloatingButton>
);

export default SendButton;
