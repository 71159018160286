import { gql } from '@apollo/client';
import { GqlUser } from './user';
import {
  CreateFileMutation,
  CreateFileMutationVariables,
  EditFileMutation,
  EditFileMutationVariables,
  UploadFileInstructionsQuery,
  UploadFileInstructionsQueryVariables,
  DeleteFileMutation,
  DeleteFileMutationVariables,
  GetSignedUrlQuery,
  GetSignedUrlQueryVariables,
} from '../__generated__/graphql';

export const GqlGetSignedUrl = gql<
  GetSignedUrlQuery,
  GetSignedUrlQueryVariables
>`
  query GetSignedUrl($uuids: [ID!]!, $query: String) {
    getSignedUrl(uuids: $uuids, query: $query)
  }
`;

export const GqlUploadFileInstructions = gql<
  UploadFileInstructionsQuery,
  UploadFileInstructionsQueryVariables
>`
  query UploadFileInstructions($files: [FileUploadRequest!]!) {
    uploadFileInstructions(files: $files) {
      httpMethod
      url
      key
      headers
    }
  }
`;

export const GqlFile = gql`
  fragment FileFragment on File {
    uuid
    mimeType
    key
    name
    creator {
      ...UserFragment
    }
  }
  ${GqlUser}
`;

export const GqlCreateFile = gql<
  CreateFileMutation,
  CreateFileMutationVariables
>`
  mutation CreateFile($input: CreateFileInput!) {
    createFile(input: $input) {
      ...FileFragment
    }
  }
  ${GqlFile}
`;

export const GqlEditFile = gql<EditFileMutation, EditFileMutationVariables>`
  mutation EditFile($input: EditFileInput!) {
    editFile(input: $input) {
      ...FileFragment
    }
  }
  ${GqlFile}
`;

export const GqlDeleteFile = gql<
  DeleteFileMutation,
  DeleteFileMutationVariables
>`
  mutation DeleteFile($uuid: ID!) {
    deleteFile(uuid: $uuid)
  }
`;
