import MenuLink from '../../components/MenuLink';
import NotFound from '../../components/NotFound';
import PageLayout from '../../components/PageLayout';
import { useUser } from '../../utility/authentication';
import { RoleEnum } from '@monorepo/graphql';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import {
  FiBriefcase,
  FiLock,
  FiShoppingCart,
  FiTag,
  FiUsers,
} from 'react-icons/fi';

export const Route = createFileRoute('/_authenticated/admin')({
  component: LayoutComponent,
  notFoundComponent: () => <NotFound to="/admin" />,
});

function LayoutComponent() {
  const { user } = useUser();
  const navigate = useNavigate();
  if (user.role !== RoleEnum.admin) {
    void navigate({
      to: '/my-account',
    });
  }
  return (
    <PageLayout sidebarClassNames="bg-red">
      <ul>
        <MenuLink
          route="/admin/orders"
          label="Customer Orders"
          Icon={FiShoppingCart}
        />
        <MenuLink
          route="/admin/quotes"
          label="Customer Quotes"
          Icon={FiBriefcase}
        >
          <ul className="pb-6">
            <MenuLink route="/admin/quotes/products" label="Products" isChild />
            <MenuLink
              route="/admin/quotes/message-templates"
              label="Message Templates"
              isChild
            />
            <MenuLink
              route="/admin/quotes/email-tokens"
              label="Email Senders"
              isChild
            />
          </ul>
        </MenuLink>
        <MenuLink route="/admin/coupons" label="Coupons" Icon={FiTag} />
        <MenuLink route="/admin/customers" label="Customers" Icon={FiUsers} />
        <MenuLink route="/admin/users" label="Users" Icon={FiLock} />
      </ul>
    </PageLayout>
  );
}
