import { useBackgroundQuery } from '@apollo/client';
import { GqlIndexCoupons } from '@monorepo/graphql/resources';
import DataLayout, { FilterOption, LinkType, TableColumn } from './DataLayout';
import { FiPlus, FiTag } from 'react-icons/fi';
import { useCallback, useMemo, useState } from 'react';
import { CouponFilterInput, IndexCouponsQuery } from '@monorepo/graphql';
import { Link } from '@tanstack/react-router';
import classNames from 'classnames';
import { elements } from '../utility/styles';
import Input from './Input';

enum TableColumnEnum {
  code = 'code',
  type = 'type',
  amount = 'amount',
  description = 'description',
  products = 'products',
  limits = 'limits',
  author = 'author',
  expiry = 'expiry',
  actions = 'actions',
}

enum FilterOptionEnum {
  search = 'search',
}

type Coupon = IndexCouponsQuery['indexCoupons']['items'][0];

const defaultFilters = {
  term: null,
};

const links: LinkType[] = [
  {
    to: '/admin/coupons/add',
    label: `Add Coupon`,
    Icon: FiPlus,
  },
];

const CouponsList = () => {
  const [filters, setFilters] =
    useState<Pick<CouponFilterInput, 'term'>>(defaultFilters);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);

  const [query] = useBackgroundQuery(GqlIndexCoupons, {
    variables: {
      input: {
        ...filters,
        orderBy: null,
        pagination: {
          page,
          perPage,
        },
      },
    },
  });

  const tableColumns = useMemo<Array<TableColumn<Coupon>>>(
    () => [
      {
        key: TableColumnEnum.code,
        label: 'Code',
        handler: (coupon: Coupon) => (
          <Link to={coupon.uuid}>{coupon.code}</Link>
        ),
        style: { width: '15%' },
      },
      {
        key: TableColumnEnum.amount,
        label: 'Coupon %',
        handler: (coupon: Coupon) => <span>{coupon.amount}%</span>,
        style: { width: '15%' },
      },
      {
        key: TableColumnEnum.description,
        label: 'Description',
        handler: (coupon: Coupon) => <span>{coupon.description}</span>,
        style: { width: '30%' },
      },
      {
        key: TableColumnEnum.author,
        label: 'Author',
        handler: (coupon: Coupon) => <span>{coupon.creator.displayName}</span>,
        style: { width: '15%' },
      },
      {
        key: TableColumnEnum.expiry,
        label: 'Expiry',
        handler: (coupon: Coupon) => (
          <span>
            {coupon.expiresAt
              ? new Date(coupon.expiresAt).toNiceDateTimeFormat()
              : 'N/A'}
          </span>
        ),
        style: { width: '15%' },
      },
      {
        key: TableColumnEnum.actions,
        label: '',
        handler: (coupon: Coupon) => (
          <Link
            className={classNames(
              elements.button.secondary,
              'text-xs inline-flex whitespace-nowrap'
            )}
            to={coupon.uuid}
          >
            View
          </Link>
        ),
        style: { width: '10%' },
      },
    ],
    []
  );

  const [term, setTerm] = useState<string | null>(null);

  const currentFilters = useMemo(
    () => ({
      term,
    }),
    [term]
  );

  const applyFilters = useCallback(() => {
    setFilters({
      term,
    });
  }, [term]);

  const resetFilters = useCallback(() => {
    setTerm(null);
  }, []);

  const filterOptions = useMemo<FilterOption[]>(
    () => [
      {
        key: FilterOptionEnum.search,
        label: 'Search',
        Component: ({ term: item }: { term?: string }) => (
          <Input
            type="text"
            placeholder="Search by Coupon Name..."
            name="search"
            defaultValue={item}
            onInput={(e) => setTerm(e.currentTarget.value)}
            useFormik={false}
          />
        ),
      },
    ],
    []
  );

  const extractItems = useCallback(
    (data: IndexCouponsQuery) => data.indexCoupons.items,
    []
  );

  const extractPagination = useCallback(
    (data: IndexCouponsQuery) => data.indexCoupons.pagination,
    []
  );

  return (
    <DataLayout
      Icon={FiTag}
      title="Coupons"
      subtitle="Manage your coupons below"
      setPage={setPage}
      setPerPage={setPerPage}
      tableColumns={tableColumns}
      filterOptions={filterOptions}
      onReset={resetFilters}
      onConfirm={applyFilters}
      currentFilters={currentFilters}
      baseFilters={defaultFilters}
      links={links}
      query={query}
      extractItems={extractItems}
      extractPagination={extractPagination}
    />
  );
};

export default CouponsList;
