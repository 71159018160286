import { ReactNode } from 'react';
import env from '../environment';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { cache } from './cache';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { ToastContainer } from 'react-toastify';
import { toaster } from './toast';
import { clearApiToken, getApiToken } from './authentication';
import { SentryLink } from 'apollo-link-sentry';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // Apollo Client will handle GraphQL errors in its default way
    // No need to provide custom logic here
    graphQLErrors.map((error) => {
      if (error.extensions.statusCode === 401) {
        toaster.error(
          {
            title: 'Not Authenticated',
            text: error.message,
          },
          { autoClose: 2000 }
        );

        clearApiToken();

        window.location.href = '/login';
      } else if (error.extensions.statusCode !== 404) {
        toaster.error(
          {
            title: 'Error',
            text: error.message,
          },
          { autoClose: 2000 }
        );
      }
    });
  }
  if (networkError) {
    // Handle network errors, including 401 and 403
    toaster.error(
      {
        title: 'Network Error',
        text: networkError.message,
      },
      { autoClose: 2000 }
    );
  }
});

const authLink = setContext(
  (_, { headers }: { headers?: Record<string, string> }) => {
    const apiToken = getApiToken();

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: apiToken ? `Bearer ${apiToken}` : '',
      },
    };
  }
);

const httpLink = new BatchHttpLink({
  uri: `${env.apiUrl}/api/graphql`,
  batchInterval: 100,
});

export const apolloClient = new ApolloClient({
  link: new SentryLink().concat(authLink).concat(errorLink).concat(httpLink),
  cache,
});

export const doQuery = apolloClient.query;

export function GqlProvider({ children }: { children: ReactNode }) {
  return (
    <ApolloProvider client={apolloClient}>
      {children}

      <ToastContainer />
    </ApolloProvider>
  );
}

type TypenameType = { __typename?: string } & Record<string, unknown>;

export function cleanTypeName<T extends TypenameType>(
  obj: T
): Omit<T, '__typename'> {
  const { __typename, ...newObj } = obj;

  return newObj;
}

type WithUuid = { uuid: string | null } & Record<string, unknown>;

export function cleanUuid<T extends WithUuid>(obj: T): Omit<T, 'uuid'> {
  const { uuid, ...newObj } = obj;

  return newObj;
}
