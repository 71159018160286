import { useSuspenseQuery } from '@apollo/client';
import { CreateQuoteInput } from '@monorepo/graphql';
import { GqlGetSignedUrl } from '@monorepo/graphql/resources';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { elements } from '../utility/styles';
import { FiDownload, FiTrash } from 'react-icons/fi';
import UploadField from './UploadField';
import { Tooltip } from 'react-tooltip';
import { Button } from './Button';

const PurchaseOrderUploadField = () => {
  const { setFieldValue, values } =
    useFormikContext<
      Pick<CreateQuoteInput, 'purchaseOrderReference' | 'purchaseOrderFileUuid'>
    >();

  const { data } = useSuspenseQuery(GqlGetSignedUrl, {
    variables: {
      uuids: values.purchaseOrderFileUuid ? [values.purchaseOrderFileUuid] : [],
      query: '',
    },
    skip: !values.purchaseOrderFileUuid,
  });

  return values.purchaseOrderFileUuid ? (
    <>
      <label htmlFor="purchaseOrderFileUuid" className={elements.inputLabel}>
        Purchase Order
      </label>
      <div className="flex items-center">
        <a
          href={data?.getSignedUrl.find(Boolean) ?? ''}
          target="_blank"
          rel="noreferrer"
          data-tooltip-content="Download"
          data-tooltip-id="purchase-order-download"
          data-tooltip-place="bottom"
          className={classNames(
            elements.button.tertiary,
            'h-10 flex items-center'
          )}
        >
          <FiDownload size={20} />
        </a>
        <Button
          type="button"
          data-tooltip-content="Delete"
          data-tooltip-id="delete-order-download"
          data-tooltip-place="bottom"
          className={classNames(
            elements.button.tertiary,
            'ml-2 h-10 flex items-center'
          )}
          onClick={async () => {
            await setFieldValue('purchaseOrderFileUuid', null);
          }}
        >
          <FiTrash size={20} />
        </Button>
        <Tooltip id="purchase-order-download" />
        <Tooltip id="delete-order-download" />
      </div>
    </>
  ) : (
    <>
      <label htmlFor="purchaseOrderFileUuid" className={elements.inputLabel}>
        Purchase Order (Upload)
      </label>
      <UploadField
        onCompleted={async (fieldData) => {
          await setFieldValue(
            'purchaseOrderFileUuid',
            fieldData.createFile.uuid
          );
        }}
      />
    </>
  );
};

export default PurchaseOrderUploadField;
