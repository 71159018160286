import { useMutation } from '@apollo/client';
import { GqlResetPasswordMutation } from '@monorepo/graphql/resources';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { elements } from '../utility/styles';
import PageLoader from './PageLoader';
import { Link } from '@tanstack/react-router';
import { FiCornerUpLeft } from 'react-icons/fi';
import Input from './Input';
import { Button } from './Button';

const LoginResetPassword = () => {
  const [resetPassword, { loading }] = useMutation(GqlResetPasswordMutation);
  const initialValues = {
    email: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, props) => {
        await resetPassword({
          variables: {
            email: values.email,
          },
        });

        props.resetForm({
          values: {
            email: '',
          },
        });
      }}
    >
      <Form className="flex flex-col grow bg-white rounded-md p-5">
        <div className="flex justify-center mb-10">
          <img src="/v2-assets/logo-old.svg" alt="Logo" className="w-40" />
        </div>
        <div className="flex flex-grow flex-col w-full relative">
          <Input
            type="email"
            name="email"
            label="Email address"
            containerClassName="mb-5"
            required
          />
          <Button
            type="submit"
            className={classNames(elements.button.primary, 'mb-5')}
          >
            Reset Password
          </Button>
          <Link
            to="/login"
            className="flex items-center text-red justify-center text-sm"
          >
            <FiCornerUpLeft className="mr-2" />
            <span>Back to Login</span>
          </Link>
          {loading && <PageLoader />}
        </div>
      </Form>
    </Formik>
  );
};

export default LoginResetPassword;
