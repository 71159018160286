import ViewOrder from '../../../../components/ViewOrder';
import { createFileRoute } from '@tanstack/react-router';
import { loader } from '../../admin/orders/$orderUuid';
import NotFound from '../../../../components/NotFound';

const ViewOrderComponent = () => {
  const { order } = Route.useLoaderData();

  return <ViewOrder order={order} />;
};

export const Route = createFileRoute(
  '/_authenticated/my-account/orders/$orderUuid'
)({
  loader,
  notFoundComponent: () => <NotFound to="/my-account/orders" />,
  component: ViewOrderComponent,
});
