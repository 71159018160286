export const internalUsers = [
  'lcostello@able.co.uk',
  'djinadasa@able.co.uk',
  'rwalker@able.co.uk',
  'dsmith@able.co.uk',
  'sstocker@able.co.uk',
  'jgalveia@able.co.uk',
  'jcoulter@able.co.uk',
  'jrowland-squires@able.co.uk',
];
