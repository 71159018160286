import {
  CSSProperties,
  Dispatch,
  ReactNode,
  SetStateAction,
  Suspense,
  useMemo,
  useState,
} from 'react';
import { QueryRef, useReadQuery } from '@apollo/client';
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';
import { elements } from '../utility/styles';
import { omitEmpty } from '../utility/helpers';
import { IconType } from 'react-icons';
import { Link } from '@tanstack/react-router';
import PageHeader from './PageHeader';
import {
  Exact,
  InputMaybe,
  PaginationFragmentFragment,
} from '@monorepo/graphql';
import { FiChevronLeft, FiChevronRight, FiFilter } from 'react-icons/fi';
import { Tooltip } from 'react-tooltip';
import Dropdown from './Dropdown';
import { Button } from './Button';
import PageLoader from './PageLoader';

export interface TableColumn<T extends Record<string, unknown>> {
  key: string;
  label: string;
  handler: (item: T) => React.ReactNode;
  style?: CSSProperties;
}

export interface LinkType {
  to: string;
  label: string;
  Icon: IconType;
}

export interface FilterOption {
  key: string;
  label?: string;
  Component: React.FC<{ term?: string }>;
}

interface Props<
  Items extends Record<string, unknown>,
  Query extends Record<string, unknown>,
  Input extends Record<string, unknown>
> {
  title: string;
  tableColumns: Array<TableColumn<Items>>;
  setPage?: Dispatch<SetStateAction<number>>;
  setPerPage?: Dispatch<SetStateAction<number>>;

  query: QueryRef<
    Query,
    Exact<{
      input?: InputMaybe<Input>;
    }>
  >;
  extractItems: (data: Query) => Items[];
  extractPagination?: (data: Query) => PaginationFragmentFragment | undefined;
}

function DataLayoutInner<
  Items extends Record<string, unknown>,
  Query extends Record<string, unknown>,
  Input extends Record<string, unknown>
>({
  title,
  tableColumns,
  query,
  extractItems,
  extractPagination,
  setPage,
  setPerPage,
}: Props<Items, Query, Input>) {
  const { error, data } = useReadQuery(query);

  const items = useMemo(() => extractItems(data), [data, extractItems]);

  const pagination = useMemo(
    () => extractPagination?.(data),
    [data, extractPagination]
  );

  return (
    <>
      <tbody>
        {error ? (
          <tr className="border-b border-black/20">
            <td className="py-5 px-8" colSpan={tableColumns.length}>
              <div className="flex items-center justify-center flex-grow w-full text-base">
                An error occurred: {error.message}
              </div>
            </td>
          </tr>
        ) : items.length === 0 ? (
          <tr className="border-b border-black/20">
            <td className="py-5 px-8" colSpan={tableColumns.length}>
              <div className="flex items-center justify-center flex-grow w-full text-sm">
                No {title.toLowerCase()} found.
              </div>
            </td>
          </tr>
        ) : (
          <>
            {items.map((item, i) => (
              <tr className="border-b border-black/20" key={i}>
                {tableColumns.map((column) => (
                  <td
                    className="p-5 first:pl-8 last:pr-8 last:text-right break-all"
                    key={column.key}
                  >
                    {column.handler(item)}
                  </td>
                ))}
              </tr>
            ))}
          </>
        )}
      </tbody>
      {!!pagination && pagination.lastPage > 1 && !!setPage && (
        <tfoot>
          <tr>
            <td colSpan={tableColumns.length}>
              <div className="flex flex-wrap justify-between items-center py-5 px-8 text-sm">
                <div className="w-full lg:w-1/3 flex items-center justify-center lg:justify-start mb-5 empty:mb-0 lg:mb-0">
                  {setPerPage && (
                    <div className="flex items-center">
                      <span className="mr-4">Rows per page</span>
                      <Dropdown
                        name="perPage"
                        options={[
                          { label: '20', value: 20 },
                          { label: '40', value: 40 },
                          { label: '60', value: 60 },
                          { label: '100', value: 100 },
                        ]}
                        value={pagination.perPage}
                        onChange={({ value }) => setPerPage(value)}
                      />
                    </div>
                  )}
                </div>
                <div className="w-full lg:w-1/3 flex justify-center mb-5 lg:mb-0">
                  <p className="text-center">
                    Displaying{' '}
                    {1 + (pagination.currentPage - 1) * pagination.perPage} to{' '}
                    {Math.min(
                      pagination.total,
                      pagination.currentPage * pagination.perPage
                    )}{' '}
                    of {pagination.total} rows
                  </p>
                </div>
                <div className="flex items-center -mx-1.5 w-full lg:w-1/3 justify-center lg:justify-end">
                  {pagination.currentPage > 1 && (
                    <div className="px-1.5 flex items-center">
                      <Button
                        type="button"
                        onClick={() => setPage((page) => page - 1)}
                      >
                        <FiChevronLeft size={24} />
                      </Button>
                    </div>
                  )}
                  <div className="px-1.5 flex items-center">
                    <Dropdown
                      name="page"
                      options={Array.from(
                        { length: pagination.lastPage },
                        (_, i) => ({ label: `Page ${i + 1}`, value: i + 1 })
                      )}
                      value={pagination.currentPage}
                      onChange={({ value }) => setPage(value)}
                    />
                    <span className="ml-3">of {pagination.lastPage}</span>
                  </div>
                  {pagination.currentPage < pagination.lastPage && (
                    <div className="px-1.5 flex items-center">
                      <Button
                        type="button"
                        onClick={() => setPage((page) => page + 1)}
                      >
                        <FiChevronRight size={24} />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      )}
    </>
  );
}

function DataLayout<
  Items extends Record<string, unknown>,
  Query extends Record<string, unknown>,
  Input extends Record<string, unknown>
>({
  Icon,
  title,
  setPage,
  setPerPage,
  tableColumns,
  filterOptions = [],
  onReset,
  onConfirm,
  currentFilters = {},
  baseFilters,
  subtitle,
  term,
  links,
  query,
  extractItems,
  extractPagination,
}: Props<Items, Query, Input> & {
  Icon: IconType;
  filterOptions?: FilterOption[];
  onReset?: () => void;
  onConfirm?: () => void;
  currentFilters?: Record<string, unknown>;
  baseFilters?: Record<string, unknown>;
  links?: LinkType[];
  subtitle: ReactNode;
  term?: string;
}) {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const filtersEmpty =
    Object.keys(omitEmpty(currentFilters)).flat().length === 0;

  const filtersDirty =
    JSON.stringify(baseFilters) !== JSON.stringify(currentFilters);

  return (
    <div className="flex-grow flex flex-col">
      <PageHeader title={title} Icon={Icon}>
        <div className="flex items-center -mx-1">
          {filterOptions.length > 0 && (
            <div className="px-1">
              <Button
                type="button"
                onClick={() => setFiltersOpen(true)}
                className={elements.button.tertiary}
                data-tooltip-id="filter"
                data-tooltip-content="Apply Filters"
                data-tooltip-place="bottom"
              >
                <FiFilter size={20} />
              </Button>
              <Tooltip id="filter" />
            </div>
          )}
          {links?.map(({ Icon: IconComponent, to, label }) => (
            <div className="px-1" key={to}>
              <Link
                to={to}
                className={elements.button.tertiary}
                data-tooltip-id={to}
                data-tooltip-content={label}
                data-tooltip-place="bottom"
              >
                <IconComponent size={20} />
              </Link>
              <Tooltip id={to} />
            </div>
          ))}
        </div>
      </PageHeader>
      <p className="mb-5 text-sm">{subtitle}</p>
      <div className="w-full grow flex flex-col">
        <Suspense
          fallback={
            <div className="bg-white rounded-md grow flex flex-col">
              <div className="overflow-x-auto flex flex-col grow">
                <table className="table-fixed text-sm lg:w-full grow">
                  <thead className="border-b border-black/20">
                    <tr>
                      {tableColumns.map((column) => (
                        <th
                          className="p-5 first:pl-8 last:pr-8 whitespace-nowrap text-black/60 uppercase text-left"
                          style={column.style}
                          key={column.key}
                        >
                          {column.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={tableColumns.length}>
                        <PageLoader />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          }
        >
          <div className="bg-white rounded-md flex flex-col">
            <div className="overflow-x-auto flex flex-col">
              <table className="table-fixed text-sm lg:w-full">
                <thead className="border-b border-black/20">
                  <tr>
                    {tableColumns.map((column) => (
                      <th
                        className="p-5 first:pl-8 last:pr-8 whitespace-nowrap text-black/60 uppercase text-left"
                        style={column.style}
                        key={column.key}
                      >
                        {column.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <DataLayoutInner
                  title={title}
                  tableColumns={tableColumns}
                  query={query}
                  extractItems={extractItems}
                  extractPagination={extractPagination}
                  setPage={setPage}
                  setPerPage={setPerPage}
                />
              </table>
            </div>
          </div>
        </Suspense>
      </div>
      {filterOptions.length > 0 && (
        <aside
          className={classNames(
            'fixed flex flex-col justify-between transition-transform transform top-0 right-0 p-5 h-full w-full max-w-96 bg-white shadow-lg border-l border-black/20',
            !filtersOpen ? 'translate-x-full' : ''
          )}
        >
          <div>
            <div className="flex items-center justify-between mb-5 text-dark">
              <div className="flex items-center">
                <h2 className="text-xl font-bold">Filters</h2>
              </div>
              <Button type="button" onClick={() => setFiltersOpen(false)}>
                <MdClose size={24} />
              </Button>
            </div>
            {filterOptions.map((option) => (
              <div className="mb-5" key={option.key}>
                {!!option.label && (
                  <label className={elements.inputLabel}>{option.label}</label>
                )}
                <option.Component term={term} />
              </div>
            ))}
          </div>
          <div className="flex justify-between flex-wrap">
            <div className="mb-5 w-full">
              <Button
                type="button"
                className={classNames(
                  filtersEmpty
                    ? elements.button.disabled
                    : elements.button.secondary,
                  'w-full'
                )}
                onClick={onReset}
                disabled={filtersEmpty}
              >
                Reset Filters
              </Button>
            </div>
            <div className="w-full">
              <Button
                type="button"
                className={classNames(elements.button.primary, 'w-full')}
                onClick={() => {
                  onConfirm?.();

                  setFiltersOpen(false);
                }}
                disabled={!filtersDirty}
              >
                Apply Filters
              </Button>
            </div>
          </div>
        </aside>
      )}
    </div>
  );
}

export default DataLayout;
