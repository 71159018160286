import { useMutation } from '@apollo/client';
import { GqlChangePasswordMutation } from '@monorepo/graphql/resources';
import { Form, Formik } from 'formik';
import SubmitButton from './SubmitButton';
import { ChangePasswordInput } from '@monorepo/graphql';
import PageLoader from './PageLoader';
import PageHeader from './PageHeader';
import { FiKey } from 'react-icons/fi';
import { toaster } from '../utility/toast';
import Input from './Input';

const initialValues: ChangePasswordInput = {
  password: '',
  newPassword: '',
};

const EditAccountPassword = () => {
  const [updatePassword, { loading }] = useMutation(GqlChangePasswordMutation);

  return (
    <div className="flex-grow flex flex-col">
      <PageHeader title="Edit Password" Icon={FiKey} />
      <p className="mb-5">Update your password below.</p>
      <Formik
        initialValues={initialValues}
        isInitialValid={false}
        validate={(values) => {
          const errors: Partial<ChangePasswordInput> = {};
          if (!values.password) {
            errors.password = 'Password is required';
          }
          if (!values.newPassword) {
            errors.newPassword = 'New Password is required';
          }
          if (values.password !== values.newPassword) {
            errors.newPassword = 'Passwords do not match';
          }
          return errors;
        }}
        onSubmit={async (values, props) => {
          await updatePassword({
            variables: {
              input: {
                ...values,
              },
            },
          });

          toaster.success(
            {
              title: 'Success',
              text: 'You have successfully changed your password!',
            },
            {
              autoClose: 5000,
            }
          );
        }}
      >
        <Form className="relative">
          <div className="flex flex-wrap -mx-2 items-center mb-5">
            <div className="w-full lg:w-1/2 px-2 mb-5">
              <Input
                type="password"
                label="Password"
                name="password"
                required
              />
            </div>
            <div className="w-full lg:w-1/2 px-2 mb-5">
              <Input
                type="password"
                label="Confirm Password"
                name="newPassword"
                required
              />
            </div>
          </div>
          <SubmitButton />
          {loading && <PageLoader />}
        </Form>
      </Formik>
    </div>
  );
};

export default EditAccountPassword;
