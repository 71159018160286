import { useAuth } from '../../utility/authentication';
import { createFileRoute } from '@tanstack/react-router';

const IndexComponent = () => {
  const auth = useAuth();

  return <h1>Hi {auth.user?.firstName}</h1>;
};

export const Route = createFileRoute('/_neutral/')({
  component: IndexComponent,
});
