import { gql } from '@apollo/client';
import {
  AddCustomerMutation,
  AddCustomerMutationVariables,
  DeleteCustomerMutation,
  DeleteCustomerMutationVariables,
  EditCustomerMutation,
  EditCustomerMutationVariables,
  IndexCustomersQuery,
  IndexCustomersQueryVariables,
  ReadCustomerQuery,
  ReadCustomerQueryVariables,
  ReadCustomerWithCartDataQuery,
  ReadCustomerWithCartDataQueryVariables,
} from '../__generated__/graphql';
import { GqlPagination } from './pagination';
import { GqlCartData } from './cart';
import { GqlCustomer } from './address';

export const GqlCustomerWithCartData = gql`
  fragment CustomerWithCartDataFragment on Customer {
    ...CustomerFragment
    cart {
      ...CartDataFragment
    }
  }
  ${GqlCustomer}
  ${GqlCartData}
`;

export const GqlEditCustomer = gql<
  EditCustomerMutation,
  EditCustomerMutationVariables
>`
  mutation EditCustomer($input: EditCustomerInput!) {
    editCustomer(input: $input) {
      ...CustomerFragment
    }
  }
  ${GqlCustomer}
`;

export const GqlAddCustomer = gql<
  AddCustomerMutation,
  AddCustomerMutationVariables
>`
  mutation AddCustomer($input: AddCustomerInput!) {
    addCustomer(input: $input) {
      ...CustomerFragment
    }
  }
  ${GqlCustomer}
`;

export const GqlIndexCustomers = gql<
  IndexCustomersQuery,
  IndexCustomersQueryVariables
>`
  query IndexCustomers($input: CustomerFilterInput) {
    indexCustomers(input: $input) {
      pagination {
        ...PaginationFragment
      }
      items {
        ...CustomerFragment
      }
    }
  }
  ${GqlCustomer}
  ${GqlPagination}
`;

export const GqlReadCustomer = gql<
  ReadCustomerQuery,
  ReadCustomerQueryVariables
>`
  query ReadCustomer($uuid: ID!) {
    readCustomer(uuid: $uuid) {
      ...CustomerFragment
    }
  }
  ${GqlCustomer}
`;

export const GqlReadCustomerWithCartData = gql<
  ReadCustomerWithCartDataQuery,
  ReadCustomerWithCartDataQueryVariables
>`
  query ReadCustomerWithCartData($uuid: ID!) {
    readCustomer(uuid: $uuid) {
      ...CustomerWithCartDataFragment
    }
  }
  ${GqlCustomerWithCartData}
`;

export const GqlDeleteCustomer = gql<
  DeleteCustomerMutation,
  DeleteCustomerMutationVariables
>`
  mutation DeleteCustomer($uuid: ID!) {
    deleteCustomer(uuid: $uuid)
  }
`;
