import { useBackgroundQuery } from '@apollo/client';
import { GqlIndexUsers } from '@monorepo/graphql/resources';
import DataLayout, { FilterOption, LinkType, TableColumn } from './DataLayout';
import { FiPlus, FiTag } from 'react-icons/fi';
import { useCallback, useMemo, useState } from 'react';
import { IndexUsersQuery, UserFilterInput } from '@monorepo/graphql';
import { Link } from '@tanstack/react-router';
import classNames from 'classnames';
import { elements } from '../utility/styles';
import Input from './Input';

enum TableColumnEnum {
  displayName = 'displayName',
  email = 'email',
  actions = 'actions',
}

enum FilterOptionEnum {
  search = 'search',
}

type User = IndexUsersQuery['indexUsers']['items'][0];

const defaultFilters = {
  term: null,
};

const links: LinkType[] = [
  {
    to: '/admin/users/add',
    label: `Add User`,
    Icon: FiPlus,
  },
];

const UsersList = () => {
  const [filters, setFilters] =
    useState<Pick<UserFilterInput, 'term'>>(defaultFilters);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);

  const [query] = useBackgroundQuery(GqlIndexUsers, {
    variables: {
      input: {
        ...filters,
        pagination: {
          page,
          perPage,
        },
      },
    },
  });

  const tableColumns = useMemo<Array<TableColumn<User>>>(
    () => [
      {
        key: TableColumnEnum.displayName,
        label: 'Name',
        handler: (user: User) => <Link to={user.uuid}>{user.displayName}</Link>,
        style: { width: '45%' },
      },
      {
        key: TableColumnEnum.email,
        label: 'Email',
        handler: (user: User) => <span>{user.email}</span>,
        style: { width: '45%' },
      },
      {
        key: TableColumnEnum.actions,
        label: '',
        handler: (user: User) => (
          <Link
            className={classNames(
              elements.button.secondary,
              'text-xs inline-flex whitespace-nowrap'
            )}
            to={user.uuid}
          >
            View
          </Link>
        ),
        style: { width: '10%' },
      },
    ],
    []
  );

  const extractItems = useCallback(
    (data: IndexUsersQuery) => data.indexUsers.items,
    []
  );

  const extractPagination = useCallback(
    (data: IndexUsersQuery) => data.indexUsers.pagination,
    []
  );

  const [term, setTerm] = useState<string | null>(null);

  const currentFilters = useMemo(
    () => ({
      term,
    }),
    [term]
  );

  const applyFilters = useCallback(() => {
    setFilters({
      term,
    });
  }, [term]);

  const resetFilters = useCallback(() => {
    setTerm(null);
  }, []);

  const filterOptions = useMemo<FilterOption[]>(
    () => [
      {
        key: FilterOptionEnum.search,
        label: 'Search',
        Component: ({ term: item }: { term?: string }) => (
          <Input
            type="text"
            placeholder="Search by Name..."
            name="search"
            defaultValue={item}
            onInput={(e) => setTerm(e.currentTarget.value)}
            useFormik={false}
          />
        ),
      },
    ],
    []
  );

  return (
    <DataLayout
      Icon={FiTag}
      title="Users"
      subtitle="Manage users below"
      setPage={setPage}
      setPerPage={setPerPage}
      tableColumns={tableColumns}
      filterOptions={filterOptions}
      onReset={resetFilters}
      onConfirm={applyFilters}
      currentFilters={currentFilters}
      baseFilters={defaultFilters}
      links={links}
      query={query}
      extractItems={extractItems}
      extractPagination={extractPagination}
    />
  );
};

export default UsersList;
