import {
  BaseMutationOptions,
  useMutation,
  useSuspenseQuery,
} from '@apollo/client';
import {
  AddressInput,
  CreateFileMutation,
  CreateQuoteInput,
  CurrencyEnum,
  InputMaybe,
  LineItemInput,
  LineItemProductInput,
  LineItemTypeEnum,
  MetaDataInput,
  ProductUploadInput,
  ProductUploadType,
  QuoteFragmentFragment,
  QuoteRecipient,
  QuoteStatusEnum,
  quoteStatuses,
  RecipientGroup,
  ShippingMethodEnum,
  currencyLabels,
  formatMoney,
  noteTypeLabels,
  shippingMethodLabels,
  defaultShippingValues,
  MetaData,
} from '@monorepo/graphql';
import {
  GqlAddQuoteNote,
  GqlCreateQuote,
  GqlDeleteQuote,
  GqlDeleteQuoteNote,
  GqlEditQuote,
  GqlEditQuoteNote,
  GqlGetSignedUrl,
  GqlIndexMessageTemplates,
  GqlReadInternalProduct,
  GqlReadProduct,
  GqlReadQuote,
  GqlSendQuoteEmail,
} from '@monorepo/graphql/resources';
import { Field, Form, Formik, FormikProps, useFormikContext } from 'formik';
import {
  createRef,
  ForwardedRef,
  forwardRef,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { elements } from '../utility/styles';
import env from '../environment';
import { getApiToken, useUser } from '../utility/authentication';
import { toaster } from '../utility/toast';
import { Link, useLocation, useNavigate } from '@tanstack/react-router';
import AddressFieldGroup from './AddressFieldGroup';
import CustomerSearchField from './CustomerSearchField';
import LegacyCustomerSearchField from './LegacyCustomerSearchField';
import PageHeader from './PageHeader';
import {
  FiArrowDown,
  FiArrowUp,
  FiBriefcase,
  FiCornerUpLeft,
  FiDownload,
  FiEdit,
  FiPlus,
  FiRefreshCcw,
  FiRefreshCw,
  FiShoppingBag,
  FiTrash,
} from 'react-icons/fi';
import ProductSearchField from './ProductSearchField';
import NextButton from './NextButton';
import SubmitButton from './SubmitButton';
import SendButton from './SendButton';
import classNames from 'classnames';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { cleanTypeName, cleanUuid } from '../utility/apollo';
import { Editor } from '@tinymce/tinymce-react';
import { INativeEvents } from '@tinymce/tinymce-react/lib/cjs/main/ts/Events';
import PageLoader from './PageLoader';
import Loader from './Loader';
import { omitEmpty } from '../utility/helpers';
import UploadField from './UploadField';
import Input from './Input';
import Checkbox from './Checkbox';
import SearchField from './SearchField';
import { internalUsers } from '../utility/internalUsers';
import { Tooltip } from 'react-tooltip';
import Dropdown from './Dropdown';
import PurchaseOrderUploadField from './PurchaseOrderUploadField';
import { Button } from './Button';
import DownloadButton from './DownloadButton';

interface Props {
  quote?: QuoteFragmentFragment;
}

const NoteTab = ({ quote }: Required<Props>) => {
  const [notes, setNotes] = useState(quote.notes);
  const [addQuoteNote, { loading: addLoading }] = useMutation(GqlAddQuoteNote, {
    onCompleted: (data) => {
      toaster.success(
        {
          title: 'Note added',
          text: 'The note has been added to this quote',
        },
        { autoClose: 2000 }
      );

      setNotes([data.addQuoteNote, ...notes]);
    },
  });

  const [editQuoteNote, { loading: editLoading }] = useMutation(
    GqlEditQuoteNote,
    {
      onCompleted: (data) => {
        toaster.success(
          {
            title: 'Note updated',
            text: 'The note has been updated',
          },
          { autoClose: 2000 }
        );

        setNotes(
          notes.map((note) =>
            note.uuid === data.editQuoteNote.uuid ? data.editQuoteNote : note
          )
        );

        setNoteToEdit(null);
      },
    }
  );

  const [deleteQuoteNote, { loading: deleteLoading }] = useMutation(
    GqlDeleteQuoteNote,
    {
      onCompleted: (data) => {
        toaster.success(
          {
            title: 'Note deleted',
            text: 'The note has been deleted',
          },
          { autoClose: 2000 }
        );

        setNotes(notes.filter((note) => note.uuid !== data.deleteQuoteNote));
      },
    }
  );

  const [noteToEdit, setNoteToEdit] = useState<string | null>(null);

  return (
    <div>
      <div className="bg-white rounded-md mb-5">
        <h3 className="text-base font-semibold text-black border-b border-black/20 py-3 px-5 text-lg">
          Notes
        </h3>
        <div className="p-5">
          {notes.length > 0 ? (
            <div className="w-full overflow-x-auto">
              <table className="text-sm w-full">
                <thead>
                  <tr>
                    <th className="text-left text-red font-bold px-4 py-2 border border-black/20">
                      Note
                    </th>
                    <th className="text-center text-red font-bold px-4 py-2 border border-black/20 border-l-0">
                      Type
                    </th>
                    <th className="text-center text-red font-bold px-4 py-2 border border-black/20 border-l-0">
                      Created
                    </th>
                    <th className="text-center text-red font-bold px-4 py-2 border border-black/20 border-l-0">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {notes.map((note) => (
                    <tr key={note.uuid}>
                      {noteToEdit === note.uuid ? (
                        <td
                          colSpan={4}
                          className="p-4 border-r border-l border-b border-black/20"
                        >
                          <Formik
                            initialValues={{
                              text: note.text,
                            }}
                            onSubmit={async (values, props) => {
                              await editQuoteNote({
                                variables: {
                                  input: {
                                    uuid: note.uuid,
                                    note: values.text,
                                  },
                                },
                              });

                              props.resetForm({
                                values,
                              });
                            }}
                          >
                            <Form className="relative">
                              <Field
                                as="textarea"
                                className={classNames(
                                  elements.textarea,
                                  'w-full h-32'
                                )}
                                name="text"
                              />
                              <SubmitButton />
                              {!!editLoading && <PageLoader />}
                            </Form>
                          </Formik>
                        </td>
                      ) : (
                        <>
                          <td className="px-4 py-2 border-r border-l border-b border-black/20">
                            {note.text}
                          </td>
                          <td className="text-center px-4 py-2 border-b  border-r border-black/20">
                            {noteTypeLabels[note.type]}
                          </td>
                          <td className="text-center px-4 py-2 border-b  border-r border-black/20">
                            {new Date(note.createdAt).toNiceDateTimeFormat()}
                          </td>
                          <td className="text-center px-4 py-2 border-b  border-r border-black/20">
                            <ul className="flex items-center -mx-1 w-full justify-center">
                              <li className="px-1">
                                <Button
                                  type="button"
                                  onClick={() => setNoteToEdit(note.uuid)}
                                  className={elements.button.tertiary}
                                >
                                  <FiEdit size={20} />
                                </Button>
                              </li>
                              <li className="px-1">
                                {deleteLoading ? (
                                  <Loader size="1.5rem" />
                                ) : (
                                  <Button
                                    type="button"
                                    className={elements.button.tertiary}
                                    onClick={async () => {
                                      await deleteQuoteNote({
                                        variables: {
                                          quoteNoteUuid: note.uuid,
                                        },
                                      });
                                    }}
                                  >
                                    <FiTrash size={20} />
                                  </Button>
                                )}
                              </li>
                            </ul>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-sm">No notes have been added to this quote</p>
          )}
        </div>
      </div>
      <div className="bg-white rounded-md">
        <Formik
          initialValues={{
            text: '',
          }}
          onSubmit={async (values, props) => {
            await addQuoteNote({
              variables: {
                input: {
                  quoteUuid: quote.uuid,
                  note: values.text,
                },
              },
            });

            props.resetForm({
              values: {
                text: '',
              },
            });
          }}
        >
          <Form className="relative">
            <h3 className="text-base font-semibold text-black border-b border-black/20 py-3 px-5 text-lg">
              Add Note
            </h3>
            <div className="p-5">
              <Field
                as="textarea"
                className={classNames(elements.textarea, 'w-full h-32')}
                name="text"
              />
            </div>
            <SubmitButton />
            {!!addLoading && <PageLoader />}
          </Form>
        </Formik>
      </div>
    </div>
  );
};

const PreviewTab = ({ quote }: Required<Props>) => {
  const [sendQuoteEmail] = useMutation(GqlSendQuoteEmail, {
    onCompleted: () => {
      toaster.success(
        {
          title: 'Email sent',
          text: 'The quote has been sent to the customer',
        },
        { autoClose: 2000 }
      );
    },
  });

  return (
    <div>
      <div className="bg-gray pt-4 px-4 mb-5 flex justify-center">
        <div className="max-w-[210mm] w-full mb-5">
          <div
            style={{
              paddingBottom: 29700 / 210 + '%',
            }}
            className="relative"
          >
            <iframe
              src={`${env.apiUrl}/api/pdf?token=${getApiToken()}&quoteUuid=${
                quote.uuid
              }`}
              className="absolute w-full h-full bg-white"
            />
          </div>
        </div>
      </div>
      <Formik
        initialValues={{}}
        onSubmit={async () => {
          await sendQuoteEmail({
            variables: {
              quoteUuid: quote.uuid,
            },
          });
        }}
      >
        <Form>
          <DownloadButton href={`${env.apiUrl}/api/pdf?token=${getApiToken()}&quoteUuid=${
              quote.uuid
            }&download=1&last=1`} />
          <SendButton showIfNotDirty />
        </Form>
      </Formik>
    </div>
  );
};

interface ProductsTabFormikValues extends Record<string, unknown> {
  lineItems: FormikLineItemType[];
  currency: CurrencyEnum;
  taxType: number;
  discountLineItem: LineItemInput;
  shippingLineItem: LineItemInput;
}

const LinkItemLink = ({
  fileUuid,
  url,
  createdAt,
}: {
  fileUuid?: string | null;
  url?: string | null;
  createdAt?: string;
}) => {
  const { data } = useSuspenseQuery(GqlGetSignedUrl, {
    variables: {
      uuids: fileUuid ? [fileUuid] : [],
      query: '',
    },
    skip: !fileUuid || !!url,
  });

  return (
    <div className="flex items-center">
      <div>
        <a
          href={url ?? data?.getSignedUrl.find(Boolean) ?? ''}
          target="_blank"
          rel="noreferrer"
          data-tooltip-content="Download Attachment"
          data-tooltip-id={url ?? data?.getSignedUrl.find(Boolean) ?? ''}
          data-tooltip-place="bottom"
          className={classNames(
            elements.button.tertiary,
            'h-10 flex items-center'
          )}
        >
          <FiDownload size={20} />
        </a>
        <Tooltip id={url ?? data?.getSignedUrl.find(Boolean) ?? ''} />
      </div>

      {createdAt && (
        <div className="ml-2">
          <span className="text-xs">
            Version: {new Date(createdAt).toNiceDateTimeFormat()}
          </span>
        </div>
      )}
    </div>
  );
};

const LinkItemUploadField = ({
  type,
  lineItemIndex,
  productIndex,
  linkIndex,
  productId,
  productSku,
  productName,
  productUuid,
}: {
  type: ProductUploadType;
  lineItemIndex: number;
  productIndex: number;
  linkIndex: number;
  productId?: number;
  productSku?: string;
  productName?: string;
  productUuid?: string;
}) => {
  const {
    setFieldValue,
    values: { lineItems },
  } = useFormikContext<ProductsTabFormikValues>();

  const { data: internalData } = useSuspenseQuery(GqlReadInternalProduct, {
    variables: {
      uuid: productUuid ?? '',
    },
    skip: !productUuid,
  });

  const { data } = useSuspenseQuery(GqlReadProduct, {
    variables: {
      id: productId ?? 0,
    },
    skip: !productId || !!productUuid,
  });

  const cmsFileUrl = data?.readProduct[type]?.url;
  const productItemFileUuid =
    data?.readProduct.uploads.find((u) => u.type === type)?.fileUuid ??
    internalData?.readInternalProduct.uploads.find((u) => u.type === type)
      ?.fileUuid;

  const onUpload = useCallback<
    (data: CreateFileMutation, clientOptions?: BaseMutationOptions) => void
  >(
    async ({ createFile }) => {
      await setFieldValue(
        'lineItems',
        lineItems.map(
          (l, i): LineItemInput =>
            i === lineItemIndex
              ? {
                  ...l,
                  products: l.products.map(
                    (p, pi): LineItemProductInput =>
                      pi === productIndex
                        ? {
                            ...p,
                            links: p.links.map(
                              (item, index): ProductUploadInput =>
                                index === linkIndex
                                  ? {
                                      ...item,
                                      fileUuid: createFile.uuid,
                                      url: null,
                                    }
                                  : item
                            ),
                          }
                        : p
                  ),
                }
              : l
        )
      );
    },
    [setFieldValue, lineItems, lineItemIndex, productIndex, linkIndex]
  );

  return (
    <div className="flex -mx-2 items-center">
      {!!productItemFileUuid && (
        <div className="px-2">
          <Button
            type="button"
            className={elements.button.tertiary}
            onClick={async () => {
              await setFieldValue(
                'lineItems',
                lineItems.map(
                  (l, i): LineItemInput =>
                    i === lineItemIndex
                      ? {
                          ...l,
                          products: l.products.map(
                            (p, pi): LineItemProductInput =>
                              pi === productIndex
                                ? {
                                    ...p,
                                    links: p.links.map(
                                      (item, index): ProductUploadInput =>
                                        index === linkIndex
                                          ? {
                                              ...item,
                                              fileUuid: productItemFileUuid,
                                            }
                                          : item
                                    ),
                                  }
                                : p
                          ),
                        }
                      : l
                )
              );
            }}
          >
            <FiRefreshCw size={20} />
          </Button>
        </div>
      )}
      {cmsFileUrl && !productItemFileUuid && (
        <div className="px-2">
          <Button
            type="button"
            className={elements.button.tertiary}
            data-tooltip-content="Pull from Product"
            data-tooltip-id={`${lineItemIndex}-${productIndex}-${linkIndex}-pullFromProduct`}
            onClick={async () => {
              await setFieldValue(
                'lineItems',
                lineItems.map(
                  (l, i): LineItemInput =>
                    i === lineItemIndex
                      ? {
                          ...l,
                          products: l.products.map(
                            (p, pi): LineItemProductInput =>
                              pi === productIndex
                                ? {
                                    ...p,
                                    links: p.links.map(
                                      (item, index): ProductUploadInput =>
                                        index === linkIndex
                                          ? {
                                              ...item,
                                              url: cmsFileUrl,
                                            }
                                          : item
                                    ),
                                  }
                                : p
                          ),
                        }
                      : l
                )
              );
            }}
          >
            <FiRefreshCcw size={20} />
          </Button>
          <Tooltip
            id={`${lineItemIndex}-${productIndex}-${linkIndex}-pullFromProduct`}
          />
        </div>
      )}
      <div className="px-2">
        <UploadField
          onCompleted={onUpload}
          product={{
            uuid: productUuid,
            sku: productSku,
            name: productName,
            type,
          }}
          legacyProduct={
            productId && productSku
              ? {
                  id: productId,
                  sku: productSku,
                  type,
                }
              : undefined
          }
        />
      </div>
    </div>
  );
};

type FormikLineItemType = Omit<LineItemInput, 'products'> & {
  products: Array<
    Omit<LineItemProductInput, 'links' | 'meta'> & {
      links: Array<
        ProductUploadInput & {
          createdAt?: string;
        }
      >;
      meta?: Array<
        MetaDataInput & {
          createdAt: string;
        }
      >;
    }
  >;
};

const LineItem = ({
  lineItem,
  index,
}: {
  lineItem: FormikLineItemType;
  index: number;
}) => {
  const {
    values: { currency, lineItems, taxType },
    setFieldValue,
  } = useFormikContext<ProductsTabFormikValues>();

  return (
    <tr>
      <td className="text-left border-b border-r border-l border-black/20">
        <div className="flex flex-col items-center my-5">
          {lineItem.products.map((p, pi) => (
            <div
              key={`${pi}-${p.productId}-${p.productUuid}`}
              className="flex flex-col w-full mb-5 last:mb-0 px-5 pb-5 border-b border-black/20"
            >
              <div className="flex items-end -mx-1">
                <div className="px-1 flex-grow">
                  <ProductSearchField
                    label={pi > 0 ? 'Secondary Product' : 'Primary Product'}
                    labelClassNames={
                      pi > 0
                        ? 'border-2 border-dark text-dark rounded-md shrink py-1 px-2'
                        : 'bg-dark text-white rounded-md shrink py-1 px-2'
                    }
                    controls={
                      <div className="flex -mr-[54px]">
                        {pi > 0 && (
                          <Button
                            type="button"
                            className={classNames(
                              elements.button.tertiary,
                              'h-10 flex items-center'
                            )}
                            data-tooltip-id={`move-secondary-product-up-${index}-${pi}`}
                            data-tooltip-content={
                              pi === 0
                                ? 'Move Primary Product Up'
                                : 'Move Secondary Product Up'
                            }
                            data-tooltip-place="bottom"
                            onClick={() => {
                              // move up
                              void setFieldValue(
                                'lineItems',
                                lineItems.map(
                                  (li, i): LineItemInput =>
                                    i === index
                                      ? {
                                          ...li,
                                          products: li.products.map(
                                            (item, ppi): LineItemProductInput =>
                                              ppi === pi
                                                ? li.products[pi - 1]
                                                : ppi === pi - 1
                                                ? li.products[pi]
                                                : item
                                          ),
                                        }
                                      : li
                                )
                              );
                            }}
                          >
                            <FiArrowUp size={20} />
                          </Button>
                        )}
                        {lineItem.products.length > pi + 1 && (
                          <Button
                            type="button"
                            className={classNames(
                              elements.button.tertiary,
                              'h-10 flex items-center ml-2'
                            )}
                            data-tooltip-id={`move-secondary-product-down-${index}-${pi}`}
                            data-tooltip-content={
                              pi === 0
                                ? 'Move Primary Product Down'
                                : 'Move Secondary Product Down'
                            }
                            data-tooltip-place="bottom"
                            onClick={() => {
                              // move down
                              void setFieldValue(
                                'lineItems',
                                lineItems.map(
                                  (li, i): LineItemInput =>
                                    i === index
                                      ? {
                                          ...li,
                                          products: li.products.map(
                                            (item, ppi): LineItemProductInput =>
                                              ppi === pi
                                                ? li.products[pi + 1]
                                                : ppi === pi + 1
                                                ? li.products[pi]
                                                : item
                                          ),
                                        }
                                      : li
                                )
                              );
                            }}
                          >
                            <FiArrowDown size={20} />
                          </Button>
                        )}
                        <Tooltip
                          id={`move-secondary-product-up-${index}-${pi}`}
                        />
                        <Tooltip
                          id={`move-secondary-product-down-${index}-${pi}`}
                        />
                      </div>
                    }
                    onSelect={async (product) => {
                      const originalAmount =
                        product.regularPrices.filter(
                          (item) => item.currency === currency
                        )[0]?.amount ?? 0;

                      const saleAmount = product.salePrices.filter(
                        (item) => item.currency === currency
                      )[0]?.amount;

                      const amount = saleAmount ? saleAmount : originalAmount;
                      const isSaleItem =
                        !!saleAmount && saleAmount < originalAmount;

                      await setFieldValue(
                        'lineItems',
                        lineItems.map(
                          (li, i): LineItemInput =>
                            i === index
                              ? {
                                  ...li,
                                  title: product.name,
                                  type: LineItemTypeEnum.product,
                                  taxRate: taxType,
                                  originalAmount,
                                  amount,
                                  products: li.products.map(
                                    (pp, ppi): LineItemProductInput =>
                                      ppi === pi
                                        ? {
                                            uuid: pp.uuid,
                                            sku: product.sku,
                                            isSaleItem,
                                            productUuid: product.uuid,
                                            productId: product.id,
                                            leadTime: product.leadTime ?? '',
                                            title: product.name,
                                            links: product.uploads.length
                                              ? product.uploads.map((u) => ({
                                                  type: u.type,
                                                  fileUuid: u.fileUuid,
                                                  url: u.url,
                                                  createdAt: u.file?.createdAt,
                                                }))
                                              : [
                                                  ...(product.datasheet
                                                    ? [
                                                        {
                                                          type: ProductUploadType.datasheet,
                                                          url: product.datasheet.url,
                                                        },
                                                      ]
                                                    : []),
                                                  ...(product.manual
                                                    ? [
                                                        {
                                                          type: ProductUploadType.manual,
                                                          url: product.manual.url,
                                                        },
                                                      ]
                                                    : []),
                                                ],
                                            meta: product.meta.map(
                                              (m): MetaDataInput => ({
                                                key: m.key,
                                                value: m.value,
                                              })
                                            ),
                                          }
                                        : pp
                                  ),
                                }
                              : li
                        )
                      );
                    }}
                    name={`lineItems.${index}.products.${pi}.title`}
                    useFormik
                  />
                </div>
                <div className="px-1">
                  <Button
                    type="button"
                    className={classNames(
                      elements.button.tertiary,
                      'h-10 flex items-center mb-5'
                    )}
                    data-tooltip-id={`delete-secondary-product-${index}-${pi}`}
                    data-tooltip-content={
                      pi === 0
                        ? 'Delete Primary Product'
                        : 'Delete Secondary Product'
                    }
                    onClick={async () => {
                      await setFieldValue(
                        'lineItems',
                        lineItems
                          .map(
                            (li, i): LineItemInput =>
                              i === index
                                ? {
                                    ...li,
                                    products: li.products.filter(
                                      (_, ppi) => ppi !== pi
                                    ),
                                  }
                                : li
                          )
                          .filter((l) => (l.products?.length ?? 0) > 0)
                      );
                    }}
                  >
                    <FiTrash size={20} />
                  </Button>
                  <Tooltip id={`delete-secondary-product-${index}-${pi}`} />
                </div>
              </div>
              <div className="mb-5">
                <Input
                  name={`lineItems.${index}.products.${pi}.sku`}
                  placeholder="Part No.: Create new product"
                  label="Part No."
                />
                {(!!p.productId || !!p.productUuid) && (
                  <small style={{ fontSize: '0.6875rem' }}>
                    Please note, editing this Part No. will create a new product
                    in the products database after saving this quote
                  </small>
                )}
              </div>
              <h4 className="mb-2 font-bold">Attachments</h4>
              <ul className="mb-5 w-full">
                {p.links.length > 0 ? (
                  <>
                    {p.links.map((link, li) => (
                      <li
                        className="flex items-center justify-between mb-5"
                        key={`${li}-${link.type}-${link.fileUuid}-${link.url}`}
                      >
                        <div className="flex items-center grow">
                          <div>
                            <Dropdown
                              name={`lineItems.${index}.products.${pi}.links.${li}.type`}
                              useFormik
                              buttonClassNames="min-w-32"
                              options={[
                                {
                                  label: 'Datasheet',
                                  value: ProductUploadType.datasheet,
                                },
                                {
                                  label: 'Manual',
                                  value: ProductUploadType.manual,
                                },
                              ]}
                            />
                          </div>
                          <span className="px-0.5">
                            <BiDotsVerticalRounded size={18} />
                          </span>
                          {!!link.fileUuid || !!link.url ? (
                            <div className="relative">
                              <Suspense fallback={<PageLoader />}>
                                <LinkItemLink {...link} />
                              </Suspense>
                            </div>
                          ) : (
                            <div className="relative">
                              <Suspense fallback={<PageLoader />}>
                                <LinkItemUploadField
                                  type={link.type}
                                  lineItemIndex={index}
                                  productIndex={pi}
                                  linkIndex={li}
                                  productId={p.productId ?? undefined}
                                  productSku={p.sku ?? undefined}
                                  productName={p.title}
                                  productUuid={p.productUuid ?? undefined}
                                />
                              </Suspense>
                            </div>
                          )}
                        </div>
                        <div className="ml-2">
                          <Button
                            type="button"
                            className={classNames(
                              elements.button.tertiary,
                              'h-10 flex items-center'
                            )}
                            data-tooltip-id={`delete-attachment-${index}-${pi}-${li}`}
                            data-tooltip-content="Delete attachment"
                            onClick={async () => {
                              await setFieldValue(
                                'lineItems',
                                lineItems.map(
                                  (l, i): LineItemInput =>
                                    i === index
                                      ? {
                                          ...l,
                                          products: l.products.map(
                                            (item, pix): LineItemProductInput =>
                                              pi === pix
                                                ? {
                                                    ...item,
                                                    links: item.links.filter(
                                                      (_, lindex) =>
                                                        lindex !== li
                                                    ),
                                                  }
                                                : item
                                          ),
                                        }
                                      : l
                                )
                              );
                            }}
                          >
                            <FiTrash size={20} />
                          </Button>
                          <Tooltip
                            id={`delete-attachment-${index}-${pi}-${li}`}
                          />
                        </div>
                      </li>
                    ))}
                    <li className="flex items-center justify-end">
                      <div>
                        <Button
                          type="button"
                          data-tooltip-id={`add-attachment-${index}-${pi}`}
                          data-tooltip-content="Add attachment"
                          className={classNames(
                            elements.button.tertiary,
                            'lg:w-auto'
                          )}
                          onClick={async () => {
                            await setFieldValue(
                              'lineItems',
                              lineItems.map(
                                (item, i): LineItemInput =>
                                  i === index
                                    ? {
                                        ...item,
                                        products: item.products.map(
                                          (pItem, pix): LineItemProductInput =>
                                            pi === pix
                                              ? {
                                                  ...pItem,
                                                  links: [
                                                    ...pItem.links,
                                                    {
                                                      type: ProductUploadType.datasheet,
                                                    },
                                                  ] as ProductUploadInput[],
                                                }
                                              : pItem
                                        ),
                                      }
                                    : item
                              )
                            );
                          }}
                        >
                          <FiPlus size={20} />
                        </Button>
                        <Tooltip id={`add-attachment-${index}-${pi}`} />
                      </div>
                    </li>
                  </>
                ) : (
                  <li>
                    <Button
                      type="button"
                      className={classNames(
                        elements.button.tertiary,
                        'lg:w-auto'
                      )}
                      data-tooltip-id={`${index}-${pi}-addAttachment`}
                      data-tooltip-content="Add Attachment"
                      data-tooltip-place="bottom"
                      onClick={async () => {
                        await setFieldValue(
                          'lineItems',
                          lineItems.map(
                            (li, i): LineItemInput =>
                              i === index
                                ? {
                                    ...li,
                                    products: li.products.map(
                                      (item, pix): LineItemProductInput =>
                                        pi === pix
                                          ? {
                                              ...item,
                                              links: [
                                                ...item.links,
                                                {
                                                  type: ProductUploadType.datasheet,
                                                },
                                              ] as ProductUploadInput[],
                                            }
                                          : item
                                    ),
                                  }
                                : li
                          )
                        );
                      }}
                    >
                      <FiPlus size={20} />
                    </Button>
                    <Tooltip id={`${index}-${pi}-addAttachment`} />
                  </li>
                )}
              </ul>
              <h4 className="mb-2 font-bold">Lead Time</h4>
              <Field
                as="textarea"
                name={`lineItems.${index}.products.${pi}.leadTime`}
                className={classNames(elements.textarea, 'mb-5 h-auto')}
              />
              <h4 className="mb-2 font-bold">Meta Data</h4>
              <ul className="w-full">
                {(p.meta?.length ?? 0) > 0 ? (
                  <>
                    {p.meta?.map((m, i) => (
                      <li
                        key={m.createdAt}
                        className="flex items-center justify-between mb-5"
                      >
                        <div className="flex items-center grow">
                          <Input
                            name={`lineItems.${index}.products.${pi}.meta.${i}.key`}
                          />
                          <span className="px-0.5">
                            <BiDotsVerticalRounded size={18} />
                          </span>
                          <Field
                            as="textarea"
                            name={`lineItems.${index}.products.${pi}.meta.${i}.value`}
                            className={classNames(elements.textarea, 'h-11')}
                          />
                        </div>

                        <div className="ml-2">
                          <Button
                            type="button"
                            className={classNames(
                              elements.button.tertiary,
                              'h-10 flex items-center'
                            )}
                            data-tooltip-id={`delete-meta-${index}-${pi}-${i}`}
                            data-tooltip-content="Delete Meta Data"
                            onClick={async () => {
                              await setFieldValue(
                                'lineItems',
                                lineItems.map(
                                  (l, li): LineItemInput =>
                                    li === index
                                      ? {
                                          ...l,
                                          products: l.products.map(
                                            (item, ppi): LineItemProductInput =>
                                              ppi === pi
                                                ? {
                                                    ...item,
                                                    meta: item.meta?.filter(
                                                      (_, mi) => mi !== i
                                                    ),
                                                  }
                                                : item
                                          ),
                                        }
                                      : l
                                )
                              );
                            }}
                          >
                            <FiTrash size={20} />
                          </Button>
                          <Tooltip id={`delete-meta-${index}-${pi}-${i}`} />
                        </div>
                      </li>
                    ))}
                    <li className="flex items-center justify-end">
                      <div>
                        <Button
                          type="button"
                          data-tooltip-id={`add-meta-${index}-${pi}`}
                          data-tooltip-content="Add Meta Data"
                          className={classNames(
                            elements.button.tertiary,
                            'lg:w-auto'
                          )}
                          onClick={async () => {
                            await setFieldValue(
                              'lineItems',
                              lineItems.map(
                                (li, lindex): LineItemInput =>
                                  lindex === index
                                    ? {
                                        ...li,
                                        products: li.products.map(
                                          (item, pix): LineItemProductInput =>
                                            pi === pix
                                              ? {
                                                  ...item,
                                                  meta: [
                                                    ...(item.meta ?? []),
                                                    {
                                                      key: '',
                                                      value: '',
                                                      createdAt:
                                                        new Date().toISOString(),
                                                    },
                                                  ],
                                                }
                                              : item
                                        ),
                                      }
                                    : li
                              )
                            );
                          }}
                        >
                          <FiPlus size={20} />
                        </Button>
                        <Tooltip id={`add-meta-${index}-${pi}`} />
                      </div>
                    </li>
                  </>
                ) : (
                  <li>
                    <Button
                      type="button"
                      data-tooltip-content="Add Meta Data"
                      data-tooltip-id={`${index}-${pi}-addMeta`}
                      data-tooltip-place="bottom"
                      className={classNames(
                        elements.button.tertiary,
                        'lg:w-auto'
                      )}
                      onClick={async () => {
                        await setFieldValue(
                          'lineItems',
                          lineItems.map(
                            (li, i): LineItemInput =>
                              i === index
                                ? {
                                    ...li,
                                    products: li.products.map(
                                      (item, pix): LineItemProductInput =>
                                        pi === pix
                                          ? {
                                              ...item,
                                              meta: [
                                                ...(item.meta ?? []),
                                                {
                                                  key: '',
                                                  value: '',
                                                },
                                              ],
                                            }
                                          : item
                                    ),
                                  }
                                : li
                          )
                        );
                      }}
                    >
                      <FiPlus size={20} />
                    </Button>
                    <Tooltip id={`${index}-${pi}-addMeta`} />
                  </li>
                )}
              </ul>
              <Field
                type="hidden"
                name={`lineItems.${index}.products.${pi}.productId`}
              />
            </div>
          ))}
          <div className="flex -mx-1 items-center">
            <div className="px-1">
              <Button
                type="button"
                className={elements.button.tertiary}
                data-tooltip-id={`${index}-addProduct`}
                data-tooltip-content={
                  lineItem.products.length > 0
                    ? 'Add Secondary Product'
                    : 'Add Primary Product'
                }
                data-tooltip-place="bottom"
                onClick={async () => {
                  await setFieldValue(
                    'lineItems',
                    lineItems.map(
                      (li, i): LineItemInput =>
                        i === index
                          ? {
                              ...li,
                              products: [
                                ...li.products,
                                {
                                  title: '',
                                  leadTime: '',
                                  meta: [],
                                  links: [],
                                  productId: 0,
                                  isSaleItem: false,
                                },
                              ] as LineItemProductInput[],
                            }
                          : li
                    )
                  );
                }}
              >
                <FiPlus size={20} />
              </Button>
              <Tooltip id={`${index}-addProduct`} />
            </div>
            <div className="px-1">
              <Button
                type="button"
                className={elements.button.tertiary}
                data-tooltip-id={`${index}-deleteProduct`}
                data-tooltip-content="Delete Line Item"
                data-tooltip-place="bottom"
                onClick={() => {
                  void setFieldValue(
                    'lineItems',
                    lineItems.filter((_, i) => i !== index)
                  );
                }}
              >
                <FiTrash size={20} />
              </Button>
              <Tooltip id={`${index}-deleteProduct`} />
            </div>
            {index > 0 && (
              <div className="px-1">
                <Button
                  type="button"
                  className={elements.button.tertiary}
                  data-tooltip-id={`${index}-moveProductUp`}
                  data-tooltip-content="Move Line Item Up"
                  data-tooltip-place="bottom"
                  onClick={() => {
                    void setFieldValue('lineItems', [
                      ...lineItems.slice(0, index - 1),
                      lineItems[index],
                      lineItems[index - 1],
                      ...lineItems.slice(index + 1),
                    ]);
                  }}
                >
                  <FiArrowUp size={20} />
                </Button>
                <Tooltip id={`${index}-moveProductUp`} />
              </div>
            )}
            {lineItems.length > index + 1 && (
              <div className="px-1">
                <Button
                  type="button"
                  className={elements.button.tertiary}
                  data-tooltip-id={`${index}-moveProductDown`}
                  data-tooltip-content="Move Line Item Down"
                  data-tooltip-place="bottom"
                  onClick={() => {
                    void setFieldValue('lineItems', [
                      ...lineItems.slice(0, index),
                      lineItems[index + 1],
                      lineItems[index],
                      ...lineItems.slice(index + 2),
                    ]);
                  }}
                >
                  <FiArrowDown size={20} />
                </Button>
                <Tooltip id={`${index}-moveProductDown`} />
              </div>
            )}
          </div>
        </div>
      </td>
      <td className="text-center p-4 border-b border-r border-black/20">
        <Input type="number" name={`lineItems.${index}.quantity`} min={1} />
      </td>
      <td className="text-center p-4 border-b border-r border-black/20">
        <Input
          type="number"
          name={`lineItems.${index}.amount`}
          min={0}
          step={0.01}
        />
      </td>
      <td className="text-center p-4 border-b border-r border-black/20">
        {formatMoney(lineItem.quantity * lineItem.amount, currency)}
      </td>
      <td className="text-center p-4 border-b border-r border-black/20">
        {formatMoney(
          lineItem.quantity * lineItem.amount * lineItem.taxRate,
          currency
        )}
      </td>
      <td className="text-center p-4 border-b border-r border-black/20 font-bold">
        {formatMoney(
          lineItem.quantity * lineItem.amount * (1 + lineItem.taxRate),
          currency
        )}
      </td>
    </tr>
  );
};

const ProductsTable = () => {
  const {
    values: {
      lineItems,
      taxType,
      currency,
      discountLineItem,
      shippingLineItem,
    },
    setFieldValue,
  } = useFormikContext<ProductsTabFormikValues>();

  const allLineItems = [
    ...lineItems,
    discountLineItem,
    shippingLineItem,
  ].filter(
    (
      l?: FormikLineItemType | LineItemInput
    ): l is FormikLineItemType | LineItemInput => !!l
  );

  return (
    <div className="mb-20">
      <table className="w-full  text-sm">
        <thead>
          <tr className="text-base">
            <th className="text-left font-bold p-4 border border-black/20">
              Product / Part No.
            </th>
            <th className="text-center font-bold p-4 border border-black/20 border-l-0 w-32">
              Qty
            </th>
            <th className="text-center font-bold p-4 border border-black/20 border-l-0 w-32">
              Amount
            </th>
            <th className="text-center font-bold p-4 border border-black/20 border-l-0 w-32">
              Gross
            </th>
            <th className="text-center font-bold p-4 border border-black/20 border-l-0 w-32">
              Tax
            </th>
            <th className="text-center font-bold p-4 border border-black/20 border-l-0 w-32">
              Net
            </th>
          </tr>
        </thead>
        <tbody>
          {lineItems.map((lineItem, index) => (
            <LineItem
              key={`${index}-${lineItem.title}`}
              lineItem={lineItem}
              index={index}
            />
          ))}
          <tr>
            <td
              colSpan={6}
              className="py-8 border-r border-l border-b border-black/20"
            >
              <div className="flex flex-col items-center">
                <Button
                  type="button"
                  className={classNames(
                    elements.button.primary,
                    'lg:w-auto text-base'
                  )}
                  onClick={async () => {
                    const lineItem: LineItemInput = {
                      quantity: 1,
                      amount: 0,
                      taxRate: taxType,
                      originalAmount: 0,
                      title: '',
                      type: LineItemTypeEnum.product,
                      products: [
                        {
                          title: '',
                          leadTime: '',
                          meta: [],
                          links: [],
                          productId: 0,
                          isSaleItem: false,
                        },
                      ],
                    };

                    const newLineItems = [...lineItems, lineItem];

                    // setFieldValue before the last two items but after the others
                    await setFieldValue('lineItems', newLineItems);
                  }}
                >
                  <FiShoppingBag size={20} />
                  <span className="ml-2">Add Primary Product</span>
                </Button>
              </div>
            </td>
          </tr>
          <tr className="text-base">
            <td
              colSpan={3}
              className="text-left p-4 border-b border-r border-l border-black/20"
            >
              <h4 className="font-bold ">Discount</h4>
            </td>
            <td className="text-center p-4 border-b  border-r border-black/20">
              <input
                type="number"
                name={`discountLineItem.amount`}
                className={classNames(elements.input, 'pl-3 w-full')}
                value={Math.abs(discountLineItem.amount) * -1}
                max={0}
                onInput={async (e) => {
                  await setFieldValue(
                    'discountLineItem',
                    omitEmpty({
                      ...discountLineItem,
                      amount: Math.abs(parseFloat(e.currentTarget.value)) * -1,
                    }) as LineItemInput
                  );
                }}
              />
            </td>
            <td className="text-center p-4 border-b  border-r border-black/20">
              {formatMoney(
                (discountLineItem.amount || 0) *
                  (discountLineItem.taxRate || 0),
                currency
              )}
            </td>
            <td className="text-center p-4 border-b  border-r border-black/20 font-bold">
              {formatMoney(
                (discountLineItem.amount || 0) *
                  (1 + (discountLineItem.taxRate || 0)),
                currency
              )}
            </td>
          </tr>
          <tr className="text-base">
            <td
              colSpan={3}
              className="text-left p-4 border-b border-r border-l border-black/20"
            >
              <div className="flex items-center justify-between">
                <div>
                  <h4 className="font-bold">Shipping</h4>
                </div>
                <div>
                  <Dropdown
                    name={`shippingLineItem.shippingMethod`}
                    useFormik
                    options={(
                      Object.keys(shippingMethodLabels) as Array<
                        keyof typeof shippingMethodLabels
                      >
                    ).map((o) => ({
                      value: o,
                      label: shippingMethodLabels[o],
                    }))}
                    onChange={async (opt) => {
                      await setFieldValue(
                        'shippingLineItem.amount',
                        defaultShippingValues[opt.value]
                      );
                    }}
                  />
                </div>
              </div>
            </td>
            <td className="text-center p-4 border-b  border-r border-black/20">
              <Input type="number" name={`shippingLineItem.amount`} min={0} />
            </td>
            <td className="text-center p-4 border-b  border-r border-black/20">
              {formatMoney(
                (shippingLineItem.amount || 0) *
                  (shippingLineItem.taxRate || 0),
                currency
              )}
            </td>
            <td className="text-center p-4 border-b  border-r border-black/20 font-bold">
              {formatMoney(
                (shippingLineItem.amount || 0) *
                  (1 + (shippingLineItem.taxRate || 0)),
                currency
              )}
            </td>
          </tr>
          <tr className="text-base">
            <td
              colSpan={3}
              className="text-left p-4 border-b border-r border-l border-black/20"
            >
              <h4 className="text-red font-bold">Total</h4>
            </td>
            <td className="text-center p-4 border-b  border-r border-black/20">
              {formatMoney(
                allLineItems.reduce(
                  (acc, li) => acc + li.amount * li.quantity,
                  0
                ),
                currency
              )}
            </td>
            <td className="text-center p-4 border-b  border-r border-black/20">
              {formatMoney(
                allLineItems.reduce(
                  (acc, li) => acc + li.amount * li.quantity * li.taxRate,
                  0
                ),
                currency
              )}
            </td>
            <td className="text-center p-4 border-b  border-r border-black/20 font-bold text-red">
              {formatMoney(
                allLineItems.reduce(
                  (acc, li) => acc + li.amount * li.quantity * (1 + li.taxRate),
                  0
                ),
                currency
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const TaxRateField = () => {
  const {
    values: { lineItems, taxType, discountLineItem, shippingLineItem },
    setFieldValue,
  } = useFormikContext<ProductsTabFormikValues>();

  useEffect(() => {
    void (async () => {
      await setFieldValue(
        'lineItems',
        lineItems.map(
          (lineItem): LineItemInput => ({
            ...lineItem,
            taxRate: taxType,
          })
        )
      );

      await setFieldValue(
        'discountLineItem',
        {
          ...discountLineItem,
          taxRate: taxType,
        } as LineItemInput
      );

      await setFieldValue(
        'shippingLineItem',
        {
          ...shippingLineItem,
          taxRate: taxType,
        } as LineItemInput
      );

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxType, setFieldValue]);
  return (
    <Dropdown
      name="taxType"
      useFormik
      options={[
        {
          value: 0.2,
          label: 'Standard',
        },
        {
          value: 0,
          label: 'Zero Rated',
        },
      ]}
    />
  );
};

const ProductsTab = forwardRef(
  (
    {
      quoteUuid,
      quote,
      loading,
      onSave,
    }: {
      quoteUuid?: string;
      quote: OverallProgress;
      loading: boolean;
      onSave: (
        arg: Pick<OverallProgress, 'lineItems' | 'currency'>
      ) => Promise<void>;
    },
    ref: ForwardedRef<FormikProps<Record<string, unknown>>>
  ) => {
    const taxType = quote.lineItems?.find(Boolean)?.taxRate ?? (quote.shippingAddress?.country !== 'GB' ? 0 : 0.2);

    const discountLineItem: LineItemInput = quote.lineItems?.find(
      (l) => l.type === LineItemTypeEnum.discount
    ) ?? {
      amount: 0,
      originalAmount: 0,
      quantity: 1,
      taxRate: taxType,
      title: 'Discount',
      type: LineItemTypeEnum.discount,
    };

    const shippingLineItem: LineItemInput = quote.lineItems?.find(
      (l) => l.type === LineItemTypeEnum.shipping
    ) ?? {
      amount:
        defaultShippingValues[
          quote.shippingAddress?.country !== 'GB'
            ? ShippingMethodEnum.international
            : ShippingMethodEnum.uk
        ],
      originalAmount: 0,
      quantity: 1,
      taxRate: taxType,
      title: 'Shipping',
      type: LineItemTypeEnum.shipping,
      shippingMethod:
        quote.shippingAddress?.country !== 'GB'
          ? ShippingMethodEnum.international
          : ShippingMethodEnum.uk,
    };

    const initialValues: ProductsTabFormikValues = {
      lineItems:
        quote.lineItems
          ?.filter((l) => l.type === LineItemTypeEnum.product)
          .map((l) => ({
            ...l,
            products:
              l.products?.map((p) => ({
                ...p,
                links: p.links ?? [],
                meta:
                  p.meta?.map((m, i) => ({
                    ...m,
                    createdAt: new Date(Date.now() + i).toISOString(),
                  })) ?? [],
              })) ?? [],
          })) ?? [],
      discountLineItem,
      shippingLineItem,
      currency: quote.currency ?? CurrencyEnum.gbp,
      taxType,
    };

    const navigate = useNavigate();

    return (
      <Formik
        initialValues={initialValues}
        innerRef={ref as ForwardedRef<FormikProps<ProductsTabFormikValues>>}
        validate={(values) => {
          const errors: Record<string, string> = {};
          if (
            values.lineItems.filter((p) => p.products.some((pp) => pp.title))
              .length === 0
          ) {
            errors.lineItems = 'At least one product is required';
          }
          return errors;
        }}
        onSubmit={async (values) => {
          await onSave({
            lineItems: [
              ...values.lineItems.map((l) => ({
                ...l,
                products: l.products.map((p) => ({
                  ...p,
                  links: p.links.map(({ url, ...link }) => ({
                    ...link,
                    url: link.fileUuid ? undefined : url,
                  })),
                })),
              })),
              values.discountLineItem,
              values.shippingLineItem,
            ].filter((l?: LineItemInput): l is LineItemInput => !!l),
            currency: values.currency,
          });
        }}
      >
        <Form className="relative">
          <div className="bg-white rounded-md">
            <div className="flex justify-between items-center border-b border-black/20 py-3 px-5">
              <h3 className="text-lg font-semibold text-black">Products</h3>
              <div className="flex -mx-2">
                <div className="px-2">
                  <TaxRateField />
                </div>
                <div className="px-2">
                  <Dropdown
                    name="currency"
                    useFormik
                    options={(
                      Object.keys(currencyLabels) as Array<
                        keyof typeof currencyLabels
                      >
                    ).map((currency) => ({
                      value: currency,
                      label: currencyLabels[currency],
                    }))}
                  />
                </div>
              </div>
            </div>
            <div className="p-5">
              <ProductsTable />
            </div>
          </div>
          <NextButton
            afterSubmit={() => {
              void navigate({
                to: quoteUuid
                  ? `/admin/quotes/${quoteUuid}#details`
                  : `/admin/quotes/add#details`,
              });
            }}
          />
          {!!loading && <PageLoader />}
        </Form>
      </Formik>
    );
  }
);

const AddressCollection = () => {
  const {
    values: { skipAddress, shippingSameAsBilling },
  } = useFormikContext<OverallProgress>();

  return (
    <>
      <div className="flex -mx-2 flex-wrap mb-8">
        <div className="px-2">
          <Checkbox
            name="skipAddress"
            label="Skip Address Collection?"
            useFormik
          />
        </div>
        {!skipAddress && (
          <div className="px-2">
            <Checkbox
              label="Shipping same as billing details?"
              name="shippingSameAsBilling"
              useFormik
            />
          </div>
        )}
      </div>
      <div className="flex -mx-2 flex-wrap">
        {!skipAddress ? (
          <>
            <div className="px-2 grow flex flex-col">
              <h3 className="text-base font-semibold text-black mb-2">
                Billing Details
              </h3>
              <AddressFieldGroup prefix="billingAddress" />
            </div>
            {!shippingSameAsBilling && (
              <>
                <div className="px-2 grow py-8 lg:hidden">
                  <div className="border-b border-black/20" />
                </div>
                <div className="px-2 grow flex flex-col">
                  <h3 className="text-base font-semibold text-black mb-2">
                    Shipping Details
                  </h3>
                  <AddressFieldGroup prefix="shippingAddress" />
                </div>
              </>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

const FormikCustomerSearchField = ({
  setEditMode,
}: {
  setEditMode: (mode: 'add' | 'edit') => void;
}) => {
  const {
    setValues,
    submitForm,
    values: {
      customer: { firstName, lastName, email, accountingReference },
    },
  } = useFormikContext<CustomerTabFormikValues>();

  return (
    <>
      <CustomerSearchField
        hideLabel
        required
        onSelect={async (customer) => {
          await setValues((values) => ({
            ...values,
            ...omitEmpty({
              customer: {
                uuid: customer.uuid,
                legacyId: customer.legacyId,
                firstName: customer.firstName,
                lastName: customer.lastName,
                email: customer.email,
                displayName: customer.displayName,
                accountingReference: customer.accountingReference,
              },
              billingAddress: customer.billingAddress
                ? cleanTypeName(
                    omitEmpty({
                      ...customer.billingAddress,
                      uuid: undefined,
                    })
                  )
                : {
                    firstName: customer.firstName,
                    lastName: customer.lastName,
                    email: customer.email,
                    line1: '',
                    line2: '',
                    city: '',
                    state: '',
                    postCode: '',
                    country: 'GB',
                  },
              shippingAddress: customer.shippingAddress
                ? cleanTypeName(
                    omitEmpty({
                      ...customer.shippingAddress,
                      uuid: undefined,
                    })
                  )
                : {
                    firstName: customer.firstName,
                    lastName: customer.lastName,
                    email: customer.email,
                    line1: '',
                    line2: '',
                    city: '',
                    state: '',
                    postCode: '',
                    country: 'GB',
                  },
            }),
          }));

          await submitForm();
        }}
        name="customer.displayName"
        useFormik
      />
      <div className="flex -mx-1">
        {(!!firstName || !!lastName || !!email || !!accountingReference) && (
          <div className="px-1">
            <Button
              type="button"
              className={elements.button.tertiary}
              onClick={() => setEditMode('edit')}
              data-tooltip-id="edit-customer"
              data-tooltip-content="Edit Customer"
              data-tooltip-place="bottom-start"
            >
              <FiEdit size={20} />
            </Button>
            <Tooltip id="edit-customer" />
          </div>
        )}
        <div className="px-1">
          <Button
            className={elements.button.tertiary}
            onClick={() => setEditMode('add')}
            type="button"
            data-tooltip-id="add-customer"
            data-tooltip-content="Add a new customer"
            data-tooltip-place="bottom-start"
          >
            <FiPlus size={20} />
          </Button>
          <Tooltip id="add-customer" />
        </div>
      </div>
    </>
  );
};

const FormikLegacyCustomerSearchField = () => {
  const { setValues, submitForm } = useFormikContext<CustomerTabFormikValues>();

  return (
    <LegacyCustomerSearchField
      hideLabel
      onSelect={async (customer) => {
        await setValues((values) => ({
          ...values,
          ...omitEmpty({
            customer: {
              legacyId: customer.id,
              firstName: customer.firstName,
              lastName: customer.lastName,
              email: customer.email,
              displayName: customer.displayName,
              legacyDisplayName: undefined,
            },
            billingAddress: customer.billingAddress
              ? cleanTypeName(
                  omitEmpty({
                    ...customer.billingAddress,
                    uuid: undefined,
                  })
                )
              : {
                  firstName: customer.firstName,
                  lastName: customer.lastName,
                  email: customer.email,
                  line1: '',
                  line2: '',
                  city: '',
                  state: '',
                  postCode: '',
                  country: 'GB',
                },
            shippingAddress: customer.shippingAddress
              ? cleanTypeName(
                  omitEmpty({
                    ...customer.shippingAddress,
                    uuid: undefined,
                  })
                )
              : {
                  firstName: customer.firstName,
                  lastName: customer.lastName,
                  email: customer.email,
                  line1: '',
                  line2: '',
                  city: '',
                  state: '',
                  postCode: '',
                  country: 'GB',
                },
          }),
        }));

        await submitForm();
      }}
      name="customer.legacyDisplayName"
      useFormik
    />
  );
};

interface CustomerTabFormikValues extends Record<string, unknown> {
  skipAddress: boolean;
  shippingSameAsBilling: boolean;
  billingAddress?: AddressInput;
  shippingAddress?: AddressInput;
  customer: CreateQuoteInput['customer'] & {
    displayName?: string;
    legacyDisplayName?: string;
  };
}

const CustomerTab = forwardRef(
  (
    {
      quoteUuid,
      loading,
      quote,
      onSave,
    }: {
      quoteUuid?: string;
      loading: boolean;
      quote: OverallProgress;
      onSave: (data: CustomerTabFormikValues) => Promise<void>;
    },
    ref: ForwardedRef<FormikProps<Record<string, unknown>>>
  ) => {
    const [editMode, setEditMode] = useState<'add' | 'edit' | undefined>(
      undefined
    );

    const initialValues: CustomerTabFormikValues = {
      customer: {
        uuid: quote.customer?.uuid ?? undefined,
        legacyId: quote.customer?.legacyId,
        firstName: quote.customer?.firstName ?? '',
        lastName: quote.customer?.lastName ?? '',
        email: quote.customer?.email ?? '',
        displayName: quote.customerDisplayName ?? '',
        accountingReference: quote.customer?.accountingReference ?? '',
      },
      skipAddress: !!quote.skipAddress,
      shippingSameAsBilling: !!quote.shippingSameAsBilling,
      billingAddress: {
        firstName:
          quote.billingAddress?.firstName ??
          quote.customer?.billingAddress?.firstName ??
          quote.customer?.firstName ??
          '',
        lastName:
          quote.billingAddress?.lastName ??
          quote.customer?.billingAddress?.lastName ??
          quote.customer?.lastName ??
          '',
        email:
          quote.billingAddress?.email ??
          quote.customer?.billingAddress?.email ??
          quote.customer?.email ??
          '',
        phone:
          quote.billingAddress?.phone ??
          quote.customer?.billingAddress?.phone ??
          '',
        company:
          quote.billingAddress?.company ??
          quote.customer?.billingAddress?.company ??
          '',
        line1:
          quote.billingAddress?.line1 ??
          quote.customer?.billingAddress?.line1 ??
          '',
        line2:
          quote.billingAddress?.line2 ??
          quote.customer?.billingAddress?.line2 ??
          '',
        city:
          quote.billingAddress?.city ??
          quote.customer?.billingAddress?.city ??
          '',
        state:
          quote.billingAddress?.state ??
          quote.customer?.billingAddress?.state ??
          '',
        postCode:
          quote.billingAddress?.postCode ??
          quote.customer?.billingAddress?.postCode ??
          '',
        country:
          quote.billingAddress?.country ??
          quote.customer?.billingAddress?.country ??
          'GB',
      },
      shippingAddress: {
        firstName:
          quote.shippingAddress?.firstName ??
          quote.customer?.shippingAddress?.firstName ??
          quote.customer?.firstName ??
          '',
        lastName:
          quote.shippingAddress?.lastName ??
          quote.customer?.shippingAddress?.lastName ??
          quote.customer?.lastName ??
          '',
        email:
          quote.shippingAddress?.email ??
          quote.customer?.shippingAddress?.email ??
          quote.customer?.email ??
          '',
        phone:
          quote.shippingAddress?.phone ??
          quote.customer?.shippingAddress?.phone ??
          '',
        company:
          quote.shippingAddress?.company ??
          quote.customer?.shippingAddress?.company ??
          '',
        line1:
          quote.shippingAddress?.line1 ??
          quote.customer?.shippingAddress?.line1 ??
          '',
        line2:
          quote.shippingAddress?.line2 ??
          quote.customer?.shippingAddress?.line2 ??
          '',
        city:
          quote.shippingAddress?.city ??
          quote.customer?.shippingAddress?.city ??
          '',
        state:
          quote.shippingAddress?.state ??
          quote.customer?.shippingAddress?.state ??
          '',
        postCode:
          quote.shippingAddress?.postCode ??
          quote.customer?.shippingAddress?.postCode ??
          '',
        country:
          quote.shippingAddress?.country ??
          quote.customer?.shippingAddress?.country ??
          'GB',
      },
    };

    const navigate = useNavigate();

    return editMode ? (
      <Formik
        key={editMode}
        initialValues={{
          customer: {
            uuid:
              editMode === 'edit'
                ? quote.customer?.uuid ?? undefined
                : undefined,
            legacyId:
              editMode === 'edit' ? quote.customer?.legacyId : undefined,
            firstName:
              editMode !== 'add' ? quote.customer?.firstName ?? '' : undefined,
            lastName:
              editMode !== 'add' ? quote.customer?.lastName ?? '' : undefined,
            email: editMode !== 'add' ? quote.customer?.email ?? '' : undefined,
            displayName:
              editMode !== 'add' ? quote.customerDisplayName ?? '' : undefined,
            accountingReference:
              editMode !== 'add'
                ? quote.customer?.accountingReference ?? ''
                : undefined,
          },
        }}
        validate={(values) => {
          const errors: Record<string, string> = {};
          if (!values.customer.email) {
            errors.email = 'Email is required';
          }
          if (!values.customer.firstName) {
            errors.firstName = 'First Name is required';
          }
          return errors;
        }}
        onSubmit={async (values) => {
          await onSave({
            skipAddress: !!quote.skipAddress,
            shippingSameAsBilling: !!quote.shippingSameAsBilling,
            billingAddress:
              editMode === 'add'
                ? {
                    firstName: values.customer.firstName ?? '',
                    lastName: values.customer.lastName ?? '',
                    email: values.customer.email ?? '',
                    line1: '',
                    line2: '',
                    city: '',
                    state: '',
                    postCode: '',
                    country: 'GB',
                  }
                : quote.billingAddress
                ? {
                    ...quote.billingAddress,
                    firstName:
                      quote.billingAddress.firstName?.toLowerCase() ===
                      quote.customer?.firstName?.toLowerCase()
                        ? values.customer.firstName ??
                          quote.billingAddress.firstName
                        : quote.billingAddress.firstName,
                    lastName:
                      quote.billingAddress.lastName?.toLowerCase() ===
                      quote.customer?.lastName?.toLowerCase()
                        ? values.customer.lastName ??
                          quote.billingAddress.lastName
                        : quote.billingAddress.lastName,
                    email:
                      quote.billingAddress.email.toLowerCase() ===
                      quote.customer?.email?.toLowerCase()
                        ? values.customer.email ?? quote.billingAddress.email
                        : quote.billingAddress.email,
                  }
                : undefined,
            shippingAddress:
              editMode === 'add'
                ? {
                    firstName: values.customer.firstName ?? '',
                    lastName: values.customer.lastName ?? '',
                    email: values.customer.email ?? '',
                    line1: '',
                    line2: '',
                    city: '',
                    state: '',
                    postCode: '',
                    country: 'GB',
                  }
                : quote.shippingAddress
                ? {
                    ...quote.shippingAddress,
                    firstName:
                      quote.shippingAddress.firstName?.toLowerCase() ===
                      quote.customer?.firstName?.toLowerCase()
                        ? values.customer.firstName ??
                          quote.shippingAddress.firstName
                        : quote.shippingAddress.firstName,
                    lastName:
                      quote.shippingAddress.lastName?.toLowerCase() ===
                      quote.customer?.lastName?.toLowerCase()
                        ? values.customer.lastName ??
                          quote.shippingAddress.lastName
                        : quote.shippingAddress.lastName,
                    email:
                      quote.shippingAddress.email.toLowerCase() ===
                      quote.customer?.email?.toLowerCase()
                        ? values.customer.email ?? quote.shippingAddress.email
                        : quote.shippingAddress.email,
                  }
                : undefined,
            customer: {
              ...values.customer,
              displayName: values.customer.email,
            },
          });

          setEditMode(undefined);
        }}
      >
        <Form>
          <Button
            type="button"
            className="flex items-center text-red mb-3"
            onClick={() => setEditMode(undefined)}
          >
            <FiCornerUpLeft className="mr-2" />
            <span className="text-sm">Go Back</span>
          </Button>
          <div className="bg-white rounded-md">
            <h5 className="text-lg border-b border-black/20 py-3 px-5 font-semibold text-black">
              {editMode === 'add' ? 'Add' : 'Edit'} Customer
            </h5>
            <div className="p-5">
              <div className="flex -mx-2 flex-wrap">
                <Input
                  name="customer.firstName"
                  label="First Name"
                  containerClassName="px-2 w-1/2 mb-5"
                  required
                  autoFocus
                />
                <Input
                  name="customer.lastName"
                  label="Last Name"
                  containerClassName="px-2 w-1/2 mb-5"
                />
                <Input
                  name="customer.email"
                  label="Email"
                  type='email'
                  pattern={new RegExp(/[^@\s]+@[^@\s]+\.[^@\s]+/)}
                  containerClassName="px-2 w-1/2 mb-5"
                  required
                />
                <Input
                  className="w-full"
                  label="Accounting Reference"
                  name="customer.accountingReference"
                  containerClassName="px-2 w-1/2 mb-5"
                />
              </div>
            </div>
          </div>
          <NextButton />
        </Form>
      </Formik>
    ) : (
      <Formik
        key="customer"
        innerRef={ref as ForwardedRef<FormikProps<CustomerTabFormikValues>>}
        initialValues={initialValues}
        validate={(values) => {
          const errors: Record<string, string> = {};
          if (!values.customer.email) {
            errors.email = 'Customer email is required';
          }
          if (!values.customer.firstName) {
            errors.firstName = 'Customer first name is required';
          }
          if (!values.skipAddress) {
            if (!values.billingAddress?.firstName) {
              errors.billingFirstName = 'Billing first name is required';
            }
            if (!values.billingAddress?.email) {
              errors.billingEmail = 'Billing email is required';
            }
            if (!values.billingAddress?.line1) {
              errors.billingLine1 = 'Billing line 1 is required';
            }
            if (!values.billingAddress?.city) {
              errors.billingCity = 'Billing city is required';
            }
            if (!values.billingAddress?.postCode) {
              errors.billingPostCode = 'Billing post code is required';
            }
            if (!values.billingAddress?.country) {
              errors.billingCountry = 'Billing country is required';
            }
            if (!values.shippingSameAsBilling) {
              if (!values.shippingAddress?.firstName) {
                errors.shippingFirstName = 'Shipping first Name is required';
              }
              if (!values.shippingAddress?.email) {
                errors.shippingEmail = 'Shipping email is required';
              }
              if (!values.shippingAddress?.line1) {
                errors.shippingLine1 = 'Shipping line 1 is required';
              }
              if (!values.shippingAddress?.city) {
                errors.shippingCity = 'Shipping city is required';
              }
              if (!values.shippingAddress?.postCode) {
                errors.shippingPostCode = 'Shipping post code is required';
              }
              if (!values.shippingAddress?.country) {
                errors.shippingCountry = 'Shipping country is required';
              }
            }
          }
          return errors;
        }}
        onSubmit={async (values) => {
          if (values.skipAddress) {
            await onSave({
              customer: values.customer,
              skipAddress: true,
              shippingSameAsBilling: true,
              billingAddress: undefined,
              shippingAddress: undefined,
            });
          } else if (values.billingAddress) {
            const billingAddress = omitEmpty({
              ...values.billingAddress,
            });

            await onSave({
              customer: values.customer,
              skipAddress: false,
              shippingSameAsBilling: values.shippingSameAsBilling,
              billingAddress,
              shippingAddress:
                values.shippingSameAsBilling || !values.shippingAddress
                  ? billingAddress
                  : omitEmpty({
                      ...values.shippingAddress,
                    }),
            });
          }
        }}
      >
        <Form className="mb-40">
          <div className="bg-white rounded-md mb-5">
            <h4 className="text-lg font-semibold text-black mb-2 py-3 px-5 border-b border-black/20">
              Customer
            </h4>
            <div className="flex flex-wrap -mx-2 p-5">
              <div className="px-2 w-full lg:w-1/2 mb-5 lg:mb-0">
                <FormikCustomerSearchField setEditMode={setEditMode} />
              </div>
              <div className="px-2 w-full lg:w-1/2">
                <FormikLegacyCustomerSearchField />
                <p className="text-xs -mt-3">
                  Can't find customer? It might be in the legacy database. Try
                  legacy search instead
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-md">
            <h4 className="text-lg font-semibold text-black mb-2 py-3 px-5 border-b border-black/20">
              Customer Information
            </h4>
            <div className="p-5">
              <AddressCollection />
            </div>
          </div>
          {loading && <PageLoader />}
          <NextButton
            afterSubmit={() => {
              void navigate({
                to: quoteUuid
                  ? `/admin/quotes/${quoteUuid}#products`
                  : '/admin/quotes/add#products',
              });
            }}
          />
        </Form>
      </Formik>
    );
  }
);

const RecipientTable = () => {
  const {
    values: { recipients = [] },
    setFieldValue,
  } = useFormikContext<OverallProgress>();

  return (
    <table className="table-fixed text-sm w-full">
      <thead className="border-b border-black/20">
        <tr>
          <th className="p-2">Group</th>
          <th className="p-2">Email</th>
          <td className="p-2 w-20" />
        </tr>
      </thead>
      <tbody>
        {recipients?.map((r, index) => (
          <tr className="border-b border-black/20" key={`${index}-${r.group}`}>
            <td className="py-3 pr-2 text-center">
              <Dropdown
                name={`recipients.${index}.group`}
                useFormik
                options={[
                  { label: 'External', value: RecipientGroup.external },
                  { label: 'Internal', value: RecipientGroup.internal },
                ]}
              />
            </td>
            <td className="py-3 pl-2 text-center">
              {r.group === RecipientGroup.internal ? (
                <SearchField
                  name={`recipients.${index}.email`}
                  placeholder="Email"
                  useFormik
                  fetchSuggestions={async (search) => {
                    const res = await Promise.resolve(
                      internalUsers
                        .filter((u) =>
                          u.toLowerCase().includes(search.toLowerCase())
                        )
                        .map((u) => ({ label: u, value: u }))
                    );

                    return res;
                  }}
                  idProp="value"
                  labelProp="label"
                  onSelect={async ({ value }) => {
                    await setFieldValue(`recipients.${index}.email`, value);
                  }}
                />
              ) : (
                <Input
                  type="email"
                  name={`recipients.${index}.email`}
                  placeholder="Email"
                />
              )}
            </td>
            <td className="py-3 pl-2 text-center">
              <div className="flex items-center justify-center">
                <Button
                  type="button"
                  className={elements.button.tertiary}
                  onClick={async () => {
                    await setFieldValue(
                      'recipients',
                      recipients.filter((_, i) => i !== index)
                    );
                  }}
                >
                  <FiTrash size={20} />
                </Button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={2} className="py-5 text-center">
            <Button
              type="button"
              className={elements.button.tertiary}
              data-tooltip-id="add-recipient"
              data-tooltip-content="Add a new recipient"
              onClick={async () => {
                await setFieldValue('recipients', [
                  ...(recipients ?? []),
                  { group: RecipientGroup.external, email: '' },
                ] as QuoteRecipient[]);
              }}
            >
              <FiPlus size={20} />
            </Button>
            <Tooltip id="add-recipient" />
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

interface DetailsTabFormikValues
  extends Record<string, unknown>,
    Pick<
      CreateQuoteInput,
      | 'customerReference'
      | 'emailMessage'
      | 'inTouchReference'
      | 'recipients'
      | 'purchaseOrderFileUuid'
      | 'purchaseOrderReference'
    > {
      hash: string;
    }

const EmailMessageField = ({
  initialValue,
  customerFirstName,
}: {
  initialValue: string;
  customerFirstName?: string;
}) => {
  const { setFieldValue } = useFormikContext<DetailsTabFormikValues>();
  const onEditorChange = useCallback<INativeEvents['onKeyUp']>(
    async (e) => {
      await setFieldValue('emailMessage', (e.target as HTMLElement).innerHTML);
    },
    [setFieldValue]
  );

  const editorRef = useRef<Editor['editor']>();
  const { user } = useUser();
  const replaceVars = (content: string) => {
    const replace = {
      '##NAME': customerFirstName ?? '##NAME',
      '##ADMIN_EMAIL': user.email,
      '##ADMIN_DISPLAY_NAME': user.displayName,
    };

    let updatedContent = content;
    Object.entries(replace).forEach(([key, value]) => {
      updatedContent = updatedContent.replace(key, value);
    });
    return updatedContent;
  };

  const {data} = useSuspenseQuery(GqlIndexMessageTemplates);

  return (
    <Editor
      tinymceScriptSrc='/v2-assets/tinymce/tinymce.min.js'
      licenseKey={env.tinyMceApiKey}
      onInit={(evt, editor) => {
        editorRef.current = editor;
        const content = replaceVars(editor.getContent());

        void setFieldValue('emailMessage', content);

        editor.ui.registry.addMenuButton('templates', {
          type: 'menubutton',
          text: 'Templates',
          fetch: async (cb) => {
            const d = await Promise.resolve(data);

            cb(
              d.indexMessageTemplates.map((template) => ({
                type: 'menuitem',
                text: template.name,
                onAction: async () => {
                  const htmlContent = replaceVars(template.html);

                  editor.setContent(
                    htmlContent.replace(/\r\n\r\n/g, '<br /><br />')
                  );

                  await setFieldValue(
                    'emailMessage',
                    htmlContent.replace(/\r\n\r\n/g, '<br /><br />')
                  );
                },
              }))
            );
          },
        });
      }}
      initialValue={replaceVars(initialValue)}
      onKeyUp={onEditorChange}
      toolbar={
        'undo redo | ' +
        'templates bold italic fontsize | alignleft aligncenter alignright | ' +
        'link copy paste | numlist bullist | ' +
        'removeformat'
      }
      plugins={['lists', 'link', 'advlist', 'autolink']}
      init={{
        height: 500,
        menubar: false,
        contextmenu: 'link copy paste',
        font_size_formats: '12px 14px 18px 24px 36px',
        content_style:
          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      }}
    />
  );
};

const DetailsTab = forwardRef(
  (
    {
      quoteUuid,
      quote,
      onSave,
      loading,
    }: {
      quoteUuid?: string;
      quote: OverallProgress;
      loading: boolean;
      onSave: (
        arg: Pick<
          OverallProgress,
          | 'customerReference'
          | 'emailMessage'
          | 'inTouchReference'
          | 'recipients'
          | 'status'
        > & {
          hash: string;
        }
      ) => Promise<void>;
    },
    ref: ForwardedRef<FormikProps<Record<string, unknown>>>
  ) => {
    const initialValues: DetailsTabFormikValues = {
      customerReference: quote.customerReference ?? '',
      emailMessage: quote.emailMessage ?? '',
      inTouchReference: quote.inTouchReference ?? undefined,
      purchaseOrderReference: quote.purchaseOrderReference ?? '',
      purchaseOrderFileUuid: quote.purchaseOrderFileUuid ?? undefined,
      recipients: quote.recipients ?? [],
      status: quote.status ?? QuoteStatusEnum.pending,
      hash: 'preview',
    };

    return (
      <Formik
        innerRef={ref as ForwardedRef<FormikProps<DetailsTabFormikValues>>}
        initialValues={initialValues}
        validate={(values) => {
          const errors: Record<string, string> = {};
          if (!values.customerReference) {
            errors.customerReference = 'Customer Reference is required';
          }
          if (!values.emailMessage) {
            errors.emailMessage = 'Email Message is required';
          }
          return errors;
        }}
        onSubmit={async (v, p) => {
          await onSave(v);

          p.resetForm({
            values: v,
          });
        }}
      >
        <Form>
          <div className="flex -mx-2 flex-wrap">
            <div className="px-2 w-full lg:w-1/2">
              <div className="bg-white rounded-md">
                <h3 className="text-lg font-semibold text-black py-3 px-5 border-b border-black/20">
                  Details
                </h3>
                <div className="p-5">
                  <Input
                    name="customerReference"
                    placeholder="Customer Reference"
                    label="Customer Reference"
                    containerClassName="mb-5"
                  />
                  <Input
                    name="inTouchReference"
                    placeholder="In Touch Reference"
                    label="In Touch Reference"
                    containerClassName="mb-5"
                  />
                  <div className='relative'>
                    <label className={elements.inputLabel}>Email Message</label>
                    <Suspense fallback={<PageLoader />}>
                      <EmailMessageField
                        initialValue={quote.emailMessage ?? ''}
                        customerFirstName={quote.customer?.firstName ?? ''}
                      />
                    </Suspense>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-2 w-full lg:w-1/2">
              <div className="bg-white rounded-md mb-5">
                <h3 className="text-lg font-semibold text-black py-3 px-5 border-b border-black/20">
                  Recipients
                </h3>
                <div className="p-5">
                  <RecipientTable />
                </div>
              </div>
              <div className="rounded-md mb-5 bg-white">
                <h4 className="text-lg font-semibold text-black px-5 py-3 border-b border-black/20">
                  Purchase Order Details
                </h4>
                <div className="flex flex-col justify-center p-5">
                  <div className="w-full mb-5">
                    <Input
                      name="purchaseOrderReference"
                      className="max-w-sm"
                      label="Purchase Order Reference"
                    />
                  </div>
                  <div className="w-full mb-5 last:mb-0 relative">
                    <Suspense fallback={<PageLoader />}>
                      <PurchaseOrderUploadField />
                    </Suspense>
                  </div>
                  <div className="w-full mb-5 last:mb-0">
                    <label className={elements.inputLabel}>Status</label>
                    <Dropdown name="status" useFormik options={quoteStatuses} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!!loading && <PageLoader />}
          <SubmitButton showIfNotDirty />
        </Form>
      </Formik>
    );
  }
);

const detailsComplete = (quoteProgress: OverallProgress) =>
  !!quoteProgress.customerReference &&
  !!quoteProgress.emailMessage &&
  !!quoteProgress.recipients;

const customerComplete = (quoteProgress: OverallProgress) =>
  !!quoteProgress.customer;

const addressComplete = (quoteProgress: OverallProgress) =>
  !!quoteProgress.skipAddress ||
  (!!quoteProgress.billingAddress &&
    (!!quoteProgress.shippingSameAsBilling || !!quoteProgress.shippingAddress));

const productsComplete = (quoteProgress: OverallProgress) =>
  !!quoteProgress.lineItems;

function assertDetailsComplete(
  quoteProgress: Partial<CreateQuoteInput> & {
    skipAddress?: boolean;
    shippingSameAsBilling?: boolean;
  }
): asserts quoteProgress is CreateQuoteInput & {
  skipAddress?: boolean;
  shippingSameAsBilling?: boolean;
} {
  if (!detailsComplete(quoteProgress)) {
    throw new Error('Details are not complete');
  }
  if (!customerComplete(quoteProgress)) {
    throw new Error('Customer details are not complete');
  }
  if (!addressComplete(quoteProgress)) {
    throw new Error('Address details are not complete');
  }
  if (!productsComplete(quoteProgress)) {
    throw new Error('Products are not complete');
  }
}

type OverallProgress = Omit<
  Partial<CreateQuoteInput>,
  'billingAddress' | 'shippingAddress' | 'lineItems'
> & {
  customerDisplayName?: string;
  customerFirstName?: string;
  skipAddress?: boolean;
  shippingSameAsBilling?: boolean;
  billingAddress?: InputMaybe<AddressInput>;
  shippingAddress?: InputMaybe<AddressInput>;
  lineItems?: Array<
    LineItemInput & {
      products?: InputMaybe<
        Array<
          LineItemProductInput & {
            links?: InputMaybe<
              Array<
                ProductUploadInput & {
                  createdAt?: string;
                }
              >
            >;
            meta?: InputMaybe<
              Array<
                MetaData & {
                  createdAt?: string;
                }
              >
            >;
          }
        >
      >;
    }
  >;
};

type Tab = 'customer' | 'details' | 'products' | 'preview' | 'notes';

const CreateOrEditQuote = ({ quote }: Props) => {
  const [updateQuote, { loading: updateLoading }] = useMutation(GqlEditQuote, {
    update: (cache, data) => {
      if (data.data?.editQuote) {
        cache.writeQuery({
          query: GqlReadQuote,
          data: {
            readQuote: data.data.editQuote,
          },
          variables: {
            uuid: data.data.editQuote.uuid,
          },
        });
      }
    },
  });

  const [addQuote, { loading: addLoading }] = useMutation(GqlCreateQuote, {
    update: (cache) => {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'indexQuotes',
      });

      cache.gc();
    }
  });

  const loading = addLoading || updateLoading;
  const navigate = useNavigate();
  const [deleteQuote, { loading: deleteLoading }] = useMutation(
    GqlDeleteQuote,
    {
      update: (cache) => {
        cache.evict({ id: `Quote:${quote?.uuid}` });

        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'indexQuotes',
        });

        cache.gc();
      },
      onCompleted: () => {
        toaster.success(
          {
            title: 'Quote cancelled',
            text: 'The quote has been cancelled',
          },
          { autoClose: 2000 }
        );

        void navigate({
          to: '/admin/quotes',
        });
      },
    }
  );

  const location = useLocation();

  const tab: Tab = location.hash ? (location.hash as Tab) : 'customer';

  useEffect(() => {
    if (!quote?.uuid && tab !== 'customer') {
      void navigate({ to: '/admin/quotes/add', hash: 'customer' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote?.uuid]);
  const [quoteProgress, setQuoteProgress] = useState<OverallProgress>({
    billingAddress: quote?.billingAddress
      ? cleanUuid(cleanTypeName(quote.billingAddress))
      : undefined,
    shippingSameAsBilling:
      !quote?.uuid ||
      JSON.stringify(quote.billingAddress) ===
        JSON.stringify(quote.shippingAddress),
    currency: quote?.currency,
    customer: quote?.customer
      ? omitEmpty(
          cleanTypeName({
            ...quote.customer,
            billingAddress: quote.customer.billingAddress
              ? cleanUuid(cleanTypeName(quote.customer.billingAddress))
              : undefined,
            shippingAddress: quote.customer.shippingAddress
              ? cleanUuid(cleanTypeName(quote.customer.shippingAddress))
              : undefined,
            displayName: undefined,
            legacyDisplayName: undefined,
          })
        )
      : undefined,
    customerReference: quote?.customerReference ?? undefined,
    emailMessage: quote?.emailMessage ?? undefined,
    inTouchReference: quote?.inTouchReference,
    lineItems: quote?.lineItems.map((li) => ({
      ...cleanTypeName(li),
      products: li.products.map((p) =>
        cleanTypeName({
          ...p,
          links: p.links.map((l) =>
            cleanTypeName({
              ...l,
              createdAt: l.file?.createdAt,
              file: undefined,
            })
          ),
          meta: p.meta.map((m, i) =>
            cleanTypeName({
              ...m,
              createdAt: new Date(Date.now() + i).toISOString(),
            })
          ),
        })
      ),
    })),
    recipients: quote?.recipients.map(cleanTypeName),
    shippingAddress: quote?.shippingAddress
      ? cleanUuid(cleanTypeName(quote.shippingAddress))
      : undefined,
    status: quote?.status,
    customerDisplayName: quote?.customer?.displayName,
    purchaseOrderFileUuid: quote?.purchaseOrderFileUuid,
    purchaseOrderReference: quote?.purchaseOrderReference,
  });

  useEffect(() => {
    if (!quote) {
      localStorage.setItem('quoteProgress', JSON.stringify(quoteProgress));
    } else {
      localStorage.removeItem('quoteProgress');
    }
  }, [quoteProgress, quote]);

  useEffect(() => {
    if (!quote) {
      const stored = localStorage.getItem('quoteProgress');
      if (stored) {
        setQuoteProgress(JSON.parse(stored) as OverallProgress);
      }
    }
  }, [quote]);
  const formRef = createRef<FormikProps<{
    hash?: string;
  }>>();

  const maybeNavigate = (cb: () => void, t: Tab) => {
    if (formRef.current?.dirty && formRef.current.isValid) {
      if (
        window.confirm('You have unsaved changes. Would you like to save them?')
      ) {
        if (formRef.current.values.hash) {
          void formRef.current.setFieldValue('hash', t).then(() => {            
            void formRef.current?.submitForm().then(() => {
              //cb();
            });
          });
        } else {
          void formRef.current.submitForm().then(() => {
            cb();
          });
        } 
      }
    } else {
      cb();
    }
  };

  return (
    <div>
      <PageHeader
        title={
          quote ? `Manage Quote - ${quote.accountingReference}` : 'Create Quote'
        }
        Icon={FiBriefcase}
      >
        {!!quote &&
          quoteProgress.status === QuoteStatusEnum.pending &&
          (deleteLoading ? (
            <Loader size="1.5rem" />
          ) : (
            <div>
              <Button
                type="button"
                className={elements.button.tertiary}
                data-tooltip-id="cancelQuote"
                data-tooltip-content="Cancel Quote"
                data-tooltip-place="bottom"
                onClick={async () => {
                  if (
                    window.confirm(
                      'Are you sure you want to cancel this quote?'
                    )
                  ) {
                    await deleteQuote({
                      variables: {
                        quoteUuid: quote.uuid,
                      },
                    });
                  }
                }}
              >
                <FiTrash size={20} />
              </Button>
              <Tooltip id="cancelQuote" />
            </div>
          ))}
      </PageHeader>
      <div className="border-b border-black/20 py-2 pb-4 mb-5">
        <select
          className={classNames(elements.input, 'lg:hidden w-full px-3')}
          value={tab}
          onChange={(e) => {
            maybeNavigate(
              () =>
                void navigate({
                  to: quote?.uuid
                    ? `/admin/quotes/${quote.uuid}#${e.target.value}`
                    : `/admin/quotes/add#${e.target.value}`,
                }), e.currentTarget.value as Tab
            );
          }}
        >
          <option value="customer">Customer</option>
          <option
            value="products"
            disabled={!addressComplete(quoteProgress) && !quote?.uuid}
          >
            Products
          </option>
          <option
            value="details"
            disabled={!productsComplete(quoteProgress) && !quote?.uuid}
          >
            Details
          </option>
          <option value="preview" disabled={!quote?.uuid}>
            Preview
          </option>
          <option value="notes" disabled={!quote?.uuid}>
            Notes
          </option>
        </select>
        <div className="-mx-4 items-center hidden lg:flex">
          <div className="px-4">
            <Link
              preload={false}
              to={
                quote?.uuid
                  ? `/admin/quotes/${quote.uuid}#customer`
                  : '/admin/quotes/add#customer'
              }
              className={`text-sm ${
                tab === 'customer' ? 'font-bold text-red' : ''
              }`}
              onClick={(e) => {
                e.preventDefault();

                maybeNavigate(() => void navigate({ to: quote?.uuid
                  ? `/admin/quotes/${quote.uuid}#customer`
                  : '/admin/quotes/add#customer' }), 'customer');
              }}
            >
              Customer
            </Link>
          </div>
          <div className="px-4">
            <Link
              preload={false}
              to={
                quote?.uuid
                  ? `/admin/quotes/${quote.uuid}#products`
                  : '/admin/quotes/add#products'
              }
              onClick={(e) => {
                e.preventDefault();

                maybeNavigate(() => void navigate({ to: quote?.uuid
                  ? `/admin/quotes/${quote.uuid}#products`
                  : '/admin/quotes/add#products' }), 'products');
              }}
              className={`text-sm ${
                !addressComplete(quoteProgress) && !quote?.uuid
                  ? 'opacity-50 pointer-events-none'
                  : ''
              } ${tab === 'products' ? 'font-bold text-red' : ''}`}
            >
              Products
            </Link>
          </div>
          <div className="px-4">
            <Link
              preload={false}
              to={
                quote?.uuid
                  ? `/admin/quotes/${quote.uuid}#details`
                  : '/admin/quotes/add#details'
              }
              onClick={(e) => {
                e.preventDefault();

                maybeNavigate(() => void navigate({ to: quote?.uuid
                  ? `/admin/quotes/${quote.uuid}#details`
                  : '/admin/quotes/add#details' }), 'details');
              }}
              className={`text-sm ${
                !productsComplete(quoteProgress) && !quote?.uuid
                  ? 'opacity-50 pointer-events-none'
                  : ''
              } ${tab === 'details' ? 'font-bold text-red' : ''}`}
            >
              Details
            </Link>
          </div>
          <div className="px-4">
            <Link
              preload={false}
              to={
                quote?.uuid
                  ? `/admin/quotes/${quote.uuid}#preview`
                  : '/admin/quotes/add#preview'
              }
              onClick={(e) => {
                e.preventDefault();

                maybeNavigate(() => void navigate({ to: quote?.uuid
                  ? `/admin/quotes/${quote.uuid}#preview`
                  : '/admin/quotes/add#preview' }), 'preview');
              }}
              className={`text-sm ${
                !quote?.uuid ? 'opacity-50 pointer-events-none' : ''
              } ${tab === 'preview' ? 'font-bold text-red' : ''}`}
            >
              Preview
            </Link>
          </div>
          <div className="px-4">
            <Link
              preload={false}
              to={
                quote?.uuid
                  ? `/admin/quotes/${quote.uuid}#notes`
                  : '/admin/quotes/add#notes'
              }
              onClick={(e) => {
                e.preventDefault();

                maybeNavigate(() => void navigate({ to: quote?.uuid
                  ? `/admin/quotes/${quote.uuid}#notes`
                  : '/admin/quotes/add#notes' }), 'notes');
              }}
              className={`text-sm ${
                !quote?.uuid ? 'opacity-50 pointer-events-none' : ''
              } ${tab === 'notes' ? 'font-bold text-red' : ''}`}
            >
              Notes
            </Link>
          </div>
        </div>
      </div>
      {tab === 'details' && (
        <DetailsTab
          ref={formRef}
          loading={loading}
          quoteUuid={quote?.uuid}
          quote={quoteProgress}
          onSave={async ({hash, ...values}) => {
            setQuoteProgress((prevQuoteProgress) => ({
              ...prevQuoteProgress,
              ...values,
            }));

            if (quote) {
              await updateQuote({
                variables: {
                  input: {
                    uuid: quote.uuid,
                    ...values,
                  },
                },
                onCompleted: () => {
                  toaster.success(
                    {
                      title: 'Quote updated',
                      text: 'The quote has been updated',
                    },
                    { autoClose: 2000 }
                  );
                },
              });
            } else {
              const { customerDisplayName, customerFirstName, ...progress } =
                quoteProgress;

              const newValues = {
                ...progress,
                ...values,
                lineItems: progress.lineItems?.map((lineItem) => ({
                  ...lineItem,
                  products: lineItem.products?.map((product) => ({
                    ...product,
                    links: product.links?.map(({ url, ...l }) =>
                      omitEmpty({
                        ...l,
                        url: l.fileUuid ? undefined : url,
                        createdAt: undefined,
                      })
                    ),
                    meta: product.meta?.map((m) =>
                      omitEmpty({
                        ...m,
                        createdAt: undefined,
                      })
                    ),
                  })),
                })),
              };

              assertDetailsComplete(newValues);

              await addQuote({
                variables: {
                  input: omitEmpty({
                    ...newValues,
                    skipAddress: undefined,
                    shippingSameAsBilling: undefined,
                  }),
                },
                onCompleted: (data) => {
                  localStorage.removeItem('quoteProgress');
            
                  void navigate({
                    to: `/admin/quotes/${data.createQuote.uuid}#${hash}`,
                  });
            
                  toaster.success(
                    {
                      title: 'Quote created',
                      text: 'The quote has been created',
                    },
                    { autoClose: 2000 }
                  );
                },
              });
            }
          }}
        />
      )}
      {tab === 'customer' && (
        <CustomerTab
          ref={formRef}
          quote={quoteProgress}
          quoteUuid={quote?.uuid}
          loading={loading}
          onSave={async ({
            skipAddress,
            shippingSameAsBilling,
            customer: { displayName, legacyDisplayName, ...customer },
            billingAddress,
            shippingAddress,
          }) => {
            setQuoteProgress((prevQuoteProgress) => ({
              ...prevQuoteProgress,
              customer,
              billingAddress,
              shippingAddress,
              customerFirstName: customer.firstName ?? undefined,
              customerDisplayName: displayName ?? legacyDisplayName ?? undefined,
              emailMessage:
                prevQuoteProgress.emailMessage?.replace(
                  prevQuoteProgress.customer?.firstName ?? '##NAME',
                  customer.firstName ?? ''
                ) ?? undefined,
              skipAddress,
              shippingSameAsBilling,
            }));

            if (quote) {
              await updateQuote({
                variables: {
                  input: {
                    uuid: quote.uuid,
                    billingAddress,
                    shippingAddress,
                    customer,
                  },
                },
                onCompleted: () => {
                  toaster.success(
                    {
                      title: 'Quote updated',
                      text: 'The quote has been updated',
                    },
                    { autoClose: 2000 }
                  );
                },
              });
            }
          }}
        />
      )}
      {tab === 'products' && (
        <ProductsTab
          ref={formRef}
          quoteUuid={quote?.uuid}
          quote={quoteProgress}
          loading={loading}
          onSave={async (values) => {
            const { customerDisplayName, customerFirstName, ...progress } =
              quoteProgress;

            let newValues = {
              ...progress,
              ...values,
            };
            setQuoteProgress({
              ...newValues,
              customerDisplayName,
              customerFirstName,
            });

            newValues = {
              ...newValues,
              lineItems: newValues.lineItems?.map((lineItem) => ({
                ...lineItem,
                products: lineItem.products?.map((product) => ({
                  ...product,
                  links: product.links?.map(({ url, ...l }) =>
                    omitEmpty({
                      ...l,
                      url: l.fileUuid ? undefined : url,
                      createdAt: undefined,
                    })
                  ),
                  meta: product.meta?.map((m) =>
                    omitEmpty({
                      ...m,
                      createdAt: undefined,
                    })
                  ),
                })),
              })),
            };
            if (quote) {
              await updateQuote({
                variables: {
                  input: {
                    uuid: quote.uuid,
                    ...omitEmpty({
                      ...newValues,
                      skipAddress: undefined,
                      shippingSameAsBilling: undefined,
                    }),
                  },
                },
                onCompleted: (data) => {
                  toaster.success(
                    {
                      title: 'Quote updated',
                      text: 'The quote has been updated',
                    },
                    { autoClose: 2000 }
                  );
                },
              });
            }
          }}
        />
      )}
      {tab === 'preview' && quote && <PreviewTab quote={quote} />}
      {tab === 'notes' && quote && <NoteTab quote={quote} />}
    </div>
  );
};

export default CreateOrEditQuote;
