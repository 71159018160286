import { useMemo } from 'react';
import { useCountriesAndCities } from './AddressFieldGroup';
import Dropdown from './Dropdown';
import Input from './Input';
import { useFormikContext } from 'formik';

interface Props {
  name: string;
  value?: string;
  onChange?: (phone: string) => void;
  containerClassName?: string;
}

const TelField = ({ name, value, onChange, containerClassName }: Props) => {
  const { values, setFieldValue } = useFormikContext();

  const phone = value ?? (values as Record<string, string | undefined>)[name];

  const { phoneCodes } = useCountriesAndCities();

  const phoneCode = phoneCodes.find((p) => phone?.startsWith(p.value))?.value;

  const PhoneCode = useMemo(() => {
    const items = phoneCodes.map((code) => ({
      value: code.value,
      label: `${code.flag} ${code.name} ${code.value}`,
      displayName: `${code.flag} ${code.value}`,
    }));

    return () => (
      <div className="mr-4">
        <Dropdown
          placeholder="Code +"
          name="phoneCode"
          inputClassNames="px-3"
          buttonClassNames="bg-white border-r border-black/20"
          arrowClassNames="bg-white text-dark h-[2.625rem]"
          options={items}
          canSearch
          onChange={async ({ value: newValue }) => {
            const fullPhone = `${
              phoneCodes.find((p) => newValue.startsWith(p.value))?.value ?? ''
            }${
              phone?.replace(
                new RegExp(
                  phoneCodes
                    .map((p) => p.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
                    .join('|'),
                  'g'
                ),
                ''
              ) ?? ''
            }`;

            await setFieldValue(name, fullPhone);

            onChange?.(fullPhone);
          }}
          value={phoneCode}
        />
      </div>
    );
  }, [phone, phoneCodes, onChange, name, setFieldValue, phoneCode]);

  return (
    <Input
      type="tel"
      className="w-full"
      placeholder="Phone"
      label="Phone number"
      Icon={PhoneCode}
      iconToEdge
      value={
        phone?.replace(
          new RegExp(
            phoneCodes
              .map((p) => p.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
              .join('|'),
            'g'
          ),
          ''
        ) ?? ''
      }
      onInput={async (e: React.FormEvent<HTMLInputElement>) => {
        const code = phoneCode;
        const newPhone = `${
          phoneCodes.find((p) => code?.startsWith(p.value))?.value ?? ''
        }${e.currentTarget.value}`;

        await setFieldValue(name, newPhone);

        onChange?.(newPhone);
      }}
      name={name}
      useFormik={false}
      containerClassName={containerClassName}
    />
  );
};

export default TelField;
