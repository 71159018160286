import { ReactNode, useMemo, useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  Button,
} from '@headlessui/react';
import { FiChevronDown, FiSearch } from 'react-icons/fi';
import { elements } from '../utility/styles';
import Checkbox from './Checkbox';
import { Link, useRouter } from '@tanstack/react-router';
import classNames from 'classnames';
import { To } from './NotFound';
import { Field } from 'formik';
import Input from './Input';

export interface Item {
  value: string | number;
  label: ReactNode;
  displayName?: string;
  onClick?: () => void;
  isInternalLink?: boolean;
  isExternalLink?: boolean;
  closeOnClick?: boolean;
  disabled?: boolean;
}

const isMobile = () => {
  if (typeof window === 'undefined') return false;

  const userAgent =
    navigator.userAgent ||
    (
      window as unknown as {
        opera: string;
      }
    ).opera;

  return (
    /android|bb\d+|meego.+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
      userAgent
    ) ||
    navigator.maxTouchPoints > 0 ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      userAgent.substring(0, 4)
    )
  );
};

interface Props<T extends Item> {
  label?: ReactNode;
  placeholder?: ReactNode;
  options: T[];
  value?: T['value'] | Array<T['value']>;
  onChange?: (item: T) => void;
  buttonClassNames?: string;
  arrowClassNames?: string;
  inputClassNames?: string;
  name: string;
  required?: boolean;
  canSearch?: boolean;
}

function DropdownInner<T extends Item>({
  label,
  name,
  placeholder,
  options,
  value,
  onChange,
  buttonClassNames,
  arrowClassNames = 'border border-black/20 border-l-0 text-dark h-11',
  inputClassNames = classNames(
    elements.input,
    'border-r-0 rounded-r-none grow pl-3 min-w-0'
  ),
  required,
  canSearch,
}: Props<T>) {
  const multiple = Array.isArray(value);

  const activeValues = useMemo(
    () => (multiple ? value.map(String) : String(value) ? [String(value)] : []),
    [value, multiple]
  );

  const activeObjects = useMemo<Item[]>(
    () =>
      activeValues
        .map((selectedValue) =>
          options.find(
            ({ value: newValue }) => String(newValue) === String(selectedValue)
          )
        )
        .filter(Boolean) as Item[],
    [activeValues, options]
  );

  const isMobileDevice = isMobile();
  const router = useRouter();

  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  return (
    <Menu as="div" className="flex grow relative">
      <MenuButton
        className={classNames(
          'flex items-center justify-between grow group relative',
          buttonClassNames ?? ''
        )}
        onClick={(e) => {
          if (isMobileDevice) {
            e.preventDefault();

            e.stopPropagation();
          }
        }}
      >
        <div className={inputClassNames}>
          <span
            className={`truncate ${
              activeObjects.length > 0 || label ? '' : 'opacity-50'
            }`}
          >
            {label ??
              (activeObjects.length > 0
                ? activeObjects.length > 2
                  ? `${String(
                      activeObjects[0].displayName ?? activeObjects[0].label
                    )} and ${activeObjects.length - 1} more`
                  : activeObjects
                      .map(
                        (activeObject) =>
                          activeObject.displayName ?? activeObject.label
                      )
                      .join(', ')
                : placeholder)}
          </span>
        </div>
        <div
          className={classNames(
            'p-2 flex items-center justify-center rounded-r-md',
            arrowClassNames
          )}
        >
          <FiChevronDown
            className="h-5 w-5 transition-all group-data-[open]:rotate-180"
            aria-hidden="true"
          />
        </div>
        {isMobileDevice && (
          <select
            name={name}
            multiple={multiple}
            value={activeValues}
            onChange={(e) => {
              const item = options.find((i) => i.value === e.target.value);
              if (item?.isInternalLink) {
                router.history.push(item.value as To);
              } else if (item?.isExternalLink) {
                window.location.href = item.value as string;
              } else if (item?.onClick) {
                item.onClick();
              } else if (onChange && item) {
                onChange(item);
              }
            }}
            className="appearance-none absolute h-full w-full opacity-0"
            required={required}
          >
            <option value="" disabled>
              Select an option
            </option>
            {options.map((option) => (
              <option
                key={option.value}
                value={option.value}
                selected={
                  Array.isArray(value)
                    ? value.includes(String(option.value))
                    : value === option.value
                }
              >
                {option.label}
              </option>
            ))}
          </select>
        )}
      </MenuButton>
      <MenuItems
        anchor={{
          to: 'bottom start',
          gap: 12,
          padding: 12,
        }}
        className="rounded-md bg-white shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col min-w-[--button-width] shadow-xl"
      >
        {multiple && (
          <div className="p-3 font-bold text-sm border-b border-black/20 flex justify-between">
            <span>Select options</span>
          </div>
        )}
        {canSearch && (
          <div className="p-3 border-b border-black/20">
            <Input
              name="search"
              useFormik={false}
              value={searchTerm}
              placeholder="Search..."
              Icon={FiSearch}
              containerClassName="w-full"
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onInput={(e) => {
                setSearchTerm(e.currentTarget.value);
              }}
              autoFocus
            />
          </div>
        )}
        <div className="overflow-y-scroll overflow-x-hidden w-full">
          {options
            .filter(
              (o) =>
                !searchTerm ||
                o.label
                  ?.toString()
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
            )
            .map((item) => (
              <MenuItem key={item.value}>
                {({ close }) => (
                  <>
                    {item.isExternalLink ? (
                      <a
                        href={item.value as string}
                        className={classNames(
                          activeValues.includes(String(item.value)) && !multiple
                            ? 'bg-red/10'
                            : '',
                          'w-full group/checkbox px-5 py-4 text-sm flex-grow text-left hover:bg-red/10 flex items-center last:mb-0 last:border-b-0 border-b border-black/20  disabled:bg-lightGray/20 disabled:text-lightGray'
                        )}
                        onClick={() => {
                          if (item.closeOnClick) {
                            close();
                          }
                          if (item.onClick) {
                            item.onClick();
                          }
                        }}
                      >
                        <span className="leading-relaxed whitespace-nowrap">
                          {item.label}
                        </span>
                      </a>
                    ) : item.isInternalLink ? (
                      <Link
                        to={item.value as string}
                        onClick={() => {
                          if (item.closeOnClick) {
                            close();
                          }
                          if (item.onClick) {
                            item.onClick();
                          }
                        }}
                        preload={false}
                        className={classNames(
                          activeValues.includes(String(item.value)) && !multiple
                            ? 'bg-red/10'
                            : '',
                          'w-full group/checkbox px-5 py-4 text-sm flex-grow text-left hover:bg-red/10 flex items-center last:mb-0 last:border-b-0 border-b border-black/20  disabled:bg-lightGray/20 disabled:text-lightGray'
                        )}
                      >
                        <span className="leading-relaxed whitespace-nowrap">
                          {item.label}
                        </span>
                      </Link>
                    ) : (
                      <Button
                        type="button"
                        onClick={() => {
                          if (
                            !!item.closeOnClick ||
                            (value && value === item.value)
                          ) {
                            close();
                          }
                          if (item.onClick) {
                            item.onClick();
                          } else if (onChange) {
                            onChange(item);
                          }
                        }}
                        className={classNames(
                          activeValues.includes(String(item.value)) && !multiple
                            ? 'bg-red/10'
                            : '',
                          'w-full group/checkbox px-5 py-4 text-sm flex-grow text-left hover:enabled:bg-red/10 flex items-center justify-between last:mb-0 last:border-b-0 border-b border-black/20  disabled:bg-lightGray/20 disabled:text-lightGray'
                        )}
                      >
                        {multiple ? (
                          <Checkbox
                            checked={activeValues.includes(String(item.value))}
                            label={item.label}
                            useFormik={false}
                            reverse
                            grow
                          />
                        ) : (
                          <>
                            <span className="leading-relaxed whitespace-nowrap">
                              {item.label}
                            </span>
                            {!!value && value === item.value && (
                              <div className="border rounded-full w-4 h-4 flex items-center justify-center border-red ml-5">
                                <div className="w-2 h-2 rounded-full bg-red" />
                              </div>
                            )}
                          </>
                        )}
                      </Button>
                    )}
                  </>
                )}
              </MenuItem>
            ))}
        </div>
        {multiple && (
          <MenuItem>
            {({ close }) => (
              <div className="bg-white grow p-3 flex justify-between items-center">
                <div className="px-1 w-1/2">
                  <Button
                    onClick={() =>
                      options.map(
                        (i) =>
                          !activeValues.includes(String(i.value)) &&
                          onChange?.(i)
                      )
                    }
                    className={classNames(elements.button.secondary, 'w-full')}
                  >
                    Select all
                  </Button>
                </div>
                <div className="px-1 w-1/2">
                  <Button
                    className={classNames(elements.button.primary, 'w-full')}
                    onClick={() => {
                      close();
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            )}
          </MenuItem>
        )}
      </MenuItems>
    </Menu>
  );
}

export default function Dropdown<T extends Item>(
  props: Props<T> & {
    useFormik?: boolean;
  }
) {
  if (!props.useFormik) {
    return <DropdownInner {...props} />;
  }

  return (
    <Field
      name={props.name}
      as="div"
      component={({
        field,
      }: {
        field: {
          name: string;
          value: string;
          onChange: (v: {
            target: {
              value: string | number;
              name: string;
            };
          }) => void;
        };
      }) => (
        <DropdownInner
          {...props}
          name={field.name}
          value={field.value}
          onChange={(item) => {
            field.onChange({
              target: {
                value: item.value,
                name: field.name,
              },
            });

            props.onChange?.(item);
          }}
        />
      )}
    />
  );
}
