import { FiLock, FiMail } from 'react-icons/fi';
import { elements } from '../utility/styles';
import classNames from 'classnames';
import { Link } from '@tanstack/react-router';
import { useMutation } from '@apollo/client';
import { GqlSignInMutation } from '@monorepo/graphql/resources';
import { writeApiToken } from '../utility/authentication';
import { toaster } from '../utility/toast';
import { Form, Formik } from 'formik';
import PageLoader from './PageLoader';
import Input from './Input';
import Checkbox from './Checkbox';
import { Button } from './Button';

export const LoginFields = ({
  onLogin,
  hideIcons,
  fullWidthButton,
}: {
  onLogin?: () => void;
  hideIcons?: boolean;
  fullWidthButton?: boolean;
}) => {
  const [loginMutation, { loading }] = useMutation(GqlSignInMutation, {
    onCompleted: (data) => {
      writeApiToken(data.signIn.jwt);

      toaster.success(
        {
          title: 'Welcome Back!',
          text: 'You have successfully logged in.',
        },
        { autoClose: 5000 }
      );

      setTimeout(() => onLogin?.(), 10);
    },
  });

  return (
    <Formik
      initialValues={{ email: '', password: '', remember: false }}
      validate={(values) => {
        const errors: Record<string, string> = {};
        if (!values.email) {
          errors.email = 'Required';
        }
        if (!values.password) {
          errors.password = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, props) => {
        await loginMutation({
          variables: {
            input: {
              email: values.email,
              password: values.password,
              remember: values.remember,
            },
          },
        });

        props.resetForm({
          values: {
            email: '',
            password: '',
            remember: false,
          },
        });
      }}
    >
      <Form className="flex-grow flex-col w-full relative">
        <Input
          label="Email address"
          Icon={!hideIcons ? FiMail : undefined}
          type="email"
          name="email"
          required
          autoFocus
          containerClassName="mb-5"
        />
        <Input
          label="Password"
          Icon={!hideIcons ? FiLock : undefined}
          type="password"
          name="password"
          required
          containerClassName="mb-3"
        />
        <div className="mb-8 flex items-center justify-between -mx-2">
          {!fullWidthButton && (
            <div className="px-2">
              <Checkbox
                label="Remember me"
                name="remember"
                id="rememberMe"
                labelClassName="text-sm"
              />
            </div>
          )}
          <div className="px-2">
            <Link to="/login/reset-password" className="text-red text-sm block">
              Forgotten password?
            </Link>
          </div>
        </div>
        <div
          className={classNames(
            'flex items-center grow',
            fullWidthButton ? 'mb-4' : ''
          )}
        >
          <Button
            type="submit"
            className={classNames(
              elements.button.primary,
              fullWidthButton ? 'w-full' : 'mr-5'
            )}
          >
            Log In
          </Button>
        </div>
        {fullWidthButton && (
          <Checkbox
            label="Remember me"
            name="remember"
            id="rememberMe"
            labelClassName="text-sm"
          />
        )}
        {loading && <PageLoader />}
      </Form>
    </Formik>
  );
};
