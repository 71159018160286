import LoginConfirmResetPassword from '../../../../components/LoginConfirmResetPassword';
import { doQuery } from '../../../../utility/apollo';
import { GqlVerifyResetPassword } from '@monorepo/graphql/resources';
import { createFileRoute, notFound } from '@tanstack/react-router';

const ConfirmPasswordComponent = () => {
  const { token, user } = Route.useLoaderData();

  return <LoginConfirmResetPassword token={token} user={user} />;
};

export const Route = createFileRoute(
  '/_unauthenticated/login/confirm-password/$token'
)({
  loader: async ({ params }) => {
    try {
      const { data } = await doQuery({
        query: GqlVerifyResetPassword,
        variables: {
          token: params.token,
        },
      });

      return {
        token: params.token,
        user: data.verifyResetPasswordToken,
      };
    } catch (error) {
      throw notFound();
    }
  },
  component: ConfirmPasswordComponent,
});
