import EditOrder from '../../../../components/EditOrder';
import NotFound from '../../../../components/NotFound';
import { doQuery } from '../../../../utility/apollo';
import { GqlReadOrder } from '@monorepo/graphql/resources';
import { createFileRoute, notFound } from '@tanstack/react-router';

export const loader = async ({ params }: { params: { orderUuid: string } }) => {
  try {
    const { data } = await doQuery({
      query: GqlReadOrder,
      variables: {
        uuid: params.orderUuid,
      },
    });

    return { order: data.readOrder };
  } catch (error) {
    throw notFound();
  }
};

const EditOrderComponent = () => {
  const { order } = Route.useLoaderData();

  return <EditOrder order={order} />;
};

export const Route = createFileRoute('/_authenticated/admin/orders/$orderUuid')(
  {
    loader,
    errorComponent: () => <NotFound to="/admin/orders" />,
    component: EditOrderComponent,
  }
);
