import { PaymentElement } from '@stripe/react-stripe-js';
import { useFormikContext } from 'formik';
import { elements } from '../utility/styles';
import { PaymentMethodEnum } from '@monorepo/graphql';
import { Button } from './Button';

const PaymentMethod = () => {
  const { values } = useFormikContext<{ paymentMethodId: string }>();

  return (
    <>
      {values.paymentMethodId === PaymentMethodEnum.card && (
        <div className="mb-8">
          <PaymentElement
            options={{
              layout: {
                type: 'auto',
                defaultCollapsed: false,
              },
            }}
          />
        </div>
      )}
      <Button
        type="submit"
        className={elements.button.primary}
        hotjarEvent="Checkout_PlaceOrder"
      >
        Place Order
      </Button>
    </>
  );
};

export default PaymentMethod;
