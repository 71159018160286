import { Currency } from '@prisma/client';
import {
  CurrencyEnum,
  NoteTypeEnum,
  ShippingMethodEnum,
} from '../__generated__/graphql';

const currencies = {
  [CurrencyEnum.chf]: 'CHF',
  [CurrencyEnum.eur]: '€',
  [CurrencyEnum.usd]: '$',
  [CurrencyEnum.gbp]: '£',
};

export const currencyLabels = {
  [CurrencyEnum.chf]: 'CHF',
  [CurrencyEnum.eur]: 'EUR',
  [CurrencyEnum.usd]: 'USD',
  [CurrencyEnum.gbp]: 'GBP',
};

export const shippingMethodLabels = {
  [ShippingMethodEnum.international]: 'International',
  [ShippingMethodEnum.uk]: 'ABLE Standard UK',
  [ShippingMethodEnum.collect]: 'Ex-Works',
};

export const defaultShippingValues = {
  [ShippingMethodEnum.international]: 0,
  [ShippingMethodEnum.uk]: 16,
  [ShippingMethodEnum.collect]: 0,
};

export const noteTypeLabels = {
  [NoteTypeEnum.freeText]: 'Message',
  [NoteTypeEnum.notification]: 'Notification',
  [NoteTypeEnum.statusChange]: 'Status Change',
};

export const formatMoney = (
  amount: number,
  currency: CurrencyEnum | Currency
) => {
  const currencySymbol = currencies[currency] || currency;

  return `${0 > amount ? '-' : ''}${currencySymbol}${Math.abs(amount)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};
